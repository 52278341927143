import React, { useContext } from 'react';
import cx from 'classnames';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';
import { LocalizationContext } from 'Services/LocalizationService';
import { DeviceServiceContext } from 'Services/DeviceService';

import styles from './styles.module.css';

export interface RadioOptionProps {
	title: string;
	description: string;
	price: number;
	value: string;
	groupName: string;
	checked: boolean;
	onChange: (bundleId: string) => void;
}

const RadioOption = ({
	title,
	description,
	price,
	value,
	groupName,
	onChange,
	checked,
}: RadioOptionProps) => {
	const { useFormatMessage, activeCurrency } = useContext(LocalizationContext);
	const t = useFormatMessage();

	const handleChange = () => {
		onChange(value);
	};

	const { isDesktop } = useContext(DeviceServiceContext);

	if (!isDesktop) {
		return (
			<label
				htmlFor={value ?? ''}
				className={styles.radioLabel}
			>
				<div className={styles.radioInputWrapper}>
					<div className={styles.radioOuterCircle}>
						<input
							type="radio"
							id={value ?? ''}
							name={groupName}
							value={value ?? ''}
							className={styles.radioInput}
							checked={checked}
							onChange={handleChange}
						/>
						<div className={styles.radioInnerCircle} />
					</div>
				</div>
				<div className={styles.radioTitleGroup}>
					<strong className={styles.radioTitle}>{title}</strong>
					<div
						className={styles.description}
						dangerouslySetInnerHTML={{
							__html: description,
						}}
					/>
				</div>
				<strong
					className={cx(styles.price, {
						[styles.green]: price === 0,
						[styles.red]: price > 0,
					})}
				>
					{price
						? getLocalizedPrice({ price, currency: activeCurrency.currency })
						: t({ id: 'basket.price.free' })}
				</strong>
			</label>
		);
	}

	return (
		<label
			htmlFor={value}
			className={styles.radioLabel}
		>
			<div className={styles.radioHeader}>
				<div className={styles.radioTitleGroup}>
					<div className={styles.radioInputWrapper}>
						<div className={styles.radioOuterCircle}>
							<input
								type="radio"
								id={value}
								name={groupName}
								value={value}
								className={styles.radioInput}
								checked={checked}
								onChange={handleChange}
							/>
							<div className={styles.radioInnerCircle} />
						</div>
					</div>
					<strong className={styles.radioTitle}>{title}</strong>
				</div>
				<strong
					className={cx(styles.price, {
						[styles.green]: price === 0,
						[styles.red]: price > 0,
					})}
				>
					{price
						? getLocalizedPrice({ price, currency: activeCurrency.currency })
						: t({ id: 'basket.price.free' })}
				</strong>
			</div>
			<div
				className={styles.description}
				dangerouslySetInnerHTML={{
					__html: description,
				}}
			/>
		</label>
	);
};

export default RadioOption;
