import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import FacebookPixelLoader from 'App/FacebookPixelLoader';
import PreloaderProvider from 'Components/Preloader/context';
import DocumentHeaderContextProvider from 'Components/DocumentHeader/context';
import WarehouseServiceContextProvider from 'Services/WarehouseService';
import EnvironmentServiceProvider from 'Services/EnvironmentProvider/context';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isPrerender } from 'Helpers/prerender';
import Config from 'Config';
import LocalizationServiceProvider from 'Services/LocalizationService';
import AuthenticationProvider from 'Services/AuthenticationService/context';
import NotificationsServiceProvider from 'Services/NotificationService';
import RoutingService from 'Services/RoutingService';
import StorageServiceProvider from 'Services/StorageService/context';
import DeviceServiceProvider from 'Services/DeviceService';
import FavoritesServiceProvider from 'Services/FavoritesService';
import OrderServiceProvider from 'Services/OrderService/context';
import Layout from 'Pages/Layout';
import ModalProvider from 'Components/Modal/context';

import './styles.css';
import EhubLoader from './EHubLoader';
import ChatboxLoader from './chat-loader';

const queryClient = new QueryClient({
	defaultOptions: Config.queryClient ?? {},
});

const VinistoEshopApp = () => {
	return (
		<div>
			<FacebookPixelLoader />
			<EnvironmentServiceProvider>
				<BrowserRouter>
					<WarehouseServiceContextProvider>
						<PreloaderProvider>
							<QueryClientProvider client={queryClient}>
								<DeviceServiceProvider>
									<StorageServiceProvider>
										<LocalizationServiceProvider>
											<DocumentHeaderContextProvider>
												<NotificationsServiceProvider>
													<ModalProvider>
														<AuthenticationProvider>
															{!isPrerender() && <ChatboxLoader />}
															<FavoritesServiceProvider>
																<OrderServiceProvider>
																	<EhubLoader />
																	<Layout>
																		<RoutingService />
																	</Layout>
																</OrderServiceProvider>
															</FavoritesServiceProvider>
														</AuthenticationProvider>
													</ModalProvider>
												</NotificationsServiceProvider>
											</DocumentHeaderContextProvider>
										</LocalizationServiceProvider>
									</StorageServiceProvider>
								</DeviceServiceProvider>
								<ReactQueryDevtools
									initialIsOpen={false}
									position="top-left"
								/>
							</QueryClientProvider>
						</PreloaderProvider>
					</WarehouseServiceContextProvider>
				</BrowserRouter>
			</EnvironmentServiceProvider>
		</div>
	);
};

export default VinistoEshopApp;
