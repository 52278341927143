import { apiServiceInstance } from 'Services/ApiService';
import {
	UsersAddressesDeleteParams,
	UsersAddressesDetailParams,
	UsersBillingInformationDeleteParams,
	UsersBillingInformationDetailParams,
	VinistoAuthDllModelsApiAddressUserAddressCreateParameters,
	VinistoAuthDllModelsApiAddressUserAddressEditParameters,
	VinistoAuthDllModelsApiAddressUserAddressesReturn,
	VinistoAuthDllModelsApiAddressUserAddressReturn,
	VinistoAuthDllModelsApiBillingInfoUserBillingInfoCreateParameters,
	VinistoAuthDllModelsApiBillingInfoUserBillingInfoEditParameters,
	VinistoAuthDllModelsApiBillingInfoUserBillingInfoReturn,
	VinistoAuthDllModelsApiBillingInfoUserBillingInfosReturn,
} from 'vinisto_api_client/src/api-types/user-api';

export const AddressesApi = {
	getAll: ({ userId, ...params }: UsersAddressesDetailParams) =>
		apiServiceInstance.get(
			`user-api/users/${userId}/addresses`,
			true,
			undefined,
			Object.entries(params)?.map(([key, value]) => ({
				key,
				value,
			}))
		) as Promise<VinistoAuthDllModelsApiAddressUserAddressesReturn>,
	create: ({
		userId,
		...requestData
	}: VinistoAuthDllModelsApiAddressUserAddressCreateParameters & {
		userId: string;
	}) =>
		apiServiceInstance.post(
			`user-api/users/${userId}/addresses`,
			requestData,
			true
		) as Promise<VinistoAuthDllModelsApiAddressUserAddressReturn>,
	update: ({
		userId,
		addressId,
		...requestData
	}: VinistoAuthDllModelsApiAddressUserAddressEditParameters & {
		userId: string;
		addressId: string;
	}) =>
		apiServiceInstance.put(
			`user-api/users/${userId}/addresses/${addressId}`,
			requestData,
			true
		) as Promise<VinistoAuthDllModelsApiAddressUserAddressReturn>,
	delete: ({ userId, addressId, ...params }: UsersAddressesDeleteParams) =>
		apiServiceInstance.delete(
			`user-api/users/${userId}/addresses/${addressId}`,
			undefined,
			true,
			Object.entries(params)?.map(([key, value]) => ({ key, value }))
		),
};

export const BillingInfoApi = {
	getAll: ({ userId, ...params }: UsersBillingInformationDetailParams) =>
		apiServiceInstance.get(
			`user-api/users/${userId}/billing-information`,
			true,
			undefined,
			Object.entries(params)?.map(([key, value]) => ({
				key,
				value,
			}))
		) as Promise<VinistoAuthDllModelsApiBillingInfoUserBillingInfosReturn>,
	create: ({
		userId,
		...requestData
	}: VinistoAuthDllModelsApiBillingInfoUserBillingInfoCreateParameters & {
		userId: string;
	}) =>
		apiServiceInstance.post(
			`user-api/users/${userId}/billing-information`,
			requestData,
			true
		) as Promise<VinistoAuthDllModelsApiBillingInfoUserBillingInfoReturn>,
	update: ({
		userId,
		billingInfoId,
		...requestData
	}: VinistoAuthDllModelsApiBillingInfoUserBillingInfoEditParameters & {
		userId: string;
		billingInfoId: string;
	}) =>
		apiServiceInstance.put(
			`user-api/users/${userId}/billing-information/${billingInfoId}`,
			requestData,
			true
		) as Promise<VinistoAuthDllModelsApiBillingInfoUserBillingInfoReturn>,
	delete: ({
		userId,
		billingInfoId,
		...params
	}: UsersBillingInformationDeleteParams) =>
		apiServiceInstance.delete(
			`user-api/users/${userId}/billing-information/${billingInfoId}`,
			undefined,
			true,
			Object.entries(params)?.map(([key, value]) => ({ key, value }))
		),
};
