import { FC, useContext, useEffect } from 'react';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { LocalizationContext } from 'Services/LocalizationService';
import ContainerFullWidth from 'Components/View/ContainerFullWidth';
import Card from 'Components/View/Card';
import Config from 'Config';

const TermsAndConditions: FC = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const documentHeaderContext = useContext(DocumentHeaderContext);

	useEffect(() => {
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.termsAndConditions.name' })}` }
			)}`,
		});
	}, []);

	return (
		<section id="content-wrapper">
			<ContainerFullWidth>
				<Card>
					<div className="vinisto-font-18">
						<h2 className="text-center">Všeobecné obchodní podmínky</h2>
						<p className="text-center fw-bolder">účinné od 1. 11. 2022</p>
						<p className="text-center">
							přijaté níže uvedeného dne, měsíce a roku
						</p>
						<p>
							Obchodní společnost <strong>vinisto s.r.o.</strong>, IČO: 087 46
							893, sídlem Jankovcova 1057/6, Holešovice, 170 00 Praha 7,
							společnost zapsaná v OR MS v Praze, sp. zn. C 324492 (dále jen „
							<strong>prodávající</strong>“), vydává tímto v souladu se zákonem
							č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů
							(dále jen „<strong>NOZ</strong>“) a zákonem č. 634/1992 Sb., o
							ochraně spotřebitele, ve znění pozdějších předpisů, tyto Všeobecné
							obchodní podmínky (dále jen „<strong>VOP</strong>“).
						</p>
						<p className="text-center fw-bolder mb-1">I.</p>
						<p className="text-center fw-bolder">
							<u>Účel VOP</u>
						</p>
						<p>
							1) Účelem těchto VOP je upravit práva a povinnosti mezi
							prodávajícím a jednotlivými kupujícími při uzavírání kupních
							smluv, jejichž předmětem koupě je zboží nabízené prodávajícím v
							rámci jeho internetového obchodu provozovaného na internetových
							stránkách www.vinisto.cz
						</p>
						<p>
							2) K návštěvě e-shopu a otevření jeho prezentace je oprávněna
							pouze fyzická osoba, jež dovršila 18 let věku. Dovršení věku 18
							let zákazník vždy potvrdí před otevřením prezentace e-shopu
							kliknutím na políčko „Bylo mi 18 přijímám všechny Cookies“, které
							se vždy zobrazí před otevřením prezentace e-shopu s upozorněním na
							požadavek dovršení věku 18 let.
						</p>
						<p>
							3) Ustanovení těchto VOP se vztahují na kupní smlouvy uzavírané
							mezi prodávajícím jako podnikatelem a kupujícími jako
							spotřebiteli. Pro případ kupních smluv uzavíraných mezi
							prodávajícím a kupujícími, kteří nejsou spotřebiteli:
						</p>
						<div>
							<ol>
								<li>
									budou aplikována pouze následující ustanovení těchto VOP: čl.
									I. VOP, čl. II. odst. 1) a 2) VOP, čl. III. VOP, čl. IV. VOP,
									čl. V. až čl. IX. VOP, čl. XII. odst. 10) VOP, čl. XIV. odst.
									2) až 5) VOP;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									platí, že ustanovení těchto VOP, v rozsahu, v jakém se
									použijí, jsou nedílnou součástí uzavřené kupní smlouvy;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									platí, že ustanovení tohoto odstavce odlišné od VOP a písemné
									ujednání mezi smluvními stranami odlišné od VOP má před těmito
									VOP přednost;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									platí, že v případě rozhodování sporů vznikajících z kupní
									smlouvy uzavřené mezi prodávajícím a kupujícími, kteří jsou
									podnikateli, a s kupní smlouvou souvisejících bude místně
									příslušným soudem prvního stupně Obvodní soud pro Prahu 10,
									je-li dána věcná příslušnost okresního soudu, a Městský soud v
									Praze, je-li dána věcná příslušnost krajského soudu;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									u případných dárků podle čl. IV. odst. 15) těchto VOP není
									kupující oprávněn uplatňovat žádná práva z vadného plnění;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									budou v ostatních záležitostech aplikovány obecné právní
									předpisy, zejména NOZ.
								</li>
							</ol>
						</div>
						<p>
							4) Pro vyloučení pochybností platí, že každá osoba identifikující
							se vůči prodávajícímu svým IČO se považuje za podnikatele ve
							smyslu § 420 NOZ.
						</p>
						<p className="text-center fw-bolder mb-1">II.</p>
						<p className="text-center fw-bolder">
							<u>Informace o prodávajícím</u>
						</p>
						<p>
							<strong></strong>
						</p>
						<p>1) Základní údaje o prodávajícím:</p>
						<p>
							obchodní firma: <strong>vinisto s.r.o.</strong>
						</p>
						<p>
							IČO: <strong>087 46 893</strong>
						</p>
						<p>
							DIČ: <strong>CZ08746893</strong>
							<br /> sídlo:{' '}
							<strong>Jankovcova 1057/6, Holešovice, 170 00 Praha 7</strong>
						</p>
						<p>
							rejstříkový soud:{' '}
							<strong>Městský soud v Praze, oddíl C, vložka </strong>
							<strong>324492</strong>{' '}
						</p>
						<p>
							číslo bankovního účtu (Kč): {Config.market.bankAccount}, číslo
							bankovního účtu (€): {Config.market.bankAccountEUR}, adresa pro
							doručování elektronické pošty: info@vinisto.cz
						</p>
						<p>
							2) Kontaktní údaje jako poštovní adresa pro doručování, e-mail a
							telefonní čísla jsou dále uvedeny na internetových stránkách
							www.vinisto.cz v záložce „/kontaktujte-nas“.
						</p>
						<p>
							3) Prodávající není ve vztahu ke kupujícímu vázán žádnými kodexy
							chování ve smyslu ustanovení § 1826 odst. 1), písm. e) NOZ.
						</p>
						<p className="text-center fw-bolder mb-1">III.</p>
						<p className="text-center fw-bolder">
							<u>Použité pojmy</u>
						</p>
						<p>Pro účely těchto VOP platí, že:</p>
						<div>
							<ol>
								<li>
									spotřebitelem je každý člověk, který mimo rámec své
									podnikatelské činnosti nebo mimo rámec samostatného výkonu
									svého povolání uzavírá smlouvu s podnikatelem, tzn. pro případ
									těchto VOP s prodávajícím nebo s ním jinak jedná. Pro
									vyloučení pochybností platí, že každá osoba identifikující se
									vůči prodávajícímu svým IČO se považuje za podnikatele ve
									smyslu § 420 NOZ;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>smluvními stranami jsou prodávající a kupující;</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									prostředkem komunikace na dálku je takový prostředek, který
									umožní uzavřít kupní smlouvu bez současné fyzické přítomnosti
									smluvních stran;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									internetovým obchodem prodávajícího je místo provozované na
									internetových stránkách www.vinisto.cz, které umožňuje
									uzavření kupní smlouvy mezi smluvními stranami;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									zbožím jsou produkty nabízené prodávajícím v rámci
									internetového obchodu, které jsou předmětem koupě podle kupní
									smlouvy;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									kupní smlouvou je smluvní vztah mezi smluvními stranami, ze
									kterého vznikne prodávajícímu povinnost odevzdat kupujícímu
									jím poptané zboží a kupujícímu povinnost toto zboží převzít a
									zaplatit za něj prodávajícímu kupní cenu.
								</li>
							</ol>
						</div>
						<p className="text-center fw-bolder mb-1">IV.</p>
						<p className="text-center fw-bolder">
							<u>Kupní smlouva</u>
						</p>
						<p>
							<strong></strong>
						</p>
						<p>
							1) Kupní smlouva je mezi smluvními stranami uzavírána prostředkem
							komunikace na dálku, kterým je internetový obchod prodávajícího.
						</p>
						<p>
							2) Kupní smlouva je uzavírána tak, že kupující v rámci
							internetového obchodu prodávajícího vybere zboží a jím vybrané
							zboží vloží do košíku, resp. nákupního košíku a podle jednotlivých
							kroků (krok 1 až krok 10) uvedených na internetových stránkách
							www.vinisto.cz v rámci objednávkového formuláře zadá požadované
							údaje a zboží závazně objedná. Postup učinění objednávky je
							následující:
						</p>
						<div className="ps-4">
							<p>
								<em>Krok 1: Vyberte zboží, které si chcete zakoupit</em>
							</p>
							<p>
								<em>
									Krok 2: Vybrané zboží pomocí tlačítka „Do košíku“ vložte do
									košíku
								</em>
							</p>
							<p>
								<em>
									Krok 3: V Nákupním košíku najdete všechny Vámi vybrané
									produkty
								</em>
							</p>
							<p>
								<em>
									Krok 4: Zadejte kód Slevového poukazu, pokud jej máte k
									dispozici.
								</em>
							</p>
							<p>
								<em>Krok 5: Zvolte způsob dopravy zboží (způsob doručení)</em>
							</p>
							<p>
								<em>
									Krok 6: Zvolte způsob platby za zboží (výběr způsobu úhrady)
								</em>
							</p>
							<p>
								<em>
									Krok 7: Zvolte, zdali budete nakupovat s registrací (v takovém
									případě doplňte Vaše registrační údaje a přihlaste se, či
									proveďte novou registraci) či bez registrace
								</em>
							</p>
							<p>
								<em>
									Krok 8: Zadejte fakturační adresu, případně adresu dodací,
									případně nakupujete-li jako podnikatel uveďte své
									podnikatelské údaje (firma, IČO, DIČ)
								</em>
							</p>
							<p>
								<em>
									Krok 9: Vyslovte souhlas s těmito VOP zaškrtnutím políčka v
									objednávkovém formuláři
								</em>
							</p>
							<p>
								<em>
									Krok 10: Dokončete objednávku kliknutím na pole „Dokončit
									objednávku“.
								</em>
							</p>
						</div>
						<p>
							3) Objednáním zboží potvrzuje kupující, že se seznámil s těmito
							VOP, že je jimi vázán, a že souhlasí s uzavřením kupní smlouvy
							užitím prostředku komunikace na dálku.{' '}
						</p>
						<p>
							4) Objednávka kupujícího podle odst. 2) je považována za platnou,
							obsahuje-li veškeré předepsané údaje tak, jak jsou uvedeny v
							objednávkovém formuláři.
						</p>
						<p>
							5) Učiněná objednávka musí být akceptována prodávajícím.
							Prodávající není povinen objednávku kupujícího akceptovat, a to i
							bez uvedení důvodu. Akceptace prodávajícího nabývá účinnosti a
							kupní smlouva je uzavřena a účinná okamžikem, kdy jeho akceptace
							obsahu objednávky dojde kupujícímu. Akceptace obsahu objednávky
							prodávajícím bude učiněna zpravidla emailem na emailovou adresu
							uvedenou kupujícím v objednávce podle odst. 2) a obsahuje zejména
							potvrzení o učinění objednávky a její shrnutí, které obsahuje
							zejména označení kupujícího, označení prodávajícího, označení
							zboží, uvedení kupní ceny zboží a dalších plateb kupujícího
							(dopravné, apod.), uvedení zvoleného způsobu dopravy, úhrady kupní
							ceny zboží, apod. a jsou k ní připojeny tyto VOP. Akceptace obsahu
							objednávky prodávajícím může být taktéž prodávajícím provedena v
							listinné podobě, telefonicky, předáním zboží kupujícímu, nebo
							jiným prokazatelným způsobem. Tímto není dotčen odst. 6).{' '}
						</p>
						<p>
							6) Prodávající si vyhrazuje, že v jím určených případech může
							podmínit uzavření kupní smlouvy uhrazením zálohy na úhradu kupní
							ceny kupujícím nebo může po uzavření kupní smlouvy žádat o
							uhrazení zálohy na úhradu kupní ceny kupujícím, a to ve lhůtě
							stanovené prodávajícím. Nedojde-li k úhradě zálohy řádně a včas,
							je prodávající oprávněn odmítnout učiněnou objednávku a byla-li
							již uzavřena kupní smlouva, je oprávněn od kupní smlouvy
							odstoupit. Takto může být postupováno zejména v případech většího
							objemu objednaného zboží, vyšší kupní ceny zboží, u dražšího
							zboží, apod. O lhůtu k uhrazení zálohy se prodlužuje lhůta pro
							akceptaci či neakceptaci objednávky, případně lhůta pro dodání
							zboží.
						</p>
						<p>
							7) Prodávající je oprávněn vyžádat si od kupujícího potvrzení
							pravosti a pravdivosti údajů uvedených na jeho objednávce, tzn.
							autorizaci objednávky kupujícího, na základě které budou ověřeny
							údaje v objednávce, a to zejména formou písemné listovní zásilky,
							telefonicky nebo e-mailem. Nebude-li kupující reagovat na žádost
							prodávajícího o autorizaci objednávky do deseti (10) pracovních
							dnů ode dne učinění žádosti prodávajícího o autorizaci objednávky,
							nebo odmítne-li kupující objednávku autorizovat, tzn. potvrdit
							pravost a pravdivost všech údajů na ní, je prodávající oprávněn
							odmítnout učiněnou objednávku a byla-li již uzavřena kupní
							smlouva, je oprávněn od kupní smlouvy odstoupit. O lhůtu k
							potvrzení pravosti a pravdivosti údajů se prodlužuje lhůta pro
							akceptaci či neakceptaci objednávky, případně lhůta pro dodání
							zboží.
						</p>
						<p>
							8) Kupující je oprávněn jednostranně zrušit svou objednávku do
							dvaceti čtyř (24) hodin od okamžiku, kdy tuto svou objednávku vůči
							prodávajícímu učinil. Zrušení objednávky provede kupující tak, že
							oznámení o zrušení objednávky ve lhůtě uvedené v předchozí větě
							odešle na elektronickou adresu prodávajícího uvedenou v čl. II.
							odst. 1) těchto VOP.
						</p>
						<p>
							9) Náklady na použití komunikačních prostředků na dálku, které
							kupujícímu vzniknou v souvislosti s uzavřením kupní smlouvy (např.
							náklady na připojení k síti INTERNET), si hradí kupující sám.
							Prodávající neúčtuje kupujícímu náklady na prostředky komunikace
							na dálku.
						</p>
						<p>
							10) Ustanovení těchto VOP jsou nedílnou součástí uzavřené kupní
							smlouvy. Kopii těchto VOP obdrží kupující jako přílohu akceptace
							obsahu objednávky kupujícího prodávajícím. Smluvní strany výslovně
							vylučují, aby se na úpravu jejich práv a povinností užily obchodní
							podmínky kupujícího.
						</p>
						<p>
							11) Kupní smlouva bude po svém uzavření uložena u prodávajícího a
							je přístupná kupujícímu k jeho žádosti zaslané prodávajícímu na
							elektronickou adresu prodávajícího, která je uvedena v čl. II.
							odst. 1) těchto VOP, a to nejdéle po dobu 48 měsíců ode dne
							účinnosti kupní smlouvy. Po přihlášení do svého zaregistrovaného
							uživatelského profilu (účtu) má registrovaný kupující možnost
							vstoupit do přehledu Historie objednávek, kde si registrovaný
							kupující po dobu existence svého uživatelského profilu může
							kdykoliv zobrazit detailní přehled všech svých uskutečněných
							objednávek.
						</p>
						<p>
							12) Kupující má možnost před odesláním objednávky zkontrolovat již
							vložená data, a pro případ, že bude třeba z jeho strany objednávku
							upravit, má možnost měnit vstupní údaje, které do objednávky
							vložil až do okamžiku, kdy objednávku ukončil a závazně objednal.
						</p>
						<p>
							13) V případě technické závady na internetovém obchodu
							prodávajícího je prodávající oprávněn odmítnout učiněnou
							objednávku a byla-li již uzavřena kupní smlouva, je oprávněn od
							kupní smlouvy odstoupit.
						</p>
						<p>
							14) Kupující bere na vědomí, že prodávající není zejména povinen
							uzavřít kupní smlouvu s osobami, které v minulosti podstatným
							způsobem porušily kupní smlouvu.
						</p>
						<p>
							15) V případě objednání některého zboží v rámci internetového
							obchodu prodávajícího může být kupujícímu nabídnuto prodávajícím
							poskytnutí dárku k objednanému zboží, a to zcela zdarma. Kupující
							má možnost v rámci objednávky zboží s poskytnutím dárku ke zboží
							vyslovit souhlas, v takovém případě mu bude dárek poskytnut spolu
							se zbožím. Neudělí-li kupující souhlas podle předchozí věty, dárek
							mu nebude poskytnut. Je-li kupujícímu společně se zbožím poskytnut
							dárek, není kupující oprávněn ve vztahu k takovému dárku
							uplatňovat práva kupujícího podle těchto VOP, a ve vztahu k
							takovému dárku se právní vztah mezi kupujícím a prodávajícím
							posuzuje podle obecně závazných právních předpisů, zejména podle
							ustanovení NOZ o darování; to neplatí, je-li v těchto VOP ve
							vztahu k dárku výslovně uvedeno jinak.
						</p>
						<p className="text-center fw-bolder mb-1">V.</p>
						<p className="text-center fw-bolder">
							<u>Zboží</u>
						</p>
						<p>
							<strong></strong>
						</p>
						<p>
							1) Předmětem koupě podle kupní smlouvy je zboží uvedené v závazné
							objednávce kupujícího učiněné podle čl. IV. odst. 2) těchto VOP.
						</p>
						<p>
							2) Popis zboží je uveden v rámci internetového obchodu. Rozměry,
							váha a ostatní údaje uvedené v internetovém obchodu vycházejí z
							údajů výrobců (dodavatelů) s tím, že výrobce (dodavatel) si vždy
							vyhrazuje právo učinit technické změny na výrobku bez předchozího
							upozornění. Všechny informace jsou uváděny podle aktuálních
							dostupných údajů od výrobce nebo jednotlivých dodavatelů zboží.
						</p>
						<p>
							3) Kupující je oprávněn se před učiněním závazné objednávky zboží
							podle čl. IV. odst. 2) těchto VOP formou e-mailové zprávy dotázat
							prodávajícího na jednotlivé údaje vztahující se ke zboží.
						</p>
						<p>
							4) Kupující bere na vědomí, že obrázky u zboží mají pouze
							orientačně informativní charakter a nemusí odpovídat skutečnému
							stavu. Shodné platí u případných dárků podle čl. IV. odst. 15)
							těchto VOP.
						</p>
						<p>
							5) Prodávající má právo v případě zlevněného (akčního) zboží
							omezit počet kusů prodávaných jednomu kupujícímu v určitém období.
							O této skutečnosti musí být kupující informováni již v popisu
							zboží v rámci internetového obchodu prodávajícího, tedy ještě před
							objednáním zboží.
						</p>
						<p>6) Vylučuje se použití § 1732 odst. 2) NOZ.</p>
						<p>
							7) Kupující je povinen řídit se při užívání, resp. aplikaci zboží
							návodem, který je přiložen ke zboží, nebo etiketou.
						</p>
						<p></p>
						<p className="text-center fw-bolder mb-1">VI.</p>
						<p className="text-center fw-bolder">
							<u>Kupní cena</u>
						</p>
						<p>
							1) Kupní cena zboží je uvedena pro každé zboží v rámci
							internetového obchodu prodávajícího. Kupní cena je členěna na
							kupní cenu bez DPH a kupní cenu včetně DPH. Kupující je povinen
							uhradit kupní cenu včetně DPH a další platby podle odst. 3).
						</p>
						<p>
							2) Kupující je povinen zaplatit za zboží kupní cenu platnou v
							okamžiku účinnosti kupní smlouvy. Do okamžiku účinnosti kupní
							smlouvy je prodávající oprávněn kdykoli a bez udání důvodu měnit
							kupní ceny uvedené pro jednotlivé položky zboží v internetovém
							obchodu prodávajícího.
						</p>
						<p>
							3) Ke kupní ceně včetně DPH budou kupujícímu dále doúčtovány
							náklady na dopravu (náklady na dodání) do místa určeného
							kupujícím, případně náklady za zvolený způsob platby, a to podle
							platných ceníků, které jsou dostupné v internetovém obchodě.
						</p>
						<p>
							4) Bude-li prodávajícím na základě kupní smlouvy vystavován daňový
							doklad (faktura), slouží tato současně jako dodací list. Kupující
							souhlasí s případným zasíláním daňových dokladů (faktur)
							elektronicky na emailovou adresu kupujícího uvedenou kupujícím v
							objednávce podle čl. IV. odst. 2) těchto VOP.
						</p>
						<p>
							5) Nebude-li prodávajícím stanoveno jinak, je podmínkou dodání
							zboží kupujícímu plné uhrazení kupní ceny.{' '}
						</p>
						<p>
							6) Je-li na zboží poskytována jakákoli sleva, nelze tuto slevu
							sčítat s jinými slevami nebo výhodami.
						</p>
						<p>
							7) Byla-li kupujícím prodávajícímu řádně uhrazena záloha na kupní
							cenu zboží, bude tato uhrazená záloha od kupní ceny zboží
							odečtena.{' '}
						</p>
						<p className="text-center fw-bolder mb-1">VII.</p>
						<p className="text-center fw-bolder">
							<u>Způsob úhrady kupní ceny</u>
						</p>
						<p>1) Kupní cenu zboží lze uhradit takto:</p>
						<div>
							<ol>
								<li>
									na dobírku, tj. v okamžiku dodání zboží přepravcem v hotovosti
									/ kartou;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									bezhotovostně platební kartou prostřednictvím platební brány;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>bezhotovostním převodem na bankovní účet prodávajícího,</li>
							</ol>
						</div>
						<p>
							bližší podmínky a postupy pro provádění jednotlivých způsobů
							úhrad, včetně případných poplatků za provádění úhrad, jsou uvedeny
							v internetovém obchodě prodávajícího v záložce „/moznosti-platby“.
						</p>
						<p>
							2) Splatnost a okamžik úhrady kupní ceny závisí na způsobu zvolené
							úhrady kupní ceny, a to takto:
						</p>
						<div>
							<ol>
								<li>
									v případě převzetí zboží v provozovně prodávajícího je kupní
									cena splatná v hotovosti / kartou v okamžiku, kdy prodávající
									umožní kupujícímu převzetí zboží v provozovně a je uhrazena
									okamžikem, kdy kupující prodávajícímu kupní cenu uhradí;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									v případě dobírky, tj. úhrady kupní ceny při osobním převzetí
									zboží kupujícím od osoby, která byla oprávněna k doručení
									zboží pro kupujícího, je kupní cena splatná v hotovosti /
									kartou v okamžiku dodání zboží, a to k rukám osoby, která byla
									oprávněna k doručení zboží pro kupujícího, a je uhrazena v
									případě platby v hotovosti předáním kupní ceny osobě, která
									byla oprávněna k doručení zboží pro kupujícího, a v případě
									platby kartou v okamžiku připsání příslušné částky na účet
									prodávajícího;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									v případě úhrady kupní ceny prostřednictvím platební brány je
									kupující přesměrován na internetové bankovnictví své banky či
									na zabezpečenou stránku pro platbu kartou. Po realizaci
									transakce předá platební brána informaci o provedení platby
									prodávajícímu, který může expedovat zboží ihned po přijetí
									potvrzení o úhradě. V případě, že nebude platba připsána na
									bankovní účet prodávajícího do pěti (5) dnů od realizace
									transakce, bude způsob úhrady kupní ceny automaticky změněn na
									dobírku (v případě dodání přepravci) či na platbu při převzetí
									(v případě osobního odběru);{' '}
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									v případě bezhotovostního převodu na bankovní účet
									prodávajícího je kupní cena splatná do pěti (5) dnů ode dne
									účinnosti kupní smlouvy a je uhrazena připsáním kupní ceny na
									bankovní účet prodávajícího.
								</li>
							</ol>
						</div>
						<p>
							3) V případě prodlení kupujícího s úhradou kupní ceny a/nebo
							jakékoli její části je prodávající oprávněn od kupní smlouvy
							odstoupit. Ustanovením předchozí věty není dotčeno právo
							prodávajícího na náhradu škody, která mu neuhrazením kupní ceny
							kupujícím vznikla.
						</p>
						<p>
							4) Podle zákona o evidenci tržeb je prodávající povinen vystavit
							kupujícímu účtenku. Zároveň je povinen zaevidovat přijatou tržbu u
							správce daně online, v případě technického výpadku pak nejpozději
							do 48 hodin.
						</p>
						<p className="text-center fw-bolder mb-1">VIII.</p>
						<p className="text-center fw-bolder">
							<u>Výhrada vlastnictví</u>
						</p>
						<p>
							Kupující nabývá vlastnické právo ke zboží po plném zaplacení kupní
							ceny.
						</p>
						<p className="text-center fw-bolder mb-1">IX.</p>
						<p className="text-center fw-bolder">
							<u>Dodání zboží</u>
						</p>
						<p>
							<strong></strong>
						</p>
						<p>1) Zboží bude kupujícímu dodáno způsobem:</p>
						<div>
							<ol>
								<li>
									odesláním zboží prostřednictvím přepravce do místa určeného
									kupujícím, kterým je místo uvedené v závazné objednávce
									kupujícího podle čl. IV. odst. 2) těchto VOP, nebo
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									osobním převzetím zboží kupujícím v provozovně prodávajícího.
								</li>
							</ol>
						</div>
						<p>
							2) Kupující je oprávněn z nabídky v rámci internetového obchodu
							zvolit způsob dodání zboží a přepravce, který dodání zboží provede
							(tímto není dotčena možnost, že prodávající může nabízet jen
							jednoho přepravce, kdy kupujícího je povinen tohoto přepravce
							akceptovat). Volbou přepravce souhlasí kupující s cenou za
							přepravu včetně DPH tak, jak je uvedena v rámci internetového
							obchodu prodávajícího. Aktuální ceník dopravy a možnosti dodání
							zboží jsou uvedeny v internetovém obchodě prodávajícího v záložce
							„/moznosti-dopravy“.{' '}
						</p>
						<p>
							3) Náklady spojené s dodáním a převzetím zboží nese, nebude-li
							prodávajícím stanoveno jinak, kupující.
						</p>
						<p>
							4) Prodávající splnil svou povinnost dodat zboží jeho předáním
							prvnímu přepravci k přepravě pro kupujícího.
						</p>
						<p>
							5) Kupující je povinen v případě odeslání zboží prostřednictvím
							přepravce zboží převzít v místě, které ve své závazné objednávce
							podle čl. IV. odst. 2) těchto VOP určil. Kupující je povinen
							poskytnout dopravci a prodávajícímu veškerou součinnost k tomu,
							aby mu bylo možné zboží dopravit a předat. Kupující je povinen
							zboží od přepravce řádně převzít, zkontrolovat neporušenost obalů,
							lepicí pásky, která slouží jako ochrana před otevřením zásilky po
							dobu přepravy, počet balíků a v případě jakýchkoliv závad toto
							neprodleně oznámit přepravci samotnému. Podpisem přepravci či
							bezvýhradným převzetím zboží od přepravce kupující stvrzuje, že
							zásilka splňovala všechny podmínky a náležitosti výše uvedené a k
							pozdější reklamaci o porušenosti obalu zásilky bude prodávající
							přistupovat na základě podepsaného doručovacího listu, ve kterém
							kupující stvrzuje neporušenost vnějšího obalu zásilky včetně
							neporušenosti originální pásky a správný počet balíků, nebo na
							základě bezvýhradného převzetí zásilky kupujícím. Neprovede-li
							kupující prohlídku zásilky, mohou mu být přiznána práva z
							odpovědnosti za vady zboží zjistitelné při této prohlídce, pouze
							pokud kupující prokáže, že tyto vady mělo zboží již v době
							přechodu nebezpečí škody na zboží na kupujícího.
						</p>
						<p>
							6) Kupující je povinen v případě osobního odběru zboží převzít
							zboží v provozovně prodávajícího. Na převzetí zboží má kupující
							deset (10) pracovních dnů ode dne odeslání vyrozumění ze strany
							prodávajícího o připravenosti zboží k odběru kupujícímu. Kupující
							je povinen při převzetí zboží zkontrolovat neporušenost obalů,
							lepicí pásky, která slouží jako ochrana před otevřením zboží,
							počet balíků a v případě jakýchkoliv závad toto neprodleně oznámit
							prodávajícímu. Neučiní-li tak, mohou mu být přiznána práva z
							odpovědnosti za vady zboží zjistitelné při této prohlídce, pouze
							pokud kupující prokáže, že tyto vady mělo zboží již v době
							přechodu nebezpečí škody na zboží na kupujícího.{' '}
						</p>
						<p>
							7) Pokud kupující po rozbalení zásilky zjistí nekompletnost zboží
							dle objednávky, faktury nebo dodacího listu, je povinen
							neprodleně, nejpozději však do tří (3) dnů od dodání zboží podle
							odst. 5), resp. od převzetí zboží podle odst. 6), kontaktovat
							prodávajícího a informovat ho o této skutečnosti. Na pozdější
							reklamace nebude prodávajícím brán zřetel a po uplynutí lhůty
							podle předchozí věty se považuje zásilka za kompletně doručenou v
							souladu s objednávkou kupujícího, respektive fakturou či dodacím
							listem. Tímto není dotčen odst. 5) a 6) shora.
						</p>
						<p>
							8) Při splnění podmínky uvedené v čl. VIII. těchto VOP přechází na
							kupujícího vlastnické právo ke zboží okamžikem převzetí zboží.
							Nebezpečí škody na zboží přechází na kupujícího okamžikem převzetí
							zboží.
						</p>
						<p>
							9) Zboží bude podle jeho dostupnosti, provozních možností a
							vytíženosti prodávajícího, místa určeného kupujícím pro dodání,
							poptávky dalších kupujících a jiných faktorů majících vliv na
							dodání zboží dodáno v co nejkratší době po účinnosti kupní
							smlouvy, obvykle do 5 (pěti) pracovních dnů ode dne účinnosti
							kupní smlouvy. V případě, že zboží není skladem, je obvyklá lhůta
							prodloužena o dobu, kdy je zboží dodáno do skladu prodávajícího.
							Kupující bere na vědomí, že v případě zvýšené poptávky po zboží
							prodávajícího, v případě zhoršených povětrnostních podmínek nebo v
							případech vyšší moci může být dodací lhůta delší, než činí v
							obvyklých případech. V případě:
						</p>
						<div>
							<ol>
								<li>
									úhrady kupní ceny prostřednictvím platební brány nebo
									bezhotovostním převodem běží lhůta podle tohoto odstavce ode
									dne připsání kupní ceny na bankovní účet prodávajícího, nebo
									ode dne účinnosti kupní smlouvy, podle toho, který okamžik
									nastane později;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									požadavku prodávajícího na uhrazení zálohy na kupní cenu (čl.
									IV. odst. 6) těchto VOP) běží lhůta podle tohoto odstavce ode
									dne uhrazení zálohy na kupní cenu prodávajícímu, resp. ode dne
									připsání zálohy na kupní cenu na bankovní účet prodávajícího,
									nebo ode dne účinnosti kupní smlouvy, podle toho, který
									okamžik nastane později; tímto není dotčeno písm. a);
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									požadavku prodávajícího na autorizaci objednávky (čl. IV.
									odst. 7) těchto VOP) běží lhůta podle tohoto odstavce ode dne
									autorizace objednávky kupujícím, nebo ode dne účinnosti kupní
									smlouvy, podle toho, který okamžik nastane později; tímto není
									dotčeno písm. a) a/nebo b).
								</li>
							</ol>
						</div>
						<p>
							10) Nepřevezme-li si kupující zboží od prodávajícího ve lhůtě
							podle odst. 6), věta druhá, nebo vrátí-li se zásilka odesílaná
							prostřednictvím přepravce jako nedoručená či nedoručitelná (včetně
							případu, kdy kupující neuhradil dobírkovou částku), kupní smlouva
							bez dalšího zaniká dnem uplynutí lhůty k převzetí zásilky, resp.
							dnem vrácení zásilky přepravcem prodávajícímu. Tímto není dotčeno
							právo prodávajícího na náhradu škody, která mu takto vznikla,
							zejména právo na náhradu nákladů vynaložených na přípravu zboží k
							předání kupujícímu a nákladů na dodání zboží kupujícímu.{' '}
						</p>
						<p></p>
						<p className="text-center fw-bolder mb-1">X.</p>
						<p className="text-center fw-bolder">
							<u>Vadné plnění</u>
						</p>
						<p>
							1) Kupující je oprávněn uplatnit právo z vady, která se vyskytne u
							zboží v době dvaceti čtyř (24) měsíců, nestanoví-li obecně závazný
							předpis nebo tyto VOP jinak, nebo nevyplývá-li z povahy zboží
							jinak, a začíná běžet od převzetí zboží kupujícím (dále jen „
							<strong>doba pro uplatnění práv z vadného plnění</strong>“ nebo „
							<strong>záruční doba</strong>“).
						</p>
						<p>
							2) V případě zboží, u nějž je na jeho obalu, nebo návodu k němu
							připojenému nebo v reklamě v souladu s obecně závaznými právními
							předpisy uvedena doba, po kterou lze věc použít (tj. datum
							použitelnosti, minimální trvanlivosti, apod.), je dobou pro
							uplatnění práv z vadného plnění podle odst. 1) právě takto uvedená
							doba a odst. 1) se neuplatní.
						</p>
						<p>
							3) Kupující bere na vědomí skutečnost, že u případných dárků podle
							čl. IV. odst. 15) těchto VOP se odst. 1) a 2) na tyto dárky
							neuplatní.
						</p>
						<p className="text-center fw-bolder mb-1">XI.</p>
						<p className="text-center fw-bolder mb-1">
							<u>Práva kupujícího z odpovědnosti za vady</u>
						</p>
						<p>
							<strong></strong>
						</p>
						<p>1) Kupující má tato práva z odpovědnosti za vadu zboží:</p>
						<div>
							<ol>
								<li>
									Jde-li o vadu, kterou lze odstranit, má kupující právo, aby
									byla bezplatně, včas a řádně odstraněna, a prodávající je
									povinen vadu bez zbytečného odkladu odstranit, a to zejména
									opravou nebo doplněním toho, co chybí. Není-li to vzhledem k
									povaze vady neúměrné, může kupující požadovat výměnu
									reklamovaného zboží, nebo týká-li se vada jen součásti
									reklamovaného zboží, výměnu součásti. Není-li takový postup
									možný, může kupující žádat přiměřenou slevu z kupní ceny
									reklamovaného zboží nebo od kupní smlouvy ve vztahu k
									reklamovanému zboží odstoupit.
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									Jde-li o vadu, kterou nelze odstranit a která brání tomu, aby
									reklamované zboží mohlo být řádně užíváno jako věc bez vady,
									má kupující právo na výměnu reklamovaného zboží nebo má právo
									od kupní smlouvy ve vztahu k reklamovanému zboží odstoupit.
									Táž práva přísluší kupujícímu, jde-li sice o vady
									odstranitelné, jestliže však kupující nemůže pro opětovné
									vyskytnutí vady po opravě nebo pro větší počet vad reklamované
									zboží řádně užívat.
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									Jde-li o jiné vady neodstranitelné a nepožaduje-li výměnu
									reklamovaného zboží, má kupující právo na přiměřenou slevu z
									kupní ceny reklamovaného zboží nebo může od kupní smlouvy ve
									vztahu k reklamovanému zboží odstoupit.
								</li>
							</ol>
						</div>
						<p>
							Jakmile kupující uplatní některé z práv z odpovědnosti za vady
							zboží, například právo na odstranění vady či slevu, je tímto
							projevem vázán a nemůže volbu uplatněného práva měnit, pokud se
							nedohodne s prodávajícím jinak.
						</p>
						<p>
							2) Za opětovné vyskytnutí vady po opravě se pro účely těchto VOP
							považuje stav, jestliže se stejná vada, která byla v záruční době
							již nejméně dvakrát odstraňována, opětovně vyskytne. Stejnou vadou
							je taková vada, která se shodně projevuje ve vlastnostech zboží.
						</p>
						<p>
							3) Za větší počet vad zboží se považuje stav, kdy zboží má v době
							uplatnění nároku současně alespoň tři odstranitelné vady.
						</p>
						<p>
							4) Práva z odpovědnosti za vady podle odst. 2) a 3) lze uplatnit
							jen tehdy, jestliže tyto vady brání v užívání zboží jako věci bez
							vad.
						</p>
						<p>
							5) Práva z odpovědnosti za vady se nevztahují zejména na vadu
							zboží, která vznikla:
						</p>
						<div>
							<ol>
								<li>
									užíváním zboží v rozporu s obecně známými pravidly jeho
									užívání;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									neodbornou montáží nebo jiným neodborným uvedením zboží do
									provozu;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>neodborným nebo nešetrným zacházením se zbožím;</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									použitím zboží v rozporu s jeho účelem nebo návodem k obsluze
									nebo pokyny uvedenými na obalu nebo v záručním listu;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>mechanickým poškozením nebo opotřebením zboží;</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									v důsledku přirozených změn materiálu, z nichž je zboží
									vyrobeno;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>živelnými pohromami nebo vyšší mocí;</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>nedovolenými zásahy do zboží,</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									používáním zboží v podmínkách, které neodpovídají svým
									charakterem (např. svojí teplotou, prašností, vlhkostí,
									chemickými a mechanickými vlivy prostředí) prostředí, které je
									výrobcem určeno, nebo které jednoznačně vyplývá z povahy věci;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									poškozením zboží v důsledku nadměrného zatěžování nebo
									používání v rozporu s všeobecnými zásadami bezpečného chování
									a manipulování se zbožím;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>na opotřebení způsobené obvyklým užíváním zboží;</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>nedodržením podmínek pro skladování zboží, apod.;</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									u zboží prodávaného za nižší cenu za vadu, pro kterou byla
									nižší cena ujednána;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									u použitého zboží za vadu odpovídající míře používání nebo
									opotřebení, kterou mělo zboží při převzetí kupujícím.
								</li>
							</ol>
						</div>
						<p>
							Práva z odpovědnosti za vady se nevztahují na případné dárky podle
							čl. IV. odst. 15) těchto VOP.
						</p>
						<p>
							6) Práva z odpovědnosti za vady kupujícímu dále zejména nenáleží,
							pokud prodávající kupujícího před převzetím zboží upozornil, že
							zboží má vadu, pokud kupující nebo třetí osoba vadu sami
							způsobili, nebo pokud kupující nevytkl zjevné vady zboží při jeho
							převzetí (např. při poškození ochranného obalu, apod.).
						</p>
						<p>
							7) Práva z odpovědnosti za vady uplatňuje kupující bez zbytečného
							odkladu poté, co se o vadě dozví, u prodávajícího. Prodávající
							umožňuje kupujícímu uplatnit reklamaci vyplněním a odesláním
							vzorového formuláře pro reklamaci zboží. Formulář pro reklamaci
							zboží tvoří přílohu těchto VOP a dále se nachází v rámci
							internetového obchodu prodávajícího v záložce
							„/reklamace-a-vraceni“. Reklamaci lze:
						</p>
						<div>
							<ol>
								<li>
									zasláním reklamace nebo vyplněného vzorového formuláře pro
									reklamaci zboží na emailovou adresu prodávajícího
									podpora@vinisto.cz;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									zasláním reklamace nebo vyplněného vzorového formuláře pro
									reklamaci zboží na kontaktní adresu prodávajícího Jankovcova
									1057/6, Holešovice, 170 00 Praha 7.
								</li>
							</ol>
						</div>
						<p>
							8) Uplatnění práva z odpovědnosti za vady podle odst. 7) probíhá
							tak, že kupující prodávajícímu předá / odešle zejména:
						</p>
						<div>
							<ol>
								<li>
									reklamaci nebo vyplněný formulář pro reklamaci zboží, ve
									kterém bude zejména podrobně popsána vada zboží, navrhovaný
									způsob řešení reklamace a e-mailový nebo telefonní kontakt na
									kupujícího. Pokud se vada nevyskytuje trvale, je nutné ve
									formuláři pro reklamaci zboží jednoznačně uvést podmínky, za
									kterých se vada projevuje. Běh lhůty pro vyřízení reklamace
									začíná až ode dne předložení úplné a kompletní reklamace nebo
									úplného a kompletně vyplněného formuláře pro reklamaci zboží
									kupujícím prodávajícímu.{' '}
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									doklad o nákupu zboží u prodávajícího. Bez přiložení dokladu o
									nákupu zboží u prodávajícího musí kupující jiným věrohodným
									způsobem prokázat, že reklamované zboží zakoupil u
									prodávajícího a kdy. Běh lhůty pro vyřízení reklamace začíná
									až ode dne předložení dokladu o nákupu nebo ode dne prokázání
									skutečností podle předchozí věty kupujícím prodávajícímu jiným
									způsobem.{' '}
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									reklamované zboží. Reklamované zboží musí být kompletní a
									zabaleno pokud možno v původním (či v jinak adekvátním) obalu.
									V případě, že kupující nedodá reklamované zboží kompletní a
									jeho kompletnost je nezbytná ke zjištění existence reklamované
									vady a/nebo k jejímu odstranění, běh lhůty pro vyřízení
									reklamace začíná až ode dne dodání chybějících částí. V
									případě reklamace provedené zasláním zboží prodávajícímu, musí
									být zásilka zabalena ve vhodném obalu a zaslána prodávajícímu
									vhodným způsobem s ohledem na povahu zboží.
								</li>
							</ol>
						</div>
						<p>
							Běh lhůty pro vyřízení reklamace začíná až ode dne předložení a
							doručení všech listin a kompletního zboží tak, jak je uvedeno v
							písm. a) až c) tohoto odstavce.{' '}
						</p>
						<p>9) Prodávající kupujícímu vystaví:</p>
						<div>
							<ol>
								<li>
									po obdržení reklamace potvrzení o tom, kdy kupující právo z
									odpovědnosti za vady uplatnil, co je obsahem reklamace a jaký
									způsob vyřízení reklamace kupující požaduje;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									po vyřízení reklamace potvrzení o datu a způsobu vyřízení
									reklamace, včetně potvrzení o provedení opravy a době jejího
									trvání, případně písemné odůvodnění zamítnutí reklamace (dále
									jen „<strong>reklamační</strong> <strong>protokol</strong>“).
									Reklamační protokol bude kupujícímu dodán společně s
									reklamovaným zbožím.
								</li>
							</ol>
						</div>
						<p>
							10) Dopravu reklamovaného zboží k prodávajícímu nese na své
							náklady a nebezpečí kupující vyjma případu uvedeného v odst. 15).
							V případě odesílání zboží prodávajícímu musí kupující zboží vhodně
							a dostatečně pojistit.{' '}
						</p>
						<p>
							11) Prodávající rozhodne o reklamaci ihned, ve složitých případech
							do tří (3) pracovních dnů. Do této lhůty se nezapočítává doba
							přiměřená podle druhu zboží potřebná k odbornému posouzení vady.
							Reklamace včetně odstranění vady musí být vyřízena bez zbytečného
							odkladu, nejpozději do třiceti (30) dnů ode dne uplatnění
							reklamace, pokud se prodávající s kupujícím nedohodne na delší
							lhůtě.{' '}
						</p>
						<p>
							12) Kupující je povinen poskytnout osobě vyřizující reklamaci
							veškerou nezbytnou součinnost k ověření existence reklamované vady
							a k jejímu odstranění.
						</p>
						<p>
							13) Práva z odpovědnosti za vady zboží, pro které platí doba pro
							uplatnění práv z vadného plnění, zaniknou, nebyla-li uplatněna v
							době pro uplatnění práv z vadného plnění.
						</p>
						<p>
							14) Kupující bude prodávajícím vyrozuměn o vyřízení reklamace
							emailem.
						</p>
						<p>
							15) Kupující má nárok na náhradu nákladů spojených s reklamací v
							nutné výši v případě, že reklamace byla prodávajícím uznána jako
							oprávněná.{' '}
						</p>
						<p className="text-center fw-bolder mb-1">XII.</p>
						<p className="text-center fw-bolder">
							<u>Odstoupení od kupní smlouvy</u>
						</p>
						<p>
							1) Kupující nemůže odstoupit od spotřebitelské smlouvy zejména v
							případech uvedených v § 1837 NOZ a § 1840 NOZ. Nelze-li od kupní
							smlouvy odstoupit, další ustanovení tohoto článku se na kupujícího
							nevztahují. Je-li předmětem koupě podle kupní smlouvy více položek
							zboží, posuzuje se možnost odstoupení od smlouvy u každé
							jednotlivé položky tvořící předmět koupě podle kupní smlouvy
							zvlášť.{' '}
						</p>
						<p>
							2) Nejde-li o případy podle odst. 1) má kupující právo odstoupit
							od kupní smlouvy ve lhůtě čtrnácti (14) dnů. Lhůta podle věty
							první běží ode dne převzetí zboží podle kupní smlouvy. Jde-li o
							kupní smlouvu, jejímž předmětem je několik druhů zboží nebo dodání
							několika částí, ode dne převzetí poslední dodávky zboží, nebo
							jde-li o kupní smlouvu, jejímž předmětem je pravidelná opakovaná
							dodávka zboží, potom ode dne převzetí první dodávky zboží.
						</p>
						<p>
							3) Prodávající umožňuje kupujícímu odstoupit prostřednictvím
							vyplnění a odeslání vzorového formuláře pro odstoupení od kupní
							smlouvy. Formulář pro odstoupení od kupní smlouvy tvoří přílohu
							těchto VOP. Prodávající potvrdí kupujícímu v textové podobě
							přijetí formuláře pro odstoupení. Odstoupení od kupní smlouvy lze:
						</p>
						<div>
							<ol>
								<li>
									zasláním odstoupení nebo vyplněného vzorového formuláře pro
									odstoupení od kupní smlouvy na emailovou adresu prodávajícího
									podpora@vinisto.cz;
								</li>
							</ol>
						</div>
						<div>
							<ol>
								<li>
									zasláním odstoupení nebo vyplněného vzorového formuláře pro
									odstoupení od kupní smlouvy na kontaktní adresu prodávajícího
									Jankovcova 1057/6, Holešovice, 170 00 Praha 7
								</li>
							</ol>
						</div>
						<p>
							4) Odstoupí-li kupující od kupní smlouvy, zašle nebo předá
							prodávajícímu bez zbytečného odkladu, nejpozději do čtrnácti (14)
							dnů od odstoupení od kupní smlouvy, zboží, které od něho obdržel,
							včetně případných dárků podle čl. IV. odst. 15) těchto VOP.
							Kupující vrací zboží úplné a v nepoškozeném stavu. Při odstoupení
							od kupní smlouvy a následném vrácení zboží kupující odpovídá
							prodávajícímu pouze za snížení hodnoty zboží, které vzniklo v
							důsledku nakládání s tímto zbožím jinak, než jak je nutné s ním
							nakládat s ohledem na jeho povahu a vlastnosti.
						</p>
						<p>
							5) Prodávající může po kupujícím požadovat pouze úhradu nákladů
							stanovených NOZ.
						</p>
						<p>
							6) Odstoupí-li kupující od kupní smlouvy, vrátí mu prodávající bez
							zbytečného odkladu, nejpozději do čtrnácti (14) dnů od odstoupení
							od kupní smlouvy, všechny peněžní prostředky včetně nákladů na
							dodání, které od něho na základě kupní smlouvy přijal, stejným
							způsobem. Prodávající vrátí kupujícímu přijaté peněžení prostředky
							jiným způsobem jen tehdy, pokud s tím kupující souhlasil a pokud
							mu tím nevzniknou další náklady. Jestliže kupující zvolil jiný,
							než nejlevnější způsob dodání zboží, který prodávající nabízí,
							vrátí prodávající kupujícímu náklady na dodání zboží ve výši
							odpovídající nejlevnějšímu nabízenému způsobu dodání zboží.
						</p>
						<p>
							7) Prodávající tímto kupujícího podle § 1820 odst. 1), písm. g)
							NOZ upozorňuje, že v případě odstoupení od kupní smlouvy ponese
							kupující náklady spojené s navrácením zboží, a jde-li o kupní
							smlouvu uzavřenou prostřednictvím prostředku komunikace na dálku,
							náklady za navrácení zboží, jestliže toto zboží nemůže být vráceno
							pro svou povahu obvyklou poštovní cestou. V případě vracení zboží
							prodávajícímu, musí být zásilka zabalena ve vhodném obalu,
							pojištěna u přepravce nejméně na částku kupní ceny zboží a zaslána
							prodávajícímu vhodným způsobem s ohledem na povahu zboží.
						</p>
						<p>
							8) Odstoupí-li kupující od kupní smlouvy, prodávající není povinen
							vrátit přijaté peněžní prostředky kupujícímu dříve, než mu
							kupující zboží včetně případných dárků podle čl. IV. odst. 15)
							těchto VOP předá nebo prokáže, že zboží, včetně případných dárků
							podle čl. IV. odst. 15) těchto VOP, prodávajícímu odeslal.{' '}
						</p>
						<p>
							9) Ustanoveními tohoto článku je kupující poučen o svém právu
							odstoupit od kupní smlouvy ve smyslu § 1820 odst. 1), písm. f)
							NOZ.
						</p>
						<p>
							10) Je-li společně se zbožím případně poskytnut kupujícímu dárek
							podle čl. IV. odst. 15) těchto VOP, je darovací smlouva mezi
							prodávajícím a kupujícím uzavřena s rozvazovací podmínkou, že
							dojde-li k odstoupení od kupní smlouvy kupujícím, darovací smlouva
							pozbývá účinnosti a kupující je povinen spolu s vráceným zbožím
							vrátit i s ním související poskytnutý dárek včetně všeho, oč se
							obohatil. V případě, že dárek nebude vrácen zpět, bude tato
							hodnota chápána jako bezdůvodné obohacení na straně kupujícího.
							Není-li vydání předmětu bezdůvodného obohacení dobře možné, má
							prodávající právo na peněžitou náhradu ve výši obvyklé ceny
							nevráceného dárku. V případě odstoupení od darovací smlouvy
							nezaniká kupní smlouva, a smlouvy jsou posuzovány z tohoto
							hlediska samostatně.
						</p>
						<p className="text-center fw-bolder mb-1">XIII.</p>
						<p className="text-center fw-bolder">
							<u>Mimosoudní řešení sporů</u>
						</p>
						<p>
							1) Prodávající zřizuje pro případné stížnosti kupujících jako
							spotřebitelů e-mailovou adresu určenou pro podání stížností:
							podpora@vinisto.cz. Prodávající je povinen prostřednictvím e-mailu
							neprodleně potvrdit obdržení stížnosti. Stížnost kupujícího je
							prodávající povinen posoudit do pěti (5) pracovních dnů od jejího
							obdržení. Výsledek prodávající sdělí kupujícímu prostřednictvím
							e-mailu.
						</p>
						<p>
							2) Prodávající informuje kupujícího jako spotřebitele ve smyslu §
							14 zákona č. 634/1992 Sb., o ochraně spotřebitele, ve znění
							pozdějších předpisů, že subjektem mimosoudního řešení
							spotřebitelských sporů, na kterého se má kupující právo obrátit s
							návrhem na mimosoudní řešení spotřebitelského sporu z kupní
							smlouvy uzavřené s prodávajícím je Česká obchodní inspekce
							(dostupná na webové stránce:{' '}
							<a href="http://www.coi.cz/">http://www.coi.cz/</a>
							).
						</p>
						<p>
							3) Prodávající dále informuje kupujícího jako spotřebitele ve
							smyslu čl. 14 nařízení Evropského parlamentu a Rady (EU) č.
							524/2013 ze dne 21. května 2013, o řešení spotřebitelských sporů
							on-line a o změně nařízení (ES) č. 2006/2004 a směrnice 2009/22/ES
							(nařízení o řešení spotřebitelských sporů on-line), o existenci
							platformy pro řešení sporů on-line (dostupná na webové stránce:{' '}
							<a href="http://ec.europa.eu/consumers/odr/">
								http://ec.europa.eu/consumers/odr/
							</a>
							). Platformu pro řešení sporů on-line může kupující použít při
							mimosoudním řešení sporu z kupní smlouvy uzavřené online s
							prodávajícím. Email prodávajícího je:podpora@vinisto.cz.
						</p>
						<p>
							4) Kupující jako spotřebitel se dále může se stížnostmi obracet na
							Českou obchodní inspekci.
						</p>
						<p className="text-center fw-bolder mb-1">XIV.</p>
						<p className="text-center fw-bolder">
							<u>Ustanovení závěrečná</u>
						</p>
						<p>
							1) Práva a povinnosti neupravená v těchto VOP řídí se dále
							platnými právními předpisy, zejména NOZ a zákonem č. 634/1992 Sb.,
							o ochraně spotřebitele, ve znění pozdějších předpisů. Písemné
							ujednání mezi smluvními stranami odlišné od VOP má před těmito VOP
							přednost.
						</p>
						<p>
							2) Tyto VOP a jednotlivé kupní smlouvy jsou vyhotoveny v českém
							jazyce. Kupní smlouvu lze uzavřít v českém jazyce.
						</p>
						<p>
							3) Tyto VOP a jednotlivé kupní smlouvy se řídí právním řádem České
							republiky, ledaže obecně závazné právní předpisy v případě
							kupujícího - spotřebitele stanoví závazně jinak. Smluvní strany
							sjednávají pravomoc soudů České republiky pro řešení všech sporů
							vznikajících z kupní smlouvy a v souvislosti s ní, nebo pro řešení
							jiných záležitostí vznikajících z kupní smlouvy a v souvislosti s
							ní, ledaže obecně závazné právní předpisy v případě kupujícího -
							spotřebitele stanoví závazně jinak.
						</p>
						<p>
							4) Osobou odpovědnou za provoz internetového obchodu provozovaného
							na internetových stránkách{' '}
							<a href="https://www.vinisto.cz">www.vinisto.cz</a> je Michal
							Kvíčala
						</p>
						<p>5) Tyto VOP nabývají platnosti a účinnosti dnem 1. 11. 2022</p>
						<p>V Praze dne 17. 10, 2022</p>
						<p className="text-center fw-bolder mb-1">vinisto s.r.o.</p>
						<p className="text-center">Michal Kvíčala, jednatel</p>
					</div>
				</Card>
			</ContainerFullWidth>
		</section>
	);
};

export default TermsAndConditions;
