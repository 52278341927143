import { useContext } from 'react';
import { LocalizationContext } from 'Services/LocalizationService';
import { useDirectQuantityBox } from 'Components/QuantityBox/Variants/DirectQuantityBox/hooks';
import { BasketContext } from 'Services/BasketService';
import { getDiscountPriceValues } from 'vinisto_shared/src/price/get-discount-prices';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';

import { VinistoBasketDllModelsApiBasketBasketBundle } from '@/api-types/basket-api';
import { Bundle } from '@/domain/bundle';

export const useProductBasketCount = (bundle: Bundle | null) => {
	const localizationContext = useContext(LocalizationContext);
	const basketContext = useContext(BasketContext);
	const t = localizationContext.useFormatMessage();

	const cartItem = (basketContext.basketState?.items ?? []).find(
		(item: VinistoBasketDllModelsApiBasketBasketBundle) => {
			return item.bundleId === bundle?.id;
		}
	);

	const { quantityInBasket } = useDirectQuantityBox(bundle);

	const basePrice = cartItem?.itemPrice;

	const discountedPrice = cartItem?.itemDiscountPrice;

	const { discountedPriceWithVat } = getDiscountPriceValues({
		quantityInBasket,
		basePrice,
		discountedPrice,
	});

	const priceWithVat = discountedPriceWithVat ?? basePrice?.valueWithVat;

	const basketBundlesPriceWithCurrency = (priceWithVat ?? 0) * quantityInBasket;

	const currency = basketContext.basketState?.currency;

	const basketBundlesPriceWithCurrencyLocalized = getLocalizedPrice({
		price: basketBundlesPriceWithCurrency,
		// @ts-expect-error possible "undefined" issue
		currency,
	});

	const basketQuantityPopover = bundle
		? quantityInBasket &&
		  t(
				{ id: 'popover.addedToBasket' },
				{
					quantity: <strong> {quantityInBasket} </strong>,
					priceWithCurrency: (
						<strong>{basketBundlesPriceWithCurrencyLocalized}</strong>
					),
				}
		  )
		: null;

	return {
		basketQuantityPopover,
	};
};
