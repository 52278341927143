import { useContext, useEffect, useState } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { LocalizationContext } from 'Services/LocalizationService';
import { DeviceServiceContext } from 'Services/DeviceService';
import useChatbox from 'Hooks/useChat';
import useIsInBasket from 'Hooks/useIsInBasket';
import { AuthenticationContext } from 'Services/AuthenticationService/context';

const ChatboxLoader = () => {
	const { isMobile, isTablet } = useContext(DeviceServiceContext);
	const { vinistoUser } = useContext(AuthenticationContext);

	const [chatButton, setChatButton] = useState<HTMLElement | null>(null);
	const [chatIframe, setChatIframe] = useState<HTMLElement | null>(null);
	const [isChatOpen, setIsChatOpen] = useState<boolean>(false);

	const { hideWidget, showWidget } = useChatbox();
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	const location = useLocation();
	const isOnHomePage = location.pathname === '/';
	const isOnCheckout = useIsInBasket();
	const isOnProductDetail = useMatch(`/${t({ id: 'routes.product.route' })}/*`);

	// This ensures we have the chatbox button element available. It stops checking when it is loaded.
	useEffect(() => {
		if (chatButton) return;

		const interval = setInterval(() => {
			const iframe = document.getElementById('supportBox-iframe');
			if (iframe) setChatIframe(iframe);

			if (iframe == null) return;

			//@ts-expect-error contentdocument
			const button = iframe?.contentDocument.querySelector(
				'supportBoxWebChatInit'
			);

			if (button) setChatButton(button);

			if (parseInt(iframe.style.height.replace('px', '')) > 100) {
				setIsChatOpen(true);
			} else {
				setIsChatOpen(false);
			}
		}, 200);

		return () => clearInterval(interval);
	}, [chatButton]);

	useEffect(() => {
		if (!chatIframe) return;

		if (!isMobile && !isTablet) {
			chatIframe.style.bottom = '0px';
			return;
		}

		if (isChatOpen) {
			chatIframe.style.bottom = '0px';
		} else {
			if (isOnProductDetail) {
				chatIframe.style.bottom = '170px';
			} else {
				chatIframe.style.bottom = '70px';
			}
		}
	}, [isChatOpen, chatIframe, isOnProductDetail]);

	useEffect(() => {
		const supportboxScript = document.createElement('script');
		supportboxScript.type = 'text/javascript';
		supportboxScript.async = true;
		supportboxScript.innerHTML = `
		var supportBoxChatRunAsHidden = true
		var supportBoxChatId = 2141;
		var supportBoxChatSecret = 'e1e5a8ca0f46b7c3faf7cc68e1892d3f';
		var supportBoxChatVariables = {};
		`;

		const supportboxScriptSrc = document.createElement('script');
		supportboxScriptSrc.src = 'https://chat.supportbox.cz/web-chat/entry-point';
		supportboxScriptSrc.async = true;
		supportboxScriptSrc.defer = true;

		document.body.appendChild(supportboxScript);
		document.body.appendChild(supportboxScriptSrc);

		return () => {
			document.body.removeChild(supportboxScript);
			document.body.removeChild(supportboxScriptSrc);
		};
	}, []);

	useEffect(() => {
		const { supportBoxChatVariables } = window;
		if (!supportBoxChatVariables) return;
		if (vinistoUser.email && vinistoUser.id) {
			supportBoxChatVariables.fullName = 'Přihlášený uživatel';
			supportBoxChatVariables.email = vinistoUser.email;
			supportBoxChatVariables.customerId = vinistoUser.id;
		} else {
			delete window.supportBoxChatVariables.fullName;
			delete window.supportBoxChatVariables.email;
			delete window.supportBoxChatVariables.customerId;
		}
	}, [vinistoUser.email, vinistoUser.id]);

	const [scrolled, setScrolled] = useState(false);

	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrolled(position > 51);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	/**
	 * Zobrazuje a skrývá Chat widget pravidel definovaných v tomto ticketu:
	 * https://vinisto.atlassian.net/browse/VWA-681
	 */
	useEffect(() => {
		if (isOnCheckout) {
			hideWidget();
			return;
		}

		if (!isMobile && !isTablet) {
			showWidget();
			return;
		}

		chatIframe?.classList.add('isOnProductDetail');

		if (isOnHomePage || isOnProductDetail) {
			scrolled ? showWidget() : hideWidget();
		} else {
			hideWidget();
		}

		if (!isOnProductDetail) chatIframe?.classList.remove('isOnProductDetail');
	}, [
		chatIframe?.classList,
		hideWidget,
		isMobile,
		isOnCheckout,
		isOnHomePage,
		isOnProductDetail,
		isTablet,
		scrolled,
		showWidget,
	]);

	return null;
};

export default ChatboxLoader;
