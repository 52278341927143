import isObject from 'lodash-es/isObject';
import StorageService from 'Services/StorageService';
import { IdField } from 'Pages/CartShippingData/interfaces';

const storageService = new StorageService();

export const isObjectType = (
	value: unknown
): value is Record<PropertyKey, unknown> => {
	return isObject(value);
};

export const setShippingId = (
	key: IdField,
	value: string | null | undefined
) => {
	const shippingIds = storageService.getStorageItem('CART_SHIPPING_DATA');
	storageService.setItem('CART_SHIPPING_DATA', {
		...(isObjectType(shippingIds) && shippingIds),
		[key]: value,
	});
};

export const getShippingId = (key: IdField) => {
	const maybeData = storageService.getStorageItem('CART_SHIPPING_DATA');
	if (isObjectType(maybeData)) {
		return maybeData[key] ? String(maybeData[key]) : null;
	}
};
