import { FC } from 'react';

import { IIconProps } from './Interfaces';

const StarFullIcon: FC<IIconProps> = ({ className, id }) => {
	return (
		<svg
			width={14.997}
			height={14.484}
			viewBox="0 0 14.997 14.484"
			className={className}
		>
			<defs>
				<filter id={`${id}`}>
					<feOffset
						dx={1}
						dy={3}
					/>
					<feGaussianBlur
						stdDeviation={3}
						result="blur"
					/>
					<feFlood
						floodColor="#fff"
						floodOpacity={0.251}
						result="color"
					/>
					<feComposite
						operator="out"
						in="SourceGraphic"
						in2="blur"
					/>
					<feComposite
						operator="in"
						in="color"
					/>
					<feComposite
						operator="in"
						in2="SourceGraphic"
					/>
				</filter>
			</defs>
			<g data-type="innerShadowGroup">
				<path
					d="M11.987 12.759c.007 1.275-.179 1.425-1.087 1.056s-1.8-.79-2.689-1.219a1.513 1.513 0 0 0-1.421 0c-.865.42-1.737.828-2.625 1.193-1 .412-1.2.275-1.147-.825.048-.946.161-1.891.3-2.828a1.653 1.653 0 0 0-.473-1.5 65.475 65.475 0 0 1-1.72-1.889 4.385 4.385 0 0 1-.526-.741c-.178-.324-.13-.506.223-.632.577-.2 1.172-.35 1.766-.49s1.2-.245 1.8-.365a1.2 1.2 0 0 0 .865-.636c.412-.759.827-1.517 1.263-2.263a8.163 8.163 0 0 1 .669-.938.357.357 0 0 1 .619-.006 7.813 7.813 0 0 1 .649.908 16.882 16.882 0 0 1 .972 1.782 2.152 2.152 0 0 0 1.849 1.311 22.051 22.051 0 0 1 2.539.552c.793.218.878.48.357 1.123-.6.743-1.241 1.457-1.9 2.145a1.888 1.888 0 0 0-.582 1.768c.161.921.228 1.858.3 2.492"
					fill="#c7ab59"
				/>
				<g filter={`url(#${id})`}>
					<path
						d="M11.988 12.759c.007 1.275-.179 1.425-1.087 1.056s-1.8-.79-2.689-1.219a1.513 1.513 0 0 0-1.421 0c-.865.42-1.737.828-2.625 1.193-1 .412-1.2.275-1.147-.825.048-.946.161-1.891.3-2.828a1.653 1.653 0 0 0-.473-1.5 65.475 65.475 0 0 1-1.72-1.889A4.385 4.385 0 0 1 .6 6.006C.422 5.682.47 5.5.823 5.374c.577-.2 1.172-.35 1.766-.49s1.2-.245 1.8-.365a1.2 1.2 0 0 0 .865-.636c.412-.759.827-1.517 1.263-2.263a8.163 8.163 0 0 1 .669-.938.357.357 0 0 1 .619-.006 7.813 7.813 0 0 1 .649.908 16.882 16.882 0 0 1 .972 1.782 2.152 2.152 0 0 0 1.849 1.311 22.051 22.051 0 0 1 2.539.552c.793.218.878.48.357 1.123-.6.743-1.241 1.457-1.9 2.145a1.888 1.888 0 0 0-.582 1.768c.161.921.228 1.858.3 2.492"
						fill="#fff"
					/>
				</g>
				<path
					d="M11.987 12.759c.007 1.275-.179 1.425-1.087 1.056s-1.8-.79-2.689-1.219a1.513 1.513 0 0 0-1.421 0c-.865.42-1.737.828-2.625 1.193-1 .412-1.2.275-1.147-.825.048-.946.161-1.891.3-2.828a1.653 1.653 0 0 0-.473-1.5 65.475 65.475 0 0 1-1.72-1.889 4.385 4.385 0 0 1-.526-.741c-.178-.324-.13-.506.223-.632.577-.2 1.172-.35 1.766-.49s1.2-.245 1.8-.365a1.2 1.2 0 0 0 .865-.636c.412-.759.827-1.517 1.263-2.263a8.163 8.163 0 0 1 .669-.938.357.357 0 0 1 .619-.006 7.813 7.813 0 0 1 .649.908 16.882 16.882 0 0 1 .972 1.782 2.152 2.152 0 0 0 1.849 1.311 22.051 22.051 0 0 1 2.539.552c.793.218.878.48.357 1.123-.6.743-1.241 1.457-1.9 2.145a1.888 1.888 0 0 0-.582 1.768c.161.921.228 1.858.3 2.492"
					fill="none"
					stroke="rgba(0,0,0,0)"
				/>
			</g>
		</svg>
	);
};

export default StarFullIcon;
