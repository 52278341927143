import api from '@/api';
import {
	BasketChangeBasketCountryOfSaleUpdateParams,
	BasketChangeBundleForShippingPackagingPartialUpdatePayload,
	BasketDeleteParams,
	BasketGiftsListParams,
	BasketListParams,
	BasketRemoveDiscountCouponUpdateParams,
	VinistoBasketDllModelsApiBasketBasketAuthParameters,
	VinistoBasketDllModelsApiBasketBasketMergeParameters,
	VinistoBasketDllModelsApiBasketBasketSetDiscountCouponParameters,
	VinistoBasketDllModelsApiBasketBasketSetGiftsRefusedParameters,
	VinistoBasketDllModelsApiBasketBasketUpdateParameters,
	VinistoBasketDllModelsApiBasketBasketUpdateRangeParameters,
	VinistoBasketDllModelsApiReturnDataBasketReturn,
	VinistoGiftsDllModelsApiReturnDataGiftsReturn,
	VinistoHelperDllBaseBaseReturn,
} from '@/api-types/basket-api';

/** Get basket */
const getBasket = async (params: BasketListParams) => {
	const res = await api.get<VinistoBasketDllModelsApiReturnDataBasketReturn>(
		`basket-api/basket`,
		params
	);

	if (res.basket === undefined) {
		throw new Error('No basket data in response');
	}

	if (res.isError) {
		throw new Error('Error getting basket');
	}

	return res;
};

/** Update bundle to the basket. If quantity is less than 1, then the item is removed.
 * If bundle is already in the basket, provided quantity will override the current
 * quantity in the basket. */
const updateBasket = async (
	request: VinistoBasketDllModelsApiBasketBasketUpdateParameters
) => {
	const res = await api.put<VinistoBasketDllModelsApiReturnDataBasketReturn>(
		`basket-api/basket`,
		undefined,
		request
	);

	if (res.basket === undefined) {
		throw new Error('No basket data in response');
	}

	if (res.isError) {
		throw new Error('Error updating basket');
	}

	return res;
};

/** Remove all items and discount coupons from basket */
const emptyBasket = async (params: BasketDeleteParams) => {
	const res = await api.delete<VinistoBasketDllModelsApiReturnDataBasketReturn>(
		`basket-api/basket`,
		params,
		{
			serialize: false,
		}
	);

	if (res.basket === undefined) {
		throw new Error('No basket data in response');
	}

	if (res.isError) {
		throw new Error('Error emptying basket');
	}

	return res;
};

/** Add or remove bundles to the basket. If quantity is less then 1, then the item is removed.
 * If bundle is already in the basket, the set quantity will override the current quantity
 * in the basket. */
const updateBasketRange = async (
	request: VinistoBasketDllModelsApiBasketBasketUpdateRangeParameters
) => {
	const res = await api.put<VinistoBasketDllModelsApiReturnDataBasketReturn>(
		`basket-api/basket/UpdateBasketRange`,
		undefined,
		request
	);

	if (res.basket === undefined) {
		throw new Error('No basket data in response');
	}

	if (res.isError) {
		throw new Error('Error updating basket');
	}

	return res;
};

/** Merge baskets */
const mergeBaskets = async (
	request: VinistoBasketDllModelsApiBasketBasketMergeParameters
) => {
	const res = await api.put<VinistoHelperDllBaseBaseReturn>(
		`basket-api/basket/MergeBaskets`,
		undefined,
		request
	);

	if (res.isError) {
		throw new Error('Error merging baskets');
	}

	return res;
};

/** Set basket discount coupon */
const applyDiscountCoupon = async (
	request: VinistoBasketDllModelsApiBasketBasketSetDiscountCouponParameters
) => {
	const res = await api.put<VinistoBasketDllModelsApiReturnDataBasketReturn>(
		'basket-api/basket/SetDiscountCoupon',
		undefined,
		request
	);

	if (res.basket === undefined) {
		throw new Error('No basket data in response');
	}

	if (res.isError) {
		throw new Error('Error applying discount coupon');
	}

	return res;
};

/** Remove discount coupon from basket */
const removeDiscountCoupon = async (
	params: BasketRemoveDiscountCouponUpdateParams,
	request: VinistoBasketDllModelsApiBasketBasketAuthParameters
) => {
	const res = await api.put<VinistoBasketDllModelsApiReturnDataBasketReturn>(
		'basket-api/basket/RemoveDiscountCoupon',
		params,
		request
	);

	if (res.basket === undefined) {
		throw new Error('No basket data in response');
	}

	if (res.isError) {
		throw new Error('Error removing discount coupon');
	}

	return res;
};

/** Set refuse gifts to true or false */
const refuseGifts = async (
	request: VinistoBasketDllModelsApiBasketBasketSetGiftsRefusedParameters
) => {
	const res = await api.put<VinistoBasketDllModelsApiReturnDataBasketReturn>(
		'basket-api/basket/refuse-gifts',
		undefined,
		request
	);

	if (res.basket === undefined) {
		throw new Error('No basket data in response');
	}

	if (res.isError) {
		throw new Error('Error refusing discount coupon');
	}

	return res;
};

/** Get gifts related to basket */
const getGifts = async (params: BasketGiftsListParams) => {
	const res = await api.get<VinistoGiftsDllModelsApiReturnDataGiftsReturn>(
		`basket-api/basket/gifts`,
		params
	);

	if (res.isError) {
		throw new Error('Error getting gifts');
	}

	return res;
};

/** Change basket country of sale */
const changeBasketCountryOfSale = async (
	params: BasketChangeBasketCountryOfSaleUpdateParams
) => {
	const res = await api.put<VinistoBasketDllModelsApiReturnDataBasketReturn>(
		'basket-api/basket/change-basket-country-of-sale',
		params
	);

	if (res.basket === undefined) {
		throw new Error('No basket data in response');
	}

	if (res.isError) {
		throw new Error('Error refusing discount coupon');
	}

	return res;
};

const changePackaging = async (
	request: BasketChangeBundleForShippingPackagingPartialUpdatePayload
): Promise<VinistoBasketDllModelsApiReturnDataBasketReturn> => {
	const res = await api.patch<VinistoBasketDllModelsApiReturnDataBasketReturn>(
		'basket-api/basket/change-bundle-for-shipping-packaging',
		undefined,
		request
	);

	if (res.basket === undefined) {
		throw new Error('Error changing packaging');
	}

	return res;
};

const BasketService = {
	getBasket,
	updateBasket,
	emptyBasket,
	updateBasketRange,
	mergeBaskets,
	applyDiscountCoupon,
	removeDiscountCoupon,
	refuseGifts,
	getGifts,
	changeBasketCountryOfSale,
	changePackaging,
};

export { BasketService };
