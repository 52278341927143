import { lazy, Suspense, useContext } from 'react';
import cx from 'classnames';
import { FavoritesContext } from 'Services/FavoritesService';
import { LocalizationContext } from 'Services/LocalizationService';
const HeartIcon = lazy(() => import('Components/Icons/Heart'));
const HeartEmptyIcon = lazy(() => import('Components/Icons/HeartEmpty'));
import Loader from 'Components/View/Loader';

import styles from './styles.module.css';

type TFavoriteProps = {
	size?: 'xs' | 'sm' | 'md' | 'bundle';
	filled?: boolean;
	showCount?: boolean;
	count?: number;
	className?: string;
	inverted?: boolean;
	onClick?: () => void;
};

const Favorite = ({
	filled = false,
	showCount = false,
	count = 0,
	className,
	size = 'md',
	inverted = false,
	onClick,
}: TFavoriteProps) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	const allowToShowCount =
		showCount && typeof count === 'number' && count !== 0;

	return (
		<div
			className={cx(
				styles.wrapper,
				{
					[styles.withCount]: allowToShowCount,
					[styles.sizeXs]: size === 'xs',
					[styles.sizeSm]: size === 'sm',
					[styles.sizeMd]: size === 'md',
					[styles.sizeBundle]: size === 'bundle',
					[styles.inverted]: inverted,
				},
				className
			)}
			onClick={onClick}
			role="presentation"
		>
			{filled ? (
				<Suspense fallback={<Loader blank />}>
					<HeartIcon
						className={styles.icon}
						alt={`${t({ id: 'alt.inFavorites' })}`}
						fill={inverted ? '#fff' : undefined}
					/>
				</Suspense>
			) : (
				<Suspense fallback={<Loader blank />}>
					<HeartEmptyIcon
						className={styles.icon}
						alt={`${t({ id: 'alt.addToFavorites' })}`}
						fill={inverted ? '#fff' : undefined}
					/>
				</Suspense>
			)}
			{allowToShowCount && <div className={styles.count}>{count}</div>}
		</div>
	);
};

type TFavoriteCountProps = Omit<TFavoriteProps, 'showCount' | 'count'>;

export const FavoriteCount = (props: TFavoriteCountProps) => {
	const { favoritesState } = useContext(FavoritesContext);
	const count = favoritesState.favoritesData?.length ?? 0;

	return (
		<Favorite
			count={count}
			showCount
			{...props}
		/>
	);
};

export default Favorite;
