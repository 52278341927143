import { isPlainObject } from 'lodash-es';
import { IQueryArgument } from 'Services/ApiService/interfaces';
import { apiServiceInstance } from 'Services/ApiService';
import { CategoryData } from 'Services/Bundle/interfaces';

import {
	AUTOCOMPLETE_API_ENDPOINT,
	AUTOCOMPLETE_LIMIT,
	BUNDLES_URI,
	CATEGORIES_URI,
	SIMILAR_BUNDLES_CAROUSEL_ENDPOINT,
	WAREHOUSE_API_ENDPOINT,
} from './constants';

import { VinistoWarehouseDllModelsApiWarehouseItemWarehouseItemQuantityReturn } from '@/api-types/warehouse-api';
import {
	BundlesGetBundleByUrlDetailParams,
	VinistoHelperDllEnumsLanguage,
	VinistoProductDllModelsApiBundleBundleCategoriesReturn,
	VinistoProductDllModelsApiBundleBundleReturn,
	VinistoProductDllModelsApiBundleBundlesFilterReturn,
	VinistoProductDllModelsApiBundleBundlesGetWithoutLimitParameters,
	VinistoProductDllModelsApiBundleBundlesReturn,
	VinistoProductDllModelsApiCategoryCategoriesReturn,
	VinistoProductDllModelsApiCategoryCategory,
	VinistoProductDllModelsApiCategoryCategoryReturn,
	VinistoProductDllModelsApiFullSearchFullSearchSeparatedReturn,
} from '@/api-types/product-api';
import { DEFAULT_BUNDLE_API_PARAMS_PROCESSED } from '@/shared';

const mapApiToCategoryEntity = (
	category?: VinistoProductDllModelsApiCategoryCategory | null
): CategoryData | undefined => {
	if (
		!category ||
		!isPlainObject(category) ||
		!category.id ||
		!category.name ||
		!category.description
	) {
		return;
	}
	return {
		id: category.id,
		name: category.name,
		description: category.description,
		url: category.url ?? [],
		images: category.images ?? [],
		type: category.type,
		specificationDetails: category.specificationDetails ?? [],
		metaDescription: category.metaDescription ?? [],
	};
};

const getAutocompleteBundles = (value: string) => {
	return apiServiceInstance.getCollection<VinistoProductDllModelsApiFullSearchFullSearchSeparatedReturn>(
		AUTOCOMPLETE_API_ENDPOINT,
		[
			{ key: 'searchingNameString', value: value },
			{ key: 'limit', value: AUTOCOMPLETE_LIMIT },
			...DEFAULT_BUNDLE_API_PARAMS_PROCESSED,
		],
		true
	);
};

const getWarehouseItemsQuantities = (bundleIds: string[]) => {
	const queryParams: IQueryArgument[] = bundleIds.map((id) => ({
		key: 'bundleIds',
		value: id,
	}));

	return apiServiceInstance.get<VinistoWarehouseDllModelsApiWarehouseItemWarehouseItemQuantityReturn>(
		WAREHOUSE_API_ENDPOINT,
		true,
		undefined,
		queryParams
	);
};

const getBundleById = (id: string) =>
	apiServiceInstance
		.get<VinistoProductDllModelsApiBundleBundleReturn>(`${BUNDLES_URI}/${id}`)
		.then((payload) => payload.bundle);

const getBundleByUrl = (
	url: string,
	params: Omit<BundlesGetBundleByUrlDetailParams, 'bundleUrl'>
) => {
	const requestParams = [
		{ key: 'IsCache', value: true },
		{ key: 'currency', value: params.currency },
		{ key: 'countryOfSale', value: params.countryOfSale },
	];

	return apiServiceInstance
		.get<VinistoProductDllModelsApiBundleBundleReturn>(
			`${BUNDLES_URI}/${url}/GetBundleByUrl`,
			true,
			undefined,
			// @ts-expect-error Ignore this, migrate to new api client
			requestParams
		)
		.then((payload) => {
			if (!payload.bundle) throw new Error('No bundle in response');

			return payload.bundle;
		});
};

const getIdenticalBundles = async (bundleId: string) => {
	const requestParams = [
		{
			key: 'IsCache',
			value: true,
		},
	];
	return apiServiceInstance
		.get<VinistoProductDllModelsApiBundleBundlesReturn>(
			`${BUNDLES_URI}/${bundleId}/GetIdenticalBundles`,
			true,
			undefined,
			requestParams
		)
		.then((response) => response.bundles);
};

const getAvailableFilters = async (
	req: VinistoProductDllModelsApiBundleBundlesGetWithoutLimitParameters
) => {
	const response =
		await apiServiceInstance.post<VinistoProductDllModelsApiBundleBundlesFilterReturn>(
			`${BUNDLES_URI}/get-available-filters`,
			req,
			true
		);
	return response;
};
const getBundlesCategories = async (
	req: VinistoProductDllModelsApiBundleBundlesGetWithoutLimitParameters
) => {
	const response =
		await apiServiceInstance.post<VinistoProductDllModelsApiCategoryCategoriesReturn>(
			`${BUNDLES_URI}/get-bundles-categories`,
			req,
			true
		);
	return response;
};

const getSimilarBundles = async (bundleId: string) => {
	const requestParams = [
		{
			key: 'BundleId',
			value: bundleId,
		},
		{
			key: 'IsCache',
			value: true,
		},
		{
			key: 'Language',
			value: VinistoHelperDllEnumsLanguage.CZECH,
		},
	];
	const response =
		await apiServiceInstance.get<VinistoProductDllModelsApiBundleBundlesReturn>(
			`${SIMILAR_BUNDLES_CAROUSEL_ENDPOINT}`,
			true,
			undefined,
			requestParams
		);
	return response;
};

export type BundleCategoryData = {
	loading: boolean;
	categoryData: Record<string, any>;
};

const getBundleCategories = (bundleId: string): Promise<CategoryData[]> => {
	const requestParams = [
		{
			key: 'IsCache',
			value: true,
		},
		/*{
			key: 'IsB2b',
			value: false,
		},*/
	];
	return apiServiceInstance
		.get<VinistoProductDllModelsApiBundleBundleCategoriesReturn>(
			`${BUNDLES_URI}/${bundleId}/categories`,
			true,
			undefined,
			requestParams
		)
		.then((payload) => {
			if (payload?.categories == null) return [];

			const data = payload?.categories.map((category) => {
				return mapApiToCategoryEntity(category);
			}) as CategoryData[];

			return data;
		});
};

const getCategoriesById = (categoryId: string) => {
	const requestParams = [
		{
			key: 'IsCache',
			value: true,
		},
	];
	return apiServiceInstance
		.get<VinistoProductDllModelsApiCategoryCategoryReturn>(
			`${CATEGORIES_URI}/${categoryId}`,
			true,
			undefined,
			requestParams
		)
		.then((payload) => {
			return mapApiToCategoryEntity(payload.category);
		});
};

/**
 * @deprecated use vinisto_api_client instead, this one should be removed asap
 */
const BundleService = {
	getAutocompleteBundles,
	getBundleById,
	getBundleByUrl,
	getBundleCategories,
	getWarehouseItemsQuantities,
	getIdenticalBundles,
	getAvailableFilters,
	getBundlesCategories,
	getSimilarBundles,
	getCategoriesById,
};

export default BundleService;
