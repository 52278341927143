import { ReactNode, useContext, useMemo } from 'react';
import {
	ControllerProps,
	FieldPath,
	FieldValues,
	useController,
} from 'react-hook-form';
import { PHONE_CODE_SEPARATOR, PHONE_FORMAT } from 'Components/Forms/constants';
import { LocalizationContext } from 'Services/LocalizationService';
import Form from 'Components/Forms';
import { TestIdType } from 'Constants/test-ids';

import styles from './styles.module.css';

type TPhoneField = {
	id?: string;
	name: FieldPath<FieldValues>;
	label?: ReactNode;
	type?: string;
	placeholder?: string;
	showSuccess?: boolean;
	showSuccessIcon?: boolean;
	showError?: boolean;
	successMessage?: string;
	isRequired?: boolean;
	rules?: ControllerProps['rules'];
	dataTestid?: TestIdType;
};

const phoneRegex = new RegExp(`${Object.values(PHONE_FORMAT).join('|')}`);

const PhoneField = ({
	id,
	name,
	label,
	type,
	placeholder,
	showSuccess = false,
	showSuccessIcon = false,
	showError = true,
	successMessage,
	isRequired = false,
	rules,
	dataTestid,
}: TPhoneField) => {
	const localizationContext = useContext(LocalizationContext);

	const t = localizationContext.useFormatMessage();

	const updatedRules = useMemo(() => {
		if (rules?.required) {
			const origValidate = rules.validate;
			rules.validate = {
				...(typeof origValidate === 'function'
					? { origValidate }
					: rules.validate),
				required: (value: string) => {
					const [, phoneNumber] = value.split(PHONE_CODE_SEPARATOR);
					return !phoneNumber ? `${t({ id: 'form.input.required' })}` : true;
				},
			};
		}
		return {
			...rules,
			validate: {
				...(rules?.validate ?? {}),
				format: (value: string) => {
					if (!phoneRegex.exec(value)) {
						return `${t({ id: 'form.input.phone.wrongFormat' })}`;
					}
				},
			},
		};
	}, [rules, t]);

	const {
		field,
		fieldState: { error, isTouched },
	} = useController({
		name,
		rules: updatedRules,
	});

	return (
		<div className={styles.wrapper}>
			{!!label && (
				<Form.Label isRequired={isRequired || !!rules?.required}>
					{label}
				</Form.Label>
			)}
			<Form.PhoneInput
				id={id}
				type={type}
				placeholder={placeholder}
				isError={!!error && showError}
				isSuccess={!error && isTouched && showSuccess}
				showSuccessIcon={showSuccess && showSuccessIcon}
				dataTestid={dataTestid}
				{...field}
			/>
			{!!error && <Form.Message variant="error">{error.message}</Form.Message>}
			{!error && isTouched && successMessage && (
				<Form.Message variant="success">{successMessage}</Form.Message>
			)}
		</div>
	);
};

export default PhoneField;
