import { Combobox } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import CloseIcon from 'Components/Icons/Close';
import { NavbarContext } from 'Components/Navbar/context';
import LoadingSpinner from 'Components/Preloader/Components/LoadingSpinner';
import Loader from 'Components/View/Loader';
import { TEST_IDS } from 'Constants/test-ids';
import useDebounce from 'Hooks/useDebounce';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import useOnClickOutside from 'Hooks/useOnClickOutside';
import { VinistoProductDllModelsApiCategoryCategory } from 'vinisto_api_client/src/api-types/product-api';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import { WarehouseContext } from 'Services/WarehouseService';
import cx from 'classnames';
import {
	ChangeEvent,
	KeyboardEventHandler,
	Suspense,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LinkTile } from 'vinisto_ui';
import useSectionLinkWidgetsQuery from 'Hooks/use-section-link-widgets-query';
import { useRecommendedBundles } from 'Hooks/useRecommendedBundles';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import usePreventScroll from 'Hooks/usePreventScroll';

import styles from './styles.module.css';
import SearchResultBundleItem from './SearchResultBundleItem';
import { MIN_SEARCH_LENGTH } from './constants';

import SearchService from '@/search-service';
import { Allowed_Sections } from '@/domain/link-widget/enums';
import { bundleAdapter } from '@/index';
import { Bundle } from '@/domain/bundle';

const { getSearchResults } = SearchService;
interface SearchProps {
	onToggleResult?: (value: boolean) => void;
}

const Search = ({ onToggleResult }: SearchProps) => {
	const { isMobile, isTablet, layoutHeight, footerHeight } =
		useContext(DeviceServiceContext);
	const localizationContext = useContext(LocalizationContext);
	const {
		activeCurrency: { currency },
		countryOfSale,
	} = localizationContext;
	const t = localizationContext.useFormatMessage();
	const warehouseContext = useContext(WarehouseContext);
	const navigate = useNavigate();
	const getLocalizedValue = useLocalizedValue();

	const [isFocused, setIsFocused] = useState(false);
	const [search, setSearch] = useState('');

	const debouncedSearch = useDebounce(search, 600);

	const { data: recommendedBundles } = useRecommendedBundles();
	const { filteredLinks: recommendedCategories } = useSectionLinkWidgetsQuery(
		Allowed_Sections.SEARCH
	);

	const inputRef = useRef<HTMLInputElement>(null);
	const searchButtonRef = useRef<HTMLButtonElement>(null);
	const { menuRef } = useContext(NavbarContext);

	const mobileHeaderHeight = 38;

	const searchResultsHeight =
		isMobile || isTablet
			? layoutHeight - mobileHeaderHeight - footerHeight
			: 'auto';

	useOnClickOutside([menuRef], () => setIsFocused(false));

	const query = useQuery(
		['search-bundles', debouncedSearch, { currency, countryOfSale }],
		() =>
			getSearchResults({
				searchingNameString: debouncedSearch,
				language: localizationContext.activeLanguageKey,
				currency,
			})
				.then((data) => {
					const bundleIds = data?.bundles
						?.map((b) => b?.id)
						.filter((id): id is string => id !== null && id !== undefined);

					if (bundleIds && bundleIds.length > 0)
						warehouseContext.fetchQuantity(bundleIds);

					const bundles = (data?.bundles ?? []).map((bundle) =>
						bundleAdapter.fromApi(bundle, {
							currency,
						})
					);

					// This seem like a bad practice, but while rendering Combobox.Options directly from 'data' did work,
					// it caused an annoying Combobox.Options flickering on every new search request.
					setCategoriesList(data?.categories ?? []);
					setBundlesList(bundles);
					return data;
				})
				.catch((e) => {
					return Promise.reject(e);
				}),
		{
			cacheTime: 0,
			staleTime: 0,
			enabled: debouncedSearch?.length >= MIN_SEARCH_LENGTH && isFocused,
		}
	);

	const showLoadingSpinner =
		query.isLoading &&
		debouncedSearch?.length >= MIN_SEARCH_LENGTH &&
		isFocused;

	const [bundlesList, setBundlesList] = useState<Bundle[]>([]);

	const [categoriesList, setCategoriesList] = useState<
		VinistoProductDllModelsApiCategoryCategory[]
	>([]);

	const showNothingFoundMessage =
		bundlesList?.length === 0 &&
		categoriesList?.length === 0 &&
		search.length >= MIN_SEARCH_LENGTH;

	const areThereCategoriesToShow = useMemo(() => {
		return (
			(categoriesList?.length > 0 && search.length >= MIN_SEARCH_LENGTH) ||
			(search.length < MIN_SEARCH_LENGTH &&
				recommendedCategories &&
				recommendedCategories.length > 0)
		);
	}, [categoriesList, recommendedCategories, search]);

	const areThereBundlesToShow = useMemo(() => {
		return (
			bundlesList?.length > 0 ||
			(search.length < MIN_SEARCH_LENGTH &&
				recommendedBundles &&
				recommendedBundles.length > 0)
		);
	}, [bundlesList, recommendedBundles, search]);

	usePreventScroll({ isScrollDisabled: isFocused });

	useEffect(() => {
		if (isFocused) {
			onToggleResult?.(true);
		}
	}, [isFocused, onToggleResult]);

	useEffect(() => {
		document.addEventListener('keydown', closeMenuOnEscape);
		return () => {
			document.removeEventListener('keydown', closeMenuOnEscape);
		};
	}, []);

	const closeMenuOnEscape = (e: KeyboardEvent) => {
		if (e.key === 'Escape') {
			setIsFocused(false);
			setSearch('');
		}
	};

	const unfocus = () => {
		if (document.activeElement instanceof HTMLElement) {
			document.activeElement.blur();
		}
	};

	const openSearchResults = () => {
		if (!isFocused) {
			setIsFocused(true);
		}

		inputRef.current?.dispatchEvent(new Event('input', { bubbles: true }));
	};

	const clearSearchInput = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		event.stopPropagation();
		setSearch('');
		requestAnimationFrame(() => {
			inputRef.current?.focus();
		});
	};

	const handleClearButtonInteraction = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		if (isMobile || isTablet) {
			// For mobile and tablet, use onMouseDown
			clearSearchInput(event);
		} else {
			// For desktop, use onClick (which will be triggered after onMouseDown)
			if (event.type === 'click') {
				clearSearchInput(event);
			}
		}
	};

	const closeSearchResults = () => {
		if (isFocused) {
			setIsFocused(false);
		}
	};

	const handleOnItemClick = (item: Record<string, any>) => {
		closeSearchResults();
		const newSearchValue = getLocalizedValue(item?.name ?? []);
		if (newSearchValue) {
			setSearch(newSearchValue);
		}
	};

	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
		openSearchResults();
		setSearch(e.target.value);
	};

	const handleOnSelect = (item: any) => {
		unfocus();
		closeSearchResults();

		if (typeof item === 'object') {
			const newSearchValue = getLocalizedValue(item?.name ?? []);
			setSearch(newSearchValue);
			if (item?.type === 'bundle') {
				navigate(
					`/${t({ id: 'routes.product.route' })}/${getLocalizedValue(
						item?.url ?? []
					)}`
				);
			} else if (item?.type === 'category') {
				navigate(
					`/${t({ id: 'routes.category.route' })}/${getLocalizedValue(
						item?.url ?? []
					)}`
				);
			}
		} else {
			navigate(`/${t({ id: 'routes.search.route' })}/${search}`);
		}
	};

	const handleOnKeyDown = (
		event:
			| (KeyboardEventHandler<HTMLInputElement> & { key: string })
			| undefined,
		activeOption: any
	) => {
		if (event?.key !== 'Enter' || !activeOption) return;
		return handleOnSelect(activeOption);
	};

	const handleOnSearchButtonClick = () => {
		if (search?.length >= MIN_SEARCH_LENGTH) {
			closeSearchResults();
			navigate(`/${t({ id: 'routes.search.route' })}/${search}`);
			setTimeout(() => unfocus(), 10);
		}
	};

	return (
		<div className={styles.relativeWrapperForCloseSearch}>
			{isFocused && <div className={styles.searchResultsBackdrop}></div>}
			<Combobox
				value={search}
				onChange={handleOnSelect}
			>
				{({ activeOption }) => {
					return (
						<>
							<div
								className={cx(
									styles.searchWrapper,
									isFocused && styles.maximize
								)}
								ref={menuRef}
							>
								<Combobox.Label className={styles.searchLabel}>
									{showLoadingSpinner && (
										<LoadingSpinner wrapperClass={styles.loadingSpinner} />
									)}
									<Combobox.Input
										className={styles.searchInput}
										placeholder={`${t({
											id: 'search.placeholder',
										})}`}
										onChange={handleOnChange}
										onClick={openSearchResults}
										// @ts-expect-error wrong React types for event
										onKeyDown={(e) => handleOnKeyDown(e, open, activeOption)}
										autoComplete="off"
										ref={inputRef}
										value={search}
									/>
									{search.length > 0 && (
										<button
											// On mobile and tablet, onClick just blurs the input, so we need to use onMouseDown
											onClick={handleClearButtonInteraction}
											onMouseDown={handleClearButtonInteraction}
											className={styles.closeSearch}
											style={{
												'--submit-btn-width': `${
													searchButtonRef.current?.offsetWidth ?? 0
												}p`,
											}}
										>
											<Suspense fallback={<Loader blank />}>
												<CloseIcon className={styles.closeSearchIcon} />
											</Suspense>
										</button>
									)}
								</Combobox.Label>
								{isFocused && (
									<Combobox.Options
										className={styles.searchResults}
										style={{
											height: searchResultsHeight,
										}}
										static
									>
										<div className="position-relative">
											<button
												className={cx(styles.closeSearch, {
													[styles.quicklyVisualyCenterWhenResultsAreEmpty]: !(
														areThereCategoriesToShow || areThereBundlesToShow
													),
												})}
												onClick={closeSearchResults}
											>
												<Suspense fallback={<Loader blank />}>
													<CloseIcon className={styles.closeSearchIcon} />
												</Suspense>
											</button>
										</div>

										<Combobox.Option value="showMoreBtn" />
										{!showNothingFoundMessage ? (
											<>
												{areThereCategoriesToShow && (
													<div
														className={styles.searchResultsHeader}
														role="heading"
														aria-level={1}
													>
														{t({
															id: 'search.popup.categoriesHeader',
														})}
													</div>
												)}
												<div className={styles.categoriesContainer}>
													{search.length < MIN_SEARCH_LENGTH &&
														recommendedCategories?.map((category) => (
															<Combobox.Option
																value={{
																	...category,
																	type: 'category',
																}}
																key={category?.id}
															>
																{() => (
																	<Link
																		to={category.url ?? ''}
																		onClick={() => handleOnItemClick(category)}
																		className={cx(
																			styles.searchResultsCategoryItem
																		)}
																	>
																		<LinkTile
																			title={category.name ?? ''}
																			img={{
																				src: category.imageLocator ?? '',
																				alt: category.name ?? '',
																			}}
																		/>
																	</Link>
																)}
															</Combobox.Option>
														))}
													{search.length >= MIN_SEARCH_LENGTH &&
														categoriesList?.map(
															(
																category: VinistoProductDllModelsApiCategoryCategory
															) => (
																<Combobox.Option
																	value={{
																		...category,
																		type: 'category',
																	}}
																	key={category?.id}
																>
																	{() => (
																		<Link
																			to={`/${t({
																				id: 'routes.category.route',
																			})}/${getLocalizedValue(
																				category?.url ?? []
																			)}`}
																			onClick={() =>
																				handleOnItemClick(category)
																			}
																			className={cx(
																				styles.searchResultsCategoryItem
																			)}
																		>
																			<LinkTile
																				title={getLocalizedValue(
																					category?.name ?? []
																				)}
																				img={{
																					src: getBundleImage(
																						category.images,
																						IMAGE_SIZE_THUMB_64x80
																					),
																					alt: getLocalizedValue(
																						category?.name ?? []
																					),
																				}}
																			/>
																		</Link>
																	)}
																</Combobox.Option>
															)
														)}
												</div>
												{areThereBundlesToShow && (
													<div
														className={styles.searchResultsHeader}
														role="heading"
														aria-level={1}
													>
														{t({
															id: 'search.popup.bundlesHeader',
														})}
													</div>
												)}
												<div className={styles.bundlesContainer}>
													{(search.length < MIN_SEARCH_LENGTH
														? recommendedBundles
														: bundlesList
													)?.map((bundle) => (
														<Combobox.Option
															className={styles.searchResultsBundleItem}
															value={{
																...bundle,
																type: 'bundle',
															}}
															key={bundle?.id}
														>
															{() => {
																return (
																	<div
																		className={cx(
																			'vinisto-search-productbox',
																			styles.searchResultsBundleItem
																		)}
																		onClick={() => {
																			setIsFocused(false);
																		}}
																		onKeyDown={() => setIsFocused(false)}
																		role="button"
																		tabIndex={0}
																	>
																		<SearchResultBundleItem bundle={bundle} />
																	</div>
																);
															}}
														</Combobox.Option>
													))}
												</div>
												{search.length >= MIN_SEARCH_LENGTH && (
													<Combobox.Option value="showMoreBtn">
														{({ active }) => (
															<Link
																to={`${t({
																	id: 'routes.search.route',
																})}/${search}`}
																className={cx(styles.searchResultsShowAllBtn, {
																	[styles.searchResultsShowAllBtnActive]:
																		active,
																})}
																key={'viewAllButton'}
																onClick={closeSearchResults}
															>
																{t({
																	id: 'search.popup.viewAll',
																})}
															</Link>
														)}
													</Combobox.Option>
												)}
											</>
										) : (
											search.length >= MIN_SEARCH_LENGTH && (
												<div className={styles.searchNoResults}>
													<Link
														to={`${t({
															id: 'routes.search.route',
														})}/${search}`}
														className={cx(styles.searchResultsShowAllBtn)}
														key={'viewAllButton'}
														onClick={closeSearchResults}
													>
														{t({
															id: 'search.popup.viewAll',
														})}
													</Link>
												</div>
											)
										)}
									</Combobox.Options>
								)}
								<Combobox.Button
									ref={searchButtonRef}
									className={cx(
										'vinisto-btn vinisto-bg-green',
										styles.searchButton
									)}
									onClick={handleOnSearchButtonClick}
									data-testid={TEST_IDS.MAIN_SEARCH_BUTTON_DESKTOP}
								>
									{t({ id: 'search' })}
								</Combobox.Button>
							</div>
						</>
					);
				}}
			</Combobox>
		</div>
	);
};

export default Search;
