import { GiftPurchaseProps } from 'vinisto_ui/src/components/gift-purchase/types';
import { LocalizationContext } from 'Services/LocalizationService';
import { useContext } from 'react';
import { PURCHASE_GIFT_TYPES } from 'vinisto_ui/src/components/gift-progress-bar/types';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { Gift, GiftType } from 'Hooks/useGiftProducts/types';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import { GiftProductProps } from 'vinisto_ui/src/components/gift-product/types';

import { VinistoHelperDllEnumsGiftGiftRuleType } from '@/api-types/product-api';
import { GiftRule, GiftRuleBundle } from '@/domain/gift-rule/types';

const useGiftsOrderPriceFrom = (
	minimalPriceForFreeDelivery: number | null,
	totalMoneySpent: number,
	assignedGifts?: Gift[],
	possibleGifts?: Gift[]
): GiftPurchaseProps[] => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();

	if (minimalPriceForFreeDelivery === null) {
		return [];
	}

	const isDeliveryFree = Boolean(
		totalMoneySpent - minimalPriceForFreeDelivery > 0
	);

	const deliveryFreeMark = [
		{
			isFulfilled: isDeliveryFree,
			type: PURCHASE_GIFT_TYPES.transport,
			text: !isDeliveryFree
				? `${t(
						{
							id: 'giftInfo.description.deliveryFree',
						},
						{
							orderPriceLimitFrom: `${createCurrencyValue(
								minimalPriceForFreeDelivery - totalMoneySpent
							)} ${localizationContext.activeCurrency.title}`,
						}
				  )}`
				: `${t({
						id: 'giftInfo.description.deliveryFree.complete',
				  })}`,
			orderPriceLimitFrom: minimalPriceForFreeDelivery,
		},
	];

	const getGifts = (gifts: Gift[], giftType: GiftType) =>
		gifts &&
		gifts
			.filter(
				(gift) =>
					gift.ruleType ===
					VinistoHelperDllEnumsGiftGiftRuleType.GIFT_ORDER_PRICE_FROM
			)
			.map((gift) => {
				const giftName = `${getLocalizedValue(gift.bundles[0].bundle.name)}`;
				const assignedGift = {
					isFulfilled: Boolean(giftType === GiftType.ASSIGNED_GIFT),
					type: PURCHASE_GIFT_TYPES.bundle,
					ruleType: gift.ruleType,
					imageUrl:
						gift.bundles?.[0].bundle.images?.[0]?.domainUrls?.thumb_64x80 ?? '',
					text:
						giftType === GiftType.ASSIGNED_GIFT
							? giftName
							: `${t(
									{
										id: 'giftInfo.description',
									},
									{
										orderPriceLimitFrom: `${createCurrencyValue(
											gift.leftToSpent
										)} ${localizationContext.activeCurrency.title}`,
									}
							  )} ${giftName}`,
					orderPriceLimitFrom: gift.orderPriceLimitFrom,
				};
				return assignedGift;
			});
	const assignedGiftsProducts = getGifts(
		assignedGifts ?? [],
		GiftType.ASSIGNED_GIFT
	);
	const possibleGiftsProducts = getGifts(
		possibleGifts ?? [],
		GiftType.POSSIBLE_GIFT
	);
	const progressMarks = [
		...deliveryFreeMark,
		...assignedGiftsProducts,
		...possibleGiftsProducts,
	];

	const giftProductsSorted = progressMarks.sort(
		(a, b) => a.orderPriceLimitFrom - b.orderPriceLimitFrom
	);

	return giftProductsSorted;
};

const useGiftsForBundle = (giftRules?: GiftRule[]): GiftProductProps[] => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();

	const getGifts = (gifts: GiftRuleBundle[]) =>
		gifts &&
		gifts.map((gift) => {
			const giftName = `${getLocalizedValue(gift.bundle?.name)}`;
			const assignedGift = {
				imageUrl: gift.bundle?.images?.[0]?.domainUrls?.thumb_64x80 ?? '',
				bundleName: `${t(
					{
						id: 'giftInfo.description.productGift',
					},
					{
						value: giftName,
					}
				)}`,
			};
			return assignedGift;
		});

	const bundleGifts = giftRules
		? giftRules
				.map((giftRule) => {
					return getGifts(giftRule?.bundles ?? []);
				})
				.flat()
		: [];

	return bundleGifts;
};

export { useGiftsOrderPriceFrom, useGiftsForBundle };
