import { RefObject, useContext } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { LocalizationContext } from 'Services/LocalizationService';
import { BasketContext } from 'Services/BasketService';
import { DeviceServiceContext } from 'Services/DeviceService';
import Basket from 'Components/Basket';
import SideBasket from 'Components/SideBasket';
import { TEST_IDS } from 'Constants/test-ids';

import { NavbarContext } from '../../context';

const NavBasket = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const basketContext = useContext(BasketContext);
	const deviceContext = useContext(DeviceServiceContext);
	const navbarContext = useContext(NavbarContext);
	const basketRef = navbarContext?.basketRef as RefObject<HTMLDivElement>;

	const itemsQuantity = basketContext.itemsQuantity;

	const navigate = useNavigate();

	const matchCart = useMatch(`/${t({ id: 'routes.cart.route' })}`);
	const matchCartShippingPayment = useMatch(
		`/${t({ id: 'routes.cart.shippingPayment.route' })}`
	);
	const matchCartShippingData = useMatch(
		`/${t({ id: 'routes.cart.shipping.route' })}`
	);

	const matchHomepage = useMatch('/');
	const matchCategory = useMatch(`/${t({ id: 'routes.category.route' })}/*`);
	const matchTags = useMatch(`/${t({ id: 'routes.tag.route' })}/*`);
	const matchProducts = useMatch(`/${t({ id: 'routes.products.route' })}/*`);
	const matchNews = useMatch(`/${t({ id: 'routes.news.route' })}`);
	const matchOffers = useMatch(`/${t({ id: 'routes.offers.route' })}`);
	const matchRecommended = useMatch(
		`/${t({ id: 'routes.collectibleWines.route' })}`
	);
	const matchBundleDetail = useMatch(
		`/${t({ id: 'routes.product.route' })}/:itemUrl`
	);

	const showSideBasket =
		(!!matchHomepage ||
			!!matchCategory ||
			!!matchTags ||
			!!matchProducts ||
			!!matchNews ||
			!!matchOffers ||
			!!matchRecommended ||
			!!matchBundleDetail) &&
		deviceContext.isDesktopBig &&
		itemsQuantity !== 0;

	const handleGoToBasket = () => {
		if (
			!(!!matchCart || !!matchCartShippingPayment || !!matchCartShippingData)
		) {
			navigate(`/${t({ id: 'routes.cart.route' })}`);
		}
	};

	return (
		<>
			<Basket
				ref={basketRef}
				onClick={handleGoToBasket}
				className="px-2"
				showPrice={!showSideBasket}
				showCount={!showSideBasket && itemsQuantity !== 0}
				dataTestid={TEST_IDS.NAVBAR_BASKET_DESKTOP}
			/>
			{showSideBasket && <SideBasket />}
		</>
	);
};

export default NavBasket;
