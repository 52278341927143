import styles from './styles.module.css';

interface BannerListingProps {
	title?: string;
	subtitle?: string;
	imageUrl?: string;
	buttonText?: string;
	buttonLink: string;
}

const BannerListing = ({
	title,
	subtitle,
	imageUrl,
	buttonText,
	buttonLink,
}: BannerListingProps) => {
	return (
		<div
			className={styles.banner}
			style={{ backgroundImage: `url('${imageUrl}')` }}
		>
			<a
				href={buttonLink}
				className={styles.overlay}
			>
				<div className={styles.top}>
					<div className={styles.title}>{title}</div>
				</div>
				<div className={styles.bottom}>
					{subtitle && <div className={styles.subtitle}>{subtitle}</div>}
					{buttonText && <span className={styles.button}>{buttonText}</span>}
				</div>
			</a>
		</div>
	);
};

export default BannerListing;
