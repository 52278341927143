import { GiftPurchaseListView } from 'vinisto_ui';
import { useContext } from 'react';
import { LocalizationContext } from 'Services/LocalizationService';
import useCreateGiftMarks from 'vinisto_ui/src/components/gift-progress-bar/hooks';
import { BasketContext } from 'Services/BasketService';
import {
	useGiftsForBundle,
	useGiftsOrderPriceFrom,
} from 'Hooks/useGiftProducts';
import { useQuery } from '@tanstack/react-query';
import { BundleService } from 'vinisto_api_client/src/product-service';

import styles from './styles.module.css';

import { VinistoHelperDllEnumsCountryCode } from '@/api-types/product-api';

interface GiftSectionProps {
	bundleId: string;
}

const GiftSection = ({ bundleId }: GiftSectionProps) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const basketContext = useContext(BasketContext);
	const {
		activeCurrency: { currency },
		countryOfSale,
	} = localizationContext;

	const { data: bundle_gifts } = useQuery(
		['bundle-gifts', bundleId, { currency, countryOfSale }],
		() =>
			BundleService.getBundleGifts(bundleId, {
				currency,
				countryOfSale: countryOfSale as VinistoHelperDllEnumsCountryCode,
			})
	);

	const gifts_for_bundle_title = `${t({ id: 'giftInfo.heading.productGift' })}`;
	const gifts_for_bundle = useGiftsForBundle(bundle_gifts);

	const giftPurchaseListViewProducts = useGiftsOrderPriceFrom(
		basketContext.minimalPriceForFreeDelivery,
		basketContext.basketPriceWithVat ?? 0,
		// @ts-expect-error Incorrect manual type is used. Should be fixed.
		basketContext.assignedGifts,
		basketContext.possibleGifts
	);

	const GiftProgressBarMarks = useCreateGiftMarks({
		minimalPriceForFreeDelivery: basketContext.minimalPriceForFreeDelivery,
		currency: currency,
		totalMoneySpent: basketContext.basketPriceWithVat ?? 0,
		// @ts-expect-error Incorrect manual type is used. Should be fixed.
		assignedGifts: basketContext.assignedGifts,
		// @ts-expect-error Incorrect manual type is used. Should be fixed.
		possibleGifts: basketContext.possibleGifts,
	});

	return (
		<div className={styles.component}>
			<GiftPurchaseListView
				giftProgressBarProps={GiftProgressBarMarks}
				translations={{
					title: t({ id: 'bundle.giftsTitle' }) as string,
				}}
				giftPurchaseProps={giftPurchaseListViewProducts}
				giftProductsProps={{
					giftProducts: gifts_for_bundle,
					translations: { title: gifts_for_bundle_title },
				}}
			/>
		</div>
	);
};

export default GiftSection;
