import { useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { omit } from 'lodash-es';
import {
	VinistoHelperDllEnumsCurrency,
	VinistoProductDllModelsApiBundleBundlesGetWithoutLimitParameters,
} from 'vinisto_api_client/src/api-types/product-api';
import BundleService from 'Services/Bundle';
import {
	BundleSorting,
	ListingType,
} from 'Pages/Category/Components/CategoryBundlesWithFilters/interfaces';
import {
	FILTER_CODE,
	PRICE_SPECIFICATION_ID,
} from 'Pages/Category/Components/CategoryBundlesWithFilters/constants';
import { LocalizationContext } from 'Services/LocalizationService';

import ProductTag from '@/domain/tag';

interface UseBundleQueriesParams {
	categoryId: string | null;
	tagId: string | null;
	sortParam: BundleSorting | null;
	page: number[];
	limit: number;
	activeSpecificationFilters: Record<string, any>[];
	activeTagFilters: ProductTag[];
	isInStockParamRef: { filterType: number; isInStock: boolean }[];
	isDiscountedParamRef: { filterType: number; isDiscounted: boolean }[];
	isEnabled?: boolean;
	isCache?: boolean;
	isInImperialUnits?: boolean;
	isDeleted?: boolean;
	isGift?: boolean;
	isTemporaryUnavailable?: boolean;
	listingType: ListingType;
}

export const useBundleQueries = ({
	categoryId = null,
	tagId = null,
	sortParam,
	page,
	limit,
	activeSpecificationFilters,
	activeTagFilters,
	isInStockParamRef,
	isDiscountedParamRef,
	isCache = true,
	isInImperialUnits = false,
	listingType,
}: UseBundleQueriesParams) => {
	const t = useContext(LocalizationContext).useFormatMessage();
	const localizationContext = useContext(LocalizationContext);
	const {
		activeCurrency: { currency },
		countryOfSale,
		convertEURtoCZK,
	} = localizationContext;
	const queryParams = useMemo(() => {
		const processedParams = {
			hiddenSpecification: false,
			isDeleted: false,
			isEnabled: true,
			isGift: false,
			isTemporaryUnavailable: false,
			isSaleOver: false,
		};

		return {
			...processedParams,
			isCache,
			categoryId,
			tagId,
			sortingColumn: sortParam?.sortingColumn ?? '',
			isSortingDescending: sortParam?.isSortingDescending ?? false,
			page,
			currency,
			countryOfSale,
			filters: [
				...activeSpecificationFilters.map((filter) => {
					if (
						filter.specificationDefinitionId === PRICE_SPECIFICATION_ID &&
						currency !== VinistoHelperDllEnumsCurrency.CZK
					) {
						return {
							...filter,
							max: convertEURtoCZK(filter.max),
							min: convertEURtoCZK(filter.min),
						};
					}
					return omit(filter, ['specificationName', 'unit', 'imperialUnit']);
				}),
				...(activeTagFilters.length
					? [
							{
								filterType: FILTER_CODE.TAG,
								tags: activeTagFilters.map((filter) => filter.id),
							},
					  ]
					: []),
				...(isInStockParamRef
					? [
							{
								filterType: FILTER_CODE.STOCK,
								isInStock: isInStockParamRef === t({ id: 'yes' }),
							},
					  ]
					: []),
				...(isDiscountedParamRef
					? [
							{
								filterType: FILTER_CODE.DISCOUNT,
								isDiscounted: true,
							},
					  ]
					: []),
			],
			limit,
			isInImperialUnits,
		} as VinistoProductDllModelsApiBundleBundlesGetWithoutLimitParameters;
	}, [
		isCache,
		categoryId,
		tagId,
		sortParam?.sortingColumn,
		sortParam?.isSortingDescending,
		page,
		currency,
		countryOfSale,
		activeSpecificationFilters,
		activeTagFilters,
		isInStockParamRef,
		t,
		isDiscountedParamRef,
		limit,
		isInImperialUnits,
		convertEURtoCZK,
	]);

	const filtersQuery = useQuery(
		['available-filters', { queryParams }, listingType],
		() => {
			return BundleService.getAvailableFilters(queryParams);
		},
		{
			keepPreviousData: true,
			enabled:
				(listingType === ListingType.Category && !!categoryId) ||
				(listingType === ListingType.Tag && !!tagId) ||
				listingType === ListingType.All,
		}
	);

	return {
		filtersQuery,
		queryParams,
	};
};
