import { useContext, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { LocalizationContext } from 'Services/LocalizationService';

const useIsInBasket = (includeConfirmationPage = false) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const location = useLocation();

	const isInBasket = useMemo(
		() =>
			matchPath(location.pathname, `/${t({ id: 'routes.cart.route' })}`) ||
			matchPath(
				location.pathname,
				`/${t({ id: 'routes.cart.shippingPayment.route' })}`
			) ||
			matchPath(
				location.pathname,
				`/${t({ id: 'routes.cart.shipping.route' })}`
			) ||
			(includeConfirmationPage &&
				matchPath(
					location.pathname,
					`/${t({ id: 'routes.cart.confirmation.route' })}`
				)),
		[includeConfirmationPage, location.pathname, t]
	);

	return !!isInBasket;
};

export default useIsInBasket;
