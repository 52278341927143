import Skeleton from 'react-loading-skeleton';
import { useContext, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { PostTag } from 'Services/ApiService/Cms/interfaces';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { BlogContext } from 'Pages/Blog/context';
import { Link, useNavigate } from 'react-router-dom';
import { TabNavItemList } from 'vinisto_ui';

import styles from './styles.module.css';

interface Props {
	tagId?: string | undefined;
}

const BlogTagList = ({ tagId }: Props) => {
	const { isTagsLoading, tags, activeTagUrl } = useContext(BlogContext);
	const getLocalizedValue = useLocalizedValue();
	const navigate = useNavigate();
	const isActive = useMemo(
		() => (tag: PostTag) => {
			const activeTag = tags?.find((t) => t.id === tagId);
			return (
				tag.id === activeTag?.id || getLocalizedValue(tag.url) === activeTagUrl
			);
		},
		[activeTagUrl, getLocalizedValue, tagId, tags]
	);

	useEffect(() => {
		if (
			activeTagUrl !== '' &&
			isTagsLoading === false &&
			tags &&
			tags?.length > 0 &&
			tags?.filter((tag) => {
				return getLocalizedValue(tag.url) === activeTagUrl;
			}).length === 0
		) {
			navigate('/404');
		}
	}, [activeTagUrl, getLocalizedValue, isTagsLoading, navigate, tags]);

	if (isTagsLoading)
		return (
			<div className="container">
				<Skeleton />
			</div>
		);

	return (
		<div className="container">
			<TabNavItemList>
				{tags?.map((tag) => (
					<li
						key={tag.id}
						className="list-group-item"
					>
						<Link
							className={cx(styles.blogTag, {
								[styles.active]: isActive(tag),
							})}
							to={`/blog/tag/${getLocalizedValue(tag.url)}`}
						>
							{getLocalizedValue(tag.name)}
						</Link>
					</li>
				))}
			</TabNavItemList>
		</div>
	);
};

export default BlogTagList;
