import { useContext } from 'react';
import { BlogContext } from 'Pages/Blog/context';
import BlogPostCard from 'Pages/Blog/Components/PostCard';

import styles from './styles.module.css';

const BlogPostList = () => {
	const { posts } = useContext(BlogContext);

	return (
		<div className="container">
			<div className={styles.postList}>
				{posts?.slice(1).map((post) => (
					<BlogPostCard
						key={post.id}
						post={post}
					/>
				))}
			</div>
		</div>
	);
};

export default BlogPostList;
