import {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import cx from 'classnames';
import {
	every,
	find,
	flatten,
	get,
	head,
	isNaN,
	last,
	map,
	nth,
	range,
	reduce,
	some,
} from 'lodash-es';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import {
	DelimitedNumericArrayParam,
	NumberParam,
	useQueryParam,
	withDefault,
} from 'use-query-params';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { LocalizationContext } from 'Services/LocalizationService';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { NotificationsContext } from 'Services/NotificationService';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { DeviceServiceContext } from 'Services/DeviceService';
import { Link } from 'react-router-dom';
import PaginationNav from 'Components/Pagination';
import userSectionStyles from 'Pages/UserSection/styles.module.css';
import { OrderItem } from 'vinisto_ui';
import useAddItemsToBasket, {
	showBuyAgainButton,
} from 'Hooks/use-add-items-to-basket';
import useLocalizedDateTime from 'Hooks/useLocalizedDateTime';
import { IBundleChange } from 'Services/BasketService/interfaces';

import BreadCrumbsUserSection from '../Breadcrumbs';
import OrderDetail from '../OrderDetail';

import { IOrderSorting } from './interfaces';
import {
	fetchOrders,
	getLocalizedOrderState,
	getPdfDocument,
	openPdf,
} from './helpers';
import {
	CREATED,
	DEFAULT_LIMIT_PER_PAGE,
	NOT_FOUND,
	ORDER_SORTING_COLUMNS_MOBILE,
	OrderSortingColumn,
	QUERY_KEY,
} from './constants';
import styles from './styles.module.css';
import OrderMobile from './OrderMobile';

import { VinistoOrderDllModelsApiOrderOrder } from '@/api-types/order-api';

const Orders = () => {
	const { useFormatMessage } = useContext(LocalizationContext);
	const { vinistoUser } = useContext(AuthenticationContext);
	const { handleShowErrorNotification } = useContext(NotificationsContext);
	const { dispatch } = useContext(DocumentHeaderContext);
	const { isDesktop } = useContext(DeviceServiceContext);
	const t = useFormatMessage();

	const ordId = head(useQueryParam('id'));

	const { addItemsToBasket } = useAddItemsToBasket();
	const getLocalizedDate = useLocalizedDateTime();

	const userLoginHash = vinistoUser.loginHash ?? '';

	const [sorting, setSorting] = useState<IOrderSorting>({
		column: OrderSortingColumn.TIME,
		isDescending: true,
	});

	const mobileSortingLabel = useMemo(() => {
		return get(
			find(
				ORDER_SORTING_COLUMNS_MOBILE,
				(sortingItem) =>
					sortingItem.column === sorting.column &&
					sortingItem.isDescending === sorting.isDescending
			),
			'label'
		);
	}, [sorting]);

	const queryClient = useQueryClient();
	const [page, setPage] = useQueryParam(
		'page',
		withDefault(DelimitedNumericArrayParam, [1])
	);
	const currentPage =
		get(page, 'length', 0) > 1 ? get(page, '[1]', 1) : get(page, '[0]', 1);
	const [limit] = useQueryParam<number>(
		'limit',
		withDefault(NumberParam, DEFAULT_LIMIT_PER_PAGE)
	);
	const queries = useMemo(() => {
		let pagesToFetch = page as number[];
		if (get(page, 'length', 0) == 2) {
			pagesToFetch = range(get(page, '[0]', 1), get(page, '[1]', 2) + 1);
		}
		return map(pagesToFetch, (pageNum: number) => ({
			queryKey: [
				QUERY_KEY,
				{
					userLoginHash,
					sortingColumn: sorting.column,
					isDescending: sorting.isDescending,
				},
				pageNum,
			],
			queryFn: () =>
				fetchOrders(
					userLoginHash,
					vinistoUser.email ?? '',
					currentPage,
					limit,
					sorting.column,
					sorting.isDescending
				),
			refetchOnMount: true,
			refetchOnWindowFocus: false,
			cacheTime: 0,
		}));
	}, [page, currentPage, fetchOrders, userLoginHash, sorting]);

	const ordersData = useQueries({ queries });

	const isLoading = useMemo(
		() => some(ordersData, { isLoading: true }),
		[ordersData]
	);
	const ordersCount = useMemo(
		() =>
			isLoading
				? get(nth(ordersData, -2), 'data.count', 0)
				: get(last(ordersData), 'data.count', 0),
		[ordersData]
	);

	const ordersToLoadMore = useMemo(() => {
		const ordersLeft = ordersCount - currentPage * limit;
		if (ordersLeft < 1) return 0;
		if (ordersLeft > limit) return limit;
		return ordersLeft;
	}, [ordersData, limit]);

	const orders = useMemo(() => {
		const orders = flatten(
			map(ordersData, (page) => {
				return get(page, 'data.orders', []);
			})
		);

		if (isLoading) {
			let ordersLeft =
				ordersCount - (currentPage === 1 ? 1 : currentPage - 1) * limit;
			if (ordersLeft < 1) ordersLeft = limit;
			if (ordersLeft > limit) ordersLeft = limit;
			return [
				...orders,
				...map(Array(ordersLeft), () => ({ isLoading: true })),
			];
		}
		return orders;
	}, [ordersData]);

	useEffect(() => {
		dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.user-section.orders.name' })}` }
			)}`,
		});
	}, []);

	const handleOnIncreasePage = useCallback(() => {
		setPage([currentPage + 1]);
	}, [currentPage, setPage]);

	const handleOnDecreasePage = useCallback(() => {
		currentPage > 1 && setPage([currentPage - 1]);
	}, [currentPage, setPage]);

	const handleOnLoadMore = useCallback(() => {
		setPage((oldPage) => [
			get(oldPage, '[0]', 1),
			get(oldPage, '[1]', get(oldPage, '[0]', 1)) + 1,
		]);
	}, [setPage]);

	const totalPaginationPages = Math.ceil(ordersCount / limit);
	const handleOnSelectPage = useCallback(
		(page: number) => setPage([page]),
		[setPage]
	);

	const handleOnClickInvoice =
		(orderId: string, documentUrl: string) => async () => {
			const userLoginHash = vinistoUser.loginHash ?? '';
			try {
				const encodedPdfContent = await getPdfDocument(
					orderId,
					documentUrl,
					userLoginHash
				);
				openPdf(encodedPdfContent as string);
			} catch {
				handleShowErrorNotification('userSection.order.invoice.loadError');
			}
		};

	useEffect(() => {
		if (
			get(page, 'length', 0) > 2 ||
			!every(page, (pageNum) => !isNaN(pageNum)) ||
			(get(page, 'length', 0) == 2 && get(page, '[0]', 1) > get(page, '[1]', 2))
		)
			return setPage([1]);
	}, [page]);

	useEffect(() => {
		queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
	}, []);

	const handleOnSelectSorting = useCallback(
		(eventKey: number | null) => {
			const sortingColumn = ORDER_SORTING_COLUMNS_MOBILE[eventKey ?? 0];
			if (!sortingColumn) {
				return;
			}
			setSorting({
				column: sortingColumn.column,
				isDescending: sortingColumn.isDescending,
			});
		},
		[setSorting]
	);

	if (ordId) return <OrderDetail orderId={ordId as string} />;

	return (
		<>
			<BreadCrumbsUserSection />

			<h1 className={userSectionStyles.userSectionMainHeader}>
				{t({ id: 'routes.user-section.myOrders.name' })}
			</h1>

			<div>
				<div className={styles.sorting}>
					{map(ORDER_SORTING_COLUMNS_MOBILE, (sortingItem, key) => (
						<button
							key={key}
							onClick={() => handleOnSelectSorting(key)}
							className={cx(styles.sortingButton, {
								[styles.active]: mobileSortingLabel === sortingItem.label,
							})}
						>
							{t({ id: sortingItem.label })}
						</button>
					))}
				</div>
				{!isLoading && ordersCount === 0 && (
					<div className={styles.noOrder}>
						<span>
							{t(
								{ id: 'userSection.orders.empty' },
								{
									link: (
										<Link
											to="/"
											className="color-primary pointer fw-bolder"
										>
											{t({
												id: 'userSection.orders.startShopping',
											})}
										</Link>
									),
								}
							)}
						</span>
					</div>
				)}
				<div>
					<div>
						{map(
							orders,
							// TODO this is a lie, but because the isLoading antipattern is used here, it would need more type to fix this
							(order: VinistoOrderDllModelsApiOrderOrder, key: number) => {
								const itemsToAdd: IBundleChange[] = order?.orderItems
									?.filter((orderItem: any) => !orderItem.bundle.isGift)
									.map((orderItem: any) => {
										const id = orderItem?.bundle?.id ?? '';

										return {
											bundleId: id,
											quantity: orderItem.quantity,
										};
									});

								const localizedState = getLocalizedOrderState(
									order.state ?? ''
								);

								const date: number =
									reduce(
										order.stateChangeRecords ?? [],
										(result: number, change: Record<string, any>) =>
											result > NOT_FOUND
												? result
												: change.state === CREATED
												? change.changeTime
												: result,
										NOT_FOUND
									) * 1000; // API returns seconds, not miliseconds

								const orderData = {
									...order,
									date: date > NOT_FOUND ? date : null,
									localizedState,
								};

								if (!order.orderCurrency) return null;

								return (
									<Fragment key={`order-${order.id ?? key}`}>
										{!isDesktop ? (
											<OrderMobile
												order={orderData}
												{...{ handleOnClickInvoice }}
											/>
										) : (
											<OrderItem
												isLoading={isLoading}
												id={order.id}
												internalId={order.orderNumber ?? ''}
												totalPrice={order.orderPriceWithVat ?? 0}
												currency={order.orderCurrency}
												state={
													localizedState.text &&
													`${t({ id: localizedState.text })}`
												}
												color={localizedState?.color ?? ''}
												createdAtDate={getLocalizedDate(orderData.date) ?? null}
												isDesktop={isDesktop}
												trackingLink={order.delivery?.trackingUrl ?? ''}
												showBuyAgainButton={showBuyAgainButton(order.state)}
												addItemsToBasket={addItemsToBasket}
												itemsToAdd={itemsToAdd}
												showDetailButton={true}
												orderNumberAsLink={true}
											/>
										)}
									</Fragment>
								);
							}
						)}
					</div>

					<PaginationNav
						currentPage={currentPage}
						totalPaginationPages={totalPaginationPages}
						itemsToLoadMore={ordersToLoadMore}
						handleOnLoadMore={handleOnLoadMore}
						handleOnSelectPreviousPage={handleOnDecreasePage}
						handleOnSelectNextPage={handleOnIncreasePage}
						handleOnSelectPage={handleOnSelectPage}
					/>
				</div>
			</div>
		</>
	);
};

export default Orders;
