import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LocalizationContext } from 'Services/LocalizationService';
import { DeviceServiceContext } from 'Services/DeviceService';

import UserTileMenu from '../UserTileMenu';

import styles from './styles.module.css';

interface BreadCrumbsUserSectionProps {
	subpageTitle?: string;
	subpageLink?: string;
}

const BreadCrumbsUserSection = ({
	subpageTitle,
	subpageLink,
}: BreadCrumbsUserSectionProps) => {
	const { isDesktop } = useContext(DeviceServiceContext);
	const t = useContext(LocalizationContext).useFormatMessage();

	if (isDesktop) {
		return <UserTileMenu />;
	}

	return (
		<div className={styles.breadcrumbs}>
			<Link
				to={`/${t({ id: 'routes.user-section.route' })}`}
				className={styles.breadcrumb}
			>
				{t({ id: 'userSection.myAccount' })}
			</Link>
			<span className={styles.breadcrumbArrow}>{'>'}</span>
			{subpageTitle && subpageLink && (
				<>
					<Link
						to={subpageLink}
						className={styles.breadcrumb}
					>
						{t({ id: subpageTitle })}
					</Link>
					<span className={styles.breadcrumbArrow}>{'>'}</span>
				</>
			)}
		</div>
	);
};

export default BreadCrumbsUserSection;
