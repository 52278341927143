const CMS_SERVICE_BASE_URI = 'cms-api';

const CMS_SERVICE_IMAGE_URI = `${CMS_SERVICE_BASE_URI}/images`;
const CMS_SERVICE_IMAGE_TAG_URI = `${CMS_SERVICE_BASE_URI}/image-tags`;
const AUTHOR_URI = `${CMS_SERVICE_BASE_URI}/article-authors`;
const BLOG_TAG_URI = `${CMS_SERVICE_BASE_URI}/tags`;
const BLOG_URI = `${CMS_SERVICE_BASE_URI}/articles`;
const BLOG_ARTICLES_PUBLISHED_URI = `${BLOG_URI}/articles-published`;

const CMS_QUERY_TYPES = {
	TAGS: 'tags',
	POSTS: 'posts',
	POST: 'post',
	POSTS_PREVIEW: 'posts-preview',
};

export {
	CMS_SERVICE_IMAGE_URI,
	CMS_SERVICE_IMAGE_TAG_URI,
	AUTHOR_URI,
	BLOG_TAG_URI,
	BLOG_URI,
	CMS_QUERY_TYPES,
	BLOG_ARTICLES_PUBLISHED_URI,
};
