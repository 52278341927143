function sanitizeHTML(html: string) {
	function isPossiblyDangerous(name: string, value = '') {
		const val = value.replace(/\s+/g, '').toLowerCase();
		if (['src', 'href', 'xlink:href'].includes(name)) {
			if (val.includes('javascript:') || val.includes('data:text/html'))
				return true;
		}
		if (name.startsWith('on')) return true;
	}

	function removeDangerousAttributes(elem: Element) {
		const attributes = Array.from(elem.attributes);
		for (const { name, value } of attributes) {
			if (!isPossiblyDangerous(name, value)) continue;
			elem.removeAttribute(name);
		}
	}

	const doc = new DOMParser().parseFromString(html, 'text/html');
	const scriptTags = doc.getElementsByTagName('script');
	for (let i = scriptTags.length - 1; i >= 0; i--) {
		scriptTags[i].parentNode?.removeChild(scriptTags[i]);
	}

	const elements = doc.getElementsByTagName('*');
	for (let i = elements.length - 1; i >= 0; i--) {
		removeDangerousAttributes(elements[i]);
	}

	return doc.body.innerHTML;
}

export default sanitizeHTML;
