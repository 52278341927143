import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { LocalizationContext } from 'Services/LocalizationService';

const CartShippingDataHeader: React.FunctionComponent = (): JSX.Element => {
	const localizationContext = React.useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const navigate = useNavigate();

	const handleGoToCart = React.useCallback(() => {
		navigate(`/${t({ id: 'routes.cart.route' })}`);
	}, [LocalizationContext]);

	const handleGoToShippingPayment = React.useCallback(() => {
		navigate(`/${t({ id: 'routes.cart.shippingPayment.route' })}`);
	}, [LocalizationContext]);

	return (
		<div className="vinisto-cart-header">
			<button
				className="vinisto-cart-header__step semi-active"
				onClick={handleGoToCart}
			>
				<div className="vinisto-cart-header__step__number">
					<div className="vinisto-cart-header__step__number__circle">1</div>
				</div>
				<div className="vinisto-cart-header__step__name">
					{t({ id: 'basket' })}
				</div>
			</button>
			<button
				className="vinisto-cart-header__step semi-active"
				onClick={handleGoToShippingPayment}
			>
				<div className="vinisto-cart-header__step__number">
					<div className="vinisto-cart-header__step__number__circle">2</div>
				</div>
				<div className="vinisto-cart-header__step__name">
					{t({ id: 'basket.shippingAndPayment' })}
				</div>
			</button>
			<div className="vinisto-cart-header__step active">
				<div className="vinisto-cart-header__step__number">
					<div className="vinisto-cart-header__step__number__circle">3</div>
				</div>
				<div className="vinisto-cart-header__step__name">
					{t({ id: 'basket.deliveryDetail' })}
				</div>
			</div>
		</div>
	);
};

export default CartShippingDataHeader;
