import * as React from 'react';
import { get } from 'lodash-es';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE_KEYS } from 'Services/StorageService/constants';
import { ModalContext } from 'Components/Modal/context';
import { LOGIN_MODAL } from 'Components/Modal/constants';
import { StorageContext } from 'Services/StorageService/context';

import { AuthenticationContext } from '../AuthenticationService/context';

import { IAuthorizationServiceProps } from './interfaces';

/**
 * Require Auth Component
 * @class RequireAuth
 * @return JSX Component
 */
const RequireAuth: React.FC<IAuthorizationServiceProps> = (
	props
): JSX.Element => {
	const authenticationContext = React.useContext(AuthenticationContext);
	const modalContext = React.useContext(ModalContext);
	const navigate = useNavigate();
	const location = useLocation();
	const storageContext = React.useContext(StorageContext);

	React.useEffect(() => {
		if (!authenticationContext.isLoggedIn) {
			storageContext.StorageService.setItem(
				LOCAL_STORAGE_KEYS.LOGIN_REDIRECT_PATH,
				location
			);
			modalContext.handleOpenModal(LOGIN_MODAL, {
				onCloseCallback: () => {
					navigate(-1);
					setTimeout(() => {
						storageContext.StorageService.removeItem(
							LOCAL_STORAGE_KEYS.LOGIN_REDIRECT_PATH
						);
					}, 100);
				},
				navigateFromAuthRequired: true,
				// Forgotten password link currently redirects to the the home page!
				showForgottenPasswordLink: false,
				showRegisterCta: false,
			});
		}
	}, [
		authenticationContext,
		location,
		modalContext,
		navigate,
		storageContext.StorageService,
	]);

	return authenticationContext.isLoggedIn ? (
		<>{get(props, 'children', '')}</>
	) : (
		<></>
	);
};

export default RequireAuth;
