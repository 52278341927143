import { AbstractAdapter } from '../abstract-adapter';
import { languages } from '../../shared';

import ProductTag from '.';

import { VinistoProductDllModelsApiTagTag } from '@/api-types/product-api';

class TagAdapter extends AbstractAdapter<
	ProductTag,
	VinistoProductDllModelsApiTagTag
> {
	fromApi(apiData: VinistoProductDllModelsApiTagTag): ProductTag {
		const id = apiData.id;

		if (!id) throw new Error('No id in tag');

		return {
			id,
			name: this.convertMultiLangValue(apiData.name),
			description: this.convertMultiLangValue(apiData.description),
			metaDescription: this.convertMultiLangValue(apiData.metaDescription),
			url: this.convertMultiLangValue(apiData.url),
			language: apiData.language ?? languages[0],
			color: apiData.color,
			isDisplayBundles: apiData.isDisplayBundles ?? true,
			isEnabled: apiData.isEnabled ?? false,
			isInHomePage: apiData.isInHomePage ?? false,
		};
	}
}

export default TagAdapter;
