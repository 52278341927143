import { createContext } from 'react';
import cx from 'classnames';
import { Popover as ReactTinyPopover } from 'react-tiny-popover';

import PopoverWrapper from './Components/Wrapper';
import PopoverCloseButton from './Components/CloseButton';
import { PopoverContextValue, PopoverProps } from './interfaces';
import styles from './styles.module.css';

export const PopoverContext = createContext<PopoverContextValue>({
	close: () => {},
	isClosing: false,
});

const Popover = ({
	children,
	onClose,
	containerClassName,
	isClosing,
	...props
}: PopoverProps) => {
	return (
		<PopoverContext.Provider value={{ close: onClose, isClosing }}>
			<ReactTinyPopover
				onClickOutside={onClose}
				containerClassName={cx(styles.popover, containerClassName)}
				{...props}
			>
				{children}
			</ReactTinyPopover>
		</PopoverContext.Provider>
	);
};

Popover.Wrapper = PopoverWrapper;
Popover.CloseButton = PopoverCloseButton;

export default Popover;
