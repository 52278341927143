import { useContext } from 'react';
import useFormatMessage from 'Hooks/useFormatMessage';
import { DeviceServiceContext } from 'Services/DeviceService';
import Popover from 'Components/Popover';
import { PopoverWrapperVariants } from 'Components/Popover/Components/Wrapper/constants';

import styles from './styles.module.css';

const ProductNotAvailablePopover = () => {
	const t = useFormatMessage();
	const { isMobile, isTablet } = useContext(DeviceServiceContext);

	return (
		<Popover.Wrapper
			variant={PopoverWrapperVariants.ERROR}
			withCloseButton={isMobile || isTablet}
			className={styles.wrapper}
		>
			<p className="mb-0">
				{t(
					{ id: 'popover.productNotAvailable' },
					{
						currentlyStocking: (
							<span className="fw-bolder">
								{t({ id: 'popover.productNotAvailable.currentlyStocking' })}
							</span>
						),
					}
				)}
			</p>
		</Popover.Wrapper>
	);
};

export default ProductNotAvailablePopover;
