export const REGISTRATION_API_PATH = 'user-api/users/';
export const LOG_IN_API_PATH = 'user-api/users/auth/Login';
export const LOG_OUT_API_PATH = 'user-api/users/auth/Logout';
export const FORGOTTEN_PASSWORD_API_PATH = 'user-api/forgotten-password';
export const CONFIRM_EMAIL_API_PATH = 'user-api/users/EmailAuth';
export const IS_EMAIL_ALREADY_USED_API_PATH =
	'user-api/users/IsEmailAlreadyUsed';
export const AUTH_API_PATH = 'user-api/users/auth';

export const USER_WRONG_EMAIL = 'USER_WRONG_EMAIL';
export const USER_REGISTER_ERROR_USER_EXIST = 'USER_REGISTER_ERROR_USER_EXIST';
