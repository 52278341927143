import { useContext } from 'react';
import { isEmpty, isNull } from 'lodash-es';
import useTodayTomorrowDate from 'Hooks/useTodayTomorrowDate/useTodayTomorrowDate';
import { LocalizationContext } from 'Services/LocalizationService';

import { StockAvailabilityProps } from './interfaces';

const StockAvailability = ({
	availableQuantity,
	deliveryDate,
	fallback = null,
	isTemporaryUnavailable = false,
}: StockAvailabilityProps) => {
	const localizationContext = useContext(LocalizationContext);

	const t = localizationContext.useFormatMessage();
	const getDateLabel = useTodayTomorrowDate();

	if (isTemporaryUnavailable)
		return (
			<span className="vinisto-wine__stock--not-available">
				{t({
					id: 'bundle.temporaryUnavailable',
				})}
			</span>
		);

	if (isNull(availableQuantity) || isEmpty(availableQuantity)) return fallback;

	const isAvailable = availableQuantity.some((count) => count > 0);

	return isAvailable ? (
		<span className="vinisto-wine__stock--available">
			<span className="fw-bolder">
				{t({
					id: 'bundleAvailability.available',
				})}
			</span>

			{deliveryDate !== undefined && (
				<>
					{`, ${t(
						{ id: 'bundleAvailability.availableDeliveryDate' },
						{ day: `${getDateLabel(deliveryDate)}` }
					)}`}
				</>
			)}
		</span>
	) : (
		<span className="vinisto-wine__stock--not-available">
			{t({
				id: 'bundleAvailability.outOfStock',
			})}
		</span>
	);
};

export default StockAvailability;
