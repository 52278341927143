import { useContext } from 'react';
import { get } from 'lodash-es';
import Skeleton from 'react-loading-skeleton';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import useLocalizedDateTime from 'Hooks/useLocalizedDateTime';
import { LocalizationContext } from 'Services/LocalizationService';
import { Link } from 'react-router-dom';
import useAddItemsToBasket, {
	showBuyAgainButton,
} from 'Hooks/use-add-items-to-basket';
import { IBundleChange } from 'Services/BasketService/interfaces';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';

import { IOrderProps } from '../interfaces';
import { SKELETONS_NUM_BUNDLES } from '../constants';

import styles from './styles.module.css';

const OrderMobile = ({ isLoading = false, order = {} }: IOrderProps) => {
	const localizationContext = useContext(LocalizationContext);

	const t = localizationContext.useFormatMessage();
	const getLocalizedDate = useLocalizedDateTime();

	const orderId = order.id;
	const orderDate = order.date;
	const orderCurrency = order.orderCurrency;
	const orderState = order.localizedState;
	const orderNumber = order.orderNumber || orderId;
	const orderTotalPriceWithVAT = order?.orderPriceWithVat ?? 0;
	const trackingLink = order.delivery?.trackingUrl ?? '';

	const { addItemsToBasket } = useAddItemsToBasket();

	const itemsToAdd: IBundleChange[] = order?.orderItems
		?.filter((orderItem: any) => !orderItem.bundle.isGift)
		.map((orderItem: any) => {
			const id = orderItem?.bundle?.id ?? '';

			return {
				bundleId: id,
				quantity: orderItem.quantity,
			};
		});

	return (
		<div className={styles.order}>
			<div className={styles.spaceBetween}>
				<div className={styles.orderNumber}>
					{isLoading ? (
						<Skeleton width="180px" />
					) : (
						t({ id: 'userSection.order.title' }, { value: orderNumber })
					)}
				</div>
				<div
					className={styles.orderStatus}
					style={{
						color: orderState?.color ?? '',
					}}
				>
					{isLoading ? <Skeleton width="120px" /> : t({ id: orderState.text })}
				</div>
			</div>
			<div className={styles.spaceBetween}>
				<div>
					<span className={styles.priceInfo}>
						{`${t({
							id: 'userSection.order.price.total.mobile',
						})} `}
					</span>
					<span className={styles.totalPrice}>
						{isLoading ? (
							<Skeleton width="160px" />
						) : (
							getLocalizedPrice({
								price: orderTotalPriceWithVAT,
								currency: orderCurrency,
							})
						)}
					</span>
				</div>
				<div className={styles.date}>
					{isLoading ? (
						<Skeleton width="85px" />
					) : (
						orderDate && getLocalizedDate(orderDate)
					)}
				</div>
			</div>
			<div className={styles.productsPreview}>
				{isLoading ? (
					Array(SKELETONS_NUM_BUNDLES).map((index: number) => (
						<div key={`order-item-mobile-bundle-${index}-skeleton`}>
							<Skeleton
								width="28px"
								height="3.125rem"
							/>
						</div>
					))
				) : (
					<>
						<>
							{order?.orderItems?.map(
								(orderItem: Record<any, any>, index: number) => (
									<div
										className={styles.productPreview}
										key={`order-item-mobile-bundle-${get(
											orderItem,
											'bundle.id',
											index
										)}-closed`}
									>
										<div className={styles.productPreviewCount}>
											{orderItem.quantity ?? 0}
										</div>
										<img
											src={getBundleImage(
												[get(orderItem, 'bundle.mainImage', {})],
												IMAGE_SIZE_THUMB_64x80
											)}
											alt={`${t({ id: 'alt.bundleImage' })}`}
										/>
									</div>
								)
							)}
						</>
						<>
							{order?.giftItems?.map(
								(giftItem: Record<any, any>, index: number) => (
									<div
										className={styles.productPreview}
										key={`order-item-mobile-giftBundle-${giftItem.bundle.id}-${index}-closed`}
									>
										<div className={styles.productPreviewCount}>
											{giftItem.quantity}
										</div>
										<img
											src={getBundleImage(
												[giftItem.bundle.mainImage ?? {}],
												IMAGE_SIZE_THUMB_64x80
											)}
											alt={`${t({ id: 'alt.bundleImage' })}`}
										/>
									</div>
								)
							)}
						</>
					</>
				)}
			</div>
			<div className={styles.buttons}>
				{!showBuyAgainButton(order.state) && trackingLink && (
					<a
						href={trackingLink}
						className={styles.detailsButton}
					>
						Sledovat zásilku
					</a>
				)}
				{showBuyAgainButton(order.state) && (
					<button
						className={styles.buyAgainButton}
						disabled={isLoading}
						onClick={async () => await addItemsToBasket(itemsToAdd)}
					>
						{t({ id: 'userSection.order.btn.orderAgain' })}
					</button>
				)}
				<Link
					className={styles.detailsButton}
					to={`/${t({ id: 'routes.user-section.route' })}/${t({
						id: 'routes.user-section.orders.route',
					})}?id=${orderId}`}
				>
					{t({ id: 'userSection.order.btn.details' })}
				</Link>
			</div>
		</div>
	);
};

export default OrderMobile;
