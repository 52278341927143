import { lazy, Suspense, useCallback, useContext } from 'react';
import cx from 'classnames';
import { get, uniqueId } from 'lodash-es';
import { LocalizationContext } from 'Services/LocalizationService';
import { BasketContext } from 'Services/BasketService';
import Loader from 'Components/View/Loader';
const AddToCartButtonMinus = lazy(
	() => import('Components/Icons/AddToCartButtonMinus')
);
const AddToCartButtonPlus = lazy(
	() => import('Components/Icons/AddToCartButtonPlus')
);
const BottleTimesIcon = lazy(() => import('Components/Icons/BottleTimes'));
const CloseIcon = lazy(() => import('Components/Icons/Close'));
import useLocalizedValue from 'Hooks/useLocalizedValue';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import Rating from 'Components/Rating';
import { getDiscountPriceValues } from 'vinisto_shared/src/price/get-discount-prices';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';

import { IInActiveCatItemProps } from './interfaces';

import { VinistoHelperDllEnumsCurrency } from '@/api-types/product-api';

const InActiveCartItem = ({ cartItem }: IInActiveCatItemProps) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const {
		convertToActiveCurrencyIfPriceCurrencyIsDifferent,
		activeCurrency: { currency },
	} = localizationContext;
	const basketContext = useContext(BasketContext);
	const handleOnChangeItemQuantity = get(
		basketContext,
		'handleOnChangeItemQuantity',
		() => {}
	);

	const bundleId = cartItem?.bundleId ?? '';
	const bundleData = cartItem.bundle ?? null;
	const supplierName = bundleData?.supplier?.nameWeb ?? '';
	const bundleQuantity = cartItem.quantity ?? 1;
	const bundleEvaluation = bundleData?.bundleEvaluation ?? null;
	const bundleAvgRating = bundleEvaluation?.averageStars
		? bundleEvaluation.averageStars / 2
		: 0;
	const bundleTotalRatingCount = get(
		bundleEvaluation,
		'totalEvaluationCount',
		0
	);
	const handleOnReturnItemToBasket = useCallback(() => {
		handleOnChangeItemQuantity(bundleQuantity, bundleId);
	}, [get(basketContext, 'basketState')]);

	const getLocalizedValue = useLocalizedValue();

	const basePrice = cartItem?.itemPrice;

	const discountedPrice = cartItem?.itemDiscountPrice;

	const { discountedPriceWithoutVat, discountedPriceWithVat, isDiscounted } =
		getDiscountPriceValues({
			quantityInBasket: bundleQuantity,
			basePrice,
			discountedPrice,
		});

	const priceWithVat = discountedPriceWithVat ?? basePrice?.valueWithVat;

	const priceWithoutVat = discountedPriceWithoutVat ?? basePrice?.value;

	const totalPriceWithVAT =
		convertToActiveCurrencyIfPriceCurrencyIsDifferent({
			price: priceWithVat ?? 0,
			activeCurrency: currency,
			// @ts-expect-error Enum vs enum value issue
			priceCurrency: basePrice?.currency ?? VinistoHelperDllEnumsCurrency.CZK,
		}) * bundleQuantity;

	const totalPriceWithoutVAT =
		convertToActiveCurrencyIfPriceCurrencyIsDifferent({
			price: priceWithoutVat ?? 0,
			activeCurrency: currency,
			// @ts-expect-error Enum vs enum value issue
			priceCurrency: basePrice?.currency ?? VinistoHelperDllEnumsCurrency.CZK,
		}) * bundleQuantity;

	const originalTotalPriceWithVAT = isDiscounted
		? convertToActiveCurrencyIfPriceCurrencyIsDifferent({
				price: basePrice?.valueWithVat ?? 0,
				activeCurrency: currency,
				// @ts-expect-error Enum vs enum value issue
				priceCurrency: basePrice?.currency ?? VinistoHelperDllEnumsCurrency.CZK,
		  }) * bundleQuantity
		: null;

	const flagSpecification = getFlagSpecification(
		bundleData?.specificationDetails as Record<string, any>[]
	);

	return (
		<div
			className={cx(
				'vinisto-user-orders__orders__order-body__item vinisto-cart__item',
				{ discounted: isDiscounted }
			)}
		>
			<div className="vinisto-user-orders__orders__order-body__item__info">
				<div className="vinisto-user-orders__orders__order-body__item__info__img">
					<img
						src={getBundleImage(
							get(bundleData, 'images', []),
							IMAGE_SIZE_THUMB_64x80
						)}
						alt={`${t({ id: 'alt.bundleImage' })}`}
					/>
				</div>
				<div className="vinisto-user-orders__orders__order-body__item__info__data">
					<div className="vinisto-user-orders__orders__order-body__item__info__data__name">
						{getLocalizedValue(bundleData?.name ?? []) ?? '-'}
					</div>
					<div className="vinisto-user-orders__orders__order-body__item__info__data__score vinisto-wine__review">
						<Rating
							defaultValue={bundleAvgRating}
							readOnly
						/>
						<span className="vinisto-wine__review__wrap-count">
							({bundleTotalRatingCount})
						</span>
					</div>
					<div className="vinisto-user-orders__orders__order-body__item__info__data__winery">
						{get(flagSpecification, 'component') &&
							get(flagSpecification, 'component')}
						<span>
							{get(flagSpecification, 'shortVariety') &&
								get(flagSpecification, 'shortVariety')}
						</span>
					</div>
				</div>
			</div>
			<div className="vinisto-user-orders__orders__order-body__item__prices">
				<div className="vinisto-user-orders__orders__order-body__item__prices__total">
					{isDiscounted && (
						<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
							{getLocalizedPrice({
								price: basePrice?.valueWithVat ?? 0,
								currency,
							})}
						</span>
					)}
					<span className="vinisto-user-orders__orders__order-body__item__prices__big-price-discounted">
						{getLocalizedPrice({
							price: priceWithVat ?? 0,
							currency,
						})}
					</span>
				</div>
				<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
					{`${t({ id: 'basket.priceWithoutVAT' })} `}
					<span className="fw-bolder price-span">
						{getLocalizedPrice({
							price: priceWithoutVat ?? 0,
							currency,
						})}
					</span>
				</div>
				<div className="vinisto-cart__supplier-name">
					{t(
						{ id: 'bundle.supplier.name' },
						{
							name: (
								<span className="vinisto-color-success fw-bolder">
									{supplierName.length > 0
										? supplierName
										: t({
												id: 'productDetail.seller.name.others',
										  })}
								</span>
							),
						}
					)}
				</div>
			</div>
			<div className="vinisto-wine__count">
				<button className="vinisto-wine__count__minus btn-plus-minus">
					<Suspense fallback={<Loader blank />}>
						<AddToCartButtonMinus
							id={uniqueId()}
							alt={t({ id: 'alt.less' })}
							title={``}
							className={``}
						/>
					</Suspense>
				</button>
				<label className="vinisto-wine__count__amount-label">
					<Suspense fallback={<Loader blank />}>
						<BottleTimesIcon
							id={uniqueId()}
							alt={t({ id: 'alt.numberOfBottles' })}
							title={``}
							className={`BottleTimesIcon`}
						/>
					</Suspense>
					<input
						type="number"
						min="1"
						onChange={() => {}}
						value={cartItem.quantity ?? 1}
						name="vinisto-wine__count__amount"
						className="vinisto-wine__count__amount"
					/>
				</label>
				<button className="vinisto-wine__count__plus btn-plus-minus">
					<Suspense fallback={<Loader blank />}>
						<AddToCartButtonPlus
							id={uniqueId()}
							alt={t({ id: 'alt.more' })}
							title={``}
							className={``}
						/>
					</Suspense>
				</button>
			</div>
			<div className="vinisto-cart__item__price-remove">
				<div className="vinisto-user-orders__orders__order-body__item__prices">
					<div className="vinisto-user-orders__orders__order-body__item__prices__total">
						{`${t({ id: 'basket.totalPrice' })} `}
						{isDiscounted && (
							<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
								{getLocalizedPrice({
									price: originalTotalPriceWithVAT ?? 0,
									currency,
								})}
							</span>
						)}
						<span className="vinisto-user-orders__orders__order-body__item__prices__big-price-discounted">
							{getLocalizedPrice({
								price: totalPriceWithVAT,
								currency,
							})}
						</span>
					</div>
					<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
						{`${t({ id: 'basket.priceWithoutVAT' })} `}
						<span className="fw-bolder price-span">
							{getLocalizedPrice({
								price: totalPriceWithoutVAT,

								currency,
							})}
						</span>
					</div>
				</div>

				<div className="vinisto-user-favorite__close">
					<Suspense fallback={<Loader blank />}>
						<CloseIcon
							id={uniqueId()}
							alt={t({ id: 'alt.delete' })}
							title={``}
							className={`CloseIcon `}
						/>
					</Suspense>
				</div>
			</div>
			<div className="vinisto-user-favorite__delete-wrap">
				<div className="vinisto-user-favorite__delete-wrap__text">
					{t({ id: 'basket.removed.label' })}
					<br />
					<button
						className="vinisto-btn vinisto-clear-btn vinisto-color-success fw-bold"
						onClick={handleOnReturnItemToBasket}
					>
						{t({ id: 'basket.removed.returnBack' })}
					</button>
				</div>
			</div>
		</div>
	);
};

export default InActiveCartItem;
