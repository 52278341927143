import { ReactNode, useContext } from 'react';
import { Link } from 'react-router-dom';
import { LocalizationContext } from 'Services/LocalizationService';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';

import styles from './styles.module.css';

import { VinistoOrderDllModelsApiOrderOrderItem } from '@/api-types/order-api';
import { VinistoHelperDllEnumsCurrency } from '@/api-types/product-api';

interface ProductMobileProps {
	isLoading: boolean;
	orderItem: VinistoOrderDllModelsApiOrderOrderItem;
	index: number;
	url: string;
	flagComponent: ReactNode;
	producerSpecification: string;
	quantity: number;
	totalPriceWithVAT: number;
	orderCurrency: VinistoHelperDllEnumsCurrency;
	totalPriceWithoutVAT: number;
}

const ProductMobile = ({
	isLoading,
	orderItem,
	url,
	flagComponent,
	producerSpecification,
	quantity,
	totalPriceWithVAT,
	orderCurrency,
	totalPriceWithoutVAT,
}: ProductMobileProps) => {
	const t = useContext(LocalizationContext).useFormatMessage();

	return (
		<div className={styles.product}>
			{!isLoading && (
				<Link
					className={styles.overlay}
					to={`/${t({
						id: 'routes.product.route',
					})}/${url}`}
				/>
			)}
			<div className={styles.productImg}>
				<img
					src={getBundleImage(
						[orderItem.bundle.mainImage ?? {}],
						IMAGE_SIZE_THUMB_64x80
					)}
					alt={`${t({
						id: 'alt.bundleImage',
					})}`}
				/>
			</div>
			<div className={styles.productName}>{orderItem.bundle.name ?? ''}</div>
			<div className={styles.productProducer}>
				<BundleProducer
					flag={flagComponent}
					name={producerSpecification}
				/>
			</div>
			<div className={styles.productCount}>
				{t(
					{ id: 'order.pcs' },
					{
						count: quantity,
					}
				)}
			</div>
			<div className={styles.productPrice}>
				{getLocalizedPrice({
					price: totalPriceWithVAT,
					currency: orderCurrency,
				})}
			</div>
			<div className={styles.productSeller}>
				prodejce:{' '}
				<span className={styles.sellerName}>
					{orderItem?.bundle?.supplierDetail?.nameWeb ?? ''}
				</span>
			</div>
			<div className={styles.productPriceNoVat}>
				{t(
					{
						id: 'userSection.order.price.totalWithoutVat',
					},
					{
						value: (
							<span className={styles.productPriceNoVatPrice}>
								{getLocalizedPrice({
									price: totalPriceWithoutVAT,
									currency: orderCurrency,
								})}
							</span>
						),
					}
				)}
			</div>
		</div>
	);
};

export default ProductMobile;
