import * as React from 'react';
import { get } from 'lodash-es';
import { useLocation } from 'react-router-dom';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import AuthenticationService from 'Services/AuthenticationService';

import { INTERVAL_TO_AUTH_USER } from './constants';
import { IAuthorizationServiceProps } from './interfaces';

/**
 * Authentication Service
 * @class AuthorizationService
 * @return JSX Component
 */
const AuthorizationService: React.FC<IAuthorizationServiceProps> = (
	props: IAuthorizationServiceProps
): JSX.Element => {
	const { children } = props;
	const location = useLocation();
	const authenticationContext = React.useContext(AuthenticationContext);
	const loginHash = authenticationContext?.vinistoUser?.loginHash ?? null;
	const isLoggedIn = authenticationContext?.isLoggedIn;

	const handleOnAuthUser = React.useCallback(() => {
		const authenticationService = new AuthenticationService();
		authenticationService
			.auth(loginHash)
			.catch(() => authenticationContext.handleOnForceLogOut());
	}, [loginHash]);

	React.useEffect(() => {
		// If location is not changed, auth user after certain interval
		if (isLoggedIn && get(location, 'state.authUser', true)) {
			handleOnAuthUser();
			const interval = setInterval(() => {
				handleOnAuthUser();
			}, INTERVAL_TO_AUTH_USER);
			return () => clearInterval(interval);
		}
	}, [location.pathname, isLoggedIn]);

	return <>{children}</>;
};

export default AuthorizationService;
