import { forwardRef, lazy, Suspense, useContext } from 'react';
import cx from 'classnames';
import { LocalizationContext } from 'Services/LocalizationService';
import { BasketContext } from 'Services/BasketService';
const CartIcon = lazy(() => import('Components/Icons/Cart'));
import Loader from 'Components/View/Loader';
import { TestIdType } from 'Constants/test-ids';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';

import styles from './styles.module.css';

type BasketSize = 'xs' | 'sm' | 'md';

type BasketUIProps = {
	price: string | null;
	count?: number;
	showCount?: boolean;
	showPrice?: boolean;
	className?: string;
	size?: BasketSize;
	inverted?: boolean;
	onClick?: () => void;
	isNavBottom?: boolean;
	dataTestid?: TestIdType;
};

const BasketUI = forwardRef<HTMLDivElement, BasketUIProps>(
	(
		{
			showCount = true,
			showPrice = false,
			count = 0,
			price = null,
			onClick,
			className,
			size,
			inverted = false,
			dataTestid,
		},
		forwardedRef
	) => {
		const localizationContext = useContext(LocalizationContext);
		const t = localizationContext.useFormatMessage();

		return (
			<div
				className={cx(
					styles.wrapper,
					{ [styles.inverted]: inverted },
					className
				)}
				onClick={onClick}
				role="presentation"
				ref={forwardedRef}
				data-testid={dataTestid}
			>
				<div
					className={cx(styles.iconWrapper, {
						[styles.sizeXs]: size === 'xs',
						[styles.sizeSm]: size === 'sm',
						[styles.sizeMd]: size === 'md',
					})}
				>
					<Suspense fallback={<Loader blank={true} />}>
						<CartIcon
							className={styles.icon}
							fill={inverted ? null : undefined}
						/>
					</Suspense>
					{showCount && (
						<span className={cx(styles.count, styles.countBgGreen)}>
							{count}
						</span>
					)}
				</div>
				{showPrice && (
					<span className={styles.price}>{price ?? t({ id: 'basket' })}</span>
				)}
			</div>
		);
	}
);

BasketUI.displayName = 'BasketUI';

type BasketProps = Omit<BasketUIProps, 'price' | 'count'>;

const Basket = forwardRef<HTMLDivElement, BasketProps>(
	(props, forwardedRef) => {
		const { basketPriceWithVat, itemsQuantity, basketState } =
			useContext(BasketContext);
		const count = itemsQuantity ?? 0;

		const currency = basketState?.currency;

		const price =
			basketPriceWithVat && currency !== undefined
				? getLocalizedPrice({
						price: basketPriceWithVat,
						currency: currency,
				  }).replace('-0', '0')
				: null;

		return (
			<BasketUI
				{...props}
				count={count}
				price={price}
				ref={forwardedRef}
			/>
		);
	}
);

Basket.displayName = 'Basket';

export default Basket;
