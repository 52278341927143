import { apiServiceInstance } from 'Services/ApiService';
import {
	VinistoHelperDllEnumsCurrency,
	VinistoProductDllModelsApiFullSearchFullSearchSeparatedReturn,
} from 'vinisto_api_client/src/api-types/product-api';

import { VinistoHelperDllEnumsCountryCode } from '@/api-types/warehouse-api';

type FetchFullTextSearchResultsProps = {
	SearchString: string;
	Collections: ('BUNDLE' | 'CATEGORY')[];
	Page: number;
	Limit: number;
	UserLoginHash: string;
	Currency: VinistoHelperDllEnumsCurrency;
	countryOfSale: `${VinistoHelperDllEnumsCountryCode}`;
};

export const fetchFullTextSearchResults = ({
	SearchString,
	Collections,
	Page,
	Limit,
	UserLoginHash,
	Currency = VinistoHelperDllEnumsCurrency.CZK,
	countryOfSale,
}: FetchFullTextSearchResultsProps) => {
	const filters = [
		{
			key: 'IsCache',
			value: true,
		},
		{
			key: 'SearchString',
			value: SearchString,
		},
		{
			key: 'Limit',
			value: Limit,
		},
		{
			key: 'Offset',
			value: (Page - 1) * Limit,
		},
		/*{
			key: 'IsB2b',
			value: false,
		},*/
		{
			key: 'UserLoginHash',
			value: UserLoginHash,
		},
		{
			key: 'currency',
			value: Currency,
		},
		{
			key: 'countryOfSale',
			value: countryOfSale,
		},
	];

	if (Collections.length > 0) {
		Collections.forEach((collection) => {
			filters.push({
				key: 'Collections',
				value: collection,
			});
		});
	}

	return apiServiceInstance.get<VinistoProductDllModelsApiFullSearchFullSearchSeparatedReturn>(
		'search-api/full-search/search-string-separate-results',
		true,
		undefined,
		filters
	);
};
