import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { LocalizationContext } from 'Services/LocalizationService';
import BundleService from 'vinisto_api_client/src/product-service/bundle';
import { WarehouseContext } from 'Services/WarehouseService';

import { BundlesGetBundlesCreatePayload } from '@/api-types/product-api';
import { bundleAdapter } from '@/index';

export const useBundlesByTag = (
	tagId: string,
	params: BundlesGetBundlesCreatePayload
) => {
	const localizationContext = useContext(LocalizationContext);
	const { activeCurrency, countryOfSale } = localizationContext;
	const warehouseContext = useContext(WarehouseContext);

	const carouselsQuery = useQuery(
		['bundles-by-tag', tagId, { countryOfSale, currency: activeCurrency }],
		async () => {
			const bundles = await BundleService.getBundlesByTag(tagId, params).then(
				(bundles) =>
					bundles.map((bundle) =>
						bundleAdapter.fromApi(bundle, { currency: activeCurrency.currency })
					) ?? []
			);

			warehouseContext.fetchQuantity(bundles.map((bundle) => bundle.id));

			return bundles;
		}
	);

	return carouselsQuery;
};
