import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import {
	ceil,
	every,
	fill,
	first,
	flatten,
	get,
	head,
	includes,
	invoke,
	isNaN,
	last,
	map,
	range,
	reduce,
	reverse,
	size,
	some,
	uniqueId,
} from 'lodash-es';
import { useLocation } from 'react-router-dom';
import ReactSlider from 'react-slider';
import {
	DelimitedNumericArrayParam,
	NumberParam,
	useQueryParam,
	withDefault,
} from 'use-query-params';
import { useQueries } from '@tanstack/react-query';
import { DEFAULT_STAR_COUNT } from 'Components/Rating/constants';
import createFormattedDecimalNumber from 'Helpers/createFormattedDecimalNumber';
import ApiService from 'Services/ApiService';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { LocalizationContext } from 'Services/LocalizationService';
import { ModalContext } from 'Components/Modal/context';
import { NotificationsContext } from 'Services/NotificationService';
import { DeviceServiceContext } from 'Services/DeviceService';
import {
	ADD_TO_BASKET_MODAL,
	FILL_NICKNAME_MODAL,
	LOGIN_MODAL,
	REVIEW_MODAL,
} from 'Components/Modal/constants';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import Rating from 'Components/Rating';

import {
	API_EVALUATIONS_URL,
	DEFAULT_PAGE,
	QUERY_KEY,
	ReviewSortingType,
	SORTING,
} from './constants';
import { IReviewSectionProps } from './interfaces';
import { fetchReviewPage } from './helpers';
import Review from './Components/Review';
import styles from './styles.module.css';
import './styles.css';
import Info from './Components/Info';

const ReviewSection = (props: IReviewSectionProps) => {
	const authenticationContext = useContext(AuthenticationContext);
	const localizationContext = useContext(LocalizationContext);
	const modalContext = useContext(ModalContext);
	const notificationsContext = useContext(NotificationsContext);
	const deviceContext = useContext(DeviceServiceContext);

	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();

	const isLoading = get(props, 'isLoading', false);
	const bundle = get(props, 'bundle', null);
	const bundleId = bundle?.id;
	const productId = bundle?.items?.[0]?.productId ?? '';
	const bundleName = getLocalizedValue(get(bundle, 'name', []));

	const bundleAvgRating = get(
		bundle,
		'bundleEvaluation.averageStarsDecimal',
		0
	);
	const totalEvaluationCount = get(
		bundle,
		'bundleEvaluation.totalEvaluationCount',
		0
	);
	const starCategoryEvaluationCount = get(
		bundle,
		'bundleEvaluation.starCategoryEvaluationCount',
		{}
	);
	const ratingCounts = useMemo(
		() =>
			reverse(
				reduce(
					starCategoryEvaluationCount,
					(counts, rating: number, index: string) => {
						counts[ceil(Number(index) / 2) - 1] += rating;
						return counts;
					},
					fill(range(DEFAULT_STAR_COUNT), 0)
				)
			),
		[starCategoryEvaluationCount]
	);

	// refresh slider when data change
	const ratingCountSliderKeySuffix = useMemo(() => uniqueId(), [ratingCounts]);
	const reviewsSectionRef = useRef<HTMLDivElement>(null);
	const location = useLocation();

	const [page, setPage] = useQueryParam(
		'page',
		withDefault(DelimitedNumericArrayParam, DEFAULT_PAGE)
	);
	const currentPage = last(page) ?? 1;
	const [limit] = useQueryParam<number>('limit', withDefault(NumberParam, 10));
	/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
	const [sorting, setSorting] = useState<ReviewSortingType>(
		SORTING.NEWEST as ReviewSortingType
	);
	/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
	const [isSortingDesc, setIsSortingDesc] = useState<boolean>(true);
	const queries = useMemo(() => {
		if (productId === null) return [];

		const pagesToFetch =
			size(page) === 2
				? range(first(page) ?? 1, (last(page) ?? 1) + 1)
				: (page as number[]);
		return map(pagesToFetch, (pageNum) => ({
			queryKey: [QUERY_KEY, { productId, sorting, isSortingDesc }, pageNum],
			queryFn: () =>
				fetchReviewPage({
					page: pageNum,
					limit,
					productId,
					sorting,
					isSortingDesc,
				}),
			cacheTime: 0,
			staleTime: 0,
			enabled: !!productId,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		}));
	}, [productId, page, sorting, isSortingDesc, limit]);

	const reviewsData = useQueries({ queries });

	const reviewCount = useMemo(
		() =>
			reduce(
				reviewsData,
				(count, query) => count + (query.data?.count ?? 0),
				0
			),
		[reviewsData]
	);

	const isLoadingReviews = useMemo(
		() => some(reviewsData, { isLoading: true }),
		[reviewsData]
	);
	const reviews = useMemo(() => {
		if (isLoading) return map(Array(limit), () => ({ isLoading: true }));

		const reviews = flatten(
			map(reviewsData, (page) => get(page, 'data.evaluations', []))
		);
		if (isLoadingReviews) {
			let reviewsLeft =
				reviewCount - (currentPage === 1 ? 1 : currentPage - 1) * limit;
			if (reviewsLeft < 1) reviewsLeft = reviewCount;
			if (reviewsLeft > limit) reviewsLeft = limit;
			return [
				...reviews,
				...map(Array(reviewsLeft), () => ({ isLoading: true })),
			];
		}
		return reviews;
	}, [isLoadingReviews, reviewsData, reviewCount]);

	const showReviewModal = useCallback(() => {
		const apiService = new ApiService();
		apiService
			.get('product-api/evaluations/CanEvaluate', true, undefined, [
				{
					key: 'UserLoginHash',
					value: get(authenticationContext, 'vinistoUser.loginHash', ''),
				},
				{
					key: 'bundleId',
					value: String(bundleId),
				},
			])
			.then((response: Record<string, any>) => {
				if (get(response, 'result', false)) {
					apiService
						.get(API_EVALUATIONS_URL, true, undefined, [
							{
								key: 'UserId',
								value: get(authenticationContext, 'vinistoUser.id', ''),
							},
							{
								key: 'ProductId',
								value: productId,
							},
						])
						.then((response: Record<string, any>) => {
							const reviewData = head(get(response, 'evaluations', []));
							modalContext.handleOpenModal(REVIEW_MODAL, {
								bundleId,
								forceReload: () => {
									reviewsData.forEach((query) => query.refetch());
									invoke(props, 'refreshBundleData');
								},
								title: t(
									{ id: 'modal.review.modalTitle' },
									{ name: bundleName }
								),
								reviewData,
								bundleData: bundle,
							});
						});
				} else {
					modalContext.handleOpenModal(ADD_TO_BASKET_MODAL, {
						bundleData: bundle,
					});
				}
			})
			.catch(() => {
				notificationsContext.handleShowErrorNotification(
					'notification.message.bundleDetail.canComment.error'
				);
			});
	}, [
		authenticationContext,
		modalContext,
		notificationsContext,
		productId,
		reviewsData,
	]);

	const [openReviewModalAfterLogin, setOpenReviewModalAfterLogin] =
		useState(false);
	const handleOnOpenReviewModal = useCallback(() => {
		if (!authenticationContext.isLoggedIn) {
			modalContext.handleOpenModal(LOGIN_MODAL);
			setOpenReviewModalAfterLogin(true);
		} else if (get(authenticationContext, 'vinistoUser.nickname', null)) {
			showReviewModal();
		} else {
			modalContext.handleOpenModal(FILL_NICKNAME_MODAL, {
				showReviewModal,
			});
		}
	}, [authenticationContext, modalContext, showReviewModal]);

	useEffect(() => {
		if (openReviewModalAfterLogin && authenticationContext.isLoggedIn) {
			handleOnOpenReviewModal();
			setOpenReviewModalAfterLogin(false);
		}
	}, [
		openReviewModalAfterLogin,
		authenticationContext.isLoggedIn,
		handleOnOpenReviewModal,
	]);

	useEffect(() => {
		if (
			size(page) > 2 ||
			!every(page, (pageNum) => !isNaN(pageNum)) ||
			(size(page) === 2 && get(page, '[0]', 1) > get(page, '[1]', 2))
		)
			return setPage(DEFAULT_PAGE);
	}, [page]);

	useEffect(() => {
		if (
			reviewsSectionRef.current &&
			includes(location.hash, 'vinisto-reviews')
		) {
			const y =
				reviewsSectionRef.current?.getBoundingClientRect()?.top +
				window.scrollY;
			window.scrollTo({ top: y - 200, left: 0, behavior: 'smooth' });
		}
	}, [reviewsSectionRef.current, location.hash]);

	return (
		<div className={props.className}>
			<div
				className={cx(styles.reviewSection)}
				ref={reviewsSectionRef}
				style={{ transform: 'scale(0.85)', transformOrigin: 'left top' }}
			>
				<div className={styles.ratingMainHeadingWrap}>
					<h2 className={styles.ratingMainHeading}>
						{t({
							id:
								totalEvaluationCount > 0
									? 'productDetail.rating.heading'
									: 'productDetail.rating.noRating',
						})}
					</h2>
					<Info />
				</div>

				{totalEvaluationCount > 0 ? (
					<div className={styles.ratingSummaryContainer}>
						<div className={styles.ratingSummary}>
							<p className={styles.ratingSummaryTotalScore}>
								{isLoading ? (
									<Skeleton />
								) : (
									createFormattedDecimalNumber(bundleAvgRating, 1)
								)}
							</p>
							{isLoading ? (
								<Skeleton
									width="13px"
									count={DEFAULT_STAR_COUNT}
									style={{ margin: '0 .125rem' }}
									inline
								/>
							) : (
								<div className={styles.ratingSummaryStars}>
									<Rating
										defaultValue={bundleAvgRating}
										readOnly
									/>
								</div>
							)}
							<p className={styles.ratingSummaryCount}>
								{isLoading ? (
									<Skeleton />
								) : (
									t(
										{ id: 'productDetail.rating.rating' },
										{
											count: totalEvaluationCount,
										}
									)
								)}
							</p>

							<div>
								{isLoading ? (
									<Skeleton
										width="170px"
										height="30px"
									/>
								) : (
									<button
										className={cx('vinisto-btn', styles.reviewBtn)}
										onClick={handleOnOpenReviewModal}
									>
										{t({ id: 'productDetail.btn.giveReview' })}
									</button>
								)}
							</div>
						</div>
						<div className={styles.ratingTable}>
							{map(ratingCounts, (count, index) => (
								<div
									className={styles.ratingTableRow}
									key={index}
								>
									{isLoading ? (
										<Skeleton width="30px" />
									) : (
										<span className={styles.ratingDescription}>
											{DEFAULT_STAR_COUNT - index}
										</span>
									)}
									{isLoading ? (
										<div style={{ minWidth: 'max-content' }}>
											<Skeleton
												width="15px"
												style={{ margin: '0 .125rem' }}
												inline
											/>
										</div>
									) : (
										<Rating
											starCount={1}
											defaultValue={DEFAULT_STAR_COUNT - index}
											readOnly
										/>
									)}
									{isLoading ? (
										<Skeleton
											width="175px"
											style={{ margin: '0 .125rem' }}
										/>
									) : (
										<ReactSlider
											className={cx('vinisto-slider', styles.ratingSlider)}
											thumbClassName={count > 0 ? styles.ratingSliderThumb : ''}
											trackClassName={styles.ratingSliderTrack}
											value={count}
											max={totalEvaluationCount}
											key={`rating-slider-${index}-${ratingCountSliderKeySuffix}`}
											disabled
										/>
									)}
									{isLoading ? (
										<Skeleton width="30px" />
									) : (
										<span className={styles.ratingDescription}>
											{count} &times;{' '}
										</span>
									)}
								</div>
							))}
						</div>
					</div>
				) : (
					<div
						className={styles.ratingSummaryContainer}
						style={{ marginTop: '0.5rem' }}
					>
						<div
							className={styles.ratingSummaryStars}
							style={{
								transform: 'scale(1.875)',
								transformOrigin: 'left center',
								marginRight: '5rem',
								paddingTop: '0.2rem',
							}}
						>
							<Rating
								defaultValue={bundleAvgRating}
								readOnly
							/>
						</div>
						<button
							className={cx('vinisto-btn', styles.reviewBtn)}
							onClick={handleOnOpenReviewModal}
						>
							{t({ id: 'productDetail.btn.giveReview' })}
						</button>
					</div>
				)}

				<div
					className={cx('col-12 py-0', {
						'tabs-content rounded-tab-content-corners mt-0':
							!(deviceContext.isMobile || deviceContext.isTablet) &&
							reviewCount > 0,
						'mt-3': deviceContext.isMobile || deviceContext.isTablet,
					})}
				>
					<ul className="px-0 my-0 d-flex flex-column">
						{map(reviews, (review, key) => (
							<li
								key={`product-review-${key}`}
								className="vinisto-reviews__review-wrap"
							>
								{/* @ts-expect-error: The logic is hard to decypher, needs refactor */}
								<Review
									{...{
										review,
										isLoading: get(review, 'isLoading', false),
									}}
								/>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default ReviewSection;
