import { get } from 'lodash-es';
import { VinistoProductDllModelsApiTagTagReturn } from 'vinisto_api_client/src/api-types/product-api';
import ApiService from 'Services/ApiService';

import { IFetchTagByUrlProps } from './interfaces';

const apiService = new ApiService();

export const fetchTagByUrl = async (props: IFetchTagByUrlProps) => {
	const tagUrl = get(props, 'tagUrl');
	if (!tagUrl) throw new Error('Tag URL is missing.');
	const tagData = await apiService.get<VinistoProductDllModelsApiTagTagReturn>(
		`product-api/tags/${tagUrl}/GetTagByUrl`,
		false
	);
	return tagData;
};
