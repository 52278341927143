import { lazy, Suspense, useContext } from 'react';
import cx from 'classnames';
import { GiftInfoProps } from 'Pages/Cart/Components/GiftInfo/interfaces';
import { LocalizationContext } from 'Services/LocalizationService';
import createCurrencyValue from 'Helpers/createCurrencyValue';

import styles from './styles.module.css';

const InfoIcon = lazy(() => import('Components/Icons/Info'));

const GiftInfo = ({
	areGiftsRefused,
	possibleGifts,
	giftsLeftToSpent,
	handleOnAcceptGifts,
}: GiftInfoProps) => {
	const localizationContext = useContext(LocalizationContext);

	const t = localizationContext.useFormatMessage();

	const giftleftTospentInfo = t(
		{
			id: 'giftInfo.description',
		},
		{
			orderPriceLimitFrom: (
				<span className="fw-bold">
					{createCurrencyValue(giftsLeftToSpent)}{' '}
					{localizationContext.activeCurrency.title}
				</span>
			),
		}
	);
	const giftDescription = areGiftsRefused
		? t(
				{
					id: 'giftInfo.description.acceptGifts',
				},
				{
					freeValue: (
						<span className="fw-bold">
							{t({
								id: 'basket.price.free',
							})}
						</span>
					),
				}
		  )
		: t(
				{
					id: 'giftInfo.description.productName',
				},
				{
					productName: <span className="fw-bold">{possibleGifts}</span>,
				}
		  );

	return (
		<div className={cx(styles.giftInfoItem)}>
			<div>
				<Suspense fallback={<></>}>
					<InfoIcon className={styles.giftInfoIcon} />
				</Suspense>
			</div>
			<div>
				{!areGiftsRefused && giftleftTospentInfo}
				<span
					className={cx({
						['fw-bold ms-1 me-1']: !areGiftsRefused,
						['me-1']: areGiftsRefused,
					})}
				>
					{giftDescription}
				</span>
				{areGiftsRefused && (
					<button
						className={styles.acceptGiftsBtn}
						onClick={() => {
							handleOnAcceptGifts();
						}}
					>
						{t({
							id: 'giftInfo.button.acceptGifts',
						})}
					</button>
				)}
			</div>
		</div>
	);
};

export default GiftInfo;
