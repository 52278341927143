import { FC } from 'react';

import { IIconProps } from './Interfaces';

const HeaderBottleDestilIcon: FC<IIconProps> = ({ className }) => {
	return (
		<svg
			width={9.242}
			height={17.336}
			viewBox="0 0 9.242 17.336"
			className={className}
		>
			<path
				d="M359.809,126.332a4.259,4.259,0,0,0-1.664-.924c-.2-.065-.4-.108-.6-.167-.032-.01-.078-.056-.078-.086,0-.976,0-1.951,0-2.926a.208.208,0,0,1,.013-.045.239.239,0,0,0,.239-.237c0-.173,0-.342-.008-.51,0-.051-.089-.108-.147-.142a1.475,1.475,0,0,0-.233-.05c.082-.841.12-1.031-.41-1.173a1.57,1.57,0,0,0-.306-.042c-.258,0-.516-.007-.775-.007h-.006c-.258,0-.516,0-.774.007a1.569,1.569,0,0,0-.307.042c-.529.142-.492.332-.41,1.173-.09.028-.174.016-.232.05s-.144.091-.147.142c-.011.168-.01.337-.008.51a.238.238,0,0,0,.239.237.207.207,0,0,1,.013.045c0,.975,0,1.95,0,2.926,0,.029-.045.076-.078.086-.2.059-.4.1-.6.167a4.255,4.255,0,0,0-1.663.924,1.912,1.912,0,0,0-.648,1.544c.026.463.058.927.087,1.39q.1,1.559.194,3.119c.083,1.331.174,2.662.243,4a.609.609,0,0,0,.333.554,2.443,2.443,0,0,0,.314.154,3.7,3.7,0,0,0,1.327.254c.706.009,1.412.013,2.118.016h.006c.707,0,1.413-.007,2.119-.016a3.707,3.707,0,0,0,1.327-.254,2.511,2.511,0,0,0,.314-.154.611.611,0,0,0,.333-.554c.069-1.333.16-2.664.243-4l.194-3.119c.028-.463.061-.927.087-1.39A1.912,1.912,0,0,0,359.809,126.332Zm-7.623,1.977c-.014.364.009.738-.018,1.111a8.626,8.626,0,0,1-.159-1.11c-.106-1.4.846-2.066,2.121-2.247C352.983,126.525,352.2,126.953,352.185,128.309Z"
				transform="translate(-351.218 -120.021)"
				fill="#4d4d4e"
			/>
		</svg>
	);
};

export default HeaderBottleDestilIcon;
