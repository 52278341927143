import { IIconProps } from './Interfaces';

const ChatDarkIcon = ({ className, title = '', alt = '' }: IIconProps) => {
	return (
		<svg
			width="22"
			height="22"
			viewBox="0 0 22 22"
			className={className}
		>
			<title>{title}</title>
			<desc>{alt}</desc>
			<path
				d="M750.176,211.23c-.006-2.11-1.066-3.18-3.157-3.183q-7.777-.011-15.553,0c-2.177,0-3.217,1.053-3.22,3.23q-.009,4.874,0,9.748c0,2.474.528,3.16,3.011,3.811,0,1-.046,1.945.021,2.886a1.788,1.788,0,0,0,.474,1.2c.19.146.809-.1,1.133-.323,1.568-1.069,3.075-2.23,4.663-3.266a3.848,3.848,0,0,1,1.893-.609c2.517-.069,5.038-.021,7.557-.031,2.1-.008,3.175-1.08,3.18-3.167Q750.188,216.378,750.176,211.23Zm-16.039,6.265c-.816-.064-1.335-.47-1.349-1.287a1.2,1.2,0,0,1,1.263-1.339,1.22,1.22,0,0,1,1.344,1.269A1.272,1.272,0,0,1,734.136,217.495Zm5.074-.021a1.3,1.3,0,1,1,0-2.6,1.276,1.276,0,0,1,1.323,1.271C740.44,216.969,740.033,217.478,739.21,217.474Zm5.108,0a1.3,1.3,0,1,1,.033-2.6,1.264,1.264,0,0,1,1.306,1.284C745.556,216.985,745.139,217.482,744.318,217.475Z"
				transform="translate(-728.241 -208.041)"
				fill="#4d4d4e"
			/>
		</svg>
	);
};

export default ChatDarkIcon;
