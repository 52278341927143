import api from '@/api';
import {
	CategoriesCategoryBradcrumbsDetailParams,
	CategoriesDeleteParams,
	CategoriesDetailParams,
	VinistoProductDllModelsApiCategoryCategoryBreadcrumbsReturn,
	VinistoProductDllModelsApiCategoryCategoryReturn,
	VinistoProductDllModelsApiCategoryModifyParentCategoryParameters,
} from '@/api-types/product-api';
import { CATEGORY_BASE_URI } from './constants';

/** Get category for provided ID */
const getCategoryById = async (
	params: CategoriesDetailParams,
) => {

	const res = await api.get<VinistoProductDllModelsApiCategoryCategoryReturn>(
		`${CATEGORY_BASE_URI}${params.categoryId}`,
		params,
	);

	if (res.category === undefined) {
		throw new Error('No category data in response');
	}

	if (res.isError) {
		throw new Error('Error deleting category parent');
	}

	return res;
};

/** Add / edit parent category to provided category defined by id. If category has parent, then parent is overwritten. */
const modifyCategoryParent = async (
	categoryId: string,
	request: VinistoProductDllModelsApiCategoryModifyParentCategoryParameters,
) => {

	const res = await api.put<VinistoProductDllModelsApiCategoryCategoryReturn>(
		`${CATEGORY_BASE_URI}${categoryId}/modify-category-parent`,
		{categoryId},
		request,
	);

	if (res.category === undefined) {
		throw new Error('No category data in response');
	}

	if (res.isError) {
		throw new Error('Error deleting category parent');
	}

	return res;
};

/** Remove parent category from provided category defined by id. */
const removeCategoryParent = async (
	params: CategoriesDeleteParams,
) => {
	const { categoryId, UserLoginHash } = params;

	const res = await api.delete<VinistoProductDllModelsApiCategoryCategoryReturn>(
		`${CATEGORY_BASE_URI}${categoryId}/remove-category-parent`,
		{categoryId},
		{UserLoginHash},
	);

	if (res.category === undefined) {
		throw new Error('No category data in response');
	}

	if (res.isError) {
		throw new Error('Error deleting category parent');
	}

	return res;
};

const getBreadcrumbs = async (
	params: CategoriesCategoryBradcrumbsDetailParams
) => {
	const response =
		await api.get<VinistoProductDllModelsApiCategoryCategoryBreadcrumbsReturn>(
			`${CATEGORY_BASE_URI}${params.categoryId}/category-bradcrumbs`,
			params
		);

	return response.breadcrumbs;
};

const CategoryService = {
	getCategoryById,
	modifyCategoryParent,
	removeCategoryParent,
	getBreadcrumbs,
};

export default CategoryService;
