import useFormatMessage from 'Hooks/useFormatMessage';
import Popover from 'Components/Popover';
import { PopoverWrapperVariants } from 'Components/Popover/Components/Wrapper/constants';

import styles from './styles.module.css';

const MaximumQuantityReachedPopover = ({
	availableCount,
}: {
	availableCount: number;
}) => {
	const t = useFormatMessage();

	return (
		<Popover.Wrapper
			variant={PopoverWrapperVariants.ERROR}
			className={styles.wrapper}
			withCloseButton
		>
			<p className="mb-0">
				{t(
					{ id: 'popover.maximumQuantityReached' },
					{
						quantityWithStock: (
							<span className="fw-bolder">
								{t({ id: 'basket.pcs' }, { count: availableCount })}
							</span>
						),
					}
				)}
			</p>
		</Popover.Wrapper>
	);
};

export default MaximumQuantityReachedPopover;
