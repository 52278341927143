import useFormatMessage from 'Hooks/useFormatMessage';
import VinistoLink from 'Components/VinistoLink';

import styles from './styles.module.css';

const TermsAndConditionsMessage = () => {
	const t = useFormatMessage();

	return (
		<p className={styles.terms}>
			{t(
				{
					id: 'cartShippingData.completeOrderMessage',
				},
				{
					termsAndConditions: (
						<VinistoLink
							to={`/${t({
								id: 'routes.termsAndConditions.route',
							})}`}
							target="_blank"
							className={styles.link}
						>
							{t({
								id: 'cartShippingData.completeOrderMessage.termsAndConditions',
							})}
						</VinistoLink>
					),
					personalDataProcessing: (
						<VinistoLink
							to={`/${t({
								id: 'routes.privacyProtection.route',
							})}`}
							target="_blank"
							className={styles.link}
						>
							{t({
								id: 'cartShippingData.completeOrderMessage.personalDataProcessing',
							})}
						</VinistoLink>
					),
				}
			)}
		</p>
	);
};

export default TermsAndConditionsMessage;
