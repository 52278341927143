import * as React from 'react';
import { get } from 'lodash-es';
import { useLocation, useNavigate } from 'react-router-dom';
import { PreloaderContext } from 'Components/Preloader/context';
import { ModalContext } from 'Components/Modal/context';
import useCustomNavigate from 'Hooks/useCustomNavigate';
import ApiService from 'Services/ApiService';
import { LocalizationContext } from 'Services/LocalizationService';

import './styles.css';

/**
 * @category Component Payment Modal Content
 */
const PaymentModal: React.FunctionComponent = (): JSX.Element => {
	const [createPaymentState, setCreatePaymentState] = React.useState<
		Record<any, any>
	>({
		creatingPayment: false,
		createdpayment: false,
		error: null,
	});
	const preloaderContext = React.useContext(PreloaderContext);
	const navigate = useNavigate();
	const customNavigate = useCustomNavigate();
	const location = useLocation();
	const apiService = React.useMemo(() => new ApiService(), []);
	const localizationContext = React.useContext(LocalizationContext);
	const modalContext = React.useContext(ModalContext);
	const t = localizationContext.useFormatMessage();

	const handleCreatePaymentForOrder = React.useCallback(
		(event?: React.MouseEvent<HTMLElement>) => {
			preloaderContext.togglePreloader(true);
			if (
				!get(createPaymentState, 'creatingPayment', false) &&
				!get(createPaymentState, 'createdpayment', false) &&
				!get(createPaymentState, 'error', null)
			) {
				setCreatePaymentState({
					creatingPayment: true,
					createdpayment: false,
					error: null,
				});

				const orderId = get(modalContext, 'modalData.order.order.id');
				const paymentType = get(
					modalContext,
					'modalData.order.order.payment.paymentType'
				);

				if (paymentType === 'GO_PAY') {
					apiService
						.post(
							`services-api/gopay/CreatePaymentFromOrder`,
							{
								orderId,
								notificationUrl: `${get(window, 'location.origin')}/${t({
									id: 'routes.cart.confirmation.route',
								})}`,
								returnUrl: `${get(window, 'location.origin')}/${t({
									id: 'routes.cart.confirmation.route',
								})}`,
							},
							true
						)
						.then((payload) => {
							setCreatePaymentState({
								creatingPayment: false,
								createdpayment: true,
								error: null,
							});
							const gwUrl = get(payload, 'payment.gwUrl');
							if (gwUrl) window.location.replace(gwUrl);
						})
						.catch((error: Error) => {
							setCreatePaymentState({
								creatingPayment: false,
								createdpayment: false,
								error: get(error, 'message', ''),
							});
						})
						.finally(() => {
							preloaderContext.togglePreloader(false);
						});
				} else if (orderId) {
					modalContext.handleCloseModal();
					preloaderContext.togglePreloader(false);
					if (event) {
						customNavigate(
							event,
							`/${t({
								id: 'routes.cart.confirmation.route',
							})}?oid=${orderId}`
						);
					} else {
						navigate(
							`/${t({
								id: 'routes.cart.confirmation.route',
							})}?oid=${orderId}`
						);
					}
					setCreatePaymentState({
						creatingPayment: false,
						createdpayment: true,
						error: null,
					});
				}
			}
		},
		[modalContext, location, navigate, t]
	);

	React.useEffect(() => {
		handleCreatePaymentForOrder();
	}, [modalContext, location, navigate]);

	if (
		get(modalContext, 'modalData.order.order.payment.paymentType') !== 'GO_PAY'
	) {
		return <></>;
	}

	return (
		<div className="vinisto-popup__payment">
			<div className="vinisto-popup__payment__heading">
				{t({ id: 'modal.payment.modalTitle' })}
				<br />
				{t({ id: 'modal.payment.modalSubtitle' })}
			</div>
			<div className="vinisto-popup__payment__text">
				{t({ id: 'modal.payment.text' })}
			</div>
			<div className="vinisto-popup__payment__btn">
				<button
					onMouseDown={handleCreatePaymentForOrder}
					role="button"
					className="vinisto-btn vinisto-bg-green vinisto-font-18"
				>
					{t({ id: 'modal.payment.button' })}
				</button>
			</div>
		</div>
	);
};

export default PaymentModal;
