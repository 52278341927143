import { LocalizationContext } from 'Services/LocalizationService';
import { useContext } from 'react';

const SupplierMessage = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	return (
		<div>
			{t({
				id: 'notification.message.discountCoupon.discountCouponDoesNotMatch.supplier',
			})}
		</div>
	);
};

export default SupplierMessage;
