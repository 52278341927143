import { useParams } from 'react-router-dom';

import TagBundlesWithFilters from './Components/TagBundlesWithFilters';
import BundlesWithFiltersProvider from './Components/TagBundlesWithFilters/context';
import TagContextProvider from './context';
import { ITagProps } from './interfaces';

const Tag = (props: ITagProps) => {
	let tagUrl = props.tagUrl ?? '';
	const urlParams = useParams();
	if (tagUrl === '') {
		tagUrl = urlParams.tagUrl ?? '';
	}

	return (
		<TagContextProvider tagUrl={tagUrl}>
			<section id="content-wrapper">
				<BundlesWithFiltersProvider>
					<TagBundlesWithFilters />
				</BundlesWithFiltersProvider>
			</section>
		</TagContextProvider>
	);
};

export default Tag;
