import * as React from 'react';
import { LOGIN_MODAL, REGISTRATION_MODAL } from 'Components/Modal/constants';
import { requireEmail } from 'Components/Form/validators';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { LocalizationContext } from 'Services/LocalizationService';
import { Form, InputEmail } from 'Components/Form';
import { VinistoSpanLink } from 'Components/VinistoLink';

import { ModalContext } from '../../context';

import { OPEN_MODAL_DELAY } from './constants';

/**
 * @category Component Forgotten Password Modal Content
 */
const ForgottenPasswordModal: React.FunctionComponent = (): JSX.Element => {
	const modalContext = React.useContext(ModalContext);
	const authenticationContext = React.useContext(AuthenticationContext);
	const localizationContext = React.useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	const handleOnForgottenPassword = React.useCallback(
		(formValues: Record<any, any>) => {
			authenticationContext.handleOnForgottenPassword(formValues.email);
		},
		[authenticationContext]
	);

	const handleOpenRegistrationModal = React.useCallback(() => {
		modalContext.handleCloseModal();
		setTimeout(() => {
			modalContext.handleOpenModal(REGISTRATION_MODAL);
		}, OPEN_MODAL_DELAY);
	}, [modalContext]);

	const handleOpenLogInModal = React.useCallback(() => {
		modalContext.handleCloseModal();
		setTimeout(() => {
			modalContext.handleOpenModal(LOGIN_MODAL);
		}, OPEN_MODAL_DELAY);
	}, [modalContext]);

	return (
		<>
			<Form
				submitCallback={handleOnForgottenPassword}
				submitText={'modal.forgottenPassword.submitButtonText'}
			>
				<div className="mb-3">
					<p>{t({ id: 'modal.forgottenPassword.infoMessage' })}</p>
					<InputEmail validate={requireEmail} />
				</div>
			</Form>
			<div className="vinisto-popup__links underline-effect underline-effect--vinisto">
				<VinistoSpanLink
					className="vinisto-popup__link--gray"
					onClick={handleOpenLogInModal}
				>
					{t({ id: 'modal.forgottenPassword.moveToLoginButton' })}
				</VinistoSpanLink>

				<VinistoSpanLink
					onClick={handleOpenRegistrationModal}
					className="vinisto-popup__link--gray"
				>
					{t({
						id: 'modal.forgottenPassword.moveToRegistrationButton',
					})}
				</VinistoSpanLink>
			</div>
		</>
	);
};

export default ForgottenPasswordModal;
