import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import cx from 'classnames';
import { get } from 'lodash-es';
import { Link } from 'react-router-dom';
import getBundleImage, {
	IMAGE_SIZE_THUMB_88x138,
} from 'Helpers/getBundleImage';
import { LocalizationContext } from 'Services/LocalizationService';
import useLocalizedValue from 'Hooks/useLocalizedValue';

import { ICategoryBoxProps } from './interfaces';

const CategoryBox: React.FC<ICategoryBoxProps> = (
	props: ICategoryBoxProps
): JSX.Element => {
	const localizationContext = React.useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();
	const isLoading = get(props, 'isLoading', false);

	return (
		<Link
			to={
				isLoading
					? '#'
					: `/${t({
							id: 'routes.category.route',
					  })}/${getLocalizedValue(get(props, 'category.url', []))}`
			}
			className="vinisto-product-square"
			style={{ cursor: isLoading ? 'default' : 'pointer' }}
		>
			<div
				className={cx('vinisto-product-square__wrap position-relative', {
					'border-0': isLoading,
				})}
			>
				{isLoading ? (
					<Skeleton height="100%" />
				) : (
					<img
						className="vinisto-product-square__wine"
						src={getBundleImage(
							get(props, 'category.images', []),
							IMAGE_SIZE_THUMB_88x138
						)}
						alt={`${t({ id: 'alt.bundleImage' })}`}
					/>
				)}
			</div>
			<span className="vinisto-product-square__name">
				{isLoading ? (
					<Skeleton />
				) : (
					<span className="underline-item">
						{getLocalizedValue(get(props, 'category.name', [])) ?? '-'}
					</span>
				)}
			</span>
		</Link>
	);
};

export default CategoryBox;
