import { createContext, useCallback, useContext, useEffect } from 'react';
import { get } from 'lodash-es';
import { StorageContext } from 'Services/StorageService/context';
import { ModalContext } from 'Components/Modal/context';
import { COOKIES_MODAL } from 'Components/Modal/constants';
import { isPrerender } from 'Helpers/prerender';

import {
	ICookieServiceContextModel,
	ICookieServiceProviderProps,
} from './interfaces';

const defaultCookieServiceContextModel: ICookieServiceContextModel = {
	saveCookie: () => null,
	invalidateCookie: () => null,
};

export const CookieServiceContext = createContext(
	defaultCookieServiceContextModel
);

const CookieServiceProvider = (props: ICookieServiceProviderProps) => {
	const storageContext = useContext(StorageContext);
	const modalContext = useContext(ModalContext);

	const saveCookie = useCallback(() => {
		const cookieData =
			storageContext.StorageService.getStorageItem('COOKIE_DATA');
		if (cookieData) {
			storageContext.StorageService.removeItem('COOKIE_DATA');
		}
		storageContext.StorageService.setItem('COOKIE_DATA', {
			allowed: true,
		});
	}, [storageContext]);

	const invalidateCookie = useCallback(() => {
		const cookieData =
			storageContext.StorageService.getStorageItem('COOKIE_DATA');
		if (cookieData) {
			storageContext.StorageService.removeItem('COOKIE_DATA');
		}
		storageContext.StorageService.setItem('COOKIE_DATA', {
			allowed: false,
		});
	}, [storageContext]);

	useEffect(() => {
		if (isPrerender()) return;

		const cookieData =
			storageContext.StorageService.getStorageItem('COOKIE_DATA');
		if (!cookieData || !get(cookieData, 'allowed', false)) {
			modalContext.handleOpenModal(COOKIES_MODAL);
		}
	}, [storageContext, modalContext]);

	const cookieServiceContextModel: ICookieServiceContextModel = {
		saveCookie,
		invalidateCookie,
	};

	return (
		<CookieServiceContext.Provider value={cookieServiceContextModel}>
			{props.children}
		</CookieServiceContext.Provider>
	);
};

export default CookieServiceProvider;
