import { QuantityBoxTypes } from 'Components/QuantityBox/constants';
import { ReactNode } from 'react';

import { Bundle } from '@/domain/bundle';

export enum CARD_TYPE {
	SINGLE,
}

export type IProductBoxProps =
	| {
			bundleData: Bundle;
			cardType?: CARD_TYPE;
			isLoading?: false;
			redirectToCrossSell?: boolean;
			displayPriceAsRange?: boolean;
			isCrossSell?: boolean;
			showAddToBasketBtn?: boolean;
			quantityBoxType?: QuantityBoxTypes;
			showSpecifications?: boolean;
			showAddToFavoritesBtn?: boolean;
			showProducer?: boolean;
			carouselType?: string;
			position?: number;
	  }
	| {
			bundleData?: null;
			cardType?: CARD_TYPE;
			isLoading: true;
			redirectToCrossSell?: boolean;
			displayPriceAsRange?: boolean;
			isCrossSell?: boolean;
			showAddToBasketBtn?: boolean;
			quantityBoxType?: QuantityBoxTypes;
			showSpecifications?: boolean;
			showAddToFavoritesBtn?: boolean;
			showProducer?: boolean;
			carouselType?: string;
			position?: undefined;
	  };

export interface ProductBoxContextProps {
	addToBasketCallback: () => void;
	addToBasketButtonLabel?: string;
}

export interface ProductBoxProviderProps extends ProductBoxContextProps {
	children?: ReactNode;
}
