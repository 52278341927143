import { FC, useContext, useEffect } from 'react';
import cx from 'classnames';
import Accordion from 'react-bootstrap/Accordion';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { LocalizationContext } from 'Services/LocalizationService';
import ImageLocal from 'Components/View/ImageLocal';
import ContainerFullWidth from 'Components/View/ContainerFullWidth';
import Card from 'Components/View/Card';

import styles from './styles.module.css';

const FaqSeller: FC = (): JSX.Element => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const documentHeaderContext = useContext(DocumentHeaderContext);

	useEffect(() => {
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.faqSeller.name' })}` }
			)}`,
		});
	}, []);

	return (
		<section id="content-wrapper">
			<ContainerFullWidth>
				<Card className={styles.headingCard}>
					<ImageLocal
						fileName="faq-seller-icon.svg"
						alt=""
						className="d-none d-sm-block"
					/>
					<h1 className="h2 text-center mb-0">Nejčastější dotazy prodejců</h1>
					<ImageLocal
						fileName="faq-seller-icon.svg"
						alt=""
						className="d-none d-sm-block"
					/>
				</Card>
			</ContainerFullWidth>

			<ContainerFullWidth>
				<Card className="pb-4">
					<div className={cx(styles.accordions, 'vinisto-font-18')}>
						<h2 className={styles.accordionHeading}>Než začnu prodávat</h2>
						<Accordion>
							<Accordion.Item eventKey="0">
								<Accordion.Header>
									<span>
										Proč prodávat na{' '}
										<span className="vinisto-color-success">vinistu</span>?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										<span className="vinisto-color-success">vinisto</span> je
										první online tržiště a logistická služba s víny a destiláty
										na českém trhu.{' '}
										<span className="vinisto-color-success">vinisto</span> je
										platforma, která vinařům pomáhá oslovit více zákazníků,
										budovat svou značku a povědomí o produktech.{' '}
										<span className="vinisto-color-success">vinisto</span> je
										logistická služba - doprava, skladování, balení a dovoz od
										Vás až k zákazníkovi.{' '}
										<span className="vinisto-color-success">vinisto</span>{' '}
										buduje komunitu zapojením profesionálů i laické veřejnosti
										pomocí článků, hodnocení a edukace. S{' '}
										<span className="vinisto-color-success">vinistem</span>{' '}
										šetříte čas, zvyšujete zisky a stáváte se nezávislými. Vizí{' '}
										<span className="vinisto-color-success">vinista</span> je
										prodávat Vaše zboží bez starostí přímo k zákazníkům po celém
										světe.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="1">
								<Accordion.Header>
									<span>
										Jak funguje{' '}
										<span className="vinisto-color-success">vinisto</span>?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										<span className="vinisto-color-success">vinisto</span>{' '}
										funguje jako tržnice, v češtině se také zabíhá anglický
										výraz „marketplace&quot;.{' '}
										<span className="vinisto-color-success">vinisto</span> však
										za prodejce řeší kompletní logistický proces. Tedy prodejce
										se zaregistruje, zalistuje na{' '}
										<span className="vinisto-color-success">vinisto</span> své
										produkty, nastaví jim ceny a prodává.{' '}
										<span className="vinisto-color-success">vinisto</span> si
										pro zboží přijede a uskladní jej na svém skladě. Zboží je
										pojištěné a až do prodeje je majetkem prodejce. Při
										objednávce{' '}
										<span className="vinisto-color-success">vinisto</span> zboží
										zabalí a dopraví zákazníkovi. Pro vinaře je specializovaná
										platforma{' '}
										<span className="vinisto-color-success">vinisto</span>{' '}
										způsob, jak prodávat své zboží přímo zákazníkům na
										internetu, aniž by museli řešit logistický proces. Pro
										zákazníky je velkou výhodou možnost nákupu přímo od vinaře,
										respektive od více vinařů najednou, přičemž mu vše bude
										dovezeno v jedné zásilce. Velkou přidanou hodnotou prodeje
										na <span className="vinisto-color-success">vinisto</span>{' '}
										je, že platforma umožňuje budování komunity a povědomí o
										Vaší značce. Můžete zde prezentovat své vinařství, produkty,
										nebo můžete vína hodnotit a psát o nich. Můžete se stát
										součástí komunity{' '}
										<span className="vinisto-color-success">vinisto</span>.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="2">
								<Accordion.Header>
									<span>
										Jak mohu začít prodávat na{' '}
										<span className="vinisto-color-success">vinistu</span>?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										Krok 1) Registrace
										<br />
										Registrace nového prodejce, vinaře nebo distributora je
										možná na adrese{' '}
										<a
											href="https://www.prodejce.vinisto.cz"
											className="vinisto-color-success"
										>
											www.prodejce.vinisto.cz
										</a>
										. Pro registraci stačí zadat Vaše kontaktní údaje a IČO Vaší
										firmy, vše ostatní se Vám doplní automaticky. Musíme si
										ověřit, že jste to Vy, takže Vám následně zavoláme, nebo Vám
										zašleme ověřovací email. Čím více v profilu představíte Vaši
										firmu, tím lépe se Vaše produkty budou prodávat.
										<br />
										Krok 2) Zalistování produktů
										<br />
										Produkty lze vyhledat v naší databázi vín z celého světa,
										naimportovat je, nebo máte možnost produkty přidat ručně.
										Okamžitě vidíte, zda Vaše produkty na{' '}
										<span className="vinisto-color-success">vinisto</span>{' '}
										prodává jiný prodejce. Cenu svého produktu si nastavíte sami
										- doporučujeme zvolit stejnou, nebo nižší cenu než za jakou
										je Váš produkt obvykle na internetu prodáván.
										<br />
										Krok 3) Prodej
										<br />
										Zkontrolujeme Váš profil a Vaše produkty a v případě, že
										najdeme něco, co je nutné doplnit, obratem Vás kontaktujeme.
										Následně Vám zašleme první požadavek na naskladnění.
										Požadované množství je zvoleno pečlivě s ohledem na co
										nejnižší skladové zásoby a zároveň na pohodlí zákazníka, tak
										abychom mu jeho nákup mohli doručit co nejdříve a v jednom
										balíčku. Dojedeme si pro zboží, či nám zboží dodáte na sklad
										a…prodáváte.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="3">
								<Accordion.Header>
									<span>
										Co musím splňovat, abych mohl prodávat na{' '}
										<span className="vinisto-color-success">vinisto</span>?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										Být výrobcem, importérem, distributorem vína a destilátů a
										být plátcem DPH.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="4">
								<Accordion.Header>
									<span>Proč potřebuji smlouvu?</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										Každý profesionální, upřímný a transparentní obchodní vztah
										by měl být jasně zasmluvněný, aby obě strany vztahu znaly
										svá práva a povinnosti. Ve smlouvě jsou řešena všechna práva
										a povinnosti prodejce, tak abychom zajistili kvalitní službu
										pro zákazníky a vlastně i pro prodejce.
									</p>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
				</Card>
			</ContainerFullWidth>

			<ContainerFullWidth>
				<Card className="pb-4">
					<div className={cx(styles.accordions, 'vinisto-font-18')}>
						<h2 className={styles.accordionHeading}>
							Co lze na <span className="vinisto-color-success">vinistu</span>{' '}
							prodávat
						</h2>
						<Accordion>
							<Accordion.Item eventKey="0">
								<Accordion.Header>
									<span>
										Co mohu na{' '}
										<span className="vinisto-color-success">vinistu</span>{' '}
										prodávat?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										Na <span className="vinisto-color-success">vinistu</span>{' '}
										můžete prodávat vína, portské, destiláty. V nejbližší době
										umožníme také prodej služeb jako jsou degustace, pobyty ve
										sklípku apod.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="1">
								<Accordion.Header>
									<span>Mohu dělat akce a slevy?</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										Ano, s cenami Vašich produktů můžete kdykoliv libovolně
										pracovat. Nastavení cen provedete jednoduše v administraci
										Vašeho účtu. Pokud produkt zlevníte, zobrazíme u produktu po
										dobu 30 dnů vždy původní cenu a cenu po slevě. Pokud produkt
										naopak zdražíte, zobrazíme pouze novou, vyšší cenu.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="2">
								<Accordion.Header>
									<span>
										Mohu na{' '}
										<span className="vinisto-color-success">vinistu</span>{' '}
										prodávat degustace a akce ve vinařství?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										Prodej služeb jako jsou degustace a jiné akce ve vinařství
										aktuálně připravujeme. Jejich prodej bude umožněn v
										nejbližší době. Poplatky za prodej budou stejné jako u
										prodeje produktů. Neplatíte však poplatky za logistiku -
										prodej probíhá pouze online, nic neskladujeme a fyzicky
										zákazníkovi nic neposíláme.
									</p>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
				</Card>
			</ContainerFullWidth>

			<ContainerFullWidth>
				<Card className="pb-4">
					<div className={cx(styles.accordions, 'vinisto-font-18')}>
						<h2 className={styles.accordionHeading}>
							Jak funguje prodej přes{' '}
							<span className="vinisto-color-success">vinisto</span>
						</h2>
						<Accordion>
							<Accordion.Item eventKey="0">
								<Accordion.Header>
									<span>
										Jak funguje spolupráce s{' '}
										<span className="vinisto-color-success">vinisto</span>?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										Podmínkou prodeje na{' '}
										<span className="vinisto-color-success">vinisto</span> je
										držení minimálního avšak dostatečného množství zásob na
										skladě{' '}
										<span className="vinisto-color-success">vinista</span>.{' '}
										<span className="vinisto-color-success">vinisto</span>{' '}
										nabízí prodejcům dvě možnosti spolupráce. Prodejci mohou své
										zboží zavážet přímo na sklad{' '}
										<span className="vinisto-color-success">vinista</span> na
										adrese Sanderova 1366/26 na Praze 7 sami a platí pak nižší
										provizi za logistiku. Druhou možností je využití svozu přímo
										od <span className="vinisto-color-success">vinista</span>,
										přičemž je provize z prodeje o několik procent nižší. Na
										způsobu, který Vám bude vyhovovat se vždy domluvte se svým
										obchodním zástupcem nebo podporou na tel. +420 606 758 080,
										nebo si ho můžete libovolně měnit ve svém administrativním
										rozhraní.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="1">
								<Accordion.Header>
									<span>Lze změnit způsob spolupráce?</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										Způsob spolupráce si volíte při registraci. Tento způsob
										spolupráce, respektive způsob dopravy od Vás k nám můžete
										kdykoliv změnit ve Vašem administrativním rozhraní.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="2">
								<Accordion.Header>
									<span>
										Může můj produkt na{' '}
										<span className="vinisto-color-success">vinisto</span>{' '}
										prodávat i někdo jiný?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										<span className="vinisto-color-success">vinisto</span> je
										tržiště. Může se tedy stát, že Vaše zboží bude nabízet jiný
										distributor nebo přímo výrobce.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="3">
								<Accordion.Header>
									<span>
										Zajišťuje{' '}
										<span className="vinisto-color-success">vinisto</span>{' '}
										podporu pro zákazníky? V jakém rozsahu?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										<span className="vinisto-color-success">vinisto</span> má k
										dispozici celé oddělení zákaznické péče. Veškeré dotazy a
										požadavky zákazníků, včetně reklamací tedy řeší vinisto.
										Prodejce v tomto směru se zákazníkem nic řešit nemusí.
										Oddělení zákaznické péče nefunguje jako nákupní rádce. Pokud
										si zákazník neví rady s výběrem vhodného zboží, jsou mu na{' '}
										<span className="vinisto-color-success">vinistu</span> k
										dispozici hodnocení, články a další.
									</p>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
				</Card>
			</ContainerFullWidth>

			<ContainerFullWidth>
				<Card className="pb-4">
					<div className={cx(styles.accordions, 'vinisto-font-18')}>
						<h2 className={styles.accordionHeading}>Provize a platby</h2>
						<Accordion>
							<Accordion.Item eventKey="0">
								<Accordion.Header>
									<span>
										Kdy platím provizi ze zboží, které přes{' '}
										<span className="vinisto-color-success">vinisto</span>{' '}
										nabízím?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										Provizi za prodej na{' '}
										<span className="vinisto-color-success">vinistu</span>{' '}
										platíte až z prodaného zboží. Za dopravu na sklad a
										skladování zboží nic neplatíte. Vždy na konci měsíce Vám{' '}
										<span className="vinisto-color-success">vinisto</span> zašle
										vyúčtování a na jeho základě vystaví samo sobě fakturu se
										splatností 14 dnů.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="1">
								<Accordion.Header>
									<span>
										Jakým způsobem mi bude vyplacena provize za prodej?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										Provizi za prodej vám{' '}
										<span className="vinisto-color-success">vinisto</span>{' '}
										vyplatí 1x za měsíc.{' '}
										<span className="vinisto-color-success">vinisto</span> vždy
										na konci měsíce připraví vyúčtování prodaného zboží a
										provizí, na jehož základě vystaví fakturu samo sobě. V
										případě, že neproběhl v uplynulém měsíci žádný prodej Vašeho
										zboží, nic neplatíte a žádná faktura Vám nepřijde.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="2">
								<Accordion.Header>
									<span>
										Může <span className="vinisto-color-success">vinisto</span>{' '}
										měnit poplatky za prodej a logistiku?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										Ano může se to stát. Poplatky za prodej i logistiku chceme
										měnit co nejméně, nicméně pokud se to bude muset stát,
										například z důvodu enormního zdražení dopravy, prodejce vše
										transparentně uvidí a může se rozhodnout, že na{' '}
										<span className="vinisto-color-success">vinistu</span> již
										prodávat nebude.
									</p>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
				</Card>
			</ContainerFullWidth>

			<ContainerFullWidth>
				<Card className="pb-4">
					<div className={cx(styles.accordions, 'vinisto-font-18')}>
						<h2 className={styles.accordionHeading}>Ceny produktů</h2>
						<Accordion>
							<Accordion.Item eventKey="0">
								<Accordion.Header>
									<span>
										Jak nastavit ceny pro prodej na{' '}
										<span className="vinisto-color-success">vinisto</span>?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										Cenu svého produktu si nastavíte sami - doporučujeme zvolit
										stejnou, nebo nižší cenu než za jakou je Váš produkt obvykle
										na internetu prodáván.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="1">
								<Accordion.Header>
									<span>
										Může <span className="vinisto-color-success">vinisto</span>{' '}
										upravovat ceny mých produktů?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										Ano může, nicméně pouze v rámci své provize za prodej zboží.
										Tedy pokud se{' '}
										<span className="vinisto-color-success">vinisto</span>{' '}
										rozhodne zlevnit produkt o 10%, sleva jde z provize{' '}
										<span className="vinisto-color-success">vinista</span> a
										prodejce dostane celou částku za prodej bez slevy.{' '}
										<span className="vinisto-color-success">vinisto</span> však
										bude se slevou z vlastního poplatku za prodej pracovat co
										nejméně, protože jinak by platforma neměla finance na
										fungování.
									</p>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
				</Card>
			</ContainerFullWidth>

			<ContainerFullWidth>
				<Card className="pb-4">
					<div className={cx(styles.accordions, 'vinisto-font-18')}>
						<h2 className={styles.accordionHeading}>Propagace a marketing</h2>
						<Accordion>
							<Accordion.Item eventKey="0">
								<Accordion.Header>
									<span>
										Jakým způsobem{' '}
										<span className="vinisto-color-success">vinisto</span> mé
										produkty propaguje?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										<span className="vinisto-color-success">vinisto</span>{' '}
										využívá všechny marketingové kanály pro propagaci Vašeho
										zboží a portálu jako takového. Kromě sociálních sítí, kde se
										zaměřuje především na budování povědomí o portálu a budování
										komunity, využívá také výkonnostní kampaně, brandové kampaně
										a reklamu na zbožových srovnávačích. Veškeré tyto
										marketingové aktivity jsou již zahrnuty v paušálu a
										neplatíte za ně nic navíc.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="1">
								<Accordion.Header>
									<span>
										Mohu své produkty přímo na{' '}
										<span className="vinisto-color-success">vinisto</span> více
										zviditelnit?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										Ano, Vaše produkty i vinařství bude do budoucna možné na{' '}
										<span className="vinisto-color-success">vinisto</span>{' '}
										propagovat. Pro prodejce budeme mít k dispozici několik typů
										marketingových balíčků. Marketingový balíček si pak může
										prodejce zvolit již při registraci v administrativním
										rozhraní nebo si ho v administrativním rozhraní kdykoliv
										objednat. Pro bližší informace můžete kontaktovat svého
										obchodního zástupce nebo podporu na tel. +420 606 758 080.
									</p>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
				</Card>
			</ContainerFullWidth>

			<ContainerFullWidth>
				<Card className="pb-4">
					<div className={cx(styles.accordions, 'vinisto-font-18')}>
						<h2 className={styles.accordionHeading}>Doprava a skladování</h2>
						<Accordion>
							<Accordion.Item eventKey="0">
								<Accordion.Header>
									<span>Kde je zboží uskladněno?</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										Zboží skladujeme na adrese Sanderova 1366/26 na Praze 7.
										Sklad <span className="vinisto-color-success">vinista</span>{' '}
										je svými podmínkami vhodný i pro skladování naturálních vín.
										Veškeré zboží na skladě je pojištěno.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="1">
								<Accordion.Header>
									<span>
										Jak funguje zavážení zboží na{' '}
										<span className="vinisto-color-success">vinisto</span>?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										Zboží skladujeme na adrese Jankovcova 1057/6 na Praze 7. Při
										registraci si zvolíte, jaký způsob dopravy zboží na náš
										sklad Vám bude vyhovovat a orientační čas svozu. Tento
										způsob i čas lze kdykoliv změnit v nastavení v
										administrativním rozhraní nebo po domluvě s podporou pro
										prodejce.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="2">
								<Accordion.Header>
									<span>
										Zavážím zboží na sklad{' '}
										<span className="vinisto-color-success">vinisto</span>, ale
										nemůžu dorazit v domluvený termín, jak mám postupovat?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										Víme, že zpoždění může vzniknout z mnoha důvodů, a proto
										pokud se stane, že nedokážete přijet ve smluvený čas,
										informujte přes administrativní rozhraní nebo telefonní
										linku podpory pro prodejce{' '}
										<span className="vinisto-color-success">vinisto</span> o
										zpoždění.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="3">
								<Accordion.Header>
									<span>
										Zboží si{' '}
										<span className="vinisto-color-success">vinisto</span>{' '}
										vyzvedává u mě v domluvený termín, ale nikdo nedorazil, jak
										mám postupovat?
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<p>
										V takovém případě nás prosím co nejdříve informujte, a to
										buď v administrativním rozhraní, nebo na telefonní lince
										podpory pro prodejce.
									</p>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
				</Card>
			</ContainerFullWidth>
		</section>
	);
};

export default FaqSeller;
