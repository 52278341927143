import { useEffect, useState } from 'react';
import { PopoverProps } from 'react-tiny-popover';

export const usePopover = (
	popovers: Record<string, Omit<PopoverProps, 'isOpen' | 'children'>>
) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isClosing, setIsClosing] = useState(false);
	const [selectedPopoverKey, setSelectedPopoverKey] = useState(
		Object.keys(popovers)[0]
	);

	const open = (popoverKey: keyof typeof popovers) => {
		if (isClosing) {
			setIsClosing(false);
		}
		setSelectedPopoverKey(popoverKey);
		setIsOpen(true);
	};
	const close = () => {
		if (!isOpen) return;
		setIsClosing(true);
		setIsOpen(false);
	};

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout> | undefined;
		if (isClosing) {
			timer = setTimeout(() => {
				setIsClosing(false);
				setIsOpen(false);
			}, 350);
		}
		return () => clearTimeout(timer);
	}, [isClosing]);

	return {
		methods: {
			isOpen: isOpen || isClosing,
			isClosing,
			onClose: close,
			...popovers[selectedPopoverKey],
		},
		open,
		close,
	};
};
