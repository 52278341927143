import { useContext } from 'react';
import { PHONE_NUMBER } from 'Components/Navbar/constants';
import { formatPhoneNumber } from 'Components/Navbar/helpers';
import useIsInBasket from 'Hooks/useIsInBasket';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import useChat from 'Hooks/useChat';
import HeaderPhoneIcon from 'Components/Icons/HeaderPhone';
import ChatIcon from 'Components/Icons/Chat';
import { useIsFooterInViewPort } from 'Hooks/useScrollFixedFilters';

import InfoColumns from './Components/InfoColumns';
import Newsletter from './Components/Newsletter';
import SocialLinks from './Components/SocialLinks';
import styles from './styles.module.css';

import './styles.css';

/**
 * @category Component AppFooter
 */
const AppFooter = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const deviceContext = useContext(DeviceServiceContext);

	const isInBasket = useIsInBasket();

	const { openChat } = useChat();

	const footerRef = useIsFooterInViewPort();

	if (isInBasket) {
		return <></>;
	}

	return (
		<footer ref={footerRef}>
			<div className="container">
				<div className="row">
					<div className="col-md-3 col-12 vinisto-footer-18-wrap">
						<span className="vinisto-footer-18">18+</span>
						<span className="vinisto-footer-18-text">
							{t({ id: 'footer.ban' })}
						</span>
					</div>
					<div className="col-md-6 col-12">
						<Newsletter />
					</div>
					{deviceContext.isMobile && (
						<span
							className={'d-flex flex-column fw-bolder ps-4 vinisto-font-14'}
						>
							{t({ id: 'navbar.customerCare.label' })}
							<a
								href={`tel:${PHONE_NUMBER}`}
								className="vinisto-color-darker-gray fw-bolder mt-2"
							>
								<div className="vinisto-footer__phone-wrap">
									<HeaderPhoneIcon className="me-2 mb-1" />
									{formatPhoneNumber(PHONE_NUMBER)}
								</div>
							</a>
							<button
								className={styles.chatButton}
								onClick={() => {
									openChat();
								}}
							>
								<div className="vinisto-footer__phone-wrap">
									<ChatIcon className={styles.chatIcon} />
									{t({ id: 'navbar.customerCare.onlineChat' })}
								</div>
							</button>
						</span>
					)}
					<div className="col-3 d-flex align-items-end mb-4 social-media-icons mobile-hide">
						{!deviceContext.isMobile && <SocialLinks />}
					</div>
				</div>
				<InfoColumns />
				{deviceContext.isMobile && <SocialLinks />}
			</div>
		</footer>
	);
};

export default AppFooter;
