import { lazy, Suspense, useContext } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { Breadcrumb } from 'react-bootstrap';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { BundlesWithFiltersContext } from 'Pages/Category/Components/CategoryBundlesWithFilters/context';
import { LocalizationContext } from 'Services/LocalizationService';
import Loader from 'Components/View/Loader';
import ContainerFullWidth from 'Components/View/ContainerFullWidth';

import { TagContext } from '../../context';

import styles from './styles.module.css';

const BreadcrumbHomeIcon = lazy(
	() => import('Components/Icons/BreadcrumbHome')
);

const TagBreadcrumb = () => {
	const localizationContext = useContext(LocalizationContext);
	const { tagData } = useContext(TagContext);
	const { specificationsQuery } = useContext(BundlesWithFiltersContext);

	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();

	const isTagDataLoading = tagData.isLoading ?? false;
	const isLoading = isTagDataLoading || specificationsQuery?.isLoading;

	let tagName = getLocalizedValue(tagData?.data?.tag?.name ?? []);
	// TODO: hardcoded for now
	if (tagName === 'Novinka') {
		tagName = 'Novinky';
	}

	return (
		<ContainerFullWidth
			className={styles.container}
			containerClassName={styles.breadcrumbsWrap}
		>
			{isLoading ? (
				<Skeleton
					containerClassName="breadcrumb"
					width="300px"
				/>
			) : (
				<Breadcrumb>
					<Breadcrumb.Item
						linkAs={Link}
						linkProps={{ to: '/' }}
					>
						<Suspense fallback={<Loader blank />}>
							<BreadcrumbHomeIcon
								alt={t({ id: 'alt.breadcrumb.home' })}
								title={``}
								className={`BreadcrumbHomeIcon`}
							/>
						</Suspense>
					</Breadcrumb.Item>
					<Breadcrumb.Item active>{tagName}</Breadcrumb.Item>
				</Breadcrumb>
			)}
		</ContainerFullWidth>
	);
};

export default TagBreadcrumb;
