/* eslint-disable jsx-a11y/label-has-associated-control */
import {
	lazy,
	Suspense,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { uniqueId } from 'lodash-es';
import { CookieServiceContext } from 'Services/CookieService';
import Loader from 'Components/View/Loader';
import { Link } from 'react-router-dom';
import { LocalizationContext } from 'Services/LocalizationService';

import { ModalContext } from '../../context';

const FilterDropdownArrowIcon = lazy(
	() => import('Components/Icons/FilterDropdownArrow')
);

import './styles.css';

const CookiesModal = () => {
	const modalContext = useContext(ModalContext);
	const cookieServiceContext = useContext(CookieServiceContext);
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	const handleOnAllowAllCookiesModal = useCallback(() => {
		modalContext.handleCloseModal();
		cookieServiceContext.saveCookie();
	}, [modalContext]);

	const [isSettingsOpen, setSettingsOpen] = useState(false);
	const [isFunctionalOpen, setFunctionalOpen] = useState(false);
	const handleOnToggleFunctional = useCallback(() => {
		setFunctionalOpen((oldFunctional) => !oldFunctional);
		setAnalyticOpen(false);
		setMarketingOpen(false);
	}, []);
	const [isAnalyticOpen, setAnalyticOpen] = useState(false);
	const handleOnToggleAnalytic = useCallback(() => {
		setFunctionalOpen(false);
		setAnalyticOpen((oldAnalytic) => !oldAnalytic);
		setMarketingOpen(false);
	}, []);
	const [isMarketingOpen, setMarketingOpen] = useState(false);
	const handleOnToggleMarketing = useCallback(() => {
		setFunctionalOpen(false);
		setAnalyticOpen(false);
		setMarketingOpen((oldMarketing) => !oldMarketing);
	}, []);

	useEffect(() => {
		setSettingsOpen(false);
		setFunctionalOpen(false);
		setAnalyticOpen(false);
		setMarketingOpen(false);
	}, []);

	return (
		<div className="vinisto-modal-cookies">
			<div className="vinisto-modal-cookies__body">
				<div>
					<p>{t({ id: 'modal.cookie.text1' })}</p>
					<p>{t({ id: 'modal.cookie.text2' })}</p>
					<p>
						{`${t({ id: 'modal.cookie.text3' })} `}
						<span
							className="vinisto-modal-cookies__link"
							onClick={handleOnAllowAllCookiesModal}
						>
							{t({ id: 'modal.cookie.text4' })}
						</span>
						.
					</p>
					<p>
						{t({ id: 'modal.cookie.text5' })}{' '}
						<Link
							className="vinisto-modal-cookies__link"
							to="/ochrana-osobnich-udaju"
						>
							{t({ id: 'modal.cookie.text4' })}
						</Link>
						.
					</p>
				</div>
			</div>
			{isSettingsOpen ? (
				<div className="vinisto-modal-cookies__settings">
					<div className="vinisto-modal-cookies__setting">
						<div className="vinisto-modal-cookies__head">
							<div
								className="vinisto-modal-cookies__setting__name"
								onClick={handleOnToggleFunctional}
							>
								{t({ id: 'modal.cookies.functionalHeading' })}
								<Suspense fallback={<Loader blank />}>
									<FilterDropdownArrowIcon
										id={uniqueId()}
										alt={``}
										title={``}
										className={`FilterDropdownArrowIcon`}
									/>
								</Suspense>
							</div>
							<div className="vinisto-modal-cookies__setting__check">
								<label className="vinisto-popup__checkbox">
									<input
										onChange={() => {}}
										type="checkbox"
										checked
									/>
									<span className="vinisto-popup__checkmark"></span>
								</label>
							</div>
						</div>
						{isFunctionalOpen ? (
							<div className="vinisto-modal-cookies__text vinisto-font-18">
								{t({ id: 'modal.cookie.text8' })}
							</div>
						) : (
							''
						)}
					</div>
					<div className="vinisto-modal-cookies__setting">
						<div className="vinisto-modal-cookies__head">
							<div
								className="vinisto-modal-cookies__setting__name"
								onClick={handleOnToggleAnalytic}
							>
								{t({ id: 'modal.cookies.analyticHeading' })}
								<Suspense fallback={<Loader blank />}>
									<FilterDropdownArrowIcon
										id={uniqueId()}
										alt={``}
										title={``}
										className={`FilterDropdownArrowIcon`}
									/>
								</Suspense>
							</div>
							<div className="vinisto-modal-cookies__setting__check">
								<label className="vinisto-popup__checkbox">
									<input
										onChange={() => {}}
										type="checkbox"
										checked
									/>
									<span className="vinisto-popup__checkmark"></span>
								</label>
							</div>
						</div>
						{isAnalyticOpen ? (
							<div className="vinisto-modal-cookies__text vinisto-font-18">
								{t({ id: 'modal.cookie.text8' })}
							</div>
						) : (
							''
						)}
					</div>
					<div className="vinisto-modal-cookies__setting">
						<div className="vinisto-modal-cookies__head">
							<div
								className="vinisto-modal-cookies__setting__name"
								onClick={handleOnToggleMarketing}
							>
								{t({ id: 'modal.cookies.marketingHeading' })}
								<Suspense fallback={<Loader blank />}>
									<FilterDropdownArrowIcon
										id={uniqueId()}
										alt={``}
										title={``}
										className={`FilterDropdownArrowIcon`}
									/>
								</Suspense>
							</div>
							<div className="vinisto-modal-cookies__setting__check">
								<label className="vinisto-popup__checkbox">
									<input
										onChange={() => {}}
										type="checkbox"
										checked
									/>
									<span className="vinisto-popup__checkmark"></span>
								</label>
							</div>
						</div>
						{isMarketingOpen ? (
							<div className="vinisto-modal-cookies__text vinisto-font-18">
								{t({ id: 'modal.cookie.text8' })}
							</div>
						) : (
							''
						)}
					</div>
				</div>
			) : (
				''
			)}

			<div className="vinisto-modal-cookies__buttons">
				<button
					className="vinisto-btn vinisto-popup__btn vinisto-popup__btn--green"
					onClick={handleOnAllowAllCookiesModal}
					type="button"
				>
					{t({ id: 'modal.cookies.allowAll' })}
				</button>
				{isSettingsOpen ? (
					<button
						className="vinisto-btn vinisto-popup__btn mt-3"
						onClick={handleOnAllowAllCookiesModal}
						type="button"
					>
						{t({ id: 'modal.cookies.allowSelected' })}
					</button>
				) : (
					''
				)}
			</div>
		</div>
	);
};

export default CookiesModal;
