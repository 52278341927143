import { MouseEvent, useCallback, useContext, useState } from 'react';
import cx from 'classnames';
import { CARD_TYPE } from 'Components/Carousel/interfaces';
import { HOME_PAGE_PRODUCTS_CAROUSEL } from 'Components/Carousel/constants';
import { QuantityBoxTypes } from 'Components/QuantityBox/constants';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import { ProductBoxProvider } from 'Components/ProductBox/context';
import { LocalizationContext } from 'Services/LocalizationService';
import { BasketContext } from 'Services/BasketService';
import { DeviceServiceContext } from 'Services/DeviceService';
import Carousel from 'Components/Carousel';
import { getDiscountPriceValues } from 'vinisto_shared/src/price/get-discount-prices';

import { ITEM_NOT_AVAILABLE } from '../constants';
import { BasketOutOfStockItem } from '../interfaces';

import styles from './styles.module.css';
import OutOfStockCartItemMobile from './Mobile';
import OutOfStockCartItemDesktop from './Desktop';
import { OutOfStockCartItemProps } from './interfaces';

import { VinistoHelperDllEnumsCurrency } from '@/api-types/product-api';

const OutOfStockCartItem = ({
	cartItem: {
		cartItemType,
		bundleData,
		similarBundlesCarousel,
		availableWarehouseQty,
		isTemporaryUnavailable,
		isDisabled,
		bundleLimitPerOrder,
	},
	handleRemoveItemFromBasket,
	setBasketItemsOutOfStock,
}: OutOfStockCartItemProps) => {
	const basketContext = useContext(BasketContext);
	const deviceContext = useContext(DeviceServiceContext);
	const {
		useFormatMessage,
		activeCurrency,
		convertToActiveCurrencyIfPriceCurrencyIsDifferent,
	} = useContext(LocalizationContext);
	const t = useFormatMessage();

	const bundleId = bundleData.bundleId;
	const bundle = bundleData.bundle;
	const availableCount =
		isTemporaryUnavailable || isDisabled
			? bundleData.quantity
			: bundleLimitPerOrder
			? bundleLimitPerOrder
			: bundle.availableCount;
	const orderLimit = bundle?.orderLimitation?.limit;

	const bundleQuantity = bundleData.quantity ?? 1;

	const { title: currencyTitle, currency } = activeCurrency;

	const basePrice = bundleData?.itemPrice;

	const discountedPrice = bundleData?.itemDiscountPrice;

	const priceCurrency = bundleData?.itemPrice.currency;

	const { discountedPriceWithoutVat, discountedPriceWithVat, isDiscounted } =
		getDiscountPriceValues({
			quantityInBasket: bundleQuantity,
			basePrice,
			discountedPrice,
		});

	const priceWithVat = discountedPriceWithVat ?? basePrice?.valueWithVat;

	const priceWithoutVat = discountedPriceWithoutVat ?? basePrice?.value;

	const totalPriceWithVAT =
		convertToActiveCurrencyIfPriceCurrencyIsDifferent({
			price: priceWithVat ?? 0,
			activeCurrency: currency,
			priceCurrency: basePrice?.currency ?? VinistoHelperDllEnumsCurrency.CZK,
		}) * bundleQuantity;

	const totalPriceWithoutVAT =
		convertToActiveCurrencyIfPriceCurrencyIsDifferent({
			price: priceWithoutVat ?? 0,
			activeCurrency: currency,
			priceCurrency: basePrice?.currency ?? VinistoHelperDllEnumsCurrency.CZK,
		}) * bundleQuantity;

	const originalTotalPriceWithVAT = isDiscounted
		? convertToActiveCurrencyIfPriceCurrencyIsDifferent({
				price: basePrice?.valueWithVat ?? 0,
				activeCurrency: currency,
				priceCurrency: basePrice?.currency ?? VinistoHelperDllEnumsCurrency.CZK,
		  }) * bundleQuantity
		: null;

	const isCarouselLoading = similarBundlesCarousel.loading;
	const carouselItemsBundles = similarBundlesCarousel.bundles;

	const { shortVariety: producerName, component: flag } = getFlagSpecification(
		bundle.specificationDetails
	);

	const handleOnKeepInBasket = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			event.preventDefault();
			event.stopPropagation();
			setBasketItemsOutOfStock((prev) => {
				const bundleToKeep = prev.get(bundleId);
				if (!bundleToKeep) return prev;
				prev.set(bundleId, {
					...bundleToKeep,
					isBeingRemoved: true,
				});
				return new Map(prev);
			});
			basketContext.handleOnChangeItemQuantity(availableCount ?? 0, bundleId);
		},
		[setBasketItemsOutOfStock, basketContext, availableCount, bundleId]
	);

	const [showSimilarBundlesCarousel, setShowSimilarBundlesCarousel] =
		useState(false);

	const handleShowSimilarProducts = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			event.preventDefault();
			event.stopPropagation();
			setShowSimilarBundlesCarousel(
				(showSimilarBundlesCarousel) => !showSimilarBundlesCarousel
			);
		},
		[]
	);

	const getMessage = (type: BasketOutOfStockItem['cartItemType']) => {
		if (type === ITEM_NOT_AVAILABLE.IS_DISABLED) {
			return t({ id: 'cart.temporaryUnavailable' });
		}
		if (type === ITEM_NOT_AVAILABLE.OUT_OF_STOCK) {
			return t({ id: 'cart.outOfStockItem.outOfStock' });
		}
		if (type === ITEM_NOT_AVAILABLE.QUANTITY_LESS_THAN_SELECTED) {
			return t(
				{
					id: 'cart.outOfStockItem.missingItems',
				},
				{ count: availableWarehouseQty }
			);
		}
		if (type === ITEM_NOT_AVAILABLE.ORDER_LIMIT_EXCEEDED) {
			return t(
				{
					id: 'cart.outOfStockItem.quantityHigherThanOrderLimit',
				},
				{ count: orderLimit }
			);
		}
		if (type === ITEM_NOT_AVAILABLE.TEMPORARY_UNAVAILABLE) {
			return t({ id: 'cart.temporaryUnavailable' });
		}

		return null;
	};

	const message = getMessage(cartItemType);

	return (
		<div className={cx(styles.containerColumn, styles.box)}>
			{deviceContext.isMobile ? (
				<OutOfStockCartItemMobile
					{...{
						bundle,
						flag,
						producerName,
						priceCurrency,
						currency,
						isDiscounted,
						originalTotalPriceWithVAT,
						totalPriceWithVAT,
						totalPriceWithoutVAT,
						currencyTitle,
						cartItemType,
						message,
						availableWarehouseQty,
						handleShowSimilarProducts,
						isCarouselLoading,
						carouselItemsBundles,
						handleOnKeepInBasket,
						handleRemoveItemFromBasket,
					}}
				/>
			) : (
				<OutOfStockCartItemDesktop
					{...{
						bundle,
						flag,
						producerName,
						currency,
						isDiscounted,
						originalTotalPriceWithVAT,
						totalPriceWithVAT,
						totalPriceWithoutVAT,
						currencyTitle,
						cartItemType,
						message,
						availableWarehouseQty,
						handleShowSimilarProducts,
						isCarouselLoading,
						carouselItemsBundles,
						handleOnKeepInBasket,
						handleRemoveItemFromBasket,
					}}
				/>
			)}
			{showSimilarBundlesCarousel && (
				<div className="container mt-3">
					<ProductBoxProvider
						addToBasketCallback={handleRemoveItemFromBasket}
						addToBasketButtonLabel="cart.outOfStockItem.alternative.addToBasketBtnLabel"
					>
						<Carousel
							isLoading={isCarouselLoading}
							data={carouselItemsBundles}
							carouselType={HOME_PAGE_PRODUCTS_CAROUSEL}
							cardType={CARD_TYPE.CAROUSEL_CLASSIC}
							redirectToCrossSell={false}
							displayPriceAsRange
							quantityBoxType={QuantityBoxTypes.DIRECT}
						/>
					</ProductBoxProvider>
				</div>
			)}
		</div>
	);
};

export default OutOfStockCartItem;
