import {
	DocumentHeaderAction,
	OpenGraphItemType,
	TwitterCardType,
} from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import Config from 'Config';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import usePrevious from 'Hooks/usePrevious';
import { LocalizationContext } from 'Services/LocalizationService';
import { NotificationsContext } from 'Services/NotificationService';
import { get } from 'lodash-es';
import { createContext, useContext, useEffect } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { fetchCategoryByUrl } from './helpers';
import {
	ICategoryContextProviderProps,
	ICategoryContextValues,
} from './interfaces';

import { VinistoProductDllModelsApiCategoryCategoryReturn } from '@/api-types/product-api';

const categoryContextDefaultValues: ICategoryContextValues = {
	categoryData: {} as UseQueryResult<
		VinistoProductDllModelsApiCategoryCategoryReturn,
		Error
	>,
	subCategories: [],
};

export const CategoryContext = createContext(categoryContextDefaultValues);

const CategoryContextProvider = (props: ICategoryContextProviderProps) => {
	const notificationsContext = useContext(NotificationsContext);
	const documentHeaderContext = useContext(DocumentHeaderContext);
	const localizationContext = useContext(LocalizationContext);

	const t = localizationContext.useFormatMessage();
	const navigate = useNavigate();
	const getLocalizedValue = useLocalizedValue();
	const location = useLocation();
	const { categoryUrl } = useParams();

	// FFS this is a query object, not 'data'
	const categoryData = useQuery(
		['category', { categoryUrl }],
		() => fetchCategoryByUrl({ categoryUrl }),
		{
			onError: (err: Error) => {
				if (err.message === 'ObjectNotFound') {
					navigate('/');
					return notificationsContext.handleShowErrorNotification(
						'notification.message.category.error.ObjectNotFound'
					);
				}
			},
		}
	);

	const isCategoryDataLoading = categoryData?.isLoading;
	const isPreviousCategoryDataLoading = usePrevious(isCategoryDataLoading);
	const pathname = location.pathname;
	const prevPathname = usePrevious(pathname);

	useEffect(() => {
		if (
			(isPreviousCategoryDataLoading || pathname !== prevPathname) &&
			!isCategoryDataLoading
		) {
			const categoryName = getLocalizedValue(
				get(categoryData, 'data.category.name', [])
			);
			const pageTitle = `${t(
				{ id: 'app.title.page' },
				{ title: categoryName }
			)}`;
			const pageDescription = getLocalizedValue(
				get(categoryData, 'data.category.description', [])
			);
			documentHeaderContext.dispatch({
				type: DocumentHeaderAction.set,
				value: {
					title: pageTitle,
					description: pageDescription,
					twitterCard: {
						card: TwitterCardType.summary,
						title: pageTitle,
						description: pageDescription,
						image: '', // TODO: missing image from product ppl
					},
					openGraph: {
						type: OpenGraphItemType.article,
						title: pageTitle,
						description: pageDescription,
						url: `${Config.baseUrl}${t({
							id: 'routes.category.route',
						})}/${categoryUrl}`,
						image: '', // TODO: missing image from product ppl
						fbAdmins: Config.market.socials.facebookAdmins,
					},
					jsonLd: [
						{
							'@context': 'https://schema.org',
							'@type': 'BreadcrumbList',
							itemListElement: [
								{
									'@type': 'ListItem',
									position: 1,
									name: Config.domainName,
									item: Config.baseUrl,
								},
								{
									'@type': 'ListItem',
									position: 2,
									name: categoryName,
								},
							],
						},
						{
							'@context': 'https://schema.org',
							'@type': 'Article',
							headline: pageTitle,
							image: [], // TODO: missing image from product ppl
						},
					],
				},
			});
		}
		// make sure not to cause an infinte loop by adding document context :)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		categoryData,
		isCategoryDataLoading,
		isPreviousCategoryDataLoading,
		pathname,
		prevPathname,
		t,
		getLocalizedValue,
	]);

	const subCategories: Record<string, any>[] = [];

	return (
		<CategoryContext.Provider
			value={{
				categoryData,
				subCategories,
			}}
		>
			{props.children}
		</CategoryContext.Provider>
	);
};

export default CategoryContextProvider;
