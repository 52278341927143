import { useContext, useEffect } from 'react';
import cx from 'classnames';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { LocalizationContext } from 'Services/LocalizationService';
import ContainerFullWidth from 'Components/View/ContainerFullWidth';
import Container from 'Components/View/Container';
import TextHighlighted from 'Components/View/TextHighlighted';
import Message from 'Components/View/Message';

import styles from './styles.module.css';

const DeliveryOptions = (): JSX.Element => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const documentHeaderContext = useContext(DocumentHeaderContext);

	useEffect(() => {
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.delivery.name' })}` }
			)}`,
		});
	}, [documentHeaderContext, t]);

	return (
		<section id="content-wrapper">
			<ContainerFullWidth>
				<h1 className={styles.mainHeading}>
					{t({ id: 'deliveryOptions.title' })}
				</h1>
				<p className={styles.infoText}>
					{t({ id: 'deliveryOptions.before' })}
					<br />
					{t({ id: 'deliveryOptions.after' })}
					<br />
					{t({ id: 'deliveryOptions.bonus' })}
				</p>
			</ContainerFullWidth>
			<Container>
				<div className="col-12 col-xxl-6">
					<div className={styles.deliveryCard}>
						<h2 className={styles.cardHeading}>
							{t({ id: 'deliveryOptions.ppl.title' })}
						</h2>
						<p className={styles.cardText}>
							{t(
								{ id: 'deliveryOptions.ppl.info' },
								{
									limit: (
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.ppl.info.limit' })}
										</span>
									),
								}
							)}
						</p>
						<ul className={styles.list}>
							<li className={styles.li}>
								{t(
									{ id: 'deliveryOptions.list' },
									{
										limit: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.ppl.list.limit3kg' })}
											</span>
										),
										price: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.ppl.list.price3kg' })}
											</span>
										),
									}
								)}
							</li>
							<li className={styles.li}>
								{t(
									{ id: 'deliveryOptions.list' },
									{
										limit: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.ppl.list.limit6kg' })}
											</span>
										),
										price: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.ppl.list.price6kg' })}
											</span>
										),
									}
								)}
							</li>
							<li className={styles.li}>
								{t(
									{ id: 'deliveryOptions.list' },
									{
										limit: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.ppl.list.limit10kg' })}
											</span>
										),
										price: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.ppl.list.price10kg' })}
											</span>
										),
									}
								)}
							</li>
							<li className={styles.li}>
								{t(
									{ id: 'deliveryOptions.list' },
									{
										limit: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.ppl.list.limit18kg' })}
											</span>
										),
										price: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.ppl.list.price18kg' })}
											</span>
										),
									}
								)}
							</li>
						</ul>
						<p className={styles.cardText}>
							{t(
								{ id: 'deliveryOptions.cash' },
								{
									cash: (
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.ppl.cash' })}
										</span>
									),
									price: (
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.ppl.cashPrice' })}
										</span>
									),
								}
							)}
						</p>
					</div>
				</div>
				<div className="col-12 col-xxl-6">
					<div className={styles.deliveryCard}>
						<h2 className={styles.cardHeading}>
							{t({ id: 'deliveryOptions.messenger.title' })}
						</h2>
						<p className={styles.cardText}>
							{t(
								{ id: 'deliveryOptions.messenger.info' },
								{
									limit: (
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.messenger.info.limit' })}
										</span>
									),
								}
							)}
						</p>
						<ul className={styles.list}>
							<li className={styles.li}>
								{t(
									{ id: 'deliveryOptions.list' },
									{
										limit: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.messenger.list.limit4kg' })}
											</span>
										),
										price: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.messenger.list.price4kg' })}
											</span>
										),
									}
								)}
							</li>
							<li className={styles.li}>
								{t(
									{ id: 'deliveryOptions.list' },
									{
										limit: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.messenger.list.limit10kg' })}
											</span>
										),
										price: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.messenger.list.price10kg' })}
											</span>
										),
									}
								)}
							</li>
							<li className={styles.li}>
								{t(
									{ id: 'deliveryOptions.list' },
									{
										limit: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.messenger.list.limit20kg' })}
											</span>
										),
										price: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.messenger.list.price20kg' })}
											</span>
										),
									}
								)}
							</li>
							<li className={styles.li}>
								{t(
									{ id: 'deliveryOptions.list' },
									{
										limit: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.messenger.list.limit30kg' })}
											</span>
										),
										price: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.messenger.list.price30kg' })}
											</span>
										),
									}
								)}
							</li>
							<li className={styles.li}>
								{t(
									{ id: 'deliveryOptions.list' },
									{
										limit: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.messenger.list.limit50kg' })}
											</span>
										),
										price: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.messenger.list.price50kg' })}
											</span>
										),
									}
								)}
							</li>
							<li className={styles.li}>
								{t(
									{ id: 'deliveryOptions.list' },
									{
										limit: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.messenger.list.limit80kg' })}
											</span>
										),
										price: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.messenger.list.price80kg' })}
											</span>
										),
									}
								)}
							</li>
						</ul>
						<p className={styles.cardText}>
							{t(
								{ id: 'deliveryOptions.cash' },
								{
									cash: (
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.ppl.cash' })}
										</span>
									),
									price: (
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.ppl.cashPrice' })}
										</span>
									),
								}
							)}
						</p>
						<p className={styles.cardText}>
							{t({ id: 'deliveryOptions.free' })}
						</p>
					</div>
				</div>
				<div className="col-12 col-xxl-6">
					<div className={styles.deliveryCard}>
						<h2 className={styles.cardHeading}>
							{t({ id: 'deliveryOptions.zasilkovna.title' })}
						</h2>
						<p className={styles.cardText}>
							{t(
								{ id: 'deliveryOptions.zasilkovna.info' },
								{
									limit: (
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.zasilkovna.info.limit' })}
										</span>
									),
								}
							)}
						</p>
						<ul className={styles.list}>
							<li className={styles.li}>
								{t(
									{ id: 'deliveryOptions.list' },
									{
										limit: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.zasilkovna.list.limit4kg' })}
											</span>
										),
										price: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.zasilkovna.list.price4kg' })}
											</span>
										),
									}
								)}
							</li>
							<li className={styles.li}>
								{t(
									{ id: 'deliveryOptions.list' },
									{
										limit: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.zasilkovna.list.limit6kg' })}
											</span>
										),
										price: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.zasilkovna.list.price6kg' })}
											</span>
										),
									}
								)}
							</li>
						</ul>
						<p className={styles.cardText}>
							{t(
								{ id: 'deliveryOptions.cash' },
								{
									cash: (
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.ppl.cash' })}
										</span>
									),
									price: (
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.ppl.cashPrice' })}
										</span>
									),
								}
							)}
						</p>
					</div>
				</div>
				<div className="col-12 col-xxl-6">
					<div className={styles.deliveryCard}>
						<h2 className={styles.cardHeading}>
							{t(
								{ id: 'deliveryOptions.our.title' },
								{
									vinisto: (
										<TextHighlighted>
											{t({
												id: 'deliveryOptions.our.title.vinisto',
											})}
										</TextHighlighted>
									),
								}
							)}
						</h2>
						<p className={styles.cardText}>
							{t(
								{ id: 'deliveryOptions.our.info1' },
								{
									nolimit: (
										<span className="fw-bolder">
											{t({
												id: 'deliveryOptions.our.info1.nolimit',
											})}
										</span>
									),
								}
							)}
						</p>
						<ul className={styles.list}>
							<li className={styles.li}>
								{t(
									{ id: 'deliveryOptions.list' },
									{
										limit: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.vinisto.list.limit10kg' })}
											</span>
										),
										price: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.vinisto.list.price10kg' })}
											</span>
										),
									}
								)}
							</li>
							<li className={styles.li}>
								{t(
									{ id: 'deliveryOptions.list' },
									{
										limit: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.vinisto.list.limit11kg' })}
											</span>
										),
										price: (
											<span className="fw-bolder">
												{t({ id: 'deliveryOptions.vinisto.list.price11kg' })}
											</span>
										),
									}
								)}
							</li>
						</ul>
						<p className={styles.cardText}>
							{t({ id: 'deliveryOptions.our.info2' })}
						</p>
						<p className={styles.cardText}>
							{t({ id: 'deliveryOptions.free5' })}
						</p>
					</div>
				</div>
				<div className="col-12">
					<div className={styles.deliveryCard}>
						<h2 className={styles.cardHeading}>
							{t({ id: 'deliveryOptions.personal.title' })}
						</h2>
						<p className={cx(styles.cardText, 'col-12 col-xxl-6')}>
							{t(
								{ id: 'deliveryOptions.personal.info' },
								{
									nolimit: (
										<span className="fw-bolder">
											{t({
												id: 'deliveryOptions.personal.info.nolimit',
											})}
										</span>
									),
								}
							)}
						</p>
						<Message
							variant="alert"
							className={styles.message}
						>
							{t({ id: 'deliveryOptions.personal.alert' })}
						</Message>
						<div className="row">
							<div className="col-12 col-xxl-6">
								<ul className={styles.list}>
									<li className={cx(styles.li, styles.personalLi)}>
										{t({ id: 'deliveryOptions.personal.branch' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.branch.0' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.address' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.address.0' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.opening' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.opening.0' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.prices' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.prices.0' })}
										</span>
									</li>
									<li className={cx(styles.li, styles.personalLi)}>
										{t({ id: 'deliveryOptions.personal.branch' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.branch.1' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.address' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.address.1' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.opening' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.opening.1' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.prices' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.prices.1' })}
										</span>
									</li>
								</ul>
							</div>
							<div className="col-12 col-xxl-6">
								<ul className={styles.list}>
									<li className={cx(styles.li, styles.personalLi)}>
										{t({ id: 'deliveryOptions.personal.branch' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.branch.2' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.address' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.address.2' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.opening' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.opening.2' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.prices' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.prices.2' })}
										</span>
									</li>
									<li className={cx(styles.li, styles.personalLi)}>
										{t({ id: 'deliveryOptions.personal.branch' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.branch.6' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.address' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.address.6' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.opening' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.opening.6' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.prices' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.prices.6' })}
										</span>
									</li>
								</ul>
							</div>
						</div>
						<p className={styles.cardText}>
							{t({ id: 'deliveryOptions.personal.cash' })}
						</p>
					</div>
				</div>
				<div className="col-12">
					<div className={styles.deliveryCard}>
						<h3 className={styles.cardSubHeading}>
							{t({ id: 'deliveryOptions.personal.preparing.title' })}
						</h3>
						<p className={cx(styles.cardText, 'col-12 col-xxl-6 p-0 pe-xxl-5')}>
							{t({ id: 'deliveryOptions.personal.preparing.info' })}
						</p>
						<div className="row">
							<div className="col-12 col-xxl-6">
								<ul className={styles.list}>
									<li className={cx(styles.li, styles.personalLi)}>
										{t({ id: 'deliveryOptions.personal.branch' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.branch.4' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.address' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.address.4' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.opening' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.opening.4' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.prices' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.prices.4' })}
										</span>
									</li>
									<li className={cx(styles.li, styles.personalLi)}>
										{t({ id: 'deliveryOptions.personal.branch' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.branch.3' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.address' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.address.3' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.opening' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.opening.3' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.prices' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.prices.3' })}
										</span>
									</li>
								</ul>
							</div>
							<div className="col-12 col-xxl-6">
								<ul className={styles.list}>
									<li className={cx(styles.li, styles.personalLi)}>
										{t({ id: 'deliveryOptions.personal.branch' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.branch.5' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.address' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.address.5' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.opening' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.opening.5' })}
										</span>
										<br />
										{t({ id: 'deliveryOptions.personal.prices' })}
										<span className="fw-bolder">
											{t({ id: 'deliveryOptions.personal.prices.5' })}
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</section>
	);
};

export default DeliveryOptions;
