import { VinistoBasketDllModelsApiBasketBasketDiscountCoupon } from '@/api-types/basket-api';
import {
	VinistoOrderDllModelsApiOrderDiscountCouponAmountDiscountCouponDefinition,
	VinistoOrderDllModelsApiOrderDiscountCouponPercentageDiscountCouponDefinition,
} from '@/api-types/order-api';
import { VinistoHelperDllEnumsCurrency } from '@/api-types/product-api';

export enum DiscountCouponPageType {
	ORDER_SUMMARY = 'ORDER_SUMMARY',
	USER_SECTION = 'USER_SECTION',
}

export interface DiscountCouponProps {
	discountCoupon:
		| VinistoBasketDllModelsApiBasketBasketDiscountCoupon
		| VinistoOrderDllModelsApiOrderDiscountCouponAmountDiscountCouponDefinition
		| VinistoOrderDllModelsApiOrderDiscountCouponPercentageDiscountCouponDefinition;
	discountCouponPage?: DiscountCouponPageType;
	className?: string;
	orderCurrency: VinistoHelperDllEnumsCurrency;
}
