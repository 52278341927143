import { TEST_IDS } from 'Constants/test-ids';
import { DeliveryMethodsPlacement } from 'Components/DeliveryMethods/types';
import { SKELETONS_NUM_DELIVERIES } from 'Pages/CartShippingPayment/constants';
import { useContext, useState } from 'react';
import { OrderContext } from 'Services/OrderService/context';
import { LocalizationContext } from 'Services/LocalizationService';
import useGetFastestAndCheapestDelivery from 'Hooks/use-get-fastest-and-cheapest-delivery';

import DeliveryItem from '../DeliveryItem';
import ShippingItem from '../ShippingItem';

import {
	VinistoHelperDllEnumsOrderDeliveryType,
	VinistoOrderDllModelsApiDeliveryDelivery,
} from '@/api-types/warehouse-api';

const DeliveryList = ({
	deliveries,
	isDeliveriesLoading,
}: {
	deliveries: VinistoOrderDllModelsApiDeliveryDelivery[];
	isDeliveriesLoading: boolean;
}) => {
	const orderContext = useContext(OrderContext);
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const handleReset = () => orderContext.setDeliveryMethod(null);

	const [shouldShowPickupGroup, setShouldShowPickupGroup] = useState(false);

	const pickups = deliveries.filter(
		(delivery) =>
			delivery.deliveryType === VinistoHelperDllEnumsOrderDeliveryType.PICKUP
	);

	const { soonestPickupDateAsString, cheapestPickupPriceAsString } =
		useGetFastestAndCheapestDelivery({ deliveries: pickups, monthName: false });

	const otherDeliveries = deliveries.filter(
		(delivery) =>
			delivery.deliveryType !== VinistoHelperDllEnumsOrderDeliveryType.PICKUP
	);

	const isChildItemSelected = pickups.some(
		(pickup) => pickup.id === orderContext.deliveryMethod?.id
	);

	if (isDeliveriesLoading) {
		return (
			<div>
				{Array.from({ length: SKELETONS_NUM_DELIVERIES }).map((_, index) => (
					<DeliveryItem
						key={index}
						isLoading={true}
						placement={DeliveryMethodsPlacement.CHECKOUT}
					/>
				))}
			</div>
		);
	}

	return (
		<div>
			{otherDeliveries?.map((delivery, index) => (
				<DeliveryItem
					key={delivery?.id ?? index}
					delivery={delivery}
					dataTestid={TEST_IDS.BASKET_SHIPPING_TYPE + '_' + delivery?.id}
					placement={DeliveryMethodsPlacement.CHECKOUT}
				/>
			))}
			{pickups.length > 0 && (
				// TODO: In the future, it might be desirable to abstract this to a <DeliveryItemGroup /> component
				<ShippingItem
					onClick={() => setShouldShowPickupGroup(!shouldShowPickupGroup)}
					isLoading={false}
					isSelected={isChildItemSelected || shouldShowPickupGroup}
					title={`${t({ id: 'deliveryOptions.personal.parentTitle' })}`}
					titleContent={
						<div>{t({ id: 'deliveryOptions.personal.parentTitle' })}</div>
					}
					priceContent={
						isChildItemSelected
							? null
							: `cena od: ${cheapestPickupPriceAsString}`
					}
					dateContent={
						isChildItemSelected
							? null
							: t(
									{ id: 'bundleAvailability.availablePickupDate' },
									{ day: `${soonestPickupDateAsString}` }
							  )
					}
					selectable={true}
					handleReset={() => {
						handleReset();
						setShouldShowPickupGroup(false);
					}}
					placement={DeliveryMethodsPlacement.CHECKOUT}
				/>
			)}
			{(isChildItemSelected || shouldShowPickupGroup) &&
				pickups?.map((delivery, index) => (
					<DeliveryItem
						key={delivery?.id ?? index}
						delivery={delivery}
						dataTestid={TEST_IDS.BASKET_SHIPPING_TYPE + '_' + delivery?.id}
						placement={DeliveryMethodsPlacement.CHECKOUT}
						showIcons={false}
						className={'ms-4'}
					/>
				))}
		</div>
	);
};

export default DeliveryList;
