import { useMatch } from 'react-router-dom';
import { useContext } from 'react';
import { DeviceServiceContext } from 'Services/DeviceService';
import useFormatMessage from 'Hooks/useFormatMessage';

//TODO: name hook/return values more appropriately - could be done on a router level instead.
/**
 * Hook calculating the current web section using the current route/navigation.
 */
const useWebSection = () => {
	const { isDesktop } = useContext(DeviceServiceContext);

	const t = useFormatMessage();

	const matchCart = useMatch(`/${t({ id: 'routes.cart.route' })}`);
	const matchCartShippingPayment = useMatch(
		`/${t({ id: 'routes.cart.shippingPayment.route' })}`
	);
	const matchCartShippingData = useMatch(
		`/${t({ id: 'routes.cart.shipping.route' })}`
	);

	const isCheckout =
		(matchCart !== null && !isDesktop) ||
		matchCartShippingPayment !== null ||
		matchCartShippingData !== null;

	const isEshop = !isCheckout;

	return { isCheckout, isEshop };
};

export default useWebSection;
