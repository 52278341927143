import { type ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './styles.module.css';

interface FilterHeadingProps {
	children: ReactNode;
	isLoading: boolean;
}

const Heading = ({ children, isLoading }: FilterHeadingProps) => {
	return (
		<div>
			{isLoading ? (
				<Skeleton
					containerClassName={styles.legend}
					style={{ display: 'flex', width: '40%' }}
				/>
			) : (
				<legend className={styles.legend}>{children}</legend>
			)}
		</div>
	);
};

export default Heading;
