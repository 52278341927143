import { FC } from 'react';

import { IIconProps } from './Interfaces';

const HeaderVinistotekaIcon: FC<IIconProps> = ({ className }) => {
	return (
		<svg
			viewBox="0 0 10.48 14"
			className={className}
		>
			<g>
				<g>
					<path
						fill="#92013f"
						d="M10.47,6.97c0,.35,.02,.68,0,1-.03,.52-.1,1.03-.14,1.55-.01,.14-.06,.25-.17,.34-.59,.52-1.27,.88-2.01,1.13-1.16,.4-2.36,.51-3.58,.41-1.01-.08-1.97-.32-2.87-.77-.53-.26-1.01-.59-1.41-1.03-.06-.07-.12-.15-.13-.24C.05,8.55-.01,7.74,0,6.92c0-.02,.01-.04,.02-.08,1.55,1.12,3.32,1.56,5.19,1.59,1.87,.02,3.65-.38,5.25-1.45Z"
					/>
					<path
						fill="#92013f"
						d="M.32,3.32s.03,0,.03,0c.38,.56,.93,.88,1.53,1.14,.73,.31,1.5,.47,2.28,.55,.62,.06,1.24,.07,1.85,.03,.99-.07,1.95-.27,2.85-.7,.49-.24,.93-.54,1.25-1,0,0,.02-.01,.05-.03,.03,.18,.07,.35,.09,.52,.07,.66,.13,1.32,.2,1.99,.01,.13-.06,.2-.14,.26-.46,.37-.97,.66-1.51,.89-.83,.34-1.69,.53-2.57,.61-1.26,.11-2.5,0-3.71-.37-.8-.24-1.54-.6-2.18-1.13C.03,5.81,.01,5.81,.05,5.4c.07-.69,.16-1.37,.25-2.06,0,0,.02-.02,.03-.03Z"
					/>
					<path
						fill="#92013f"
						d="M10.05,10.96s.02,.04,.02,.05c-.04,.26-.07,.51-.13,.77-.08,.39-.31,.7-.6,.97-.51,.46-1.12,.74-1.77,.93-1,.3-2.02,.38-3.06,.29-.77-.06-1.53-.22-2.24-.53-.52-.23-1.01-.52-1.37-.97-.21-.26-.35-.56-.38-.91-.02-.22-.08-.44-.12-.67,0-.03,0-.06-.02-.13,1.46,1.05,3.09,1.47,4.83,1.49,1.73,.03,3.37-.35,4.84-1.3Z"
					/>
					<path
						fill="#92013f"
						d="M1.1,3.22c-.56-.37-.71-1.02-.49-1.47,.13-.27,.32-.49,.56-.67,.44-.34,.94-.55,1.46-.71C3.65,.06,4.7-.04,5.77,.01c.95,.05,1.88,.21,2.75,.6,.41,.18,.79,.41,1.1,.75,.25,.27,.39,.59,.35,.96-.05,.4-.28,.67-.57,.93-.01-.04-.02-.06-.02-.07,.16-.41,0-.72-.31-.99-.35-.31-.77-.49-1.21-.63-.86-.27-1.75-.37-2.66-.37-.82,0-1.64,.09-2.42,.33-.33,.1-.65,.24-.96,.4-.19,.1-.36,.25-.52,.4-.23,.23-.32,.5-.2,.82,0,.02,0,.04,.01,.06,0,0,0,.01,0,.02Z"
					/>
					<path
						fill="#92013f"
						d="M6.73,2.98c-.19-.04-.42-.09-.64-.15-.06-.02-.12-.06-.17-.1-.11-.1-.12-.24,0-.32,.12-.08,.27-.15,.41-.18,.34-.08,.69-.07,1.02,.03,.11,.03,.21,.09,.3,.15,.13,.1,.13,.22,0,.32-.11,.08-.24,.14-.38,.17-.17,.04-.34,.05-.55,.09Z"
					/>
				</g>
			</g>
		</svg>
	);
};

export default HeaderVinistotekaIcon;
