import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { EnvironmentContext } from 'Services/EnvironmentProvider/context';

import VinistoLogoIcon from '../../../Icons/VinistoLogo';

const Logo = () => {
	const { EnvironmentService } = useContext(EnvironmentContext);

	return (
		<Link
			to="/"
			className="logo"
		>
			<VinistoLogoIcon
				alt={`Vinisto`}
				title={`Vinisto`}
				className="vinisto-logo"
				environment={EnvironmentService.getEnvironment()}
			/>
		</Link>
	);
};

export default Logo;
