import api, { BaseResponse } from '@/api';
import SpecificationAdapter from '@/domain/specification/adapter';
import { SpecificationType } from '@/domain/specification/schema';
import {
	CategoriesSpecificationsDetailParams,
	SpecificationsListParams,
	TagsSpecificationsDetailParams,
	VinistoProductDllModelsApiSpecificationSpecificationValuesReturn,
} from '@/api-types/product-api';

const SpecificationAdapterInstance = new SpecificationAdapter();

export type SpecificationsResponse = BaseResponse & {
	specifications: SpecificationType[];
};

export const getAllSpecifications = async (
	params: SpecificationsListParams
) => {
	const response = await api.get<SpecificationsResponse>(
		`product-api/specifications`,
		params
	);

	return {
		...response,
		specifications: response.specifications.map((specification) =>
			SpecificationAdapterInstance.fromApi(specification)
		),
	};
};

const getCategorySpecifications = async (
	categoryId: string,
	params?: Omit<CategoriesSpecificationsDetailParams, 'categoryId'>
) => {
	const response = await api.get<SpecificationsResponse>(
		`product-api/categories/${categoryId}/specifications`,
		params
	);

	return {
		...response,
		specifications: response.specifications.map((specification) =>
			SpecificationAdapterInstance.fromApi(specification)
		),
	};
};

const getTagSpecifications = async (
	tagId: string,
	params?: Omit<TagsSpecificationsDetailParams, 'tagId'>
) => {
	const response = await api.get<SpecificationsResponse>(
		`product-api/tags/${tagId}/specifications`,
		params
	);
	return {
		...response,
		specifications: response.specifications.map((specification) =>
			SpecificationAdapterInstance.fromApi(specification)
		),
	};
};

const getSpecificationValueForBundle = async (
	bundleId: string,
	specificationId: string
) => {
	const response = await api.get<VinistoProductDllModelsApiSpecificationSpecificationValuesReturn>(
		`product-api/bundles/${bundleId}/get-specification-values`, { specificationId }
	);

	return response.specificationValues;
}

const SpecificationService = {
	getAllSpecifications,
	getCategorySpecifications,
	getTagSpecifications,
	getSpecificationValueForBundle,
};

export default SpecificationService;