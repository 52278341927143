import { lazy, Suspense, useContext, useMemo } from 'react';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { LocalizationContext } from 'Services/LocalizationService';
import { AddressesApiHooks } from 'Services/Addresses/hooks';
import Loader from 'Components/View/Loader';
import { Link } from 'react-router-dom';
const LogoutIcon = lazy(() => import('Components/Icons/Logout'));

import styles from './styles.module.css';

import { VinistoAuthDllModelsApiAddressAddress } from '@/api-types/user-api';

const UserHeader = () => {
	const t = useContext(LocalizationContext).useFormatMessage();
	const { handleOnLogOut, vinistoUser } = useContext(AuthenticationContext);
	const addressesData = AddressesApiHooks.useGetAll();

	const address: VinistoAuthDllModelsApiAddressAddress | undefined = useMemo(
		() =>
			!addressesData.isFetching
				? addressesData.data?.addresses?.[0]
				: undefined,
		[addressesData]
	);

	const name = address && address.name + ' ' + address.surname;

	return (
		<div className={styles.userHeaderWrap}>
			<Link
				to={`/${t({ id: 'routes.user-section.route' })}`}
				className={styles.userGrid}
			>
				<div className={styles.nickname}>
					<div className={styles.nick}>
						{vinistoUser.nickname ??
							vinistoUser?.email?.slice(0, 3).toUpperCase() ??
							''}
					</div>
				</div>
				<div className={styles.name}>{name}</div>
				<div className={styles.email}>{vinistoUser.email ?? ''}</div>
			</Link>
			<button onClick={() => handleOnLogOut()}>
				<Suspense fallback={<Loader blank />}>
					<LogoutIcon
						title={t({ id: 'navbar.actionButton.logOut' })}
						alt={t({ id: 'navbar.actionButton.logOut' })}
						className={styles.logOutIcon}
					/>
				</Suspense>
			</button>
		</div>
	);
};

export default UserHeader;
