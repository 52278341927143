import { useContext } from 'react';
import cx from 'classnames';
import { PopoverContext } from 'Components/Popover';

import PopoverCloseButton from '../CloseButton';

import { PopoverWrapperVariants } from './constants';
import { PopoverWrapperProps } from './interfaces';
import styles from './styles.module.css';

const PopoverWrapper = ({
	children,
	withCloseButton,
	variant = PopoverWrapperVariants.INFO,
	className,
}: PopoverWrapperProps) => {
	const { isClosing } = useContext(PopoverContext);

	return (
		<div
			className={cx(
				styles.wrapper,
				{
					[styles.success]: variant === PopoverWrapperVariants.SUCCESS,
					[styles.error]: variant === PopoverWrapperVariants.ERROR,
					[styles.fadeOut]: isClosing,
				},
				className
			)}
		>
			{withCloseButton && (
				<div className={styles.closeButtonWrapper}>
					<PopoverCloseButton />
				</div>
			)}
			{children}
		</div>
	);
};

export default PopoverWrapper;
