import { useContext } from 'react';
import cx from 'classnames';
import { FavoritesContext } from 'Services/FavoritesService';
import Favorite from 'Components/Favorite';
import { TEST_IDS } from 'Constants/test-ids';

import { IButtonAddToFavoritesProps } from './interfaces';

import './styles.css';

const ButtonAddToFavorites = ({
	removeItemFromFavorites,
	addToFavorites,
	itemId,
	size = 'sm',
	className = 'vinisto-wine__img-info',
	carouselType,
}: IButtonAddToFavoritesProps) => {
	const favoritesContext = useContext(FavoritesContext);

	const isAlreadyInFavorites =
		favoritesContext.isAlreadyInFavorites(itemId) === true;

	const handleOnClick = isAlreadyInFavorites
		? removeItemFromFavorites
		: addToFavorites;

	const dataIdString = `${TEST_IDS.PRODUCT_BOX_ADD_TO_FAVORITES}${
		carouselType !== undefined ? '_' + carouselType : ''
	}_${itemId}`;

	return (
		<button
			className={cx(className, { full: isAlreadyInFavorites })}
			onClick={handleOnClick}
			data-testid={dataIdString}
		>
			<Favorite
				filled={isAlreadyInFavorites}
				size={size}
			/>
		</button>
	);
};
export default ButtonAddToFavorites;
