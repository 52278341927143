import { useContext } from 'react';
import { LocalizationContext } from 'Services/LocalizationService';
import { TEST_IDS } from 'Constants/test-ids';

const RegisterCta = ({
	handleOpenRegistrationModal,
}: {
	handleOpenRegistrationModal: () => void;
}) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	return (
		<div className="vinisto-popup__split__cta">
			<ul className="vinisto-popup__usp">
				<li>
					{t(
						{
							id: 'modal.registration.usp1.label',
						},
						{
							specialProducts: (
								<span className="fw-bold">
									{t({
										id: 'modal.registration.usp1.label.specialProducts',
									})}
								</span>
							),
							prices: (
								<span className="fw-bold">
									{t({
										id: 'modal.registration.usp1.label.prices',
									})}
								</span>
							),
							club: (
								<span className="fw-bold vinisto-color-success">
									{t({
										id: 'modal.registration.usp1.label.club',
									})}
								</span>
							),
						}
					)}
				</li>
				<li>
					{t(
						{
							id: 'modal.registration.usp2.label',
						},
						{
							status: (
								<span className="fw-bold">
									{t({
										id: 'modal.registration.usp2.label.status',
									})}
								</span>
							),
						}
					)}
				</li>
				<li>
					{t(
						{
							id: 'modal.registration.usp3.label',
						},
						{
							contests: (
								<span className="fw-bold">
									{t({
										id: 'modal.registration.usp3.label.contests',
									})}
								</span>
							),
							first: (
								<span className="fw-bold">
									{t({
										id: 'modal.registration.usp3.label.first',
									})}
								</span>
							),
						}
					)}
				</li>
			</ul>
			<div className="text-end desktop-only">
				<button
					onClick={handleOpenRegistrationModal}
					className="vinisto-btn vinisto-bg-outline-green"
					data-testid={TEST_IDS.REGISTRATION_BUTTON_MODAL}
				>
					{t({ id: 'modal.registration.submitButtonText' })}
				</button>
			</div>
		</div>
	);
};

export default RegisterCta;
