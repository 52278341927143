import { apiServiceInstance } from 'Services/ApiService';

type TFetchGetPaymentDetailsProps = {
	paymentId: string | number;
};

export const fetchPaymentDetails = ({
	paymentId,
}: TFetchGetPaymentDetailsProps) =>
	apiServiceInstance.get('services-api/gopay', true, paymentId);

type TFetchPostCreatePaymentFromOrderProps = {
	orderId: string;
	notificationUrl: string;
	returnUrl: string;
};

export const fetchPostCreatePaymentFromOrder = (
	requestData: TFetchPostCreatePaymentFromOrderProps
) =>
	apiServiceInstance.post(
		'services-api/gopay/CreatePaymentFromOrder',
		requestData,
		true
	);
