import { FC, useContext, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { LocalizationContext } from 'Services/LocalizationService';
import ContainerFullWidth from 'Components/View/ContainerFullWidth';
import Card from 'Components/View/Card';

const Faq: FC = (): JSX.Element => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const documentHeaderContext = useContext(DocumentHeaderContext);

	useEffect(() => {
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.termsAndConditions.name' })}` }
			)}`,
		});
	}, []);

	return (
		<section id="content-wrapper">
			<ContainerFullWidth>
				<Card>
					<div className="vinisto-font-18">
						<h2 className="text-center">Nejčastější dotazy</h2>
						<Accordion>
							<Accordion.Item eventKey="0">
								<Accordion.Header>Možnosti dopravy</Accordion.Header>
								<Accordion.Body>
									<p>V tuto chvíli máte k dispozici tyto možnosti dopravy:</p>
									<ul>
										<li>PPL</li>
										<li>Fofr</li>
										<li>Messenger</li>
										<li>Zásilkovna</li>
									</ul>
									<p>
										Podrobný popis jednotlivých způsobů dopravy naleznete{' '}
										<Link
											to={`/${t({
												id: 'routes.delivery.route',
											})}`}
										>
											zde
										</Link>
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="1">
								<Accordion.Header>Možnosti platby</Accordion.Header>
								<Accordion.Body>
									<p>Umožňujeme tyto možnosti platby</p>
									<ul>
										<li>Platba kartou online</li>
										<li>Platba online převodem</li>
										<li>Platba na dobírku</li>
									</ul>
									<p>
										Podrobný popis jednotlivých způsobů plateb naleznete{' '}
										<Link
											to={`/${t({
												id: 'routes.payments.route',
											})}`}
										>
											zde
										</Link>
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="2">
								<Accordion.Header>Reklamace a vrácení</Accordion.Header>
								<Accordion.Body>
									<p>
										Reklamaci lze provést kontaktováním reklamačního oddělení na
										reklamace@vinisto.cz, nebo na telefonním čísle +420 606 758
										080
									</p>
									<p>
										Popis podmínek reklamace, vrácení zboží a jak reklamaci
										uplatnit naleznete{' '}
										<Link
											to={`/${t({
												id: 'routes.returns.route',
											})}`}
										>
											zde
										</Link>
									</p>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
				</Card>
			</ContainerFullWidth>
		</section>
	);
};

export default Faq;
