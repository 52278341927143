import { createContext, useCallback, useContext, useMemo } from 'react';
import { isNumber, last } from 'lodash-es';
import {
	useQueries,
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import { VIEW } from 'Hooks/useCategoryView/interfaces';
import removeDiacritics from 'Helpers/removeDiacritics';
import { useBundleQueries } from 'Hooks/Queries/useBundleQueries';
import useCategoryView from 'Hooks/useCategoryView';
import useURLParams from 'Hooks/useURLParams';
import { LocalizationContext } from 'Services/LocalizationService';
import { WarehouseContext } from 'Services/WarehouseService';
import useLocalizedValue from 'Hooks/useLocalizedValue';

import { CategoryContext } from '../../context';

import {
	FILTER_CODE,
	PRICE_SPECIFICATION_ID,
	SORTING,
	SORTING_DEFAULT,
	URL_PARAM_LIMIT,
	URL_PARAM_LIMIT_DEFAULT_VALUE,
	URL_PARAM_PAGE,
} from './constants';
import {
	BundleSorting,
	IBundlesWithFiltersContextValues,
	IBundlesWithFiltersProviderProps,
	ListingType,
} from './interfaces';
import {
	calculateBundlesToLoadMore,
	createBundlePageQueries,
	generateBundlesToShow,
	generateRequestFilters,
	getBundlesCount,
	getPageFromParam,
	mergeSpecificationsWithBundleFilters,
} from './helpers';

import SpecificationService from '@/product-service/specification';
import { Specification } from '@/domain/specification/schema';
import TagService from '@/product-service/tag';
import {
	VinistoHelperDllEnumsCurrency,
	VinistoHelperDllEnumsTagSortableColumns,
	VinistoProductDllModelsApiBundleBundlesReturn,
} from '@/api-types/product-api';
import { Bundle } from '@/domain/bundle';

const { getAll } = TagService;
const { getCategorySpecifications } = SpecificationService;

const BundlesWithFiltersContextDefaultValues: IBundlesWithFiltersContextValues =
	{
		activeSpecificationFilters: [],
		activeTagFilters: [],
		bundlesData: [],
		bundles: [],
		bundlesCount: 0,
		bundlesToLoadMore: 0,
		currentPage: 1,
		isBundlesLoading: false,
		isDataLoading: false,
		handleOnRemoveFilter: () => () => undefined,
		handleOnViewChange: () => () => undefined,
		limit: 10,
		page: [1],
		query: {},
		setPageParam: () => null,
		isInStockParam: '',
		isDiscountedParam: '',
		isInStockActive: false,
		isDiscountedActive: false,
		totalActiveFiltersCount: 0,
		setQuery: () => null,
		specificationsQuery: {} as UseQueryResult<{
			specifications: Specification[];
		}>,
		specificationsWithBundleFilters: {
			specificationFilters: [],
			tagFilters: [],
			supplierFilters: [],
			isInStockFilters: [],
			isDiscountedFilters: [],
		},
		view: VIEW.GRID,
		sorting: SORTING_DEFAULT,
		setSorting: () => null,
	};

export const BundlesWithFiltersContext = createContext(
	BundlesWithFiltersContextDefaultValues
);

export type MappedVinistoProductDllModelsApiBundleBundlesReturn = Omit<
	VinistoProductDllModelsApiBundleBundlesReturn,
	'bundles'
> & {
	bundles: Bundle[];
};

export type BundlesBatchedByPages =
	UseQueryOptions<MappedVinistoProductDllModelsApiBundleBundlesReturn>[];

const BundlesWithFiltersProvider = (
	props: IBundlesWithFiltersProviderProps
) => {
	const categoryContext = useContext(CategoryContext);
	const warehouseContext = useContext(WarehouseContext);
	// TODO destructure right in the top context, this is horrible
	// Can be probably abstracted as something like routerContext
	const categoryId = categoryContext?.categoryData?.data?.category?.id ?? '';
	const isCategoryDataLoading =
		categoryContext?.categoryData?.isLoading ?? false;

	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();

	const {
		activeLanguageKey,
		activeCurrency: { currency },
		countryOfSale,
		convertEURtoCZK,
	} = localizationContext;

	// This can be called right in the categoryContext
	// Also, same query key, different functions!
	const specificationsQuery = useQuery(
		['category-specifications', { categoryId }],
		() => getCategorySpecifications(categoryId, { IsCache: true, currency }),
		{
			enabled: !!categoryId,
			keepPreviousData: true,
		}
	);

	const tagQueryParams = {
		IsShownInFilters: true,
		IsCache: true,
		SortingColumn: VinistoHelperDllEnumsTagSortableColumns.ORDER_IN_FILTER,
		limit: 100,
		currency,
	};
	const { data: tags } = useQuery(['tags', tagQueryParams], () =>
		getAll(tagQueryParams)
	);

	const [query, setQuery] = useURLParams();

	const activeSpecificationFilters = useMemo(
		() =>
			generateRequestFilters(
				specificationsQuery?.data?.specifications ?? [],
				query,
				activeLanguageKey
			),
		[specificationsQuery?.data?.specifications, query, activeLanguageKey]
	);

	const urlParamTags = `${t({ id: 'tags.urlParam' })}`;

	const activeTagFilters = useMemo(() => {
		if (!tags) return [];

		const tagFiltersAsArray: string[] = Array.isArray(query[urlParamTags])
			? query[urlParamTags]
			: [query[urlParamTags]];

		const tagFilters = tagFiltersAsArray
			.map((slug: string) => {
				return tags.find(
					(tag) => removeDiacritics(getLocalizedValue(tag.name ?? [])) === slug
				);
			})
			.filter(
				(tag): tag is Exclude<typeof tag, undefined> => tag !== undefined
			);
		return tagFilters;
	}, [tags, query, urlParamTags, getLocalizedValue]);

	const handleOnRemoveFilter = useCallback(
		(specificationName: string) => () => {
			setQuery({ [specificationName]: undefined });
		},
		[setQuery]
	);

	const sortParamUrlName = `${t({ id: 'category.sorting.urlParam' })}`;
	const sortParam = useMemo(() => {
		const urlParam = query[sortParamUrlName];
		if (urlParam === undefined) {
			return SORTING_DEFAULT;
		}
		return (
			SORTING.filter(
				(sorting) =>
					removeDiacritics(`${t({ id: sorting.title })}`) === urlParam
			)[0] ?? SORTING_DEFAULT
		);
	}, [query, sortParamUrlName, t]);

	const setSortParam = useCallback(
		(sort: BundleSorting) => {
			const sortUrlParam =
				sort === SORTING_DEFAULT
					? undefined
					: removeDiacritics(`${t({ id: sort.title })}`);
			setQuery({ [sortParamUrlName]: sortUrlParam });
		},
		[setQuery, sortParamUrlName, t]
	);

	// Category bundles view mode
	const [view, handleOnViewChange] = useCategoryView();

	const pageParam = useMemo(() => query[URL_PARAM_PAGE] ?? [1], [query]);
	const setPageParam = useCallback(
		(pageArray: [number] | [number, number]) => {
			setQuery({ [URL_PARAM_PAGE]: pageArray });
		},
		[setQuery]
	);

	const page = useMemo(() => getPageFromParam(pageParam), [pageParam]);
	const currentPage = useMemo(() => last(page) as number, [page]);

	const limitParam = useMemo(
		() => query[URL_PARAM_LIMIT] ?? URL_PARAM_LIMIT_DEFAULT_VALUE,
		[query]
	);
	const limit = useMemo(
		() => (!isNumber(limitParam) ? URL_PARAM_LIMIT_DEFAULT_VALUE : limitParam),
		[limitParam]
	);
	const isInStockParam = `${t({ id: 'isInStock.urlParam' })}`;
	const isInStockParamRef = query[isInStockParam];

	const isDiscountedParam = `${t({ id: 'isDiscounted.urlParam' })}`;
	const isDiscountedParamRef = query[isDiscountedParam];

	const bundlePageQueries = useMemo(
		() =>
			createBundlePageQueries({
				categoryId,
				tagId: undefined,
				sortingColumn: sortParam?.sortingColumn ?? '',
				isSortingDescending: sortParam?.isSortingDescending ?? false,
				page,
				countryOfSale,
				currency,
				filters: [
					...activeSpecificationFilters.map((filter) => {
						if (
							filter.specificationDefinitionId === PRICE_SPECIFICATION_ID &&
							currency !== VinistoHelperDllEnumsCurrency.CZK
						) {
							return {
								...filter,
								max: convertEURtoCZK(filter.max),
								min: convertEURtoCZK(filter.min),
							};
						}
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						const { specificationName, unit, imperialUnit, ...rest } = filter;
						return rest;
					}),
					...(activeTagFilters.length
						? [
								{
									filterType: FILTER_CODE.TAG,
									tags: activeTagFilters.map((filter) => filter.id),
								},
						  ]
						: []),
					...(isInStockParamRef
						? [
								{
									filterType: FILTER_CODE.STOCK,
									isInStock: isInStockParamRef === t({ id: 'yes' }),
								},
						  ]
						: []),
					...(isDiscountedParamRef
						? [
								{
									filterType: FILTER_CODE.DISCOUNT,
									isDiscounted: true,
								},
						  ]
						: []),
				],
				limit,
				isEnabled: true,
				enabled: !!categoryId && specificationsQuery?.isFetched,
				onSuccess: (data) => {
					if (data?.bundles) {
						const allIds = data.bundles.map((bundle) => bundle.id);
						warehouseContext.fetchQuantity(allIds);
					}
				},
				onError: () => {
					setPageParam([1]);
				},
			}),
		[
			categoryId,
			sortParam?.sortingColumn,
			sortParam?.isSortingDescending,
			page,
			countryOfSale,
			currency,
			activeSpecificationFilters,
			activeTagFilters,
			isInStockParamRef,
			t,
			isDiscountedParamRef,
			limit,
			specificationsQuery?.isFetched,
			convertEURtoCZK,
			warehouseContext,
			setPageParam,
		]
	);

	const { filtersQuery } = useBundleQueries({
		categoryId,
		tagId: null,
		sortParam,
		page,
		limit,
		activeSpecificationFilters,
		activeTagFilters,
		isInStockParamRef,
		isDiscountedParamRef,
		listingType: ListingType.Category,
	});

	const paginatedBundlesData = useQueries<BundlesBatchedByPages>({
		queries: bundlePageQueries,
	});

	const bundlesCount = useMemo(
		() => getBundlesCount(paginatedBundlesData),
		[paginatedBundlesData]
	);
	const bundlesToLoadMore = useMemo(
		() => calculateBundlesToLoadMore(bundlesCount, currentPage, limit),
		[bundlesCount, currentPage, limit]
	);
	const isBundlesLoading = useMemo(
		() =>
			specificationsQuery?.isLoading ||
			paginatedBundlesData.some((item) => item.isLoading),
		[paginatedBundlesData, specificationsQuery]
	);
	const bundles = useMemo(
		() =>
			// TODO Refactor, this has to be a shareable function with object parameter
			generateBundlesToShow(
				categoryId,
				paginatedBundlesData,
				bundlesCount,
				currentPage,
				limit,
				isBundlesLoading
			),
		[
			bundlesCount,
			paginatedBundlesData,
			categoryId,
			currentPage,
			isBundlesLoading,
			limit,
		]
	);

	const isInStockActive = Boolean(isInStockParamRef);
	const isDiscountedActive = Boolean(isDiscountedParamRef);

	const totalActiveFiltersCount =
		activeTagFilters.length +
		activeSpecificationFilters.length +
		(isInStockActive ? 1 : 0) +
		(isDiscountedActive ? 1 : 0);

	// This is zipping specifications data (get-category-specification, get-tag-specification) with get-avaliable-filters data
	// The resultuing object is used to render the filters. Its shape is somewhat weird and should be probably refactored
	const specificationsWithBundleFilters = useMemo(() => {
		return mergeSpecificationsWithBundleFilters(
			specificationsQuery.data?.specifications ?? [],
			filtersQuery.data?.specificationFilters ?? []
		);
	}, [
		filtersQuery.data?.specificationFilters,
		specificationsQuery.data?.specifications,
	]);

	return (
		<BundlesWithFiltersContext.Provider
			value={{
				activeSpecificationFilters,
				activeTagFilters,
				bundlesData: paginatedBundlesData,
				bundles,
				bundlesCount,
				bundlesToLoadMore,
				currentPage,
				isBundlesLoading,
				isDataLoading: isCategoryDataLoading,
				handleOnRemoveFilter,
				handleOnViewChange,
				limit,
				page,
				query,
				setPageParam,
				isInStockParam,
				isInStockParamRef,
				isDiscountedParam,
				isInStockActive,
				isDiscountedActive,
				totalActiveFiltersCount,
				setQuery,
				specificationsQuery,
				specificationsWithBundleFilters,
				view,
				sorting: sortParam,
				setSorting: setSortParam,
			}}
		>
			{props.children}
		</BundlesWithFiltersContext.Provider>
	);
};

export default BundlesWithFiltersProvider;
