import {
	BundlesGetAutocompleteNamesListParams,
	VinistoHelperDllEnumsLanguage,
	VinistoProductDllModelsApiFullSearchFullSearchSeparatedReturn,
} from '@/api-types/product-api';

import { DEFAULT_BUNDLE_API_PARAMS } from '@/shared';
import api from '@/api';

const AUTOCOMPLETE_LIMIT = 5;

// TODO: @peter odmazal jsem countryofsale, neni na backendu, domluv s backendem at pridaji
const getSearchResults = ({
	searchingNameString = '',
	language = VinistoHelperDllEnumsLanguage.CZECH,
	IsCache = true,
	limit = AUTOCOMPLETE_LIMIT,
	currency,
	...rest
}: BundlesGetAutocompleteNamesListParams) => {
	return api.get<VinistoProductDllModelsApiFullSearchFullSearchSeparatedReturn>(
		`search-api/bundles`,
		{
			...DEFAULT_BUNDLE_API_PARAMS,
			searchingNameString,
			language,
			IsCache,
			limit,
			currency,
			...rest,
		}
	);
};

const SearchService = {
  getSearchResults,
};

export default SearchService;
