import { useContext, useMemo, useRef } from 'react';
import { useHideHeaderOnScroll } from 'Hooks/use-hide-header-on-scroll';
import { DeviceServiceContext } from 'Services/DeviceService';
import { WarehouseContext } from 'Services/WarehouseService';
import { BundleMeta } from 'Pages/Bundle/interfaces';
import { useStandardQuantityBox } from 'Components/QuantityBox/Variants/StandardQuantityBox/hooks';

import BundleItem from '../Components/ShopControls/Components/BundleItem';
import VolumeDiscount from '../Components/VolumeDiscount';
import {
	BundleItemVariants,
	QuantityBoxVariants,
} from '../Components/ShopControls/Components/BundleItem/constants';
import FloatingShopControls from '../Components/FloatingShopControls';

import styles from './styles.module.css';

import { Bundle } from '@/domain/bundle';
import { VinistoBasketDllModelsApiBasketBasketBundle } from '@/api-types/basket-api';
import { type VolumeDiscount as VolumeDiscountType } from '@/domain/price';

interface Props {
	bundleLimitPerOrder: number | null;
	itemInBasket: VinistoBasketDllModelsApiBasketBasketBundle | undefined;
	isQuantityLoading: boolean;
	isIdenticalBundlesDataLoading: boolean;
	availableQuantity: number;
	volumeDiscount: VolumeDiscountType | null;
	cheapestAvailableBundle: Bundle;
	bundleMeta: BundleMeta;
}

const DesktopShopControlsSection = ({
	bundleLimitPerOrder,
	itemInBasket,
	isQuantityLoading,
	isIdenticalBundlesDataLoading,
	availableQuantity,
	volumeDiscount,
	cheapestAvailableBundle,
	bundleMeta,
}: Props) => {
	const { isDesktop } = useContext(DeviceServiceContext);
	const warehouseContext = useContext(WarehouseContext);
	const shopControlsRef = useRef<HTMLDivElement>(null);
	const { isGift, isForLogged, isSaleOver, isTemporaryUnavailable } =
		cheapestAvailableBundle.flags;

	const isMaximumQuantityReached =
		(typeof bundleLimitPerOrder === 'number' &&
			bundleLimitPerOrder <= (itemInBasket?.quantity ?? 0)) ||
		availableQuantity <= (itemInBasket?.quantity ?? 0);

	const hideHeaderOptions = useMemo(
		() => ({
			enabled:
				(isQuantityLoading || availableQuantity > 0) &&
				!isGift &&
				!isSaleOver &&
				!isTemporaryUnavailable &&
				!isMaximumQuantityReached,
			isDesktop,
			threshold: 0,
		}),
		[
			availableQuantity,
			isDesktop,
			isGift,
			isMaximumQuantityReached,
			isQuantityLoading,
			isSaleOver,
			isTemporaryUnavailable,
		]
	);

	const isHeaderHidden = useHideHeaderOnScroll(
		shopControlsRef,
		hideHeaderOptions
	);

	const standardQuantityBoxMethods = useStandardQuantityBox(
		cheapestAvailableBundle,
		true
	);

	return (
		<>
			<div ref={shopControlsRef}>
				<div className={styles.mainShopControls}>
					<BundleItem
						bundle={cheapestAvailableBundle}
						variant={BundleItemVariants.STANDARD}
						isLoading={isQuantityLoading || isIdenticalBundlesDataLoading}
						quantityBox={QuantityBoxVariants.STANDARD}
						standardQuantityBoxMethods={standardQuantityBoxMethods}
					/>
				</div>

				{volumeDiscount !== null && (
					<VolumeDiscount
						bundleId={cheapestAvailableBundle.id}
						isForLogged={isForLogged}
						volumeDiscount={volumeDiscount}
						warehouseCount={
							warehouseContext.getQuantity(cheapestAvailableBundle.id) ?? 0
						}
						bundleLimitPerOrder={bundleLimitPerOrder}
					/>
				)}
			</div>
			<FloatingShopControls
				isVisible={isHeaderHidden}
				bundle={cheapestAvailableBundle}
				bundleMeta={bundleMeta}
				availableQuantity={availableQuantity}
				isLoading={isQuantityLoading || isIdenticalBundlesDataLoading}
				standardQuantityBoxMethods={standardQuantityBoxMethods}
			/>
		</>
	);
};

export default DesktopShopControlsSection;
