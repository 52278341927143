function isPrerender() {
	const userAgent = navigator.userAgent;

	if (userAgent.match(/Prerender/i)) {
		return true;
	}

	return false;
}

export { isPrerender };
