export const evaluationCriteria = [
	{
		minLabel: 'productDetail.slider.sweet',
		maxLabel: 'productDetail.slider.dry',
		prop: 'averageSweetDry',
	},
	{
		minLabel: 'productDetail.slider.light',
		maxLabel: 'productDetail.slider.bold',
		prop: 'averageLightHeavy',
	},
	{
		minLabel: 'productDetail.slider.fruity',
		maxLabel: 'productDetail.slider.savory',
		prop: 'averageFruitTannic',
	},
	{
		minLabel: 'productDetail.slider.lowAcid',
		maxLabel: 'productDetail.slider.highAcid',
		prop: 'averageLowHighAccidity',
	},
];
