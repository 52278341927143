import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import pluralGetSet from 'dayjs/plugin/pluralGetSet';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import ObjectSupport from 'dayjs/plugin/objectSupport';

const dayjsInstance = dayjs;

[utc, pluralGetSet, localizedFormat, isBetween, ObjectSupport].forEach(
	(plugin) => {
		dayjsInstance.extend(plugin);
	}
);

export default dayjsInstance;
