//import { get, split, head } from 'lodash-es';

import { DEFAULT_LANGUAGE } from './constants';

export const getBrowserLanguage = (): string => {
	// const browserLanguage = head(
	//   split(get(navigator, "language", "en_EN"), /[-_]/)
	// );

	// return browserLanguage ? browserLanguage : "cs";
	return DEFAULT_LANGUAGE;
};
