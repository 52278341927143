import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthenticationContext } from 'Services/AuthenticationService/context';

const ConfirmEmail: React.FC = (): JSX.Element => {
	const authenticationContext = React.useContext(AuthenticationContext);
	const navigate = useNavigate();

	const { hash } = useParams();

	React.useEffect(() => {
		authenticationContext.handleOnConfirmEmail(hash as string);
		navigate('/');
	});

	return <></>;
};

export default ConfirmEmail;
