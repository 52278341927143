import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { NotificationsContext } from 'Services/NotificationService';
import { LinkWidgetsService } from 'vinisto_api_client';

import { MenuLink } from '@/api-types/linkwidgets-api';

const combineUrls = (
	menuLinks: MenuLink[],
	parentUrl: string = ''
): MenuLink[] => {
	return menuLinks.map((link) => {
		if (link.type === 3 && !link.url?.startsWith(parentUrl)) {
			link.url = `${parentUrl}${link.url}`;
		}
		if (link.childLinks && link.childLinks.length > 0) {
			link.childLinks = combineUrls(link.childLinks, link.url ?? '');
		}
		return link;
	});
};

const useStaticCategories = () => {
	const { handleShowErrorNotification } = useContext(NotificationsContext);

	const { data, isLoading } = useQuery({
		queryKey: ['menu'],
		queryFn: () => LinkWidgetsService.getMenu(),
		onError: () => {
			handleShowErrorNotification('megamenu.loadError');
		},
	});

	if (isLoading) {
		return { desktopMenu: [], mobileMenu: [] };
	}

	const desktopMenuRaw =
		data?.desktopMenu !== null && data?.desktopMenu != undefined
			? data.desktopMenu
			: [];

	const mobileMenuRaw =
		data?.mobileMenu !== null && data?.mobileMenu != undefined
			? data.mobileMenu
			: [];

	const desktopMenu = combineUrls(desktopMenuRaw);
	const mobileMenu = combineUrls(mobileMenuRaw);

	return { desktopMenu, mobileMenu };
};

export default useStaticCategories;
