import * as React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { LocalizationContext } from 'Services/LocalizationService';

import './styles.css';

const DataProtection: React.FC = (): JSX.Element => {
	const localizationContext = React.useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const documentHeaderContext = React.useContext(DocumentHeaderContext);

	React.useEffect(() => {
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.privacyProtection.name' })}` }
			)}`,
		});
	}, []);

	return (
		<section id="content-wrapper">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="vinisto-card">
							<div className="vinisto-terms__text vinisto-font-18">
								<h2 className="text-center">
									Privacy policy: podmínky ochrany osobních údajů
								</h2>
								<p className="text-center fw-bolder">Základní informace</p>
								<p className="fw-bolder">Správce osobních údajů</p>
								<p>
									Jsme společnost <strong>vinisto s. r. o.</strong>, se sídlem
									Jankovcova 1057/6, Praha 7 – Holešovice, IČO 08746893, zapsaná
									v obchodním rejstříku vedeném Městským soudem v Praze, oddíl
									C, vložka 324492, a jako správce zpracováváme vaše osobní
									údaje.
								</p>
								<p className="text-center fw-bolder">
									Jaké osobní údaje zpracováváme
								</p>
								<p>
									Pokud si u nás objednáte zboží, potřebujeme od vás údaje,
									které jsou v objednávkovém procesu označené jako povinné
									(jedná se především o vaše jméno a příjmení, doručovací
									adresu). Pokud bychom tyto údaje neměli, nemohli bychom vám
									zboží správně doručit. Dále pro účely prodeje zboží či služeb
									potřebujeme vaši e-mailovou adresu, na kterou vám zašleme
									potvrzení objednávky, které zároveň slouží jako potvrzení
									uzavřené kupní smlouvy, a kopii obchodních podmínek a
									reklamačního řádu.
								</p>
								<p>
									Při objednání zboží či služeb můžete vyplnit i nepovinné
									údaje. Ty nám pomáhají k lepšímu a efektivnějšímu splnění
									uzavřené smlouvy. Nepovinné údaje nám poskytujete dobrovolně.
								</p>
								<p>
									Poté, co vám bude zboží doručeno, vám může být doručena
									e-mailová zpráva s žádostí o ohodnocení zakoupeného zboží.
								</p>
								<p>
									Pokud se obrátíte na naši zákaznickou linku či nám napíšete
									zprávu, budeme vaše osobní údaje uvedené v této komunikaci
									také zpracovávat.
								</p>
								<p>
									Vyplněním nepovinných údajů ve svém uživatelském profilu nám
									sdělíte i vaše jiné osobní údaje.
								</p>
								<p>
									Při platbě platební kartou z mobilního telefonu budete
									přesměrováni na zabezpečený server platební brány. Údaje o
									vaší platební kartě tedy nejsou zasílány naší společnosti, ale
									jsou v rámci zabezpečeného přenosu odesílány přímo
									poskytovateli naší platební brány. Platební brána dále pro
									uskutečnění platby přenáší data příslušné bankovní instituci,
									a to opět v rámci zabezpečeného přenosu dat.
								</p>
								<p className="fw-bolder">
									Osobní údaje, které získáváme jinak než přímo od vás
								</p>
								<p>
									V rámci společných marketingových kampaních můžeme vaše osobní
									údaje zpracovávat i my. Pro účely marketingových kampaní,
									případně pro účely řádného splnění smlouvy je můžeme
									kombinovat.
								</p>
								<p>
									V případě, že vaše osobní údaje budou předány jinému subjektu,
									budeme vás o tom předem informovat včetně uvedení toho, komu
									osobní údaje předáváme. Pakliže někdo předá vaše osobní údaje
									nám, musí vás o tom rovněž předem informovat.
								</p>
								<p className="fw-bolder">
									Osobní údaje třetích osob, které nám poskytnete
								</p>
								<p>
									Poskytujete-li nám osobní údaje třetích osob, je vaší
									povinností informovat o tom dotyčnou osobu a zajistit si její
									souhlas s těmito podmínkami ochrany osobních údajů.
								</p>
								<p className="fw-bolder">
									Osobní údaje, které zpracováváme automaticky
								</p>
								<p>
									Při návštěvě naší webové stránky o vás můžeme shromažďovat
									určité informace, jako jsou např. IP adresa, datum a čas
									přístupu na naší webovou stránku, informace o vašem
									internetovém prohlížeči, operačním systému či nastavení vašeho
									jazyka. Můžeme také zpracovávat informace o vašem chování na
									našich webových stránkách, tj. např. jaké odkazy na našich
									webových stránkách navštívíte a které zboží je vám zobrazeno.
								</p>
								<p>
									Přistupujete-li na naše webové stránky z mobilního telefonu či
									obdobného přístroje nebo prostřednictvím některé z našich
									mobilních aplikací, můžeme zpracovávat i informace o vašem
									mobilním zařízení (data o vašem mobilním telefonu, případné
									záznamy o selhání aplikace apod.)
								</p>
								<p className="fw-bolder">Cookies</p>
								<p>
									Automaticky zpracováváme i cookies. Personalizaci a cílenou
									reklamu si můžete kdykoliv vypnout v Nastavení cookies.
								</p>
								<Accordion className="mb-3">
									<Accordion.Item eventKey="0">
										<Accordion.Header>Více o Cookies</Accordion.Header>
										<Accordion.Body>
											<p className="fw-bolder">Co je soubor cookie?</p>
											<p>
												Jedná se vlastně o malý textový soubor, který vznikne
												navštívením každé webové stránky. Využívá se jako
												standardní nástroj pro ukládání informací o tom, jak
												jsou naše stránky využívány.
											</p>
											<p>
												Dokážeme tak odlišit (nikoliv však identifikovat)
												jednotlivé uživatele od sebe a přizpůsobit obsah
												konkrétním preferencím. Soubory cookie jsou důležité.
												Bez nich by procházení webu bylo mnohem složitější.
											</p>
											<p className="fw-bolder">K čemu se cookies používají?</p>
											<p>
												Cookies slouží k několika účelům. Na stránkách našeho
												internetového obchodu a v našich mobilních aplikacích
												používáme tyto cookies:
											</p>
											<p>
												Technické cookies: ty jsou nezbytně nutné pro fungování
												webu a jeho funkcí, které se rozhodnete využívat. Bez
												nich by náš web nefungoval, např. by nebylo možné se
												přihlásit k uživatelskému účtu.
											</p>
											<p>
												Funkční cookies: tyto cookies nám umožňují zapamatovat
												si vaše základní volby a vylepšují uživatelský komfort.
												Jde například o zapamatování si jazyka či umožnění
												zůstat trvale přihlášen.
											</p>
											<p>
												Cookies sociálních sítí: tyto cookies nám umožňují
												komfortně vás propojit s vaším profilem na sociálních
												sítích a například vám umožnit sdílet produkty a služby
												s přáteli a rodinou.
											</p>
											<p>
												Personalizace obsahu: tyto cookies nám umožní zobrazit
												vám obsah a reklamy dle údajů, které jsou o vás dostupné
												tak, abychom se co nejlépe strefili do Vašich potřeb.
												Jde zejména o to, jaký obsah jste si zobrazili, či na
												jakém zařízení na naše stránky přicházíte.
											</p>
											<p>
												Nepersonalizovaná reklama: tyto cookies nám umožňují
												zobrazovat vám obecnou reklamu na produkty a služby.
											</p>
											<p>
												Personalizovaná reklama: díky těmto cookies Vám my a
												naši partneři můžeme nabídnout relevantní produkty a
												služby na míru a to na základě Vašich nákupů, vašeho
												chování a vašich preferencí.
											</p>
											<p>
												Měření publika: tyto cookies nám umožňují optimalizovat
												naše stránky pro vaše komfortní používání a to na
												základě toho, jak je používáte. Cílem je zapamatovat si
												či předpokládat vaše volby. Jde například o využívání
												funkcí, jejich umístění, chování na stránce.
											</p>
											<p>
												Cookies třetích stran: tyto cookies jsou cookies třetích
												stran, konkrétně:
											</p>
											<table className="table table-striped">
												<tbody>
													<tr>
														<td>
															<p>
																<strong>Partner</strong>
																<br />{' '}
															</p>
														</td>
														<td>
															<p>
																<strong>&Uacute;čel</strong>
																<br />{' '}
															</p>
														</td>
														<td>
															<p>
																<strong>
																	Ochrana osobn&iacute;ch &uacute;dajů
																</strong>
																<br />{' '}
															</p>
														</td>
													</tr>
													<tr>
														<td>
															<p>Facebook</p>
														</td>
														<td>
															<p>
																Facebook je soci&aacute;ln&iacute; s&iacute;ť
																souž&iacute;c&iacute; ke komunikaci mezi
																uživateli, udržov&aacute;n&iacute; vztahů a
																z&aacute;bavě. Umožňuje sd&iacute;let produkty a
																dal&scaron;&iacute; str&aacute;nky.
																Přihl&aacute;&scaron;en&iacute; skrze tuto
																s&iacute;ť umožňujeme
																přihl&aacute;&scaron;en&iacute; k &uacute;čtu.
															</p>
														</td>
														<td>
															<p>
																<a href="http://www.facebook.com/about/privacy/">
																	http://www.facebook.com/about/privacy/
																</a>
															</p>
														</td>
													</tr>
													<tr>
														<td>
															<p>Facebook Ads</p>
														</td>
														<td>
															<p>
																Platforma pro reklamu na Facebooku, retargeting,
																měřen&iacute; konverz&iacute;.
															</p>
														</td>
														<td>
															<p>
																<br />{' '}
																<a href="http://www.facebook.com/about/privacy/">
																	http://www.facebook.com/about/privacy/
																</a>
															</p>
														</td>
													</tr>
													<tr>
														<td>
															<p>Facebook Login</p>
														</td>
														<td>
															<p>
																Platforma pro přihl&aacute;&scaron;en&iacute;
																skrze platformu facebooku.
															</p>
														</td>
														<td>
															<p>
																<a href="http://www.facebook.com/about/privacy/">
																	http://www.facebook.com/about/privacy/
																</a>
															</p>
														</td>
													</tr>
													<tr>
														<td>
															<p>Facebook Social Sharing</p>
														</td>
														<td>
															<p>
																Platforma pro možnost sd&iacute;len&iacute;
																produktu a dal&scaron;&iacute;ho obsahu na
																platformu facebook.
															</p>
														</td>
														<td>
															<p>
																<a href="http://www.facebook.com/about/privacy/">
																	http://www.facebook.com/about/privacy/
																</a>
															</p>
														</td>
													</tr>
													<tr>
														<td>
															<p>Google Ads</p>
														</td>
														<td>
															<p>
																Platforma pro reklamu ve
																vyhled&aacute;v&aacute;n&iacute;, bannerovou
																reklamu, YouTube reklamu, retargeting,
																měřen&iacute; konverz&iacute;.
															</p>
														</td>
														<td>
															<p>
																<a href="https://policies.google.com/privacy">
																	https://policies.google.com/privacy
																</a>
															</p>
														</td>
													</tr>
													<tr>
														<td>
															<p>Google Analytics</p>
														</td>
														<td>
															<p>
																Analytick&yacute; a marketingov&yacute;
																n&aacute;stroj pro
																zji&scaron;ťov&aacute;n&iacute;
																chov&aacute;n&iacute; uživatele na
																str&aacute;nce, za &uacute;čelem n&aacute;vrhu
																lep&scaron;&iacute;cho rozvržen&iacute;,
																funkcionalit a dal&scaron;&iacute;ho
																vylep&scaron;ov&aacute;n&iacute; str&aacute;nek,
																profilov&aacute;n&iacute; uživatele a
																c&iacute;len&iacute; personalizovan&eacute;
																reklamy.
															</p>
														</td>
														<td>
															<p>
																<a href="https://policies.google.com/privacy">
																	https://policies.google.com/privacy
																</a>
															</p>
														</td>
													</tr>
													<tr>
														<td>
															<p>Google Display &amp; Video 360</p>
														</td>
														<td>
															<p>
																Platforma pro bannerovou reklamu, retargeting,
																měřen&iacute; konverz&iacute;.
															</p>
														</td>
														<td>
															<p>
																<a href="https://policies.google.com/privacy">
																	https://policies.google.com/privacy
																</a>
															</p>
														</td>
													</tr>
													<tr>
														<td>
															<p>Google Optimize</p>
														</td>
														<td>
															<p>
																N&aacute;stroj pro AB testov&aacute;n&iacute;
																designu webu.
															</p>
														</td>
														<td>
															<p>
																<a href="https://policies.google.com/privacy">
																	https://policies.google.com/privacy
																</a>
															</p>
														</td>
													</tr>
													<tr>
														<td>
															<p>Google Pay</p>
														</td>
														<td>
															<p>
																Platforma placen&iacute; skrze službu Google Pay
															</p>
														</td>
														<td>
															<p>
																<a href="https://policies.google.com/privacy">
																	https://policies.google.com/privacy
																</a>
															</p>
														</td>
													</tr>
													<tr>
														<td>
															<p>Seznam.cz Sklik</p>
														</td>
														<td>
															<p>
																Platforma pro reklamu ve
																vyhled&aacute;v&aacute;n&iacute;, bannerovou
																reklamu, retargeting, měřen&iacute;
																konverz&iacute;
															</p>
														</td>
														<td>
															<p>
																<a href="https://o.seznam.cz/ochrana-udaju/">
																	https://o.seznam.cz/ochrana-udaju/
																</a>
															</p>
														</td>
													</tr>
													<tr>
														<td>
															<p>Smartlook</p>
														</td>
														<td>
															<p>
																Analytick&yacute; n&aacute;stroj pro
																zji&scaron;ťov&aacute;n&iacute;
																chov&aacute;n&iacute; uživatele na
																str&aacute;nce, za &uacute;čelem n&aacute;vrhu
																lep&scaron;&iacute;cho rozvržen&iacute;,
																funkcionalit a dal&scaron;&iacute;ho
																vylep&scaron;ov&aacute;n&iacute; str&aacute;nek.
															</p>
														</td>
														<td>
															<p>
																<a href="https://help.smartlook.com/en/articles/3244452-privacy-policy">
																	https://help.smartlook.com/en/articles/3244452-privacy-policy
																</a>
															</p>
														</td>
													</tr>
													<tr>
														<td>
															<p>Twitter</p>
														</td>
														<td>
															<p>
																Twitter (lze přeložit jako
																&bdquo;cvrlik&aacute;n&iacute;&ldquo;,
																&bdquo;&scaron;vitořen&iacute;&ldquo;,
																&bdquo;&scaron;těbet&aacute;n&iacute;&ldquo;) je
																poskytovatel soci&aacute;ln&iacute; s&iacute;tě
																a mikroblogu, kter&yacute; umožňuje uživatelům
																pos&iacute;lat a č&iacute;st př&iacute;spěvky
																zaslan&eacute; jin&yacute;mi uživateli,
																zn&aacute;m&eacute; jako tweety.
															</p>
														</td>
														<td>
															<p>
																<a href="https://twitter.com/en/privacy">
																	https://twitter.com/en/privacy
																</a>
															</p>
														</td>
													</tr>
													<tr>
														<td>
															<p>Twitter Ads</p>
														</td>
														<td>
															<p>
																Platforma pro reklamu na Twitteru, retargeting,
																měřen&iacute; konverz&iacute;.
															</p>
														</td>
														<td>
															<p>
																<a href="https://twitter.com/en/privacy">
																	https://twitter.com/en/privacy
																</a>
															</p>
														</td>
													</tr>
													<tr>
														<td>
															<p>Twitter Social Sharing</p>
														</td>
														<td>
															<p>
																Platforma pro sd&iacute;len&iacute; produktu a
																dal&scaron;&iacute;ho obsahu na platformu
																Twitter
															</p>
														</td>
														<td>
															<p>
																<a href="https://twitter.com/en/privacy">
																	https://twitter.com/en/privacy
																</a>
															</p>
														</td>
													</tr>
												</tbody>
											</table>
											<p className="fw-bolder">
												Jak můžete ovlivnit, které z vašich cookies budeme
												zpracovávat
											</p>
											<p>
												Stačí používat některý z běžných internetových
												prohlížečů (např. Internet Explorer, Safari, Firefox,
												Chrome) se zapnutou funkcí anonymního prohlížení, která
												zabrání ukládání údajů o navštívených webech, popř. si v
												prohlížeči můžete ukládání cookies zcela zakázat. Pokud
												však zakážete i zpracovávání technických a funkčních
												cookies, znemožníte tím fungování některým funkcím,
												které vám pomáhají.
											</p>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
								<p className="fw-bolder">
									Kamerové systémy na našich provozovnách
								</p>
								<p>
									Automaticky zpracováváme i záznamy z kamer na našich
									provozovnách. Zaznamenáván je pouze obraz. Kamery na
									provozovnách máme jak z důvodu prevence, tak z důvodu
									objasňování případné protiprávní činnosti. Provozovatelem
									kamerového systému jsme my, společnost vinisto s. r. o.
								</p>
								<p className="fw-bolder">
									Verze pro mobilní telefony a mobilní aplikace
								</p>
								<p>
									Vstupujete-li na naše webové stránky z telefonu, tabletu či
									podobného zařízení, tak je pro tyto přístroje optimalizujeme.
									Vaše osobní údaje v takovém případě zpracováváme obdobným
									způsobem, jako v případě přístupu z počítače.
								</p>
								<p className="fw-bolder">
									Proč shromažďujeme a zpracováváme vaše osobní údaje
								</p>
								<p>Vaše osobní údaje zpracováváme z těchto důvodů:</p>
								<ul>
									<li>
										N&aacute;kup zbož&iacute; a služeb: v prv&eacute; řadě
										zpracov&aacute;v&aacute;me va&scaron;e osobn&iacute;
										&uacute;daje k tomu, abychom ř&aacute;dně vybavili a
										doručili va&scaron;i objedn&aacute;vku. Pokud nastanou
										jak&eacute;koliv probl&eacute;my, d&iacute;ky va&scaron;im
										osobn&iacute;m &uacute;dajům v&iacute;me, na koho se můžeme
										obr&aacute;tit.
									</li>
									<li>
										P&eacute;če o z&aacute;kazn&iacute;ky: pokud se na
										n&aacute;s obr&aacute;t&iacute;te s nějak&yacute;m
										dotazem/probl&eacute;mem, mus&iacute;me k jeho
										zodpovězen&iacute;/vyře&scaron;en&iacute; zpracov&aacute;vat
										va&scaron;e &uacute;daje. V někter&yacute;ch
										př&iacute;padech mohou b&yacute;t osobn&iacute; &uacute;daje
										před&aacute;ny i třet&iacute;m subjektům (např. dopravci
										zbož&iacute; nebo servisn&iacute;mu středisku).
									</li>
									<li>
										Uživatelsk&yacute; &uacute;čet: d&iacute;ky osobn&iacute;m
										&uacute;dajům, kter&eacute; n&aacute;m poskytnete ve
										sv&eacute;m uživatelsk&eacute;m profilu, se v&aacute;m
										zpř&iacute;stupn&iacute; řada užitečn&yacute;ch
										funkc&iacute; (např. pokud uvedete sv&eacute; tel.
										č&iacute;slo, můžeme v&aacute;s snadno informovat o tom, kdy
										v&aacute;m bude objedn&aacute;vka doručena). V&aacute;mi
										zadan&eacute; &uacute;daje přitom můžete kdykoliv změnit, a
										to s v&yacute;jimkou e-mailov&eacute; adresy, kter&aacute;
										slouž&iacute; pro př&iacute;stup k va&scaron;emu
										uživatelsk&eacute;mu &uacute;čtu.
									</li>
									<li>Marketingov&aacute; činnost:</li>
									<ul>
										<li>
											<strong>E-mail marketing:</strong>
											&nbsp;e-mailov&aacute; obchodn&iacute; sdělen&iacute;
											v&aacute;m zas&iacute;l&aacute;me na z&aacute;kladě
											va&scaron;eho souhlasu. Z odběru obchodn&iacute;ch
											sdělen&iacute; se můžete snadno odhl&aacute;sit
											t&iacute;m, že si uprav&iacute;te&nbsp;
											<a href="https://www.alza.cz/muj-ucet/profil/nastavit-odber-novinek">
												nastaven&iacute; sv&eacute;ho uživatelsk&eacute;ho
												profilu
											</a>
											.&nbsp; Pokud si vytvoř&iacute;te v&iacute;ce
											uživatelsk&yacute;ch profilů, v r&aacute;mci
											kter&yacute;ch uvedete stejn&eacute; kontaktn&iacute;
											&uacute;daje (např. m&aacute;te několik &uacute;čtu pro
											různ&eacute; e-mailov&eacute; adresy, ale telefonn&iacute;
											č&iacute;slo bude na v&scaron;ech &uacute;čtech
											stejn&eacute;), nelze z technick&yacute;ch důvodů
											prov&eacute;st odhl&aacute;&scaron;en&iacute; z odběru
											obchodn&iacute;ch sdělen&iacute; pro v&scaron;echny
											uživatelsk&eacute; profily na z&aacute;kladě
											automatizovan&yacute;ch procesů. V takov&eacute;m
											př&iacute;padě je k odhl&aacute;&scaron;en&iacute; se z
											odběru obchodn&iacute;ch sdělen&iacute; pro v&scaron;echny
											uživatelsk&eacute; profily třeba, abyste n&aacute;s
											kontaktovali telefonicky nebo prostřednictv&iacute;m
											kontaktn&iacute;ho formul&aacute;ře.
										</li>
										<li>
											<strong>Telemarketing:&nbsp;</strong>
											marketingov&eacute; hovory prov&aacute;d&iacute;me za
											&uacute;čelem nab&iacute;dky na&scaron;eho zbož&iacute; a
											služeb, a s t&iacute;m souvisej&iacute;c&iacute;
											marketingov&eacute; komunikace. Pr&aacute;vn&iacute;m
											titulem pro zpracov&aacute;n&iacute; va&scaron;eho tel.
											č&iacute;sla je buď v&aacute;&scaron; souhlas, nebo
											alespoň n&aacute;&scaron; opr&aacute;vněn&yacute;
											z&aacute;jem na konvenčn&iacute;m př&iacute;m&eacute;m
											marketingu. Proti tomuto zpracov&aacute;n&iacute; můžete
											vzn&eacute;st n&aacute;mitku emailem.
										</li>
										<li>
											Zavolat v&aacute;m může jak pracovn&iacute;k na&scaron;eho
											call centra, tak n&aacute;&scaron; robot.
										</li>
										<li>
											<strong>Marketingov&eacute; soutěže:</strong>
											&nbsp;v někter&yacute;ch př&iacute;padech může b&yacute;t
											v&yacute;herce soutěže vyfocen, popř. natočen, a to
											zejm&eacute;na z důvodu zv&yacute;&scaron;en&iacute;
											transparentnosti na&scaron;ich marketingov&yacute;ch
											soutěž&iacute;. Toto zpracov&aacute;n&iacute;
											osobn&iacute;ch &uacute;dajů prov&aacute;d&iacute;me na
											z&aacute;kladě na&scaron;eho opr&aacute;vněn&eacute;ho
											z&aacute;jmu, kter&yacute; spoč&iacute;v&aacute; ve
											zv&yacute;&scaron;en&iacute; věrohodnosti
											marketingov&yacute;ch soutěž&iacute; v oč&iacute;ch
											ostatn&iacute;ch soutěž&iacute;c&iacute;ch a ve
											zv&yacute;&scaron;en&iacute; atraktivity těchto
											soutěž&iacute;. Proti tomuto zpracov&aacute;n&iacute;
											můžete vzn&eacute;st n&aacute;mitku emailem.
										</li>
										<li>
											<strong>Retargeting:&nbsp;</strong>v někter&yacute;ch
											př&iacute;padech už&iacute;v&aacute;me techniky
											retargetingu, přičemž m&aacute;te pr&aacute;vo retargeting
											kliknut&iacute;m na tento odkaz a v&yacute;běrem
											př&iacute;slu&scaron;n&eacute; možnosti odm&iacute;tnout.
										</li>
									</ul>
									<li>
										Zlep&scaron;ov&aacute;n&iacute; na&scaron;ich služeb:
										pomoc&iacute; historie va&scaron;ich objedn&aacute;vek a
										chov&aacute;n&iacute; na webu dok&aacute;žeme
										nab&iacute;dnout relevantněj&scaron;&iacute; nab&iacute;dky
										dal&scaron;&iacute;ho zbož&iacute;. Na určit&yacute;ch
										m&iacute;stech tedy zobrazujeme takov&eacute; produkty,
										kter&eacute; jsou př&iacute;mo pro v&aacute;s a
										odpov&iacute;daj&iacute; va&scaron;im potřeb&aacute;m a
										z&aacute;jmům. K optimalizaci prvků na webu a mimo web
										(personalizovan&aacute; reklama na webu třet&iacute;ch
										stran) můžeme využ&iacute;vat tak&eacute; n&aacute;stroje
										pro testov&aacute;n&iacute; různ&yacute;ch variant (tzv. A/B
										testov&aacute;n&iacute;), Google Analytics, Facebook
										Analytics apod.
									</li>
									<li>
										Z&aacute;kaznick&aacute; hodnocen&iacute; zbož&iacute; a
										služeb: pot&eacute;, co u n&aacute;s nakoup&iacute;te
										zbož&iacute; či služby, můžete b&yacute;t
										pož&aacute;d&aacute;ni o jejich ohodnocen&iacute;.
										Hodnocen&iacute; lze vložit i z vlastn&iacute; iniciativy.
									</li>
									<li>
										Call centrum: budete-li n&aacute;s kontaktovat
										prostřednictv&iacute;m na&scaron;eho call centra
										(infolinka), budeme si v&aacute;&scaron; telefonn&iacute;
										hovor po předchoz&iacute;m upozorněn&iacute;
										nahr&aacute;vat. Takov&eacute; z&aacute;znamy n&aacute;m
										slouž&iacute; ke kontrole kvality služeb
										poskytovan&yacute;ch na&scaron;imi zaměstnanci.
									</li>
									<li>
										Uplatněn&iacute; pr&aacute;v a pr&aacute;vn&iacute;ch
										n&aacute;roků a kontroly org&aacute;nů veřejn&eacute; moci:
										va&scaron;e osobn&iacute; &uacute;daje můžeme rovněž
										zpracov&aacute;vat z důvodu, že je potřebujeme k
										uplatněn&iacute; na&scaron;ich pr&aacute;v a
										pr&aacute;vn&iacute;ch n&aacute;roků (např. v
										př&iacute;padě, že vůči n&aacute;m budete m&iacute;t
										neuhrazenou pohled&aacute;vku nebo v př&iacute;padě
										prok&aacute;z&aacute;n&iacute; doručen&iacute;
										objednan&eacute;ho zbož&iacute;). D&aacute;le můžeme
										va&scaron;e osobn&iacute; &uacute;daje zpracov&aacute;vat i
										z důvodu, že je potřebujeme pro &uacute;čely kontrol
										prov&aacute;děn&yacute;ch org&aacute;ny veřejn&eacute; moci
										a z dal&scaron;&iacute;ch obdobně z&aacute;važn&yacute;ch
										důvodů.
									</li>
								</ul>
								<p className="text-center fw-bolder">
									Osobní údaje zpracováváme na těchto právních podkladech
								</p>
								<p className="fw-bolder">Plnění a uzavření smlouvy</p>
								<p>
									Velkou část vašich osobních údajů potřebujeme k tomu, abychom
									s vámi vůbec mohli uzavřít kupní či jinou smlouvu ohledně
									zboží či služeb, které si u nás chcete zakoupit. Jakmile je
									smlouva uzavřena, tak vaše osobní údaje zpracováváme k tomu,
									abychom vám řádně doručili zakoupené zboží, popř. abychom vám
									řádně poskytly zakoupené služby. Na základě tohoto právního
									důvodu tedy zpracováváme především údaje fakturační a
									doručovací.
								</p>
								<p className="fw-bolder">Oprávněné zájmy</p>
								<p>
									Vaše osobní údaje používáme i k tomu, abychom vám poskytli
									relevantní obsah, tedy obsah, který je pro vás zajímavý. Na
									základě oprávněného zájmu takto zpracováváme zejména osobní
									údaje, které zpracováváme automaticky a cookies.
								</p>
								<p>
									Ze stejného právního důvodu vám jako našim zákazníkům můžeme
									zasílat e-mailové a SMS zprávy. Zpracování vašich osobních
									údajů na tomto právním podkladě se děje i při monitoringu
									prováděném prostřednictvím kamer na našich jednotlivých
									provozovnách.
								</p>
								<p>
									Pokud jste uveden jako tzv. pověřená osoba našeho firemního
									zákazníka, budou vaše objednávky a další jednání připisovány
									tomuto firemnímu zákazníkovi. V případě, že si to nepřejete,
									vyplňte prosím při objednávce jinou e-mailovou adresu než tu,
									která je uvedena u pověřené osoby tohoto firemního zákazníka,
									popř. nás můžete požádat o výmaz z pověřených osob tohoto
									firemního zákazníka.
								</p>
								<p>
									Pakliže vaše osobní údaje zpracováváme na základě tohoto
									právního důvodu, můžete proti tomuto zpracování vznést
									námitku.
								</p>
								<p className="fw-bolder">Souhlas</p>
								<p>
									Pro účely zasílání obchodních sdělení (e-mail marketing) a
									telemarketing zpracováváme vaše osobní údaje na základě vašeho
									souhlasu. Pokud nám souhlas neudělíte a jste naším zákazníkem,
									tak vám můžeme obchodní sdělení zaslat (popř. vám v rámci
									telemarketingu zavolat) i bez toho, abyste nám udělili váš
									souhlas. V každém případě však platí, že můžete takovouto
									marketingovou komunikaci z naší strany jednoduše zakázat tím,
									že si upravíte nastavení svého uživatelského profilu.
								</p>
								<p>
									Pokud nám udělíte svůj souhlas se zpracováním osobních údajů,
									můžete jej kdykoliv odvolat.
								</p>
								<p className="fw-bolder text-center">
									Předávání osobních údajů třetím subjektům
								</p>
								<p>
									V těchto případech předáváme vaše osobní údaje třetím
									subjektům (tedy ve vztahu k těmto os. údajům vystupujeme v
									pozici jejich správce):
								</p>
								<ul>
									<li>
										<strong>Doručen&iacute; zbož&iacute;:</strong>
										&nbsp;v&aacute;mi zvolen&yacute; dopravce by v&aacute;m
										nikdy nebyl schopen doručit objednan&eacute; zbož&iacute;,
										pokud bychom mu mj. nepředali &uacute;daje, kam a komu
										konkr&eacute;tně m&aacute; zbož&iacute; doručit. Tyto
										&uacute;daje dopravci před&aacute;me podle toho, jak je
										vypln&iacute;te v objedn&aacute;vce. Takto předan&eacute;
										&uacute;daje zahrnuj&iacute; předev&scaron;&iacute;m
										va&scaron;e jm&eacute;no a př&iacute;jmen&iacute;,
										doručovac&iacute; adresu, tel. č&iacute;slo, na
										kter&eacute;m v&aacute;s může dopravce kontaktovat, a pokud
										nebylo zbož&iacute; uhrazeno předem, tak i př&iacute;padně
										č&aacute;stku, kterou je třeba při převzet&iacute;
										zbož&iacute; uhradit. Dopravce je ve vztahu k osobn&iacute;m
										&uacute;dajům, kter&eacute; mu před&aacute;me,
										opr&aacute;vněn zpracov&aacute;vat je pouze pro &uacute;čely
										doručen&iacute; zbož&iacute; a pot&eacute; osobn&iacute;
										&uacute;daje bezodkladně smazat.
									</li>
									<li>
										<strong>
											Doručen&iacute; zbož&iacute;, kter&eacute; pro n&aacute;s
											uskladňuje smluvn&iacute; partner:
										</strong>
										&nbsp;pokud si od n&aacute;s objedn&aacute;v&aacute;te
										zbož&iacute;, kter&eacute; se nach&aacute;z&iacute; ve
										skladě na&scaron;eho smluvn&iacute;ho partnera,
										mus&iacute;me k dokončen&iacute; objedn&aacute;vky předat
										va&scaron;e osobn&iacute; &uacute;daje tomuto
										smluvn&iacute;mu partnerovi, kter&yacute; objedn&aacute;vku
										vybav&iacute;. Takto předan&eacute; &uacute;daje
										zahrnuj&iacute; předev&scaron;&iacute;m va&scaron;e
										jm&eacute;no a př&iacute;jmen&iacute;, doručovac&iacute;
										adresu, tel. č&iacute;slo, na kter&eacute;m v&aacute;s může
										dopravce kontaktovat a pokud nebylo zbož&iacute; uhrazeno
										předem, tak i př&iacute;padně č&aacute;stku, kterou je třeba
										při převzet&iacute; zbož&iacute; uhradit. Tento
										smluvn&iacute; partner pot&eacute; uveden&eacute;
										osobn&iacute; &uacute;daje mus&iacute; předat dopravci,
										kter&yacute; bude zbož&iacute; doručovat, protože jinak by
										v&aacute;m dopravce nebyl schopen zbož&iacute; doručit. Jak
										smluvn&iacute; partner, kter&yacute; pro n&aacute;s skladuje
										zbož&iacute;, tak i dopravce, jsou ve vztahu k
										osobn&iacute;m &uacute;dajům, kter&eacute; jim
										před&aacute;v&aacute;me, povinni tyto osobn&iacute;
										&uacute;daje použ&iacute;t pouze pro &uacute;čely
										skladov&aacute;n&iacute;/doručen&iacute; zbož&iacute; a
										pot&eacute; je bezodkladně smazat.
									</li>
									<li>
										<strong>Platebn&iacute; karty:</strong>
										&nbsp;na&scaron;e společnost&nbsp;
										<strong>nem&aacute;&nbsp;</strong>
										&uacute;daje o platebn&iacute;ch kart&aacute;ch,
										kter&yacute;mi u n&aacute;s plat&iacute;te. &Uacute;daji o
										va&scaron;ich platebn&iacute;ch kart&aacute;ch disponuje
										pouze zabezpečen&aacute; platebn&iacute; br&aacute;na&nbsp;a
										př&iacute;slu&scaron;n&aacute; bankovn&iacute; instituce.
									</li>
								</ul>
								<ul>
									<li>
										<strong>Obchodn&iacute; sdělen&iacute;:</strong>
										&nbsp;v př&iacute;padě rozes&iacute;l&aacute;n&iacute;
										obchodn&iacute;ch sdělen&iacute; (např. e-mailem či
										prostřednictv&iacute;m SMS zpr&aacute;vy) nebo
										telemarketingu můžeme k rozes&iacute;lce či
										telefonn&iacute;m hovorům využ&iacute;t třet&iacute;
										subjekt. Tento subjekt je v&aacute;z&aacute;n
										povinnost&iacute; mlčenlivosti a va&scaron;e osobn&iacute;
										&uacute;daje nesm&iacute; použ&iacute;t k
										ž&aacute;dn&eacute;mu dal&scaron;&iacute;mu &uacute;čelu.
									</li>
									<li>
										<strong>
											Hodnocen&iacute; produktů nebo služeb
											prostřednictv&iacute;m Heureka.cz nebo
											Zbož&iacute;.cz:&nbsp;
										</strong>
										V př&iacute;padě zas&iacute;l&aacute;n&iacute;
										dotazn&iacute;ků s hodnocen&iacute;m n&aacute;kupu mohou
										b&yacute;t va&scaron;e &uacute;daje (e-mailov&aacute;
										adresa, informace o zakoupen&eacute;m
										zbož&iacute;/služb&aacute;ch) sd&iacute;leny s
										provozovatelem port&aacute;lu Heureka.cz (společnost&iacute;
										Heureka Group a.s.) nebo Zbož&iacute;.cz (společnost&iacute;
										Seznam.cz, a.s.).
									</li>
									<li>
										<strong>St&aacute;tn&iacute; org&aacute;ny:</strong>
										&nbsp;v př&iacute;padě vym&aacute;h&aacute;n&iacute;
										na&scaron;ich pr&aacute;v mohou b&yacute;t va&scaron;e
										osobn&iacute; &uacute;daje před&aacute;ny třet&iacute;mu
										subjektu (např. advok&aacute;tovi). Pokud n&aacute;m
										pr&aacute;vn&iacute; ř&aacute;d, nebo org&aacute;n
										st&aacute;tn&iacute; moci (např. Policie ČR), ulož&iacute;
										povinnost, abychom mu předali va&scaron;e osobn&iacute;
										&uacute;daje, mus&iacute;me tak učinit.
									</li>
								</ul>
								<p className="fw-bolder text-center">
									Jak dlouho vaše osobní údaje zpracováváme?
								</p>
								<p>
									V prvé řadě budeme vaše údaje zpracovávat po celou dobu trvání
									smluvního vztahu mezi námi.
								</p>
								<p>
									V případě zpracovávání osobních údajů, ke kterému byl udělen
									souhlas, budou vaše osobní údaje obecně zpracovávány po dobu 7
									let, nebo do odvolání takového souhlasu.
								</p>
								<p>
									V případě, že se přihlásíte k odběru obchodních sdělení, tak
									vaše osobní údaje budeme zpracovávat po dobu 7 let, popř. do
									doby vyslovení vašeho nesouhlasu s jejich dalším zasíláním.
									Tento nesouhlas můžete snadno vyjádřit tím, že si upravíte
									nastavení svého uživatelského profilu.
								</p>
								<p>
									Dále upozorňujeme, že ty osobní údaje, které jsou nezbytné pro
									řádné poskytnutí služeb, resp. pro splnění všech našich
									povinností, ať již tyto povinnosti vyplývají ze smlouvy mezi
									námi či z obecně závazných právních předpisů musíme
									zpracovávat bez ohledu na vámi udělený souhlas po dobu
									stanovenou příslušnými právními předpisy či v souladu s nimi
									(např. u daňových dokladů je touto dobou doba nejméně 10 let).
								</p>
								<p>
									Údaje získané prostřednictvím uživatelského účtu či jiným
									obdobným způsobem, zpracováváme po dobu využívání našich
									služeb a dále obvykle 5 let po jejich zrušení. Následně jsou
									obvykle ukládány po přiměřenou dobu pouze základní
									identifikační údaje a údaj o tom, z jakého důvodu byl
									uživatelský účet zrušen či údaje tvořící součást provozních
									záloh.
								</p>
								<p>
									Cookies: cookies zahrnující chování uživatele mažeme po 30
									dnech s tím, že starší data jsou dostupná v anonymizované
									podobě v Google Analytics.
								</p>
								<p>
									Nahrávky telefonních hovorů z Call centra jsou uchovávány po
									krátkou dobu, nejdéle však po dobu 1 roku, ledaže pro nás bude
									z důvodu oprávněného zájmu nezbytné uchovat si nahrávky po
									delší dobu (např. při podezření ze zneužití údajů, z podvodu
									apod.)
								</p>
								<p>
									Vaše kamerové záznamy standardně ukládáme po dobu 90 dní.
									Pakliže v monitorovaném prostoru dojde k protiprávnímu
									jednání, můžeme kontaktovat policii a předmětné záznamy
									příslušnému orgánu (policie, soud apod.) předat. V takovém
									případně nejsou záznamy po 90-dnech mazány, ale z důkazních
									důvodů je uchováváme do pravomocného skončení věci.
								</p>
								<p className="fw-bolder text-center">
									Zabezpečení osobních údajů
								</p>
								<p>
									Vaše osobní údaje jsou u nás v bezpečí. Abychom zamezili
									neoprávněnému přístupu a zneužití vašich osobních údajů,
									zavedli jsme přiměřená opatření jak technického, tak
									organizačního rázu.
								</p>
								<p>
									Ve vinistu nám velmi záleží na ochraně vašich osobních údajů.
									Proto nejen pravidelně kontrolujeme jejich zabezpečení, ale
									jejich ochranu průběžně zdokonalujeme. Veškerá komunikace mezi
									vaším zařízením a našimi webovými servery je šifrovaná.
									Přihlašovací údaje jsou zahashované a všechna vaše data jsou
									uložena pouze na serverech v zabezpečených datových centrech s
									omezeným, pečlivě kontrolovaným a auditovaným přístupem.
								</p>
								<p>
									Snažíme se používat taková bezpečností opatření, která s
									ohledem na aktuální stav technologií poskytují dostatečné
									zabezpečení. Přijatá bezpečnostní opatření jsou pak pravidelně
									aktualizována.
								</p>
								<p>
									Proto používáme dvoufázové ověření ve chvíli, kdy se
									přihlašujete ke svému uživatelskému účtu.
								</p>
								<p className="fw-bolder text-center">Zabezpečení hesel</p>
								<p>
									Vaše hesla v systému vůbec nemáme, do databáze neukládáme.
									Pracujeme pouze se speciálně spočítanými hašovacími klíči
									vygenerovanými pomocí hašovací funkce BCRYPT s work
									factor/cost 12. Tato funkce patří k aktuálně nejbezpečnějším a
									nejsilnějším.
								</p>
								<p className="fw-bolder text-center">
									Osobní údaje osob mladší 18-let
								</p>
								<p>
									Náš internetový obchod není určen pro osoby mladší 18-let.
								</p>
								<p className="fw-bolder text-center">
									Jaká máte práva v souvislosti s ochranou vašich osobních údajů
								</p>
								<p>
									Ve vztahu k vašim osobním údajům máte zejména právo kdykoliv
									odvolat svůj souhlas se zpracováním osobních údajů, právo
									opravit či doplnit své osobní údaje, právo požadovat omezení
									jejich zpracování, právo vznést námitku či stížnost proti
									zpracovávání vašich osobních údajů, právo na přístup k vašim
									osobním údajům, právo požadovat přenesení vašich osobních
									údajů, právo být informován o porušení zabezpečení vašich
									osobních údajů a za určitých podmínek také právo na výmaz
									některých osobních údajů, které v souvislosti s vámi
									zpracováváme (tzv. právo být „zapomenut“).
								</p>
								<p className="fw-bolder">Úprava a doplnění</p>
								<p>
									Své osobní údaje máte pod kontrolou především prostřednictvím
									vašeho uživatelského účtu. Můžete zde mazat, resp. měnit
									základní informace o vaší osobě a měnit nastavení ohledně
									zasílání obchodních sdělení (popř. se z odběru obchodních
									sdělení odhlásit) apod.
								</p>
								<p>Případně nás můžete kontaktovat.</p>
								<p className="fw-bolder">Oprava</p>
								<p>
									V případě, že se domníváte, že osobní údaje, které o vás
									zpracováváme, jsou chybné, nás můžete kontaktovat
									prostřednictvím kontaktního formuláře. Z důvodu urychlení
									opravy vašich osobních údajů a vašeho komfortu však
									doporučujeme upravit si své osobní údaje ve svém uživatelském
									profilu.
								</p>
								<p>
									Dle novely zákona č. 235/2004 Sb., o dani z přidané hodnoty
									nelze od 1. 1. 2013 měnit údaje v již vystavené faktuře. Údaje
									na faktuře je možné změnit jen pokud jste zboží ještě
									nepřijal/a a nezaplatil/a.
								</p>
								<p className="fw-bolder">Přísup (portabilita)</p>
								<p>
									Můžete nás požádat, abychom vám zaslali přehled vašich
									osobních údajů. Pro tento přehled nás kontaktujte na
									podpora@vinisto.cz, nebo na telefonním čísle +420 606 758 080.
								</p>
								<p>
									Současně máte právo na přístup k těmto informacím týkajících
									se vašich osobních údajů:
								</p>
								<ul>
									<li>
										Jak&eacute; jsou &uacute;čely zpracov&aacute;n&iacute;
										va&scaron;ich osobn&iacute;ch &uacute;dajů
									</li>
									<li>
										Jak&eacute; jsou kategorie dotčen&yacute;ch osobn&iacute;ch
										&uacute;dajů
									</li>
									<li>
										Kdo, kromě n&aacute;s, jsou př&iacute;jemci va&scaron;ich
										osobn&iacute;ch &uacute;dajů
									</li>
									<li>
										Pl&aacute;novan&aacute; doba, po kterou budou va&scaron;e
										osobn&iacute; &uacute;daje uloženy
									</li>
									<li>
										Zdali m&aacute;te pr&aacute;vo požadovat od n&aacute;s
										opravu nebo v&yacute;maz va&scaron;ich osobn&iacute;ch
										&uacute;dajů nebo omezen&iacute; jejich
										zpracov&aacute;n&iacute; nebo vzn&eacute;st n&aacute;mitku
										proti tomuto zpracov&aacute;n&iacute;
									</li>
									<li>
										Informace o zdroji osobn&iacute;ch &uacute;dajů, pokud jsme
										je nez&iacute;skali od v&aacute;s
									</li>
								</ul>
								<p className="fw-bolder">Výmaz</p>
								<p>
									Dále můžete žádat, abychom údaje o vás vymazali (výmaz se však
									nedotkne údajů na dokumentech, které musíme podle zákona
									uchovávat (např. faktury či dobropisy). Pokud vaše osobní
									údaje budeme potřebovat pro určení, výkon nebo obhajobu našich
									právních nároků, může být vaše žádost odmítnuta (např. pokud
									za vámi evidujeme neuhrazenou pohledávku či v případě
									probíhajícího reklamačního řízení).
								</p>
								<p>
									Upozorňujeme, že informace o vaší platební kartě nejsou při
									platbě bez uložení platební karty ukládány u naší společnosti,
									ale u poskytovatele platební brány (třetí osoby). Proto tato
									data nelze z naší strany vymazat a je třeba kontaktovat
									platební bránu, skrze kterou jste platbu prováděli. U naší
									společnosti jsou uloženy pouze základní informace o platební
									kartě v případě, že si u nás svoji platební kartu uložíte.
								</p>
								<p>
									S výjimkou případů výše uvedených máte právo na výmaz v těchto
									případech:
								</p>
								<ol>
									<li>
										Osobn&iacute; &uacute;daje již nejsou potřebn&eacute; pro
										&uacute;čely, pro kter&eacute; byly
										zpracov&aacute;v&aacute;ny
									</li>
									<li>
										Odvolal/a jste svůj souhlas, na jehož z&aacute;kladě byly
										&uacute;daje zpracov&aacute;ny a neexistuje
										ž&aacute;dn&yacute; dal&scaron;&iacute; pr&aacute;vn&iacute;
										důvod pro jejich zpracov&aacute;n&iacute;
									</li>
									<li>
										Vznesl/a jste n&aacute;mitku proti zpracov&aacute;n&iacute;
										os. &uacute;dajů a m&aacute;te za to, že při
										posouzen&iacute; n&aacute;mitky vyjde najevo, že v
										konkr&eacute;tn&iacute; situaci převažuje v&aacute;&scaron;
										z&aacute;jem nad na&scaron;&iacute;m z&aacute;jmem na zprac.
										těchto os. &uacute;dajů
									</li>
									<li>
										Osobn&iacute; &uacute;daje jsou zpracov&aacute;ny
										protipr&aacute;vně
									</li>
									<li>
										Povinnost v&yacute;mazu stanov&iacute;
										zvl&aacute;&scaron;tn&iacute; pr&aacute;vn&iacute; předpis
									</li>
								</ol>
								<p>
									<strong>Jak právo na výmaz uplatnit?</strong> Kontaktujte nás
									na podpora@vinisto.cz, nebo na telefonním čísle +420 606 758
									080 a zažádejte o výmaz.
								</p>
								<p className="fw-bolder">Vznesení námitky</p>
								<p>
									Některé vaše osobní údaje zpracováváme na základě našeho
									oprávněného zájmu (viz kapitola těchto podmínek nazvaná
									„Osobní údaje zpracováváme na těchto právních podkladech“).
									Pokud na vaší straně budou existovat konkrétní důvody, můžete
									vznést námitku proti zpracovávání těchto vašich osobních
									údajů. Tuto námitku můžete vznést kontaktováním linky podpory,
									podpora@vinisto.cz, nebo na telefoním čísle +420 606 758 080.
								</p>
								<p className="fw-bolder">Omezení zpracování</p>
								<p>
									Pokud (a) popíráte přesnost vašich osobních údajů, (b) vaše
									osobní údaje jsou zpracovávány protiprávně, (c) my již vaše
									osobní údaje nepotřebujeme pro účely zpracování, ale vy je
									potřebujete pro určení, výkon nebo obhajobu vašich právních
									nároků, nebo pokud jste (d) vznesl/a námitku dle předchozího
									bodu, tak máte právo na to, abychom omezili zpracování vašich
									osobních údajů.
								</p>
								<p>
									V takovém případě můžeme vaše osobní údaje zpracovávat pouze s
									vaším souhlasem (s výjimkou uložení či zálohy předmětných
									osobních údajů).
								</p>
								<p className="fw-bolder">Podání stížnosti</p>
								<p>
									Máte-li za to, že vaše osobní údaje zpracováváme protiprávně,
									máte rovněž právo podat stížnost příslušnému dozorovému úřadu.
									Vedoucím dozorovým úřadem, který je příslušný pro otázky
									zpracování osobních údajů je český Úřad pro ochranu osobních
									údajů. Budeme však rádi, pokud budete přešlapy řešit nejprve s
									námi. Vždy nás můžete snadno kontaktovat prostřednictvím
									kontaktního formuláře.
								</p>
								<p>
									Tyto Podmínky ochrany osobních údajů jsou platné a účinné{' '}
									<strong>od 1. 11. 2022</strong>, přičemž jsou k dispozici
									elektronicky na www.vinisto.cz.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default DataProtection;
