import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Card from 'Components/View/Card';
import Container from 'Components/View/Container';
import { DeviceServiceContext } from 'Services/DeviceService';
import { useScrollFixedFilters } from 'Hooks/useScrollFixedFilters';
import { NavbarContext } from 'Components/Navbar/context';

import CategoryHeader from '../CategoryHeader';
import ActiveFilters from '../ActiveFilters';

import Filters from './Components/Filters';
import Bundles from './Components/Bundles';
import styles from './styles.module.css';

const CategoryBundlesWithFilters = () => {
	const { categoryUrl } = useParams();
	const { isMobile, isTablet } = useContext(DeviceServiceContext);
	const { isFiltersVisible: isVisible } = useContext(NavbarContext);

	const filterStyles =
		isMobile || isTablet
			? {
					display: isVisible ? 'fixed' : 'none',
			  }
			: {};

	useEffect(() => {
		if (isVisible) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.removeProperty('overflow');
		}
		return () => {
			document.body.style.removeProperty('overflow');
		};
	}, [isVisible]);

	const { filtersContainerRef, filtersRef, contentRef } =
		useScrollFixedFilters();

	return (
		<Container
			className="align-items-start"
			containerClassName="mt-0"
		>
			<div
				className="col vinisto-filter"
				style={filterStyles}
				// to force React to treat it as a new component and re-render it when the isVisible state changes
				key={isVisible ? 1 : 0}
				ref={filtersContainerRef}
			>
				<Card className={styles.filtersCard}>
					<Filters
						itemUrl={categoryUrl}
						parentRef={filtersRef}
					/>
				</Card>
			</div>
			<div
				className="col vinisto-category-list"
				ref={contentRef}
			>
				<CategoryHeader />
				<ActiveFilters />
				<Bundles />
			</div>
		</Container>
	);
};

export default CategoryBundlesWithFilters;
