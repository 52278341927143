import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	filter,
	findIndex,
	get,
	isEmpty,
	remove,
	set,
	some,
	sortBy,
	sumBy,
	uniqBy,
} from 'lodash-es';
import { useLocation, useNavigate } from 'react-router-dom';
import { GA_EVENT } from 'Hooks/useAnalytics/constants';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import getItemInBasket from 'Helpers/getItemInBasket';
import useAnalytics from 'Hooks/useAnalytics';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import usePrevious from 'Hooks/usePrevious';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { LocalizationContext } from 'Services/LocalizationService';
import { NotificationsContext } from 'Services/NotificationService';
import { OrderContext } from 'Services/OrderService/context';
import { WarehouseContext } from 'Services/WarehouseService';
import { TrackEvent } from 'Services/FacebookPixel';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
	VinistoBasketDllModelsApiBasketBasket,
	VinistoBasketDllModelsApiBasketBasketBundle,
	VinistoBasketDllModelsApiReturnDataBasketReturn,
	VinistoGiftsDllModelsApiReturnDataGiftsReturn,
	VinistoHelperDllBaseBaseReturn,
} from 'vinisto_api_client/src/api-types/basket-api';
import { useOosItems } from 'Pages/Cart/hooks';
import { BasketService } from 'vinisto_api_client';
import { getLocalizedCurrency } from 'vinisto_shared/src/price/get-localized-currency';
import { useShippingPackaging } from 'Pages/Cart/Components/PackagingOptions/hooks';

import {
	INTERVAL_TO_REFETCH_BASKET,
	NOT_ENOUGH_ITEMS_IN_WAREHOUSE_ERROR,
	NOT_ENOUGH_ITEMS_IN_WAREHOUSE_ERROR_MESSAGE,
} from './constants';
import {
	BasketModel,
	IBasketServiceProviderProps,
	IBundleChange,
	SetAreGiftRefusedArgs,
} from './interfaces';

import { bundleAdapter } from '@/index';
import api from '@/api';
import { Bundle } from '@/domain/bundle';

const defaultBasketContextModel: BasketModel = {
	handleOnLoadBasket: () => null,
	handleOnAddToBasket: () => Promise.resolve(false),
	handleOnMergeBaskets: () => null,
	handleOnChangeItemQuantity: () => Promise.resolve(true),
	handleOnClearBasket: () => null,
	handleOnAddCoupon: () => Promise.resolve(),
	handleOnRemoveCoupon: () => null,
	bulkUpdate: () => Promise.resolve(true),
	basketState: {} as VinistoBasketDllModelsApiBasketBasket,
	possibleGifts: [],
	assignedGifts: [],
	assignedGiftsWeight: 0,
	minimalPriceForFreeDelivery: null,
	basketTemp: { current: [] },
	basketItemsWithTemp: {},
	basketItemsGoogleAnalyticsData: [],
	basketPrice: 0,
	basketPriceWithVat: 0,
	basketStandardPrice: 0,
	basketStandardPriceWithVat: 0,
	itemsQuantity: 0,
	isUpdatingCount: false,
	isLoading: false,
	selectedShippingPackaging: null,
	setBasketState: () => null,
	setSelectedShippingPackaging: () => null,
	setOptimisticSelectedShippingPackaging: () => null,
};

export const BasketContext = createContext(defaultBasketContextModel);

const BasketServiceProvider = ({ children }: IBasketServiceProviderProps) => {
	const { useFormatMessage, activeLanguage, activeCurrency, countryOfSale } =
		useContext(LocalizationContext);
	const currentActiveCurrency = activeCurrency.currency;
	const t = useFormatMessage();
	const { isLoggining, isLoggedIn, vinistoUser, anonymousUID } = useContext(
		AuthenticationContext
	);
	const { handleShowErrorNotification, handleShowSuccessNotification } =
		useContext(NotificationsContext);
	const orderContext = useContext(OrderContext);
	const warehouseContext = useContext(WarehouseContext);
	const [basketState, setBasketState] =
		useState<VinistoBasketDllModelsApiBasketBasket>(
			{} as VinistoBasketDllModelsApiBasketBasket // remove type assertion
		);

	const [minimalPriceForFreeDelivery, setMinimalPriceForFreeDelivery] =
		useState<number | null>(null);

	const [isLoading, setIsLoading] = useState(true);

	const basketTemp = useRef([]);
	const [basketItemsWithTemp, setBasketItemsWithTemp] = useState<
		Record<any, any>
	>({});

	const { totalPriceBasketOos, totalStandardPriceBasketOos } = useOosItems(
		basketItemsWithTemp.items
	);

	const clearDeliveryPayment = useCallback(() => {
		orderContext.clearDeliveryPayment();
	}, [orderContext]);
	const [isUpdatingCount, setIsUpdatingCount] = useState<boolean>(false);

	const history = useNavigate();
	const getLocalizedValue = useLocalizedValue();
	const { sendEvent: sendAnalyticsEvent } = useAnalytics();
	const queryClient = useQueryClient();

	const isPrevLoggining = usePrevious(isLoggining);
	const isPrevLoggingout = usePrevious(isLoggedIn);
	const userLoginHash = vinistoUser.loginHash;
	const anonymousUserId = anonymousUID.anonymousUserId ?? '';
	const prevAnonymousUID = usePrevious(anonymousUserId);
	const location = useLocation();

	useEffect(() => {
		if (isPrevLoggining && isLoggedIn) {
			handleOnMergeBaskets(prevAnonymousUID ?? '');
		}
		if (isPrevLoggingout && !isLoggedIn) {
			handleOnLoadBasket();
		}
	}, [isPrevLoggining, prevAnonymousUID, isLoggedIn]);

	useEffect(() => {
		if (get(location, 'state.updateBasket', true)) {
			handleOnLoadBasket();
		}
		// If location is not changed, update basket after certain interval
		const timeout = setTimeout(function handle() {
			handleOnLoadBasket();
			setTimeout(handle, INTERVAL_TO_REFETCH_BASKET);
		}, INTERVAL_TO_REFETCH_BASKET);

		return clearTimeout(timeout);
	}, [location.pathname, currentActiveCurrency, countryOfSale]);

	useEffect(() => {
		setBasketItemsWithTemp({
			items: uniqBy(
				sortBy(
					[...get(basketState, 'items', []), ...basketTemp.current],
					'bundleId'
				),
				'bundleId'
			),
		});
	}, [basketState]);

	const gifts = useQuery(
		[
			'possibleGifts',
			{
				userLoginHash,
				anonymousUserId,
				currency: currentActiveCurrency,
				countryOfSale,
			},
		],
		async () => {
			const response =
				await api.get<VinistoGiftsDllModelsApiReturnDataGiftsReturn>(
					`basket-api/basket/gifts`,
					{
						Currency: currentActiveCurrency,
						CountryOfSale: countryOfSale,
						UserLoginHash: userLoginHash,
						AnonymousUserId: anonymousUserId,
					},
					{ serializeUrlParams: false }
				);

			if (response.isError === true) return null;

			return response;
		},
		{ enabled: Boolean(basketState?.id), keepPreviousData: true }
	);

	const possibleGifts = gifts.data?.possibleGifts ?? [];
	const assignedGifts = gifts.data?.assignedGifts ?? [];
	const assignedGiftsWeight = gifts.data?.assignedGiftsWeight ?? 0;

	const getBundleGoogleAnalyticsData = useCallback(
		(bundleInBasket: VinistoBasketDllModelsApiBasketBasketBundle) => {
			let bundlePrices;

			try {
				bundlePrices = bundleInBasket?.bundle
					? // @ts-expect-error BasketBundle is slightly different from ProductBundle, TODO: fix this
					  bundleAdapter.fromApi(bundleInBasket.bundle, {
							currency: currentActiveCurrency,
					  }).bundlePrices
					: null;
			} catch (error) {
				bundlePrices = null;
			}

			const { basePrice, discountedPrice } = bundlePrices ?? {};

			const lowestPrice = discountedPrice?.value ?? basePrice?.value ?? 0;

			const flagSpecification = getFlagSpecification(
				bundleInBasket?.bundle?.specificationDetails ?? []
			);

			return {
				item_id: bundleInBasket?.bundleId ?? '',
				item_name: getLocalizedValue(bundleInBasket?.bundle?.name ?? []) ?? '',
				item_brand: flagSpecification?.variety ?? '',
				price: lowestPrice,
				currency: getLocalizedCurrency(basketState?.currency),
				quantity: bundleInBasket?.quantity ?? 0,
			};
		},
		[basketState?.currency, getLocalizedValue]
	);

	const sendAddToCartAnalytics = useCallback(
		(basket: Record<string, any>, bundleId: string) => {
			const bundleInBasket = getItemInBasket(basket, bundleId);
			const bundleData = getBundleGoogleAnalyticsData(bundleInBasket);

			TrackEvent('track', 'AddToCart', {
				content_type: 'product',
				contents: [
					{
						id: bundleData.item_id,
						quantity: bundleData.quantity,
					},
				],
				value: Math.round((bundleData.price + Number.EPSILON) * 100) / 100 || 0,
				content_name: bundleData.item_name,
				currency: bundleData.currency,
			});

			sendAnalyticsEvent(GA_EVENT.ADD_TO_CART, {
				items: [bundleData],
				currency: bundleData.currency,
				value: Math.round((bundleData.price + Number.EPSILON) * 100) / 100 || 0,
			});
		},
		[getBundleGoogleAnalyticsData, sendAnalyticsEvent]
	);

	const sendRemoveFromCartAnalytics = useCallback(
		(basket: Record<string, any>, bundleId: string) => {
			const bundleInBasket = getItemInBasket(basket, bundleId);
			const bundleData = getBundleGoogleAnalyticsData(bundleInBasket);
			sendAnalyticsEvent(GA_EVENT.REMOVE_FROM_CART, {
				items: [bundleData],
				currency: bundleData.currency,
				value: Math.round((bundleData.price + Number.EPSILON) * 100) / 100 || 0,
			});
		},
		[getBundleGoogleAnalyticsData, sendAnalyticsEvent]
	);

	const basketPrice =
		(basketState?.discountCoupons?.discountedBasketPrice ??
			basketState?.basketPrice ??
			0) - totalPriceBasketOos.sumWithoutVat;

	const basketPriceWithVat =
		(basketState?.discountCoupons?.discountedBasketPriceWithVat ??
			basketState?.basketPriceWithVat ??
			0) - totalPriceBasketOos.sumWithVat;

	const basketStandardPrice = basketState?.basketStandardPrice
		? basketState.basketStandardPrice -
		  totalStandardPriceBasketOos.sumWithoutVat
		: 0;

	const basketStandardPriceWithVat = basketState?.basketStandardPriceWithVat
		? basketState.basketStandardPriceWithVat -
		  totalStandardPriceBasketOos.sumWithVat
		: 0;

	const itemsQuantity = useMemo(() => {
		return sumBy(basketState.items ?? [], (basketItem: any) => {
			return basketItem.quantity;
		});
	}, [basketState, basketItemsWithTemp, activeLanguage]);

	const handleGoToCrossSell = useCallback(
		(itemUrl: string) => {
			history(`/${t({ id: 'routes.crossSell.route' })}/${itemUrl}`, {
				state: { updateBasket: false },
			});
			window.history.replaceState({}, '');
		},
		[history]
	);

	const handleOnLoadBasket = useCallback(() => {
		if (!basketState) {
			setIsLoading(true);
		}
		if (!userLoginHash && !anonymousUserId) return;
		const requestData = {
			...(isLoggedIn
				? { userLoginHash }
				: {
						anonymousUserId,
				  }),
			Currency: currentActiveCurrency,
			CountryOfSale: countryOfSale,
		};

		BasketService.getBasket(requestData)
			.then((payload) => {
				const basketWithEmptyBundlesRemoved = {
					...payload.basket,
					id: String(payload?.basket?.id),
					items: payload?.basket?.items.filter((item) => item.bundle?.id) ?? [],
				};
				setBasketState(basketWithEmptyBundlesRemoved);
				setMinimalPriceForFreeDelivery(
					payload.minimalPriceForFreeDelivery ?? null
				);
				queryClient.invalidateQueries(['possibleGifts']);
			})
			.catch((e) => {
				if (
					e.response &&
					e.response.error &&
					e.response.error[0].generalError === 'ObjectNotFound'
				) {
					void 0;
				} else {
					throw e;
				}
			})
			.finally(() => setIsLoading(false));
	}, [
		currentActiveCurrency,
		anonymousUserId,
		countryOfSale,
		isLoggedIn,
		queryClient,
		userLoginHash,
		basketState,
	]);

	const handleOnAddToBasket = useCallback(
		(
			count: number,
			bundleId: string,
			itemUrl?: string,
			availableCount?: number,
			redirectToCrossSell = true
		) => {
			const requestData = {
				...(isLoggedIn
					? { userLoginHash }
					: {
							anonymousUserId,
					  }),
				currency: currentActiveCurrency,
				countryOfSale,
				quantity: count,
				bundleId,
			};

			const itemInBasket = getItemInBasket(basketState, bundleId);
			if (itemInBasket) {
				set(requestData, 'quantity', get(itemInBasket, 'quantity', 1) + count);
			}

			return api
				.put<VinistoBasketDllModelsApiReturnDataBasketReturn>(
					`basket-api/basket`,
					undefined,
					requestData
				)
				.then((payload) => {
					const basket = payload.basket ?? {};
					const tempIndex = findIndex(
						basketTemp.current,
						(item) => get(item, 'bundleId') === bundleId
					);
					if (tempIndex > -1) {
						set(
							basketTemp,
							'current',
							filter(
								basketTemp.current,
								(_, index: number) => index !== tempIndex
							)
						);
					}
					clearDeliveryPayment();
					// @ts-expect-error incompatible type {}
					setBasketState(basket);
					setMinimalPriceForFreeDelivery(
						payload.minimalPriceForFreeDelivery ?? null
					);
					sendAddToCartAnalytics(basket, bundleId);
					if (redirectToCrossSell && itemUrl) {
						handleGoToCrossSell(itemUrl);
					}
					return true;
				})
				.catch((error: Error) => {
					handleShowErrorNotification(
						get(error, 'message') === NOT_ENOUGH_ITEMS_IN_WAREHOUSE_ERROR
							? 'notification.message.basket.error.notAvailable'
							: 'notification.message.basketAdd.error'
					);
					return false;
				});
		},
		[
			isLoggedIn,
			userLoginHash,
			anonymousUserId,
			currentActiveCurrency,
			countryOfSale,
			basketState,
			clearDeliveryPayment,
			sendAddToCartAnalytics,
			handleGoToCrossSell,
			handleShowErrorNotification,
		]
	);

	const handleOnMergeBaskets = useCallback(
		(anonymousHash: string) => {
			const requestData = {
				userLoginHash,
				anonymousUserId: anonymousHash,
				currency: currentActiveCurrency,
				countryOfSale,
			};
			api
				.put<VinistoBasketDllModelsApiReturnDataBasketReturn>(
					'basket-api/basket/MergeBaskets',
					undefined,
					requestData
				)
				.then(() => {
					handleOnLoadBasket();
				});

			clearDeliveryPayment();
		},
		[userLoginHash, currentActiveCurrency, countryOfSale]
	);

	const handleOnChangeItemQuantity = useCallback(
		(count: number, bundleId: string, isMoveToTemp = true) => {
			setIsUpdatingCount(true);

			const requestData = {
				...(isLoggedIn
					? { userLoginHash }
					: {
							anonymousUserId,
					  }),
				currency: currentActiveCurrency,
				countryOfSale,
				quantity: count,
				bundleId,
			};

			return api
				.put<VinistoBasketDllModelsApiReturnDataBasketReturn>(
					`basket-api/basket`,
					undefined,
					requestData
				)
				.then((payload) => {
					if (count === 0) {
						const bundleIndex = findIndex(
							basketState?.items ?? [],
							(bundle) => (bundle.bundleId ?? '-1') === bundleId
						);
						const bundle = basketState?.items[bundleIndex] ?? {};
						const deletedBundleId = get(bundle, 'bundleId');
						const basketTempItems = get(basketTemp, 'current', []);

						some(basketTempItems, { bundleId: deletedBundleId }) &&
							remove(
								basketTempItems,
								(basketTempItem) => get(basketTempItem, 'bundleId') === bundleId
							);
						if (isMoveToTemp && !isEmpty(bundle)) {
							const availableQuantity = warehouseContext.getQuantity(
								bundle.bundleId ?? ''
							);
							set(basketTemp, 'current', [
								...basketTemp.current,
								{
									...bundle,
									quantity:
										availableQuantity === undefined ||
										(bundle.quantity ?? 0) < availableQuantity
											? bundle.quantity
											: availableQuantity,
									temp: true,
								},
							]);
						}
					}

					const basket = payload.basket ?? {};
					if (basket)
						if (count) {
							sendAddToCartAnalytics(basket, bundleId);
						} else {
							sendRemoveFromCartAnalytics(basketState, bundleId);
						}
					clearDeliveryPayment();
					// @ts-expect-error incompatible type {}
					setBasketState(basket);
					setMinimalPriceForFreeDelivery(
						payload.minimalPriceForFreeDelivery ?? null
					);
					queryClient.invalidateQueries(['possibleGifts']);
					return true;
				})
				.catch((error: Error) => {
					if (
						error.message.includes(NOT_ENOUGH_ITEMS_IN_WAREHOUSE_ERROR_MESSAGE)
					) {
						handleShowErrorNotification(
							'notification.message.basket.error.notAvailable'
						);
					} else {
						handleShowErrorNotification(
							'notification.message.basketChange.error'
						);
					}
					return false;
				});
		},
		[
			anonymousUserId,
			basketState,
			clearDeliveryPayment,
			handleShowErrorNotification,
			isLoggedIn,
			queryClient,
			sendAddToCartAnalytics,
			sendRemoveFromCartAnalytics,
			userLoginHash,
			warehouseContext,
			currentActiveCurrency,
			countryOfSale,
		]
	);

	const handleOnClearBasket = useCallback(() => {
		const requestData = {
			...(isLoggedIn
				? { userLoginHash }
				: {
						anonymousUserId,
				  }),
			currency: currentActiveCurrency,
			countryOfSale,
		};

		api.delete('basket-api/basket', requestData).catch(() => {
			handleShowErrorNotification('notification.message.basketClear.error');
		});

		clearDeliveryPayment();
	}, [
		basketState,
		countryOfSale,
		currentActiveCurrency,
		isLoggedIn,
		userLoginHash,
		anonymousUserId,
	]);

	const handleOnAddCoupon = useCallback(
		async (discountCouponCode: string, displayToasts = true) => {
			const requestData = {
				...(isLoggedIn
					? { UserLoginHash: userLoginHash ?? '' }
					: {
							AnonymousUserId: anonymousUserId ?? '',
					  }),
				Currency: currentActiveCurrency,
				CountryOfSale: countryOfSale,
			};

			clearDeliveryPayment();

			return await BasketService.applyDiscountCoupon({
				...requestData,
				countryOfSale,
				discountCouponCode,
			})
				// TODO duplicate error handling across the functions causing unexpected behavior
				.catch((responseError: VinistoHelperDllBaseBaseReturn) => {
					const isCouponAlreadyApplied = responseError?.error?.some(
						(err) =>
							err.specificError === 'DISCOUNT_COUPON_ID_IS_ALREADY_IN_BASKET'
					);

					const isCouponNotCombinable = responseError?.error?.some(
						(err) => err.specificError === 'DISCOUNT_COUPON_IS_NOT_COMBINABLE'
					);

					const cantBeAppliedBecauseOfBasketValue = responseError?.error?.some(
						(err) =>
							err.specificError ===
							'DISCOUNT_COUPON_AMOUNT_VALUE_HIGHER_THEN_ALLOWD_FROM'
					);
					switch (true) {
						case isCouponAlreadyApplied || isCouponNotCombinable:
							throw new Error();
						case cantBeAppliedBecauseOfBasketValue:
							handleShowErrorNotification(
								'basket.discountCoupon.error.allowedFromDoNotMatch'
							);
							throw new Error();
						default:
							handleShowErrorNotification('basket.discountCoupon.error');
							throw new Error();
					}
				})
				.finally(() => {
					BasketService.getBasket(requestData).then((payload) => {
						// @ts-expect-error incompatible type {}
						setBasketState(payload?.basket ?? {});
						queryClient.invalidateQueries(['possibleGifts']);
						if (displayToasts) {
							handleShowSuccessNotification(
								'notification.message.discountCouponAdd.success'
							);
						}
					});
				});
		},
		[
			isLoggedIn,
			userLoginHash,
			anonymousUserId,
			clearDeliveryPayment,
			queryClient,
			handleShowSuccessNotification,
			handleShowErrorNotification,
			currentActiveCurrency,
			countryOfSale,
		]
	);

	const handleOnRemoveCoupon = useCallback(
		(id: string) => {
			const requestData = isLoggedIn
				? { userLoginHash }
				: {
						anonymousUserId: anonymousUserId ?? '',
				  };

			api
				.put<VinistoBasketDllModelsApiReturnDataBasketReturn>(
					`basket-api/basket/RemoveDiscountCoupon?discountCouponId=${encodeURIComponent(
						id
					)}`,
					undefined,
					{
						...requestData,
						CountryOfSale: countryOfSale,
						Currency: currentActiveCurrency,
					}
				)
				.then(() => {
					handleOnLoadBasket();
				})
				.catch((responseError: any) => {
					BasketService.getBasket({
						...requestData,
						Currency: currentActiveCurrency,
					}).then((payload) => {
						// @ts-expect-error incompatible type {}
						setBasketState(payload?.basket ?? {});
						queryClient.invalidateQueries(['possibleGifts']);
					});
					const isCouponNotCombinable = responseError?.response?.error?.some(
						(err: any) => {
							return err.specificError === 'DISCOUNT_COUPON_IS_NOT_COMBINABLE';
						}
					);

					if (!isCouponNotCombinable) {
						handleShowErrorNotification(
							'notification.message.removediscountCoupon.error'
						);
					}
				});

			clearDeliveryPayment();
		},
		[
			isLoggedIn,
			userLoginHash,
			anonymousUserId,
			clearDeliveryPayment,
			handleOnLoadBasket,
			handleShowErrorNotification,
			queryClient,
		]
	);

	const bulkUpdate = useCallback(
		async (basketBundles: IBundleChange[], isMoveToTemp = true) => {
			setIsUpdatingCount(true);
			const requestData = {
				...(isLoggedIn
					? { userLoginHash: vinistoUser.loginHash }
					: {
							anonymousUserId: anonymousUserId,
					  }),
				basketBundles,
				currency: currentActiveCurrency,
				countryOfSale,
			};

			return api
				.put<VinistoBasketDllModelsApiReturnDataBasketReturn>(
					'basket-api/basket/UpdateBasketRange',
					undefined,
					requestData
				)
				.then((payload) => {
					const basket = payload.basket ?? {};
					if (basket) {
						basketBundles.forEach((bundleUpdate) => {
							if (bundleUpdate.quantity === 0) {
								const bundleIndex = findIndex(
									get(basketState, 'items', []),
									(bundle) =>
										(bundle.bundleId ?? '-1') === bundleUpdate.bundleId
								);
								const bundle = basketState.items[bundleIndex] ?? {};
								const deletedBundleId = bundle.bundleId;
								const basketTempItems = basketTemp.current ?? [];

								some(basketTempItems, { bundleId: deletedBundleId }) &&
									remove(
										basketTempItems,
										(basketTempItem) =>
											get(basketTempItem, 'bundleId') === bundleUpdate.bundleId
									);
								if (isMoveToTemp && !isEmpty(bundle)) {
									const availableQuantity = warehouseContext.getQuantity(
										bundle.bundleId ?? ''
									);
									set(basketTemp, 'current', [
										...basketTemp.current,
										{
											...bundle,
											quantity:
												availableQuantity === undefined ||
												(bundle?.quantity ?? 0) < availableQuantity
													? bundle.quantity
													: availableQuantity,
											temp: true,
										},
									]);
								}
							}

							if (bundleUpdate.quantity) {
								sendAddToCartAnalytics(basket, bundleUpdate.bundleId);
							} else {
								sendRemoveFromCartAnalytics(basketState, bundleUpdate.bundleId);
							}
						});
					}
					clearDeliveryPayment();
					// @ts-expect-error incompatible type {}
					setBasketState(basket);
					setMinimalPriceForFreeDelivery(
						payload.minimalPriceForFreeDelivery ?? null
					);
					queryClient.invalidateQueries(['possibleGifts']);
					return true;
				})
				.catch((error: Error) => {
					// TODO: test errors
					handleShowErrorNotification(
						get(error, 'message') === NOT_ENOUGH_ITEMS_IN_WAREHOUSE_ERROR
							? 'notification.message.basket.error.notAvailable'
							: 'notification.message.basketChange.error'
					);
					return false;
				});
		},
		[
			isLoggedIn,
			vinistoUser.loginHash,
			anonymousUserId,
			clearDeliveryPayment,
			queryClient,
			basketState,
			warehouseContext,
			sendAddToCartAnalytics,
			sendRemoveFromCartAnalytics,
			handleShowErrorNotification,
			currentActiveCurrency,
			countryOfSale,
		]
	);

	useEffect(() => {
		if (isUpdatingCount) {
			setIsUpdatingCount(false);
		}
	}, [basketState]);

	const basketItemsGoogleAnalyticsData = () =>
		basketState?.items.map((item) => getBundleGoogleAnalyticsData(item));

	const setAreGiftsRefused = useMutation({
		mutationFn: async (requestParams: SetAreGiftRefusedArgs) => {
			const {
				userLoginHash = null,
				anonymousUserId = null,
				areGiftsRefused,
			} = requestParams;

			const response =
				await api.put<VinistoBasketDllModelsApiReturnDataBasketReturn>(
					`basket-api/basket/refuse-gifts`,
					undefined,
					{
						userLoginHash,
						anonymousUserId,
						areGiftsRefused,
					}
				);

			if (response.isError) {
				return Promise.reject(response.error);
			}

			return {
				...requestParams,
				basket: response.basket,
			};
		},
		onSuccess() {
			handleOnLoadBasket();
		},
	});

	const { data: shippingPackaging } = useShippingPackaging();

	const selectedShippingPackaging = useMemo(() => {
		if (!basketState.shippingPackagingBundle?.bundleId) {
			return null;
		}

		return shippingPackaging?.find(
			(bundle) => bundle.id === basketState.shippingPackagingBundle?.bundleId
		);
	}, [basketState, shippingPackaging]) as Bundle | null;

	// This is optimistic update – function updates the state without waiting for the server response
	const setOptimisticSelectedShippingPackaging = (bundle: Bundle | null) => {
		setBasketState((prev) => ({
			...prev,
			shippingPackagingBundle: bundle
				? {
						bundleId: bundle.id,
				  }
				: null,
		}));
	};

	const setSelectedShippingPackaging = (
		shippingPackagingBundle: VinistoBasketDllModelsApiBasketBasketBundle | null
	) => {
		setBasketState((prev) => ({
			...prev,
			shippingPackagingBundle,
		}));
	};

	const basketContextModel: BasketModel = {
		handleOnAddToBasket,
		handleOnMergeBaskets,
		handleOnChangeItemQuantity,
		handleOnLoadBasket,
		handleOnClearBasket,
		handleOnAddCoupon,
		handleOnRemoveCoupon,
		bulkUpdate,
		basketState,
		possibleGifts,
		assignedGifts,
		assignedGiftsWeight,
		minimalPriceForFreeDelivery,
		basketTemp,
		basketItemsWithTemp,
		basketItemsGoogleAnalyticsData,
		basketPrice,
		basketPriceWithVat,
		basketStandardPrice,
		basketStandardPriceWithVat,
		itemsQuantity,
		isUpdatingCount,
		setAreGiftsRefused,
		isLoading,
		selectedShippingPackaging,
		setSelectedShippingPackaging,
		setOptimisticSelectedShippingPackaging,
		setBasketState,
	};
	return (
		<BasketContext.Provider value={basketContextModel}>
			{children}
		</BasketContext.Provider>
	);
};

export default BasketServiceProvider;
