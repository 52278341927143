/* eslint-disable jsx-a11y/click-events-have-key-events */

import {
	Dispatch,
	FC,
	lazy,
	SetStateAction,
	Suspense,
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState,
} from 'react';
import { get, invoke, uniqueId } from 'lodash-es';
import cx from 'classnames';
import ReactSlider from 'react-slider';
import { API_EVALUATIONS_URL } from 'Pages/Bundle/Components/BundleDetail/Components/ReviewSection/constants';
import { NOT_SELECTED } from 'Components/Rating/constants';
import { apiServiceInstance } from 'Services/ApiService';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { LocalizationContext } from 'Services/LocalizationService';
import { ModalContext } from 'Components/Modal/context';
import { NotificationsContext } from 'Services/NotificationService';
import { Form, InputError, InputTextArea } from 'Components/Form';
import { required } from 'Components/Form/validators';
import { Field } from 'react-final-form';
import { MutableState, Tools } from 'final-form';
import Rating from 'Components/Rating';
import Loader from 'Components/View/Loader';

const CloseSmallIcon = lazy(() => import('Components/Icons/CloseSmall'));

import { DEFAULT_SLIDER_VALUE } from './constants';
import { ReviewFormValues } from './types';
import './styles.css';

const ReviewModal: FC = (): JSX.Element => {
	const authenticationContext = useContext(AuthenticationContext);
	const localizationContext = useContext(LocalizationContext);
	const modalContext = useContext(ModalContext);
	const notificationsContext = useContext(NotificationsContext);
	const t = localizationContext.useFormatMessage();

	const reviewData = get(modalContext, 'modalData.reviewData', {});
	const bundleData = get(modalContext, 'modalData.bundleData', {});
	const isEdit = true; // size(reviewData) > 0;
	const reviewStars = get(reviewData, 'stars');
	const reviewValue =
		reviewStars === undefined ? NOT_SELECTED : reviewStars / 2;

	const [resetSliders, setResetSliders] = useState(false);

	const sweetDryDefaultValue = get(
		reviewData,
		'sweetDry',
		get(bundleData, 'bundleEvaluation.averageSweetDry', DEFAULT_SLIDER_VALUE)
	);
	const sweetDrySlider = useRef<ReactSlider>(null);
	const [isSweetDrySelected, setIsSweetDrySelected] = useState(isEdit);
	const sweetDrySliderKey = useMemo(() => uniqueId(), [resetSliders]);

	const lightHeavyDefaultValue = get(
		reviewData,
		'lightHeavy',
		get(bundleData, 'bundleEvaluation.averageLightHeavy', DEFAULT_SLIDER_VALUE)
	);
	const lightHeavySlider = useRef<ReactSlider>(null);
	const [isLightHeavySelected, setIsLightHeavySelected] = useState(isEdit);
	const lightHeavySliderKey = useMemo(() => uniqueId(), [resetSliders]);

	const fruitTannicDefaultValue = get(
		reviewData,
		'fruitTannic',
		get(bundleData, 'bundleEvaluation.averageFruitTannic', DEFAULT_SLIDER_VALUE)
	);
	const fruitTannicSlider = useRef<ReactSlider>(null);
	const [isFruitTannicSelected, setIsFruitTannicSelected] = useState(isEdit);
	const fruitTannicSliderKey = useMemo(() => uniqueId(), [resetSliders]);

	const lowHighAccidityDefaultValue = get(
		reviewData,
		'lowHighAccidity',
		get(
			bundleData,
			'bundleEvaluation.averageLowHighAccidity',
			DEFAULT_SLIDER_VALUE
		)
	);
	const lowHighAcciditySlider = useRef<ReactSlider>(null);
	const [isLowHighAcciditySelected, setIsLowHighAcciditySelected] =
		useState(isEdit);
	const lowHighAcciditySliderKey = useMemo(() => uniqueId(), [resetSliders]);

	const handleOnSliderChange = useCallback(
		(setSelected: Dispatch<SetStateAction<boolean>>) => () => {
			setSelected(true);
		},
		[]
	);

	const handleOnReset = useCallback(() => {
		setResetSliders((oldState) => !oldState);
		setIsSweetDrySelected(isEdit);
		setIsLightHeavySelected(isEdit);
		setIsFruitTannicSelected(isEdit);
		setIsLowHighAcciditySelected(isEdit);
	}, [isEdit]);

	const handleOnSubmitReview = useCallback(
		(formValues: ReviewFormValues) => {
			const requestData = {
				userLoginHash: authenticationContext.vinistoUser.loginHash,
				bundleId: get(modalContext, 'modalData.bundleId', ''),
				text: get(formValues, 'text', ''),
				stars: get(formValues, 'rating', 0) * 2, // API accepts values 0-10, we have 5 stars
				sweetDry: invoke(sweetDrySlider, 'current.getValue') ?? 0,
				lightHeavy: invoke(lightHeavySlider, 'current.getValue') ?? 0,
				fruitTannic: invoke(fruitTannicSlider, 'current.getValue') ?? 0,
				lowHighAccidity: invoke(lowHighAcciditySlider, 'current.getValue') ?? 0,
			};
			apiServiceInstance
				.post(API_EVALUATIONS_URL, requestData, true)
				.then(() => {
					notificationsContext.handleShowSuccessNotification(
						'notification.message.review.success'
					);
					modalContext.handleCloseModal();
					invoke(modalContext, 'modalData.forceReload');
				})
				.catch(() => {
					notificationsContext.handleShowErrorNotification(
						'notification.message.review.error'
					);
				});
		},
		[authenticationContext, notificationsContext]
	);

	const validateRating = useCallback((value: any): string | undefined => {
		return !value || value <= 0 ? 'modal.review.rating.error' : undefined;
	}, []);

	const mutatorsReference = useRef<Record<string, any>>({});
	const mutators = useMemo(
		() => ({
			updateRating: (
				args: [],
				state: MutableState<Record<string, any>>,
				tools: Tools<Record<string, any>>
			) => {
				tools.changeValue(state, 'rating', () => get(args, '[0]', 0));
			},
		}),
		[]
	);

	const handleOnRatingChange = useCallback((value: number) => {
		const updateRating = get(
			mutatorsReference,
			'current.updateRating',
			() => null
		);
		updateRating(value);
	}, []);

	return (
		<div className="vinisto-add-review">
			<Form
				submitCallback={handleOnSubmitReview}
				initializationValues={{
					text: get(reviewData, 'text', ''),
					rating: reviewValue,
				}}
				customSubmitContent={() => (
					<div className="vinisto-add-review__footer">
						<div className="vinisto-add-review__footer__text">
							{t({ id: 'modal.review.disclaimer' })}
						</div>
						<button
							type="submit"
							className="vinisto-btn vinisto-popup__btn"
						>
							{t({ id: 'modal.review.button' })}
						</button>
					</div>
				)}
				{...{ mutators, mutatorsReference }}
			>
				<div className="vinisto-add-review__stars">
					<Field
						name="rating"
						validate={validateRating}
						value={reviewValue}
					>
						{(fieldPropTypes) => {
							const { input, meta } = fieldPropTypes;

							return (
								<>
									<Rating
										handleOnChange={handleOnRatingChange}
										defaultValue={get(input, 'value')}
										isLarge
									/>
									<span className="vinisto-add-review__stars-error">
										<InputError
											errorMessage={get(meta, 'error')}
											touched={get(meta, 'touched', false)}
										/>
									</span>
								</>
							);
						}}
					</Field>
				</div>
				<InputTextArea
					identifier="text"
					name="text"
					label="modal.review.label"
					className="vinisto-add-review__textarea"
					placeholder=""
					validate={required}
				/>
				<div className="vinisto-add-review__main">
					<div className="vinisto-add-review__main__left">
						<div className="vinisto-add-review__main__left__header">
							<div className="vinisto-add-review__main__left__header__heading">
								{t({ id: 'modal.review.profile' })}
							</div>
							<div className="vinisto-add-review__main__left__header__reset underline-effect underline-effect--vinisto">
								<Suspense fallback={<Loader blank />}>
									<CloseSmallIcon
										id={uniqueId()}
										alt={t({ id: 'alt.resetSliders' })}
										title={``}
										className={`CloseSmallIcon`}
									/>
								</Suspense>
								<span
									className="underline-item"
									role="button"
									onClick={handleOnReset}
									tabIndex={0}
								>
									{t({ id: 'modal.review.reset' })}
								</span>
							</div>
						</div>
						<div className="vinisto-add-review__main__left__sliders">
							<div
								className={cx('vinisto-wine-profile__property', {
									empty: !isSweetDrySelected,
								})}
							>
								<div className="vinisto-wine-profile__property__heading">
									<span className="vinisto-wine-profile__property__min">
										{t({
											id: 'productDetail.slider.sweet',
										})}
									</span>
									<span className="vinisto-wine-profile__property__max">
										{t({ id: 'productDetail.slider.dry' })}
									</span>
								</div>
								<ReactSlider
									className="vinisto-slider"
									thumbClassName="vinisto-thumb"
									trackClassName="vinisto-track"
									defaultValue={sweetDryDefaultValue}
									ref={sweetDrySlider}
									key={sweetDrySliderKey}
									onChange={handleOnSliderChange(setIsSweetDrySelected)}
								/>
							</div>
							<div
								className={cx('vinisto-wine-profile__property', {
									empty: !isLightHeavySelected,
								})}
							>
								<div className="vinisto-wine-profile__property__heading">
									<span className="vinisto-wine-profile__property__min">
										{t({
											id: 'productDetail.slider.light',
										})}
									</span>
									<span className="vinisto-wine-profile__property__max">
										{t({ id: 'productDetail.slider.bold' })}
									</span>
								</div>
								<ReactSlider
									className="vinisto-slider"
									thumbClassName="vinisto-thumb"
									trackClassName="vinisto-track"
									defaultValue={lightHeavyDefaultValue}
									ref={lightHeavySlider}
									key={lightHeavySliderKey}
									onChange={handleOnSliderChange(setIsLightHeavySelected)}
								/>
							</div>
							<div
								className={cx('vinisto-wine-profile__property', {
									empty: !isFruitTannicSelected,
								})}
							>
								<div className="vinisto-wine-profile__property__heading">
									<span className="vinisto-wine-profile__property__min">
										{t({
											id: 'productDetail.slider.fruity',
										})}
									</span>
									<span className="vinisto-wine-profile__property__max">
										{t({
											id: 'productDetail.slider.savory',
										})}
									</span>
								</div>
								<ReactSlider
									className="vinisto-slider"
									thumbClassName="vinisto-thumb"
									trackClassName="vinisto-track"
									defaultValue={fruitTannicDefaultValue}
									ref={fruitTannicSlider}
									key={fruitTannicSliderKey}
									onChange={handleOnSliderChange(setIsFruitTannicSelected)}
								/>
							</div>
							<div
								className={cx('vinisto-wine-profile__property', {
									empty: !isLowHighAcciditySelected,
								})}
							>
								<div className="vinisto-wine-profile__property__heading">
									<span className="vinisto-wine-profile__property__min">
										{t({
											id: 'productDetail.slider.lowAcid',
										})}
									</span>
									<span className="vinisto-wine-profile__property__max">
										{t({
											id: 'productDetail.slider.highAcid',
										})}
									</span>
								</div>
								<ReactSlider
									className="vinisto-slider"
									thumbClassName="vinisto-thumb"
									trackClassName="vinisto-track"
									defaultValue={lowHighAccidityDefaultValue}
									ref={lowHighAcciditySlider}
									key={lowHighAcciditySliderKey}
									onChange={handleOnSliderChange(setIsLowHighAcciditySelected)}
								/>
							</div>
						</div>
					</div>
				</div>
			</Form>
		</div>
	);
};

export default ReviewModal;
