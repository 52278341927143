export const SPECIFICATION_API_URI = 'product-api/specifications';

export const TYPE_TEXT = 'TEXT';
export const TYPE_COMBO_BOX = 'COMBO_BOX';
export const TYPE_MULTI_COMBO_BOX = 'MULTI_COMBO_BOX';
export const TYPE_CHECK_BOX = 'CHECK_BOX';
export const TYPE_NUMBER = 'NUMBER';
export const TYPE_NUMBER_IMPERIAL = 'NUMBER_IMPERIAL';
export const TYPE_DECIMAL_NUMBER = 'DECIMAL_NUMBER';
export const TYPE_DECIMAL_NUMBER_IMPERIAL = 'DECIMAL_NUMBER_IMPERIAL';
export const TYPE_PRICE = 'PRICE';

export enum SpecificationType {
	CHECK_BOX = 'CHECK_BOX',
	MULTI_COMBO_BOX = 'MULTI_COMBO_BOX',
	COMBO_BOX = 'COMBO_BOX',
	NUMBER = 'NUMBER',
	NUMBER_IMPERIAL = 'NUMBER_IMPERIAL',
	DECIMAL_NUMBER = 'DECIMAL_NUMBER',
	DECIMAL_NUMBER_IMPERIAL = 'DECIMAL_NUMBER_IMPERIAL',
	TEXT = 'TEXT',
}
