import { useCallback, useContext } from 'react';
import { LocalizationContext } from 'Services/LocalizationService';

import { LangValuePair } from './interfaces';
import { FALLBACK_LANGUAGE } from './constants';

const useLocalizedValue = () => {
	const { activeLanguageKey = FALLBACK_LANGUAGE } =
		useContext(LocalizationContext);

	return useCallback(
		(list: LangValuePair[] = [], language: string = activeLanguageKey) => {
			if (typeof list === 'undefined') return '';

			const item = list.find((it) => it.language === language);
			return item ? item.value ?? '' : '';
		},
		[activeLanguageKey]
	);
};

export default useLocalizedValue;

export function isLangValuePairArray(obj: any): obj is LangValuePair[] {
	return (
		Array.isArray(obj) &&
		obj.every(
			(element) =>
				typeof element === 'object' &&
				typeof element.language === 'string' &&
				typeof element.value === 'string'
		)
	);
}
