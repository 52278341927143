import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { BundleService } from 'vinisto_api_client';

const useUpdateLastViewedRecord = ({ bundleId }: { bundleId: string }) => {
	const [visitedBundleIds, setVisitedBundlesIds] = useState<Set<string>>(
		() => new Set()
	);

	useEffect(() => {
		if (!visitedBundleIds.has(bundleId)) {
			setVisitedBundlesIds((prev) => new Set(prev).add(bundleId));
		}
	}, [bundleId, visitedBundleIds]);

	const updateLastViewedRecordMutation = useMutation(
		BundleService.updateLastViewedRecord
	);

	return {
		updateRecord: updateLastViewedRecordMutation.mutateAsync,
		hasBeenAlreadyUpdated: visitedBundleIds.has(bundleId),
	};
};

export default useUpdateLastViewedRecord;
