import { BasketService, BundleService } from 'vinisto_api_client';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { LocalizationContext } from 'Services/LocalizationService';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { BasketContext } from 'Services/BasketService';

import { bundleAdapter } from '@/index';
import { VinistoBasketDllModelsApiBasketBasket } from '@/api-types/basket-api';
import { Bundle } from '@/domain/bundle';

export const useShippingPackaging = () => {
	const { activeCurrency, countryOfSale } = useContext(LocalizationContext);

	return useQuery<Bundle[]>(
		['shipping-packaging', activeCurrency.currency],
		async () => {
			const { bundles } = await BundleService.getPackagingBundles({
				countryOfSale,
				currency: activeCurrency.currency,
			});
			if (!bundles) return [];

			return bundles?.map((bundle) =>
				bundleAdapter.fromApi(bundle, {
					currency: activeCurrency.currency,
				})
			);
		}
	);
};

export const useChangePackaging = () => {
	const {
		setSelectedShippingPackaging,
		setOptimisticSelectedShippingPackaging,
		handleOnLoadBasket,
		setBasketState,
	} = useContext(BasketContext);
	const { activeCurrency, countryOfSale } = useContext(LocalizationContext);
	const { isLoggedIn, vinistoUser, anonymousUID } = useContext(
		AuthenticationContext
	);
	const userLoginHash = vinistoUser.loginHash;
	const currentActiveCurrency = activeCurrency.currency;
	const anonymousUserId = anonymousUID.anonymousUserId ?? '';

	const { data: shippingPackaging } = useShippingPackaging();

	return useMutation<
		VinistoBasketDllModelsApiBasketBasket,
		Error,
		string | null
	>({
		mutationFn: async (bundleId: string | null) => {
			const requestData = {
				...(isLoggedIn
					? { userLoginHash }
					: {
							anonymousUserId,
					  }),
				Currency: currentActiveCurrency,
				CountryOfSale: countryOfSale,
				bundleId,
			};
			const { basket } = await BasketService.changePackaging(requestData);
			if (!basket) throw new Error();

			return basket;
		},
		// Optimistic update so the UI is updated immediately no matter how fast the backend responds
		onMutate: (bundleId) => {
			if (!bundleId) {
				setSelectedShippingPackaging(null);
				return;
			}

			const bundle =
				shippingPackaging?.find((bundle) => bundle.id === bundleId) ?? null;

			setOptimisticSelectedShippingPackaging(bundle);
			return;
		},
		// Once backend responds, we use returned basket data to repaint the basket
		onSuccess: (data) => {
			setBasketState(data);
		},
		// If backend responds with error, we restore the basket to its previous state by refetching it
		onError: () => {
			handleOnLoadBasket();
		},
	});
};
