import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import Config from 'Config';
import { ENVIRONMENT } from 'Config/environments';

Sentry.init({
	dsn: 'https://896a9a5ed69e4e22ad50b1a8b51ef92a@sentry.merkatos.dev/19',
	integrations: [new BrowserTracing(), new Sentry.Replay()],
	environment: Config?.environment ?? ENVIRONMENT.DEVELOPMENT,

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: Config?.environment === ENVIRONMENT.PRODUCTION ? 0.8 : 1.0,
	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate:
		Config?.environment === ENVIRONMENT.PRODUCTION ? 0.1 : 1.0,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,
	beforeSend: (event) => {
		// Don't log localhost
		if (window.location.hostname === 'localhost') {
			return null;
		}
		return event;
	},
});
