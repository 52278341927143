import { FC } from 'react';

import { IIconProps } from './Interfaces';

const HeaderPencilIcon: FC<IIconProps> = ({ className }) => {
	return (
		<svg
			width={13.991}
			height={13.985}
			viewBox="0 0 13.991 13.985"
			className={className}
		>
			<g transform="translate(-405.304 -121.36)">
				<path
					d="M415.788,126.985l.867.865c-.634.638-1.26,1.278-1.9,1.909-1.343,1.328-2.687,2.664-4.047,3.979a1.854,1.854,0,0,1-.717.424c-1.28.392-2.568.761-3.857,1.124-.686.193-.967-.093-.774-.772q.571-2.028,1.172-4.041a.92.92,0,0,1,.218-.373q3-3,6.02-6a.462.462,0,0,1,.078-.052l.7.732c-.781.778-1.585,1.572-2.379,2.369q-1.716,1.713-3.426,3.433a.928.928,0,0,0-.223.366c-.221.716-.411,1.442-.644,2.153-.093.288.126.373.247.522a.353.353,0,0,0,.434.148c.672-.217,1.356-.4,2.031-.61a1.031,1.031,0,0,0,.387-.254q2.818-2.807,5.627-5.627a1.582,1.582,0,0,0,.186-.3"
					fill="#4d4d4e"
				/>
				<path
					d="M414.114,125.511l.868.909-5.931,6.034c-.094-.252-.112-.559-.28-.7a1.757,1.757,0,0,0-.691-.2l6.034-6.044"
					fill="#4d4d4e"
				/>
				<path
					d="M414.747,122.228c.417-.386.771-.956,1.491-.856a1.476,1.476,0,0,1,.766.313c.682.63,1.333,1.3,1.976,1.967a1.221,1.221,0,0,1,.074,1.549c-.2.257-.43.486-.636.715l-3.671-3.688"
					fill="#4d4d4e"
				/>
				<path
					d="M413.25,123.568l.783-.731,3.8,3.841-.761.722-3.823-3.832"
					fill="#4d4d4e"
				/>
			</g>
		</svg>
	);
};

export default HeaderPencilIcon;
