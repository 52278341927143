import { useContext } from 'react';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { GiftItemProps } from 'Pages/Cart/Components/GiftItemDesktop/interfaces';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';
import { Link } from 'react-router-dom';
import getBundleImage from 'Helpers/getBundleImage';

import styles from './styles.module.css';

const GiftItemMobile = ({
	orderItemData: { bundle, amount },
	noBorder,
}: GiftItemProps) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const deviceServiceContext = useContext(DeviceServiceContext);
	const getLocalizedValue = useLocalizedValue();
	const { shortVariety: producerName, component: flag } = getFlagSpecification(
		bundle?.specificationDetails ?? []
	);

	// Why isDesktop is used in GiftItemMOBILE?
	const isDesktop =
		deviceServiceContext.isDesktop || deviceServiceContext.isDesktopBig;

	const supplierName = bundle.supplier?.nameWeb ?? '';
	const isLoading = bundle === null;

	return (
		<div
			className={cx(
				'vinisto-user-orders__orders__order-body__item',
				styles.giftItemItemBody,
				{
					[styles.desktopOnly]: isDesktop,
				},
				{
					[styles.noBorder]: noBorder,
				}
			)}
		>
			{!isLoading && (
				<Link
					className="vinisto-wine__item-overlay"
					to={`/${t({
						id: 'routes.product.route',
					})}/${getLocalizedValue(bundle.url ?? [])}`}
				/>
			)}
			<div
				className={cx(
					'vinisto-user-orders__orders__order-body__item__info',
					styles.giftItemItemInfoBody
				)}
			>
				<div
					className={cx(
						'vinisto-user-orders__orders__order-body__item__info__img',
						styles.giftItemItemInfoImage
					)}
				>
					{isLoading ? (
						<Skeleton
							height="80px"
							width="80%"
						/>
					) : (
						<img
							src={getBundleImage(bundle.images ?? [], 'thumb_64x80')}
							alt={`${t({ id: 'alt.bundleImage' })}`}
						/>
					)}
				</div>
				<div
					className={cx(
						'vinisto-user-orders__orders__order-body__item__info__data',
						styles.giftItemItemInfoData
					)}
				>
					<div className={cx(styles.giftItemItemInfoDataName)}>
						{isLoading ? <Skeleton /> : bundle.name}
					</div>
					{isLoading ? (
						<Skeleton width="90%" />
					) : (
						<>
							<div
								className={cx(
									'vinisto-user-orders__orders__order-body__item__info__data__winery',
									styles.giftItemItemInfoDataWinery
								)}
							>
								<BundleProducer
									flag={flag}
									name={producerName}
								/>
							</div>
							<div className={styles.giftItemPriceSupplier}>
								<div>
									<div className={styles.giftItemPrice}>
										{`${t(
											{ id: 'basket.pcs' },
											{
												count: amount,
											}
										)}`}
									</div>
									<div className={styles.giftItemSupplier}>
										{t(
											{ id: 'bundle.supplierSmallShort.name' },
											{
												name: (
													<span className={styles.giftItemSupplierName}>
														{supplierName.length > 0
															? supplierName
															: t({
																	id: 'productDetail.seller.name.others',
															  })}
													</span>
												),
											}
										)}
									</div>
								</div>
								<div className={cx(styles.giftItemPriceBody)}>
									<div className={cx(styles.giftItemPriceTotal)}>
										{`${t({ id: 'giftInfo.price.free' })}`}
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default GiftItemMobile;
