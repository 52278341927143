import { useCallback, useContext, useEffect, useState } from 'react';
import { debounce } from 'lodash-es';
import { BasketContext } from 'Services/BasketService';
import { WarehouseContext } from 'Services/WarehouseService';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { useFindBundleInBasket } from 'Pages/Bundle/hooks';
import { LocalizationContext } from 'Services/LocalizationService';

import { Bundle } from '@/domain/bundle';

export const useDirectQuantityBox = (bundle: Bundle | null | undefined) => {
	const { handleOnChangeItemQuantity, isUpdatingCount } =
		useContext(BasketContext);
	const {
		activeCurrency: { currency },
	} = useContext(LocalizationContext);
	const { getQuantity } = useContext(WarehouseContext);
	const { loginHash } = useContext(AuthenticationContext).vinistoUser;

	const availableCount = getQuantity(bundle?.id ?? '');

	const itemInBasket = useFindBundleInBasket({ bundleId: bundle?.id });

	const quantityInBasket = itemInBasket?.quantity ?? 0;

	const bundlePrices = bundle?.bundlePrices;

	const { isDiscounted, basePrice, discountedPrice } = bundlePrices ?? {};

	const priceWithVat = isDiscounted
		? discountedPrice?.valueWithVat
		: basePrice?.valueWithVat;

	const [count, setCount] = useState(quantityInBasket);

	const addToBasketImmediately = (
		count: number,
		callback?: (result: boolean) => void
	) =>
		handleOnChangeItemQuantity(count, bundle?.id as string).then((result) => {
			if (typeof callback === 'function') {
				callback(result);
			}
			return result;
		});

	const addToBasket = useCallback(
		debounce((count, callback?: (result: boolean) => void) => {
			addToBasketImmediately(count, callback);
		}, 500),
		[bundle?.id, loginHash, currency]
	);

	const onCountChange = (
		value: string,
		callback?: (result: boolean) => void
	) => {
		if (
			value?.length > 0 &&
			value?.split('')?.some((number: string) => Number.isNaN(parseInt(number)))
		) {
			return;
		} else if (value === '') {
			setCount(-1);
		} else if (
			!Number.isNaN(parseInt(value)) &&
			typeof availableCount === 'number' &&
			parseInt(value) > availableCount
		) {
			setCount(availableCount);
			addToBasket(availableCount, callback);
		} else if (
			!Number.isNaN(parseInt(value)) &&
			typeof availableCount === 'number' &&
			parseInt(value) < 0
		) {
			setCount(0);
			addToBasket(0, callback);
		} else {
			setCount(parseInt(value));
			addToBasket(parseInt(value), callback);
		}
	};

	const onBlur = (callback?: (result: boolean) => void) => {
		if (
			typeof availableCount === 'number' &&
			availableCount > 0 &&
			count === -1
		) {
			setCount(0);
			addToBasket(0, callback);
		}
	};

	const onIncrement = (callback?: (result: boolean) => void) => {
		if (
			typeof availableCount === 'number' &&
			count < availableCount &&
			!isUpdatingCount
		) {
			setCount((count) => {
				addToBasket(count + 1, callback);
				return count + 1;
			});
		}
	};

	const onDecrement = (callback?: (result: boolean) => void) => {
		if (typeof availableCount === 'number' && count > 0 && !isUpdatingCount) {
			setCount((count) => {
				addToBasket(count - 1, callback);
				return count - 1;
			});
		}
	};

	const onAddToBasket = (callback?: (result: boolean) => void) => {
		setCount(1);
		return addToBasketImmediately(1, callback);
	};

	useEffect(() => {
		if (typeof quantityInBasket === 'number' && count !== quantityInBasket) {
			setCount(quantityInBasket);
		}
		// Adding "count" to the dependency array would cause break the functionality as "count" is synchronously updated
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quantityInBasket]);

	return {
		count,
		onIncrement,
		onDecrement,
		onCountChange,
		onBlur,
		onAddToBasket,
		availableCount,
		quantityInBasket,
		bundlePriceWithVat: priceWithVat,
	};
};
