import { v4 as uuidv4 } from 'uuid';
import { dayjsInstance as dayjs } from 'Services/Date';

export const generateUniqueUserHash = () => {
	return {
		anonymousUserId: `${uuidv4()}-${uuidv4()}-${dayjs().format(
			'YYYY-MM-DD-HH:mm:ss'
		)}`,
		initial_timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss'),
		expiresOn: dayjs().add(365, 'days').format('YYYY-MM-DD HH:mm:ss'),
	};
};
