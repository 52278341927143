import { useContext } from 'react';
import cx from 'classnames';
import CarouselArticles from 'Components/CarouselArticles';
import { HOME_PAGE_ARTICLES_CAROUSEL } from 'Components/CarouselArticles/constants';
import { DeviceServiceContext } from 'Services/DeviceService';
import BlogService, {
	GetPreviewListError,
	GetPreviewListSuccess,
} from 'Services/ApiService/Cms/Blog';
import { useQuery } from '@tanstack/react-query';
import { CmsPostPublishedListQueryArgument } from 'Services/ApiService/Cms/interfaces';
import { VinistoHelperDllEnumsCmsArticleSortableColumns } from 'vinisto_api_client/src/api-types/cms-api';
import { CMS_QUERY_TYPES } from 'Services/ApiService/Cms/constants';
import { LocalizationContext } from 'Services/LocalizationService';
import { Link } from 'react-router-dom';
import useArticleTitleImages from 'Hooks/Queries/useArticleTitleImages';

import ArticleInfo from './ArticleInfo';
import styles from './styles.module.css';

const ArticleTabs = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	const req: CmsPostPublishedListQueryArgument[] = [
		{
			key: 'Limit',
			value: 4,
		},
		{
			key: 'IsSortingDescending',
			value: true,
		},
		{
			key: 'SortingColumn',
			value: VinistoHelperDllEnumsCmsArticleSortableColumns.PUBLISH_DATE,
		},
	];

	const { getPreviewList } = BlogService;
	const { isLoading, data } = useQuery<
		GetPreviewListSuccess,
		GetPreviewListError
	>([`${CMS_QUERY_TYPES.POSTS_PREVIEW}, ${req}`], () => getPreviewList(req), {
		staleTime: 10 * 60 * 1000,
	});

	const deviceServiceContext = useContext(DeviceServiceContext);

	const articles = data?.articles;

	const { isLoading: isImagesLoading, data: images } = useArticleTitleImages(
		articles?.map((article) => article?.titleImageId) ?? []
	);

	return (
		<>
			{deviceServiceContext.isMobile ? (
				<div className="col">
					<div className={styles.newsHeading}>
						{t({ id: 'productDetail.news.heading' })}
					</div>
					<div className="vinisto-card overflow-hidden vinisto-articles pb-0">
						<CarouselArticles
							carouselType={HOME_PAGE_ARTICLES_CAROUSEL}
							data={articles}
							isLoading={isLoading}
						/>
					</div>
					<div className="text-center">
						<Link
							to="/blog/tag/blog"
							className={styles.blogLink}
						>
							{t({ id: 'productDetail.news.link' })}
						</Link>
					</div>
				</div>
			) : (
				<div className="col">
					<div className={styles.newsHeading}>
						{t({ id: 'productDetail.news.heading' })}
						<Link
							to="/blog/tag/blog"
							className={styles.blogLink}
						>
							{t({ id: 'productDetail.news.link' })}
						</Link>
					</div>
					<div className={cx(styles.articlesWrap, { loading: isLoading })}>
						{articles?.map((article, index) => (
							<ArticleInfo
								data={article}
								key={index}
								isImageLoading={isImagesLoading}
								imageData={images?.[index] ?? null}
							/>
						))}
					</div>
				</div>
			)}
		</>
	);
};

export default ArticleTabs;
