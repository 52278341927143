import { LocalizationContext } from 'Services/LocalizationService';
import { useContext } from 'react';

import styles from './styles.module.css';

const NotCombinableMessage = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	return (
		<div className={styles.component}>
			{t({
				id: 'notification.message.discountCouponAdd.discountCouponNotCombinable',
			})}
		</div>
	);
};

export default NotCombinableMessage;
