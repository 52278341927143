import HeaderVinistotekaIcon from 'Components/Icons/HeaderVinistoteka';
import HeaderBottleDestilIcon from 'Components/Icons/HeaderBottleDestil';
import HeaderPeopleIcon from 'Components/Icons/HeaderPeople';
import HeaderPencilIcon from 'Components/Icons/HeaderPencil';
import HeaderSomelierIcon from 'Components/Icons/HeaderSomelier';
import HeaderBottleWineIcon from 'Components/Icons/HeaderBottleWine';
import HeaderContactIcon from 'Components/Icons/HeaderContact';
import { useContext, useMemo } from 'react';
import { LocalizationContext } from 'Services/LocalizationService';

const useNavLinks = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	const links = useMemo(
		() => [
			{
				to: `https://b2b.vinisto.cz`,
				name: t({ id: 'routes.horeca.name' }),
			},
			{
				to: `/${t({ id: 'routes.sommelierAdvice.route' })}`,
				icon: <HeaderSomelierIcon />,
				name: t({ id: 'routes.sommelierAdvice.name' }),
			},
			{
				to: `/${t({ id: 'routes.blog.route' })}/tag/vino-a-jidlo`,
				icon: <HeaderPencilIcon />,
				name: t({ id: 'routes.blogWineAndFood.name' }),
			},
			{
				to: `/${t({ id: 'routes.vinistoteque.route' })}`,
				icon: <HeaderVinistotekaIcon />,
				name: t({ id: 'routes.vinistoteka.name' }),
			},
			{
				to: `/${t({ id: 'routes.community.route' })}/tag/prodejci`,
				icon: <HeaderPeopleIcon />,
				name: t({ id: 'routes.sellers.name' }),
			},
			{
				to: `/${t({ id: 'routes.community.route' })}/tag/vinarstvi`,
				icon: <HeaderBottleWineIcon />,
				name: t({ id: 'routes.wineries.name' }),
			},
			{
				to: `/${t({ id: 'routes.community.route' })}/tag/destilerie`,
				icon: <HeaderBottleDestilIcon />,
				name: t({ id: 'routes.destilery.name' }),
			},
			{
				to: `/${t({ id: 'routes.contact.route' })}`,
				icon: <HeaderContactIcon />,
				name: t({ id: 'routes.contact.name' }),
			},
		],
		[t]
	);

	return links;
};

export default useNavLinks;
