import { createContext, useEffect, useMemo, useReducer } from 'react';
import { debounce, noop } from 'lodash-es';

import { IDeviceServiceModel, IDeviceServiceProps } from './interfaces';
import {
	DeviceServiceAction,
	DIMENSIONS,
	RESIZE_EVENT,
	RESIZE_HANDLER_DELAY,
} from './constants';
import { DeviceServiceModelReducer } from './reducer';

const defaultDeviceServiceModel: IDeviceServiceModel = {
	isDesktopBig: document.documentElement.clientWidth >= DIMENSIONS.DESKTOP_BIG,
	isDesktop: document.documentElement.clientWidth >= DIMENSIONS.DESKTOP,
	isTablet:
		document.documentElement.clientWidth < DIMENSIONS.DESKTOP &&
		document.documentElement.clientWidth >= DIMENSIONS.TABLET,
	isMobile: document.documentElement.clientWidth < DIMENSIONS.TABLET,
	layoutHeight: document.documentElement.clientHeight,
	layoutWidth: document.documentElement.clientWidth,
	headerHeight: 0,
	footerHeight: 0,
	isFooterInViewPort: false,
	sellerSelectionFooterHeight: 0,
	dispatch: noop,
};

export const DeviceServiceContext = createContext(defaultDeviceServiceModel);

const vinistoEshopRoot = document.getElementById('vinisto_eshop_root');

const DeviceServiceProvider = ({ children }: IDeviceServiceProps) => {
	const [deviceServiceModel, dispatch] = useReducer(
		DeviceServiceModelReducer,
		defaultDeviceServiceModel
	);

	useEffect(() => {
		const handleOnResize = () => {
			dispatch([
				DeviceServiceAction.setDeviceInfo,
				{
					isDesktopBig:
						document.documentElement.clientWidth >= DIMENSIONS.DESKTOP_BIG,
					isDesktop: document.documentElement.clientWidth >= DIMENSIONS.DESKTOP,
					isTablet:
						document.documentElement.clientWidth < DIMENSIONS.DESKTOP &&
						document.documentElement.clientWidth >= DIMENSIONS.TABLET,
					isMobile: document.documentElement.clientWidth < DIMENSIONS.TABLET,
					layoutHeight: document.documentElement.clientHeight,
					layoutWidth: document.documentElement.clientWidth,
				},
			]);
		};
		const debouncedHandler = debounce(handleOnResize, RESIZE_HANDLER_DELAY);
		window.addEventListener(RESIZE_EVENT, debouncedHandler);

		return () => {
			window.removeEventListener(RESIZE_EVENT, debouncedHandler);
		};
	}, []);

	useEffect(() => {
		if (!vinistoEshopRoot) return;
		if (!deviceServiceModel.isMobile && !deviceServiceModel.isTablet) {
			vinistoEshopRoot.style.paddingBottom = '';
			return;
		}
		if (deviceServiceModel.footerHeight) {
			vinistoEshopRoot.style.paddingBottom = `${deviceServiceModel.sellerSelectionFooterHeight}px`;
		}
	}, [
		deviceServiceModel.footerHeight,
		deviceServiceModel.isMobile,
		deviceServiceModel.isTablet,
		deviceServiceModel.sellerSelectionFooterHeight,
	]);

	const deviceContextModel: IDeviceServiceModel = useMemo(
		() => ({
			...deviceServiceModel,
			dispatch,
		}),
		[deviceServiceModel]
	);

	return (
		<DeviceServiceContext.Provider value={deviceContextModel}>
			{children}
		</DeviceServiceContext.Provider>
	);
};

export default DeviceServiceProvider;
