export const BASE_FA_ICONS_URL = '/assets/font-awesome';

const FAS = 'fas';
const FAR = 'far';

export const PATH_PREFIX_MAP = {
	[FAS]: 'solid',
	[FAR]: 'regular',
};

const solidIconsPrefix = 'fas';
const regularIconsPrefix = 'far';

const soldIconsPathPrefix = 'solid';
const regularIconsPathPrefix = 'regular';

const solidIcons = new Set([
	'arrow-circle-right',
	'grin-beam',
	'grin-squint-tears',
	'underline',
	'quidditch',
	'globe-africa',
	'hand-lizard',
	'battery-quarter',
	'tshirt',
	'clipboard',
	'chevron-circle-up',
	'tram',
	'bell-slash',
	'skating',
	'car',
	'mercury',
	'feather',
	'chevron-up',
	'box-open',
	'industry',
	'project-diagram',
	'splotch',
	'grin-tongue-wink',
	'balance-scale-left',
	'qrcode',
	'chevron-right',
	'user-times',
	'building',
	'hourglass',
	'hat-wizard',
	'user-astronaut',
	'bug',
	'folder-minus',
	'sink',
	'medal',
	'vote-yea',
	'house-user',
	'phone',
	'car-crash',
	'road',
	'stroopwafel',
	'stream',
	'arrow-up',
	'file-import',
	'kaaba',
	'fill-drip',
	'filter',
	'pound-sign',
	'lira-sign',
	'gifts',
	'arrow-alt-circle-left',
	'chess-bishop',
	'eye-slash',
	'sort-amount-down-alt',
	'drum',
	'store-alt-slash',
	'unlock-alt',
	'guitar',
	'plane-departure',
	'kiwi-bird',
	'graduation-cap',
	'battery-half',
	'caret-square-down',
	'hot-tub',
	'calendar-plus',
	'share-alt',
	'trademark',
	'euro-sign',
	'times',
	'chevron-circle-right',
	'quote-right',
	'caret-up',
	'snowman',
	'sign-in-alt',
	'percent',
	'headphones',
	'compact-disc',
	'comment-dollar',
	'biking',
	'bookmark',
	'grin-tongue',
	'gopuram',
	'rss',
	'fingerprint',
	'smoking',
	'file-alt',
	'laptop-house',
	'memory',
	'biohazard',
	'calculator',
	'fan',
	'copyright',
	'hotel',
	'wrench',
	'arrows-alt-h',
	'list-alt',
	'menorah',
	'tenge',
	'sun',
	'head-side-cough-slash',
	'kiss',
	'vial',
	'file-excel',
	'tablet-alt',
	'trash-restore-alt',
	'star-half',
	'dungeon',
	'radiation-alt',
	'football-ball',
	'archive',
	'transgender-alt',
	'sd-card',
	'user-friends',
	'fire',
	'calendar-minus',
	'plus-square',
	'sort-alpha-down-alt',
	'keyboard',
	'yen-sign',
	'dumbbell',
	'cogs',
	'ethernet',
	'sort-numeric-down-alt',
	'bible',
	'ticket-alt',
	'temperature-high',
	'universal-access',
	'globe',
	'backward',
	'venus',
	'user-tie',
	'life-ring',
	'globe-americas',
	'infinity',
	'bullseye',
	'less-than',
	'paint-brush',
	'wifi',
	'sort-alpha-down',
	'money-bill-alt',
	'magnet',
	'blender',
	'thumbtack',
	'less-than-equal',
	'chevron-down',
	'hospital-alt',
	'praying-hands',
	'parachute-box',
	'coffee',
	'portrait',
	'check',
	'edit',
	'pump-soap',
	'restroom',
	'minus-circle',
	'soap',
	'stamp',
	'grin-tears',
	'glass-martini-alt',
	'meh',
	'grin-hearts',
	'table-tennis',
	'umbrella',
	'car-battery',
	'ring',
	'voicemail',
	'microphone',
	'file-pdf',
	'snowflake',
	'eject',
	'exclamation-triangle',
	'solar-panel',
	'id-card-alt',
	'file-archive',
	'carrot',
	'sort-down',
	'question-circle',
	'object-ungroup',
	'h-square',
	'pen-fancy',
	'window-maximize',
	'torah',
	'sort',
	'cannabis',
	'crosshairs',
	'deaf',
	'long-arrow-alt-up',
	'bone',
	'balance-scale-right',
	'thermometer-three-quarters',
	'viruses',
	'square-full',
	'medkit',
	'map-marker-alt',
	'flask',
	'hand-point-right',
	'crutch',
	'glasses',
	'gift',
	'headset',
	'fast-backward',
	'cloud',
	'bahai',
	'film',
	'bell',
	'monument',
	'object-group',
	'tired',
	'file-upload',
	'mars-double',
	'clock',
	'umbrella-beach',
	'ice-cream',
	'beer',
	'superscript',
	'heartbeat',
	'sync',
	'dice-d6',
	'image',
	'volume-up',
	'pencil-alt',
	'prescription',
	'signal',
	'shuttle-van',
	'laptop-code',
	'stopwatch-20',
	'circle',
	'laptop',
	'male',
	'history',
	'wine-glass-alt',
	'syringe',
	'map-pin',
	'pump-medical',
	'hand-point-left',
	'play-circle',
	'users',
	'window-restore',
	'angle-right',
	'water',
	'hand-paper',
	'hand-sparkles',
	'screwdriver',
	'ruler-combined',
	'times-circle',
	'child',
	'lock',
	'id-badge',
	'truck-monster',
	'egg',
	'pencil-ruler',
	'mobile',
	'kiss-beam',
	'store',
	'award',
	'thermometer-half',
	'signature',
	'otter',
	'long-arrow-alt-down',
	'mountain',
	'level-down-alt',
	'book-reader',
	'kiss-wink-heart',
	'shopping-cart',
	'sign-out-alt',
	'smoking-ban',
	'flag',
	'user-plus',
	'binoculars',
	'greater-than-equal',
	'frown',
	'code-branch',
	'ellipsis-h',
	'heading',
	'plus',
	'car-alt',
	'chess-rook',
	'chess-knight',
	'icons',
	'street-view',
	'sort-numeric-down',
	'user-cog',
	'border-all',
	'arrow-alt-circle-right',
	'prescription-bottle-alt',
	'angle-double-left',
	'grip-horizontal',
	'bed',
	'inbox',
	'hdd',
	'border-none',
	'caret-right',
	'chalkboard-teacher',
	'mars',
	'dog',
	'camera-retro',
	'receipt',
	'ruble-sign',
	'toggle-on',
	'i-cursor',
	'phone-square',
	'flag-checkered',
	'digital-tachograph',
	'band-aid',
	'border-style',
	'battery-empty',
	'hiking',
	'retweet',
	'temperature-low',
	'hands-wash',
	'pen-nib',
	'landmark',
	'file-signature',
	'hand-holding-heart',
	'sim-card',
	'poop',
	'trailer',
	'expand-alt',
	'wheelchair',
	'prescription-bottle',
	'chevron-circle-down',
	'arrow-right',
	'baseball-ball',
	'globe-europe',
	'cloud-showers-heavy',
	'joint',
	'quote-left',
	'head-side-mask',
	'radiation',
	'republican',
	'pager',
	'bomb',
	'arrow-circle-left',
	'female',
	'cubes',
	'horse-head',
	'rupee-sign',
	'file-image',
	'paperclip',
	'sort-alpha-up',
	'user-check',
	'hourglass-half',
	'sticky-note',
	'grin-squint',
	'microphone-alt',
	'luggage-cart',
	'cash-register',
	'print',
	'bacteria',
	'minus',
	'bacon',
	'arrow-alt-circle-down',
	'undo-alt',
	'burn',
	'font-awesome-logo-full',
	'desktop',
	'cloud-sun-rain',
	'holly-berry',
	'caret-square-right',
	'chess',
	'wallet',
	'plane',
	'laptop-medical',
	'parking',
	'external-link-alt',
	'om',
	'flushed',
	'basketball-ball',
	'hotdog',
	'ambulance',
	'bold',
	'credit-card',
	'mobile-alt',
	'procedures',
	'route',
	'cookie',
	'campground',
	'angle-up',
	'ankh',
	'laugh-beam',
	'calendar-day',
	'volleyball-ball',
	'chart-line',
	'tape',
	'camera',
	'eye',
	'clinic-medical',
	'gavel',
	'microphone-alt-slash',
	'users-cog',
	'dumpster',
	'long-arrow-alt-right',
	'pen-square',
	'address-book',
	'star-and-crescent',
	'golf-ball',
	'arrow-circle-up',
	'suitcase-rolling',
	'redo-alt',
	'at',
	'socks',
	'dice-d20',
	'laugh-squint',
	'baby-carriage',
	'indent',
	'swimming-pool',
	'utensil-spoon',
	'ribbon',
	'book',
	'phone-square-alt',
	'fist-raised',
	'jedi',
	'microchip',
	'laugh-wink',
	'people-carry',
	'hand-rock',
	'atom',
	'sad-cry',
	'map-signs',
	'check-circle',
	'satellite-dish',
	'store-alt',
	'sync-alt',
	'text-height',
	'drafting-compass',
	'neuter',
	'angle-double-right',
	'broadcast-tower',
	'yin-yang',
	'user-circle',
	'lock-open',
	'map-marked-alt',
	'plane-arrival',
	'calendar-alt',
	'draw-polygon',
	'rocket',
	'fill',
	'percentage',
	'dice-four',
	'file-invoice',
	'registered',
	'bullhorn',
	'briefcase',
	'head-side-virus',
	'dollar-sign',
	'concierge-bell',
	'remove-format',
	'vest',
	'wind',
	'question',
	'money-bill-wave-alt',
	'pallet',
	'wine-bottle',
	'images',
	'wine-glass',
	'info-circle',
	'chair',
	'grip-vertical',
	'divide',
	'forward',
	'sort-numeric-up',
	'weight',
	'cheese',
	'file-powerpoint',
	'dolly-flatbed',
	'stop-circle',
	'vihara',
	'bars',
	'tint-slash',
	'laugh',
	'exchange-alt',
	'magic',
	'mug-hot',
	'location-arrow',
	'book-dead',
	'money-bill',
	'map-marker',
	'star-of-david',
	'balance-scale',
	'barcode',
	'play',
	'fighter-jet',
	'bacterium',
	'ship',
	'phone-volume',
	'car-side',
	'place-of-worship',
	'meh-blank',
	'smile-beam',
	'recycle',
	'shield-alt',
	'battery-full',
	'search-minus',
	'dice-two',
	'user-graduate',
	'virus-slash',
	'marker',
	'glass-whiskey',
	'map',
	'cross',
	'sitemap',
	'reply',
	'volume-off',
	'arrows-alt-v',
	'surprise',
	'window-minimize',
	'hand-scissors',
	'battery-three-quarters',
	'crop',
	'arrow-alt-circle-up',
	'pastafarianism',
	'grin-stars',
	'bezier-curve',
	'meh-rolling-eyes',
	'square',
	'expand',
	'user-nurse',
	'align-left',
	'cart-arrow-down',
	'smile-wink',
	'pray',
	'plane-slash',
	'highlighter',
	'folder-open',
	'arrow-down',
	'teeth',
	'briefcase-medical',
	'scroll',
	'th-large',
	'box-tissue',
	'assistive-listening-systems',
	'charging-station',
	'lightbulb',
	'faucet',
	'not-equal',
	'ruler',
	'chart-area',
	'copy',
	'pills',
	'file-export',
	'smile',
	'glass-cheers',
	'tablet',
	'file-download',
	'motorcycle',
	'caret-square-left',
	'door-closed',
	'microscope',
	'bus',
	'undo',
	'file-csv',
	'ban',
	'random',
	'notes-medical',
	'spray-can',
	'volume-down',
	'tools',
	'truck-loading',
	'th',
	'hand-point-up',
	'meteor',
	'person-booth',
	'shipping-fast',
	'circle-notch',
	'venus-double',
	'folder-plus',
	'allergies',
	'sort-alpha-up-alt',
	'train',
	'pause-circle',
	'fast-forward',
	'toilet-paper-slash',
	'truck-pickup',
	'square-root-alt',
	'eye-dropper',
	'tachometer-alt',
	'puzzle-piece',
	'tags',
	'shower',
	'hospital',
	'fire-extinguisher',
	'air-freshener',
	'teeth-open',
	'lungs',
	'bicycle',
	'thermometer-empty',
	'clipboard-list',
	'ruler-vertical',
	'shopping-bag',
	'mask',
	'hands',
	'sort-amount-up',
	'sad-tear',
	'gas-pump',
	'city',
	'search',
	'thumbs-up',
	'handshake-slash',
	'globe-asia',
	'mouse-pointer',
	'tooth',
	'clipboard-check',
	'dolly',
	'mail-bulk',
	'comment-medical',
	'adjust',
	'align-right',
	'mortar-pestle',
	'cocktail',
	'minus-square',
	'ad',
	'hand-peace',
	'grin-wink',
	'layer-group',
	'search-location',
	'shoe-prints',
	'sort-amount-down',
	'heart-broken',
	'hand-point-down',
	'user',
	'passport',
	'blender-phone',
	'hand-holding-usd',
	'hourglass-start',
	'paw',
	'flag-usa',
	'cube',
	'share-alt-square',
	'sort-amount-up-alt',
	'tv',
	'file-prescription',
	'gem',
	'suitcase',
	'icicles',
	'genderless',
	'hand-holding',
	'people-arrows',
	'poll',
	'snowboarding',
	'caret-down',
	'rainbow',
	'code',
	'asterisk',
	'mars-stroke-h',
	'chart-pie',
	'phone-slash',
	'american-sign-language-interpreting',
	'external-link-square-alt',
	'cut',
	'head-side-cough',
	'book-open',
	'pepper-hot',
	'list',
	'shekel-sign',
	'poo',
	'lungs-virus',
	'grip-lines',
	'anchor',
	'glass-martini',
	'moon',
	'couch',
	'outdent',
	'door-open',
	'envelope-square',
	'brain',
	'skiing',
	'subway',
	'torii-gate',
	'newspaper',
	'grin',
	'toilet',
	'archway',
	'toggle-off',
	'frog',
	'shapes',
	'lemon',
	'record-vinyl',
	'cog',
	'cloud-download-alt',
	'satellite',
	'file-medical',
	'plus-circle',
	'chess-pawn',
	'expand-arrows-alt',
	'hourglass-end',
	'hand-holding-medical',
	'pizza-slice',
	'key',
	'hamsa',
	'hands-helping',
	'id-card',
	'dot-circle',
	'hand-holding-water',
	'tablets',
	'certificate',
	'envelope-open-text',
	'unlink',
	'fax',
	'spider',
	'candy-cane',
	'angle-double-up',
	'low-vision',
	'brush',
	'music',
	'taxi',
	'stop',
	'terminal',
	'table',
	'chalkboard',
	'atlas',
	'sign',
	'envelope-open',
	'hat-cowboy',
	'won-sign',
	'comment-slash',
	'phone-alt',
	'hryvnia',
	'photo-video',
	'quran',
	'rss-square',
	'reply-all',
	'dna',
	'capsules',
	'sms',
	'user-injured',
	'grin-tongue-squint',
	'sliders-h',
	'apple-alt',
	'futbol',
	'arrow-left',
	'swatchbook',
	'robot',
	'tasks',
	'tint',
	'compress',
	'user-tag',
	'snowplow',
	'file-contract',
	'closed-captioning',
	'baby',
	'oil-can',
	'microphone-slash',
	'bong',
	'feather-alt',
	'angle-down',
	'font',
	'grin-alt',
	'hashtag',
	'calendar-week',
	'hand-middle-finger',
	'virus',
	'drum-steelpan',
	'blog',
	'handshake',
	'directions',
	'window-close',
	'skull',
	'money-bill-wave',
	'comment-alt',
	'comment-dots',
	'chevron-left',
	'hard-hat',
	'list-ol',
	'skull-crossbones',
	'sleigh',
	'hospital-symbol',
	'school',
	'truck-moving',
	'audio-description',
	'power-off',
	'shield-virus',
	'file-audio',
	'book-medical',
	'funnel-dollar',
	'truck',
	'first-aid',
	'mouse',
	'mitten',
	'italic',
	'cloud-sun',
	'text-width',
	'trophy',
	'columns',
	'user-lock',
	'ghost',
	'x-ray',
	'angry',
	'map-marked',
	'cloud-rain',
	'traffic-light',
	'money-check-alt',
	'heart',
	'user-md',
	'compress-arrows-alt',
	'palette',
	'sign-language',
	'wave-square',
	'chart-bar',
	'smog',
	'long-arrow-alt-left',
	'vector-square',
	'mosque',
	'hat-cowboy-side',
	'backspace',
	'redo',
	'cloud-moon',
	'trash',
	'boxes',
	'weight-hanging',
	'hand-spock',
	'th-list',
	'database',
	'comments',
	'hamburger',
	'space-shuttle',
	'file-invoice-dollar',
	'stethoscope',
	'user-alt-slash',
	'calendar-check',
	'spell-check',
	'dice',
	'bread-slice',
	'box',
	'headphones-alt',
	'dove',
	'hammer',
	'thermometer-full',
	'cookie-bite',
	'upload',
	'angle-double-down',
	'sort-up',
	'star-of-life',
	'venus-mars',
	'pen',
	'crop-alt',
	'cloud-meatball',
	'step-forward',
	'poll-h',
	'share',
	'list-ul',
	'tag',
	'house-damage',
	'church',
	'cart-plus',
	'user-alt',
	'disease',
	'save',
	'user-ninja',
	'vials',
	'video-slash',
	'pen-alt',
	'calendar-times',
	'khanda',
	'crown',
	'bolt',
	'coins',
	'dumpster-fire',
	'hippo',
	'server',
	'chevron-circle-left',
	'exclamation',
	'file-word',
	'check-square',
	'thumbs-down',
	'transgender',
	'align-center',
	'user-secret',
	'grip-lines-vertical',
	'mars-stroke',
	'poo-storm',
	'arrow-circle-down',
	'file-medical-alt',
	'step-backward',
	'network-wired',
	'arrows-alt',
	'bus-alt',
	'hospital-user',
	'walking',
	'synagogue',
	'tree',
	'grimace',
	'comments-dollar',
	'file-video',
	'paint-roller',
	'university',
	'check-double',
	'helicopter',
	'user-clock',
	'video',
	'shopping-basket',
	'exclamation-circle',
	'chess-queen',
	'address-card',
	'user-shield',
	'swimmer',
	'paste',
	'piggy-bank',
	'clone',
	'tractor',
	'broom',
	'journal-whills',
	'birthday-cake',
	'unlock',
	'grin-beam-sweat',
	'horse',
	'toolbox',
	'folder',
	'money-check',
	'ruler-horizontal',
	'star',
	'compass',
	'bowling-ball',
	'hockey-puck',
	'paragraph',
	'toilet-paper',
	'caret-left',
	'search-dollar',
	'users-slash',
	'store-slash',
	'dice-five',
	'leaf',
	'level-up-alt',
	'donate',
	'chess-board',
	'running',
	'star-half-alt',
	'trash-alt',
	'comment',
	'theater-masks',
	'hanukiah',
	'user-edit',
	'calendar',
	'dragon',
	'dizzy',
	'compress-alt',
	'plug',
	'envelope',
	'cat',
	'bath',
	'caret-square-up',
	'info',
	'dice-one',
	'share-square',
	'stopwatch',
	'utensils',
	'vr-cardboard',
	'sort-numeric-up-alt',
	'cloud-moon-rain',
	'volume-mute',
	'language',
	'user-slash',
	'pause',
	'slash',
	'vest-patches',
	'cloud-upload-alt',
	'handshake-alt-slash',
	'hand-pointer',
	'warehouse',
	'download',
	'dharmachakra',
	'tty',
	'business-time',
	'user-minus',
	'crow',
	'braille',
	'home',
	'greater-than',
	'mars-stroke-v',
	'equals',
	'subscript',
	'ellipsis-v',
	'file',
	'spa',
	'gamepad',
	'strikethrough',
	'angle-left',
	'align-justify',
	'caravan',
	'skiing-nordic',
	'frown-open',
	'drumstick-bite',
	'search-plus',
	'peace',
	'fish',
	'igloo',
	'thermometer-quarter',
	'file-code',
	'thermometer',
	'paper-plane',
	'dice-three',
	'blind',
	'democrat',
	'fire-alt',
	'podcast',
	'trash-restore',
	'link',
	'chess-king',
	'eraser',
	'dice-six',
	'diagnoses',
	'seedling',
	'spinner',
]);

const regularIcons = new Set([
	'calendar-check',
	'bell',
	'laugh-squint',
	'edit',
	'arrow-alt-circle-down',
	'square',
	'closed-captioning',
	'kiss',
	'tired',
	'file-excel',
	'grin-tongue',
	'clipboard',
	'trash-alt',
	'hand-point-right',
	'id-card',
	'newspaper',
	'grin',
	'circle',
	'comments',
	'file-alt',
	'thumbs-up',
	'grin-squint',
	'hand-lizard',
	'grin-beam-sweat',
	'comment',
	'copy',
	'star-half',
	'meh-blank',
	'grimace',
	'sun',
	'images',
	'stop-circle',
	'hand-scissors',
	'eye-slash',
	'folder',
	'envelope',
	'heart',
	'caret-square-left',
	'kiss-wink-heart',
	'money-bill-alt',
	'smile-beam',
	'caret-square-right',
	'image',
	'font-awesome-logo-full',
	'sad-tear',
	'hospital',
	'futbol',
	'id-badge',
	'grin-squint-tears',
	'address-book',
	'pause-circle',
	'bookmark',
	'frown-open',
	'compass',
	'grin-stars',
	'file-code',
	'paper-plane',
	'snowflake',
	'lemon',
	'hand-peace',
	'check-square',
	'grin-hearts',
	'hand-rock',
	'arrow-alt-circle-left',
	'calendar-minus',
	'lightbulb',
	'folder-open',
	'comment-dots',
	'window-maximize',
	'file-powerpoint',
	'thumbs-down',
	'file-audio',
	'file-word',
	'file',
	'angry',
	'credit-card',
	'file-video',
	'question-circle',
	'grin-beam',
	'hand-point-down',
	'plus-square',
	'hand-point-up',
	'file-archive',
	'grin-tears',
	'life-ring',
	'laugh-beam',
	'check-circle',
	'frown',
	'eye',
	'clock',
	'meh-rolling-eyes',
	'surprise',
	'grin-tongue-wink',
	'share-square',
	'smile',
	'map',
	'flag',
	'comment-alt',
	'moon',
	'sticky-note',
	'hdd',
	'smile-wink',
	'list-alt',
	'calendar-times',
	'hourglass',
	'dot-circle',
	'chart-bar',
	'object-group',
	'file-image',
	'sad-cry',
	'star',
	'minus-square',
	'save',
	'hand-paper',
	'user-circle',
	'kiss-beam',
	'grin-tongue-squint',
	'clone',
	'hand-pointer',
	'caret-square-down',
	'object-ungroup',
	'window-restore',
	'calendar-alt',
	'address-card',
	'arrow-alt-circle-up',
	'envelope-open',
	'laugh',
	'times-circle',
	'play-circle',
	'file-pdf',
	'flushed',
	'arrow-alt-circle-right',
	'copyright',
	'registered',
	'gem',
	'calendar',
	'building',
	'caret-square-up',
	'keyboard',
	'window-minimize',
	'hand-spock',
	'laugh-wink',
	'grin-wink',
	'user',
	'grin-alt',
	'calendar-plus',
	'meh',
	'bell-slash',
	'window-close',
	'handshake',
	'hand-point-left',
	'dizzy',
]);

export const availableFaIcons = [
	{
		prefix: regularIconsPrefix,
		pathPrefix: regularIconsPathPrefix,
		icons: regularIcons,
	} as const,
	{
		prefix: solidIconsPrefix,
		pathPrefix: soldIconsPathPrefix,
		icons: solidIcons,
	} as const,
];
