import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LocalizationContext } from 'Services/LocalizationService';
import { FavoriteCount } from 'Components/Favorite';

const Favorites = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	return (
		<Link
			className="vinisto-favorite d-flex pointer me-2"
			to={`${t({ id: 'routes.user-section.route' })}/${t({
				id: 'routes.user-section.favorites.route',
			})}`}
		>
			<FavoriteCount />
		</Link>
	);
};

export default Favorites;
