import { apiServiceInstance } from 'Services/ApiService';
import { IVinistoUser } from 'Services/AuthenticationService/interfaces';
import { get } from 'lodash-es';

import { USER_API_PATH } from './constants';
import { IUserUpdateData } from './interfaces';

const UserService = {
	update: (userId: string, data: IUserUpdateData) =>
		apiServiceInstance
			.put(`${USER_API_PATH}/${userId}`, data, true)
			.then((response) => {
				const newVinistoUser: IVinistoUser = {
					id: get(response, 'user.id', null),
					email: get(response, 'user.email', null),
					loginKey: get(response, 'user.loginKey', null),
					loginHash: data.userLoginHash,
					registrationTime: get(response, 'user.registrationTime', null),
					// lastLoginTime: get(response, 'user.lastLoginTime', null),
					isAgreementCC: get(response, 'user.isAgreementCC', false),
					// agreementCCTime: get(response, 'user.agreementCCTime', null),
					isEmailVerified: get(response, 'user.isEmailVerified', false),
					// permissions: get(response, 'user.permissions', null),
					isNewsletterActive: get(response, 'user.isNewsletterActive', false),
					// isNewsletterActiveTime: get(response, 'user.isNewsletterActiveTime', null),
					// suppliers: get(response, 'user.suppliers', null),
					nickname: get(response, 'user.nickname', null),
				};
				return newVinistoUser;
			}),
};

export default UserService;
