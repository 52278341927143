import { IconProps } from './interfaces';

const GiftIcon2 = ({
	className,
	width = 18,
	height = 20,
	fill = '#92013f',
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 18 20"
			className={className}
		>
			<g transform="translate(-622.652 -117.164)">
				<path
					d="M627.359,117.164h.625a3.06,3.06,0,0,1,1.55.767c.407.373.814.751,1.183,1.16.342.377.635.8.955,1.21l.049-.064c.294-.39.558-.8.885-1.162a5.05,5.05,0,0,1,2.721-1.912h.625a1.186,1.186,0,0,0,.125.039,2.513,2.513,0,0,1,.368,4.838c-.073.029-.144.062-.216.092l.012.03h.214c1.061,0,2.122,0,3.182,0a.961.961,0,0,1,1,.914c.018,1.054.014,2.109,0,3.162a.947.947,0,0,1-.731.884c-.08.021-.162.035-.254.055v8.829a1.026,1.026,0,0,1-1.152,1.155H624.819A1.029,1.029,0,0,1,623.653,136v-8.82a1.075,1.075,0,0,1-1-1.22c.024-.91,0-1.822.007-2.733a.994.994,0,0,1,1.078-1.066H626.9c.059,0,.118-.005.212-.01-.241-.115-.454-.2-.649-.311a2.5,2.5,0,0,1,.2-4.452A5.806,5.806,0,0,1,627.359,117.164Zm2.31,6.014V136.15h3.973V123.178Zm-1.031,12.973v-8.974h-3.969v8.974Zm10-8.974H634.67v8.973h3.973Zm-14.979-1.031h4.973v-2.964h-4.973Zm11.007-2.969v2.966h4.975v-2.966Zm-2.517-1.521c.159.033.271.063.386.079a4.983,4.983,0,0,0,1.591-.08,7.456,7.456,0,0,0,2.19-.665,1.493,1.493,0,1,0-1.49-2.585,7.779,7.779,0,0,0-2.505,2.785A3.523,3.523,0,0,0,632.154,121.656Zm-1.007-.013a1.862,1.862,0,0,0-.135-.394,14.572,14.572,0,0,0-.827-1.247,6.6,6.6,0,0,0-1.711-1.609,1.5,1.5,0,0,0-2.283,1.3,1.493,1.493,0,0,0,1.041,1.414,9.059,9.059,0,0,0,2.875.655A2.533,2.533,0,0,0,631.147,121.642Z"
					fill={fill}
				/>
			</g>
		</svg>
	);
};

export default GiftIcon2;
