type SocialMediaLinksProps = {
	bundleName: string;
	isTabletMobile: boolean;
	className?: string;
};

enum ShareBundleLinkVariant {
	FACEBOOK = 'FACEBOOK',
	TWITTER = 'TWITTER',
	COPY_LINK = 'COPY_LINK',
}

export type { SocialMediaLinksProps };
export { ShareBundleLinkVariant };
