export const countries: Record<any, any>[] = [
	{
		key: 'argentina',
		countryCode: 'ar',
	},
	{
		key: 'armenie',
		countryCode: 'arm',
	},
	{
		key: 'australie',
		countryCode: 'aus',
	},
	{
		key: 'bahamy',
		countryCode: 'bhs',
	},
	{
		key: 'barbados',
		countryCode: 'brb',
	},
	{
		key: 'bermudy',
		countryCode: 'bm',
	},
	{
		key: 'bulharsko',
		countryCode: 'bgr',
	},
	{
		key: 'brazilie',
		countryCode: 'bra',
	},
	{
		key: 'ceska-republika',
		countryCode: 'cz',
	},
	{
		key: 'chile',
		countryCode: 'chl',
	},
	{
		key: 'chorvatsko',
		countryCode: 'hrv',
	},
	{
		key: 'dansko',
		countryCode: 'dnk',
	},
	{
		key: 'dominikanska-republika',
		countryCode: 'dom',
	},
	{
		key: 'etiopie',
		countryCode: 'eth',
	},
	{
		key: 'fidzi',
		countryCode: 'fji',
	},
	{
		key: 'filipiny',
		countryCode: 'phl',
	},
	{
		key: 'finsko',
		countryCode: 'fin',
	},
	{
		key: 'francie',
		countryCode: 'fra',
	},
	{
		key: 'gruzie',
		countryCode: 'geo',
	},
	{
		key: 'guatemala',
		countryCode: 'gtm',
	},
	{
		key: 'guyana',
		countryCode: 'guy',
	},
	{
		key: 'holandsko',
		countryCode: 'nld',
	},
	{
		key: 'indie',
		countryCode: 'ind',
	},
	{
		key: 'irsko',
		countryCode: 'irl',
	},
	{
		key: 'italie',
		countryCode: 'ita',
	},
	{
		key: 'izrael',
		countryCode: 'isr',
	},
	{
		key: 'jamajka',
		countryCode: 'jam',
	},
	{
		key: 'japonsko',
		countryCode: 'jpn',
	},
	{
		key: 'jihoafricka-republika',
		countryCode: 'zaf',
	},
	{
		key: 'karibik',
		countryCode: 'bhs',
	},
	{
		key: 'kena',
		countryCode: 'ken',
	},
	{
		key: 'kolumbie',
		countryCode: 'col',
	},
	{
		key: 'kostarika',
		countryCode: 'cri',
	},
	{
		key: 'kuba',
		countryCode: 'cub',
	},
	{
		key: 'lotyssko',
		countryCode: 'lva',
	},
	{
		key: 'madarsko',
		countryCode: 'hun',
	},
	{
		key: 'makedonie',
		countryCode: 'mkd',
	},
	{
		key: 'mauricius',
		countryCode: 'mus',
	},
	{
		key: 'mexiko',
		countryCode: 'mex',
	},
	{
		key: 'moldavie',
		countryCode: 'mda',
	},
	{
		key: 'nemecko',
		countryCode: 'deu',
	},
	{
		key: 'nikaragua',
		countryCode: 'nic',
	},
	{
		key: 'novy-zeland',
		countryCode: 'nzl',
	},
	{
		key: 'panama',
		countryCode: 'pan',
	},
	{
		key: 'peru',
		countryCode: 'per',
	},
	{
		key: 'polsko',
		countryCode: 'pol',
	},
	{
		key: 'portugalsko',
		countryCode: 'prt',
	},
	{
		key: 'rakousko',
		countryCode: 'aut',
	},
	{
		key: 'recko',
		countryCode: 'grc',
	},
	{
		key: 'reunion',
		countryCode: 'reu',
	},
	{
		key: 'rusko',
		countryCode: 'rus',
	},
	{
		key: 'skotsko',
		countryCode: 'GB_SCT',
	},
	{
		key: 'slovensko',
		countryCode: 'svk',
	},
	{
		key: 'slovinsko',
		countryCode: 'svn',
	},
	{
		key: 'spanelsko',
		countryCode: 'esp',
	},
	{
		key: 'svedsko',
		countryCode: 'swe',
	},
	{
		key: 'svycarsko',
		countryCode: 'che',
	},
	{
		key: 'ukrajina',
		countryCode: 'ukr',
	},
	{
		key: 'usa',
		countryCode: 'usa',
	},
	{
		key: 'velka-britanie',
		countryCode: 'gbr',
	},
	{
		key: 'venezuela',
		countryCode: 'ven',
	},
];
