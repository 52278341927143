const ChevronIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		xmlns="http://www.w3.org/2000/svg"
		width="12.147"
		height="21.809"
		viewBox="0 0 12.147 21.809"
	>
		<path
			d="m2.12 2.119 8 8.786-8 8.786"
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeWidth="3"
		></path>
	</svg>
);

export default ChevronIcon;
