import cx from 'classnames';
import { type HTMLAttributes, type ReactNode } from 'react';

import styles from './styles.module.css';

interface HTMLDivProps extends HTMLAttributes<HTMLDivElement> {}

interface SommelierRatingProps extends HTMLDivProps {
	label: ReactNode;
	rating: number;
	separator?: string;
	maximum?: number;
	divProps?: HTMLDivProps;
}

const SommelierRating = ({
	label,
	rating,
	separator = '/',
	maximum = 100,
	...divProps
}: SommelierRatingProps) => {
	return (
		<div
			{...divProps}
			className={cx(styles.component, divProps?.className)}
		>
			<span className={styles.label}>{label}</span>
			<span className={styles.rating}>{rating}</span>
			<span>{separator}</span>
			<span>{maximum}</span>
		</div>
	);
};

export default SommelierRating;
