import { useContext } from 'react';
import cx from 'classnames';
import useFormatMessage from 'Hooks/useFormatMessage';
import { ProducersPageContext } from 'Pages/Producers/context';

import LetterBox from './Components/LetterBox';
import styles from './styles.module.css';

const Header = () => {
	const t = useFormatMessage();
	const { producers, producersData } = useContext(ProducersPageContext);

	return (
		<div className="col-12 vinisto-card">
			<h1 className={cx('h2', styles.title)}>{t({ id: 'producers.title' })}</h1>
			<div className={styles.lettersContainer}>
				{Object.keys(producers)?.map((letter, index) => (
					<LetterBox
						key={letter ?? index}
						href={`#${letter}`}
						isLoading={producersData.isLoading}
					>
						{letter}
					</LetterBox>
				))}
			</div>
		</div>
	);
};

export default Header;
