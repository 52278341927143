import { lazy, Suspense, useContext } from 'react';
import { PopoverContext } from 'Components/Popover';
import Loader from 'Components/View/Loader';
const CloseSmallIcon = lazy(() => import('Components/Icons/CloseSmall'));

import styles from './styles.module.css';

const PopoverCloseButton = () => {
	const { close } = useContext(PopoverContext);

	return (
		<button
			type="button"
			className={styles.button}
			onClick={close}
		>
			<Suspense fallback={<Loader blank />}>
				<CloseSmallIcon fill="#fff" />
			</Suspense>
		</button>
	);
};

export default PopoverCloseButton;
