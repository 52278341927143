import { LocalizationContext } from 'Services/LocalizationService';
import { useContext } from 'react';

const NotForDiscountedItemsMessage = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	return (
		<div className="fst-italic">
			{t({
				id: 'notification.message.discountCoupon.discountNotForDiscountedItems',
			})}
		</div>
	);
};

export default NotForDiscountedItemsMessage;
