import { createContext, useCallback, useState } from 'react';

import { getModalConfigByType } from './helpers';
import { IModalProviderProps, IModalValues } from './interfaces';

const defaultModalValues: IModalValues = {
	isOpened: false,
	modalType: null,
	modalData: null,
	modalConfig: {},
	showModalPreloader: false,
	handleOpenModal: () => null,
	handleCloseModal: () => null,
	handleUpdateModalConfig: () => null,
	handleToggleModalPreloader: () => null,
};

export const ModalContext = createContext(defaultModalValues);

const ModalProvider = (props: IModalProviderProps) => {
	const [isOpened, setIsOpened] = useState<boolean>(
		defaultModalValues.isOpened
	);
	const [modalType, setModalType] = useState<string | null>(
		defaultModalValues.modalType
	);
	const [modalData, setModalData] = useState<
		Record<any, any> | null | undefined
	>(defaultModalValues.modalData);
	const [modalConfig, setModalConfig] = useState<Record<any, any>>(
		getModalConfigByType(modalType)
	);
	const [showModalPreloader, setShowModalPreloader] = useState<boolean>(
		defaultModalValues.showModalPreloader
	);

	const handleToggleModalPreloader = useCallback((show: boolean) => {
		setShowModalPreloader(show);
	}, []);

	const handleUpdateModalConfig = useCallback(
		(updatedModalConfig: Record<any, any>) => {
			setModalConfig(updatedModalConfig);
		},
		[]
	);

	const handleOpenModal = useCallback(
		(newModalType: string, mData?: Record<any, any> | null | undefined) => {
			if (!isOpened || newModalType !== modalType) {
				setIsOpened(true);
				setModalType(newModalType);
				setModalConfig(getModalConfigByType(newModalType));
				if (mData) {
					setModalData(mData);
				}
			}
		},
		[isOpened, modalType]
	);

	const handleCloseModal = useCallback(() => {
		if (isOpened && modalType) {
			setTimeout(() => {
				setIsOpened(false);
			}, 100);
			setModalType(null);
			setModalData(null);
		}
	}, [isOpened, modalType]);

	const modalValues: IModalValues = {
		modalConfig,
		modalData,
		isOpened,
		modalType,
		showModalPreloader,
		handleUpdateModalConfig,
		handleOpenModal,
		handleCloseModal,
		handleToggleModalPreloader,
	};

	return (
		<ModalContext.Provider value={modalValues}>
			{props.children}
		</ModalContext.Provider>
	);
};

export default ModalProvider;
