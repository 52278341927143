import * as React from 'react';
import { LocalizationContext } from 'Services/LocalizationService';
import { BasketContext } from 'Services/BasketService';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import './styles.css';

const CartHeader: React.FunctionComponent = (): JSX.Element => {
	const localizationContext = React.useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const navigate = useNavigate();
	const basketContext = React.useContext(BasketContext);
	const basketItemsQuantity = basketContext.itemsQuantity;

	const handleGoToShippingPayment = React.useCallback(
		(event: any) => {
			event?.preventDefault;
			basketItemsQuantity > 0 &&
				navigate(`/${t({ id: 'routes.cart.shippingPayment.route' })}`);
		},
		[basketItemsQuantity, navigate, t]
	);

	return (
		<div className="vinisto-cart-header">
			<div className="vinisto-cart-header__step active">
				<div className="vinisto-cart-header__step__number">
					<div className="vinisto-cart-header__step__number__circle">1</div>
				</div>
				<div className="vinisto-cart-header__step__name">
					{t({ id: 'basket' })}
				</div>
			</div>
			<button
				className={cx('vinisto-cart-header__step', {
					['semi-active']: basketItemsQuantity > 0,
				})}
				onClick={handleGoToShippingPayment}
			>
				<div className="vinisto-cart-header__step__number">
					<div className="vinisto-cart-header__step__number__circle">2</div>
				</div>
				<div className="vinisto-cart-header__step__name">
					{t({ id: 'basket.shippingAndPayment' })}
				</div>
			</button>
			<div className="vinisto-cart-header__step">
				<div className="vinisto-cart-header__step__number">
					<div className="vinisto-cart-header__step__number__circle">3</div>
				</div>
				<div className="vinisto-cart-header__step__name">
					{t({ id: 'basket.deliveryDetail' })}
				</div>
			</div>
		</div>
	);
};

export default CartHeader;
