import { useContext } from 'react';
import cx from 'classnames';
import { ProducersPageContext } from 'Pages/Producers/context';

import Column from './Components/Column';
import styles from './styles.module.css';

const List = () => {
	const { producers, producersData } = useContext(ProducersPageContext);

	return (
		<div className={cx('col-12 vinisto-card mt-3', styles.container)}>
			{Object.entries(producers)?.map(([letter, values], index) => (
				<Column
					key={letter ?? index}
					letter={letter}
					values={values}
					isLoading={producersData.isLoading}
				/>
			))}
		</div>
	);
};

export default List;
