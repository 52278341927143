import { useMemo } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { BlogArticlePreview } from 'Services/ApiService/Cms/Blog/interfaces';
import Config from 'Config';

import CarouselArticleCard from './Components/CarouselArticleCard';

interface CarouselProps {
	carouselType: string;
	data?: BlogArticlePreview[] | undefined;
	isLoading?: boolean;
}

const CarouselArticles = ({ carouselType, data, isLoading }: CarouselProps) => {
	const currentCarouselConfig = useMemo(
		() =>
			Config?.carousels?.[carouselType as keyof (typeof Config)['carousels']] ??
			{},
		[carouselType]
	);

	return (
		<AliceCarousel
			{...currentCarouselConfig}
			items={data?.map((card, index) => (
				<CarouselArticleCard
					key={index}
					data={card}
					isLoading={isLoading}
				/>
			))}
		/>
	);
};

export default CarouselArticles;
