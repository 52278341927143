import { FC } from 'react';

import { IIconProps } from './Interfaces';

const ChatIcon: FC<IIconProps> = ({ className }) => {
	return (
		<svg
			width={18.04}
			height={17.02}
			viewBox="0 0 18.04 17.02"
			className={className}
		>
			<g>
				<g>
					<path
						fill="#4d4d4e"
						d="M2.5,13.67C.46,13.14,.03,12.59,.03,10.57V2.65C-.16,1.39,.71,.21,1.97,.03c.23-.03,.46-.03,.69,0C6.92,.02,11.17,.02,15.43,.03c1.26-.17,2.42,.71,2.59,1.97,.03,.2,.03,.41,0,.62,0,2.79,0,5.58,0,8.37,.16,1.27-.74,2.42-2.01,2.58-.2,.02-.4,.02-.6,0-2.07,0-4.13-.03-6.2,.03-.55,.03-1.09,.2-1.55,.49-1.3,.84-2.54,1.79-3.83,2.65-.27,.18-.77,.38-.93,.26-.25-.26-.39-.61-.39-.97-.05-.76-.02-1.53-.02-2.34Zm1.24,1.61c1.32-.93,2.44-1.76,3.61-2.51,.42-.27,.9-.42,1.4-.44,2.19-.05,4.37-.02,6.56-.02,1.16,0,1.46-.29,1.47-1.4,0-2.76,0-5.52,0-8.28,0-1.06-.31-1.36-1.41-1.36-4.19,0-8.39,0-12.58,0-1.23,0-1.5,.28-1.5,1.55,0,2.61,0,5.22,0,7.83,0,1.41,.17,1.61,1.57,1.66,.66,.02,.91,.31,.9,.93s0,1.22,0,2.04h0Z"
					/>
					<path
						fill="#4d4d4e"
						d="M4.84,7.67c-.64-.05-1.05-.37-1.06-1.01-.06-.52,.31-.99,.84-1.05,.05,0,.11,0,.16,0,.53-.05,1,.33,1.06,.86,0,.05,0,.09,0,.14,.04,.55-.38,1.03-.93,1.07-.02,0-.04,0-.07,0Z"
					/>
					<path
						fill="#4d4d4e"
						d="M10.07,6.6c-.08,.65-.4,1.05-1.05,1.05-.57,0-1.03-.46-1.03-1.03s.46-1.02,1.03-1.02c.56-.02,1.02,.41,1.05,.97,0,.01,0,.02,0,.04Z"
					/>
					<path
						fill="#4d4d4e"
						d="M14.29,6.61c-.08,.65-.41,1.05-1.05,1.04-.57,0-1.02-.47-1.01-1.04,0-.57,.47-1.02,1.04-1.01,.55-.02,1.01,.42,1.03,.97,0,.01,0,.03,0,.04Z"
					/>
				</g>
			</g>
		</svg>
	);
};

export default ChatIcon;
