import { ReactNode, useContext } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import cx from 'classnames';
import { MobileMenuContext } from 'Components/MobileMenu';

import styles from './styles.module.css';

interface TItemLinkProps extends LinkProps {
	children: ReactNode;
	to: string;
}

/**
 * Styled Mobile Menu Link that navigates in browser router and closes menu after click
 */
const ItemLink = ({ children, to, className, ...rest }: TItemLinkProps) => {
	const { closeMenu } = useContext(MobileMenuContext);

	return (
		<Link
			to={to}
			onClick={closeMenu}
			className={cx(styles.itemLink, className)}
			{...rest}
		>
			{children}
		</Link>
	);
};

export default ItemLink;
