import { useContext } from 'react';
import { OrderSummaryProps } from 'Pages/CartConfirmation/Components/OrderSummary/interfaces';
import { AddressType } from 'Pages/CartConfirmation/Components/Address/interfaces';
import { LocalizationContext } from 'Services/LocalizationService';
import { DeviceServiceContext } from 'Services/DeviceService';
import OrderItem from 'Pages/CartConfirmation/Components/OrderItem';
import DeliveryPaymentItem from 'Pages/CartConfirmation/Components/DeliveryPaymentItem';
import Address from 'Pages/CartConfirmation/Components/Address';
import DiscountCoupon from 'Components/DiscountCoupon/index';

import './styles.css';
import { VinistoOrderDllModelsApiOrderOrderItem } from '@/api-types/order-api';

const OrderSummary = ({
	orderItems,
	orderGiftItems,
	delivery,
	payment,
	billingAddress,
	discountCoupons,
	orderCurrency,
	shippingPackaging,
}: OrderSummaryProps) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const deviceContext = useContext(DeviceServiceContext);

	const deliveryAdress =
		delivery?.deliveryAddress ?? delivery?.pickupPoint?.address;
	const deliveryPhone =
		delivery?.pickupPoint != null
			? delivery.pickupPoint.phone
			: delivery?.deliveryAddress?.phone;

	const deliveryEmail =
		delivery?.pickupPoint != null
			? delivery.pickupPoint.email
			: delivery?.deliveryAddress?.email;

	return (
		<div className="container">
			<div className="row">
				<div className="col-12">
					<div>
						<h2 className="vinisto-heading underline mt-3 mt-xxl-5 mb-0">
							{t({
								id: 'orderConfirmation.orderSummary.title',
							})}
						</h2>
						<div
							className={
								deviceContext.isDesktop
									? 'vinisto-user-orders__orders__order-body vinisto-cart__items vinisto-order-summary__items'
									: 'vinisto-user-orders__orders__order vinisto-user-favorites--mobile vinisto-crosssell--mobile vinisto-cart__items--mobile vinisto-order-summary__items--mobile'
							}
						>
							{orderItems?.map((orderItemData) => (
								<OrderItem
									key={orderItemData.bundle.id}
									orderItemData={orderItemData}
								/>
							))}
							{orderGiftItems?.map((assignedGift, key) => {
								return (
									<OrderItem
										key={`assignedGift-${key}`}
										orderItemData={assignedGift}
										isGift={true}
									/>
								);
							})}
							{discountCoupons &&
								discountCoupons?.map((discountCoupon, index) => {
									return (
										<DiscountCoupon
											key={index}
											discountCoupon={discountCoupon}
											orderCurrency={orderCurrency}
										/>
									);
								})}
							<ShippingPackagingItem shippingPackaging={shippingPackaging} />
							<DeliveryPaymentItem
								name={delivery?.name}
								price={delivery?.price}
							/>
							<DeliveryPaymentItem
								name={payment?.name}
								price={payment?.price}
							/>
						</div>
						<h2 className="vinisto-heading underline mt-3 mt-xxl-4">
							{t({
								id: 'orderConfirmation.orderSummary.address.deliveryShipping.title',
							})}
						</h2>
						<div className="vinisto-order-summary__addresses">
							<Address
								addressData={deliveryAdress}
								addressType={AddressType.DELIVERY}
								addressPhone={deliveryPhone}
								addressEmail={deliveryEmail}
							/>
							<Address
								addressData={billingAddress}
								addressType={AddressType.BILLING}
								addressPhone={billingAddress?.phone}
								addressEmail={billingAddress?.email}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderSummary;

interface ShippingPackagingItemProps {
	shippingPackaging?: VinistoOrderDllModelsApiOrderOrderItem | null;
}
const ShippingPackagingItem = ({
	shippingPackaging,
}: ShippingPackagingItemProps) => {
	if (!shippingPackaging) {
		return null;
	}

	return (
		<DeliveryPaymentItem
			name={shippingPackaging?.bundle.name}
			price={shippingPackaging?.bundle.price}
		/>
	);
};
