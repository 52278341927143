import cx from 'classnames';

import { IContainerProps } from './interfaces';

const Container = (props: IContainerProps) => {
	return (
		<div
			className={cx('container', props?.containerClassName)}
			ref={props.parentRef}
		>
			<div className={cx('row', props?.className)}>{props.children}</div>
		</div>
	);
};

export default Container;
