import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { WarehouseContext } from 'Services/WarehouseService';
import { LocalizationContext } from 'Services/LocalizationService';

import { RECOMMENDED_CAROUSEL_ID } from '../Components/Navbar/Components/Search/constants';

import { DEFAULT_BUNDLE_API_PARAMS } from '@/shared';
import { VinistoProductDllModelsApiHomePageHomePageCustomCarouselReturn } from '@/api-types/product-api';
import api from '@/api';
import { bundleAdapter } from '@/index';

export const useRecommendedBundles = () => {
	const warehouseContext = useContext(WarehouseContext);
	const localizationContext = useContext(LocalizationContext);
	const {
		activeCurrency: { currency },
		countryOfSale,
	} = localizationContext;
	const BUNDLE_SLICE_INDICES: [number, number] = [0, 5];

	const { data, isLoading } = useQuery(
		['recommendedCarousel', { currency, countryOfSale }],
		async () => {
			const response =
				await api.get<VinistoProductDllModelsApiHomePageHomePageCustomCarouselReturn>(
					`product-api/home-page/custom-carousels/${RECOMMENDED_CAROUSEL_ID}`,
					{
						...DEFAULT_BUNDLE_API_PARAMS,
						isCache: true,
						countryOfSale,
						currency,
					}
				);

			if (response.isError) return Promise.reject(response.error);

			const uniqueIds = Array.from(
				new Set(
					(response.homePageCustomCarousel?.bundles ?? []).map(
						(bundle) => bundle.id
					)
				)
			).filter((id): id is string => Boolean(id));

			warehouseContext.fetchQuantity(uniqueIds);

			return (
				response.homePageCustomCarousel?.bundles
					?.slice(...BUNDLE_SLICE_INDICES)
					.map((bundle) => bundleAdapter.fromApi(bundle, { currency })) ?? []
			);
		}
	);

	return { data, isLoading };
};
