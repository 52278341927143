import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import cx from 'classnames';
import { LocalizationContext } from 'Services/LocalizationService';
import removeInlineStyles from 'Helpers/removeInlineStyles';
const fallbackImage = '/assets/images/blog-fallback.webp';
import sanitizeHTML from 'Helpers/sanitizeHTML';
import getSrcSet from 'Helpers/getSrcSet';

import { IArticleInfoProps } from './interfaces';
import styles from './styles.module.css';

const ArticleInfo = ({
	data,
	isLoading,
	isImageLoading,
	imageData,
}: IArticleInfoProps) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	const articleContentNoStyles = sanitizeHTML(
		removeInlineStyles(data?.perex ?? '')
	).substring(0, 140);

	const srcSet = getSrcSet(imageData?.image?.urls);

	const altText = imageData?.image?.alternativeText || '';

	return (
		<Link
			to={
				isLoading ? '#' : `/${t({ id: 'routes.community.route' })}/${data?.url}`
			}
			className={cx(styles.blogArticle)}
		>
			<div className={styles.blogArticleThumbnail}>
				{isLoading || isImageLoading ? (
					<Skeleton
						height="99%"
						width="99.75%"
					/>
				) : (
					<img
						className={styles.blogArticleImage}
						srcSet={srcSet ?? ''}
						// There is not much choice here - images are only 300 or 1000px wide
						sizes={`300px`}
						src={imageData?.image?.urls.original_png ?? fallbackImage}
						alt={altText}
						loading="lazy"
					/>
				)}
			</div>
			{isLoading ? (
				<Skeleton width="100%" />
			) : (
				<p
					className={styles.blogAritcleHeading}
					dangerouslySetInnerHTML={{
						__html: data?.title ?? '',
					}}
				/>
			)}
			{isLoading ? (
				<Skeleton width="100%" />
			) : (
				<div className={styles.blogArticleInfo}>
					<div className={styles.blogArticleDate}>
						{data?.publishDate.toLocaleDateString()}
					</div>
					<div className={styles.blogArticleInfoSeparator}>-</div>
					<div className={styles.blogArticleAuthor}>
						{data?.authors?.map((author) => author.name).join(', ')}
					</div>
					<div className={styles.blogArticleInfoSeparator}>-</div>
					<div className={styles.blogArticleReadingTime}>
						{t(
							{ id: 'blog.readingTime' },
							{
								time: data?.readingTime.toString(),
							}
						)}
					</div>
				</div>
			)}
			<div>
				{isLoading ? (
					<Skeleton count={5.6} />
				) : (
					<>
						<span
							className={styles.blogArticleContent}
							dangerouslySetInnerHTML={{
								__html: `${articleContentNoStyles ?? ''} ${'...'}`,
							}}
						/>
						<span className={styles.articleblogArticleLink}>
							{t({ id: 'articleTabs.link.continueReading' })}
						</span>
					</>
				)}
			</div>
		</Link>
	);
};

export default ArticleInfo;
