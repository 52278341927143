import { FC } from 'react';

import { IIconProps } from './Interfaces';

const HeaderPhoneIcon: FC<IIconProps> = ({ className }) => {
	return (
		<svg
			width={16.955}
			height={17}
			viewBox="0 0 16.955 17"
			className={className}
		>
			<path
				d="M265.231,250.695a13.249,13.249,0,0,0,5.708,5.724l.95-1.215c.107-.138.211-.279.322-.414a1.127,1.127,0,0,1,1.4-.313q1.768.894,3.522,1.816a1.1,1.1,0,0,1,.417,1.592c-.478.766-.959,1.53-1.462,2.279a1.821,1.821,0,0,1-1.8.752,12.562,12.562,0,0,1-6.449-2.535,18.315,18.315,0,0,1-5.68-6.4,11.106,11.106,0,0,1-1.259-3.5,9.69,9.69,0,0,1-.121-1.718,1.448,1.448,0,0,1,.7-1.158q1.119-.761,2.257-1.5a1.13,1.13,0,0,1,1.656.483c.425.815.841,1.636,1.26,2.454.16.311.321.62.479.931a1.128,1.128,0,0,1-.329,1.481c-.466.369-.937.731-1.405,1.1C265.344,250.6,265.291,250.647,265.231,250.695Z"
				transform="translate(-260.782 -243.926)"
				fill="#4d4d4e"
			/>
		</svg>
	);
};

export default HeaderPhoneIcon;
