import { FC, useContext } from 'react';
import { get } from 'lodash-es';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { LocalizationContext } from 'Services/LocalizationService';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';

import { IOrderDesktopProps } from './interfaces';

const OrderDesktop: FC<IOrderDesktopProps> = ({
	basketItem,
	isLoading,
}): JSX.Element => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();

	const quantity = get(basketItem, 'quantity', 1);
	const bundle = get(basketItem, 'bundle', {});
	const supplierName = get(bundle, 'supplier.nameWeb', '');
	const { shortVariety: producerName, component: flag } = getFlagSpecification(
		get(bundle, 'specificationDetails', [])
	);

	return (
		<div className="vinisto-user-orders__orders__order-body vinisto-crosssell__items">
			<div className="vinisto-user-orders__orders__order-body__item cta-item-simplified vinisto-crosssell__item vinisto-crosssell__item-link">
				{!isLoading && (
					<Link
						className="vinisto-crosssell__item-overlay"
						to={`/${t({
							id: 'routes.product.route',
						})}/${getLocalizedValue(get(bundle, 'url', []))}`}
					/>
				)}
				<div className="vinisto-user-orders__orders__order-body__item__info">
					<div className="vinisto-user-orders__orders__order-body__item__info__img">
						{isLoading ? (
							<Skeleton
								containerClassName="h-100"
								height="66px"
								width="90%"
							/>
						) : (
							<img
								src={getBundleImage(
									get(basketItem, 'bundle.images', []),
									IMAGE_SIZE_THUMB_64x80
								)}
								alt={`${t({ id: 'alt.bundleImage' })}`}
							/>
						)}
					</div>
					<div className="vinisto-user-orders__orders__order-body__item__info__data">
						<div className="vinisto-user-orders__orders__order-body__item__info__data__name">
							{isLoading ? (
								<Skeleton />
							) : (
								getLocalizedValue(get(bundle, 'name', [])) ?? '-'
							)}
						</div>
						{isLoading ? (
							<Skeleton containerClassName="w-100" />
						) : (
							<div className="vinisto-user-orders__orders__order-body__item__info__data__winery">
								<BundleProducer
									flag={flag}
									name={producerName}
									isLoading={isLoading}
								/>
							</div>
						)}
					</div>
				</div>
				<div className="vinisto-user-orders__orders__order-body__item__prices">
					<div className="vinisto-user-orders__orders__order-body__item__info__data">
						<div className="vinisto-user-orders__orders__order-body__item__info__data__name vinisto-font-16">
							{t({ id: 'basket.pcs' }, { count: quantity })}
						</div>
						{isLoading ? (
							<Skeleton containerClassName="w-100" />
						) : (
							<div className="vinisto-user-orders__orders__order-body__item__info">
								<>
									<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
										{t(
											{ id: 'bundle.supplierSmall.name' },
											{
												name: (
													<span className="vinisto-color-success fw-bolder vinisto-font-16">
														{supplierName.length > 0
															? supplierName
															: t({
																	id: 'productDetail.seller.name.others',
															  })}
													</span>
												),
											}
										)}
									</div>
								</>
							</div>
						)}
					</div>
				</div>

				<div className="vinisto-user-orders__orders__order-body__item__stock"></div>

				<div className="vinisto-user-orders__orders__order-body__item__prices">
					<span className="vinisto-crosssell__message">
						{t({ id: 'crossSell.productAddedToCart' })}
					</span>
				</div>
			</div>
		</div>
	);
};

export default OrderDesktop;
