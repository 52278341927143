import api from '@/api';
import { BundleDetailCarouselsListParams, VinistoHelperDllEnumsCurrency, VinistoHelperDllEnumsLanguage, VinistoProductDllModelsApiBundleBundleDetailCarouselReturn, VinistoProductDllModelsApiBundleBundlesReturn } from '@/api-types/product-api';

// Params should be 'BundleDetailCarouselGetParameters' but it's not generated from swagger
const getSupplierBundlesCarousel = async (params: {
  bundleId: string;
  language?: VinistoHelperDllEnumsLanguage;
  currency?: VinistoHelperDllEnumsCurrency | keyof typeof VinistoHelperDllEnumsCurrency;
  countryOfSale?: "CZ" | "SK" | "DE" | "UK";
  isCache?: boolean;
}) => {
	const response = await api.get<VinistoProductDllModelsApiBundleBundlesReturn>(
		`product-api/bundle-detail/carousels/get-supplier-bundles`, params
	);

	return response.bundles;
};

const getBundleCarousels = async (
	bundleId: string,
	params: Omit<BundleDetailCarouselsListParams, 'BundleId'>
) => {
	const response = await api.get<VinistoProductDllModelsApiBundleBundleDetailCarouselReturn>(
    `product-api/bundle-detail/carousels`, {
      BundleId: bundleId,
      IsCache: true,
      Currency: String(params.Currency),
      CountryOfSale: String(params.CountryOfSale),
    }
		)
		if(response) {
      return {
        lastViewedBundles: response.lastViewedBundles ?? [],
        similarBundles: response.similarBundles ?? [],
        manufacturerBundles: response.manufacturerBundles ?? [],
      };
    }
  }

const CarouselService = {
  getSupplierBundlesCarousel,
  getBundleCarousels,
};

export default CarouselService;