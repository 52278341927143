import { HTMLProps, MouseEvent, ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { MobileMenuContext } from '../..';

export interface IMobileMenuLinkProps extends HTMLProps<HTMLDivElement> {
	to: string;
	children: ReactNode;
	hasSubmenu?: boolean;
}

/**
 * Custom Mobile Menu Link that navigates inside mobile menu instead of browser router
 */
const MobileMenuLink = ({
	to,
	children,
	onClick,
	hasSubmenu,
	...rest
}: IMobileMenuLinkProps) => {
	const { pagePath, navigateToPage } = useContext(MobileMenuContext);
	const { closeMenu } = useContext(MobileMenuContext);
	const navigate = useNavigate();

	const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
		if (typeof onClick === 'function') {
			onClick(e);
		}
		if (!hasSubmenu) {
			navigate(to);
			closeMenu();
		}
		if (to[0] !== '/') {
			if (pagePath[pagePath?.length - 1] !== '/') {
				navigateToPage(`${pagePath}/${to}`);
			} else {
				navigateToPage(`${pagePath}${to}`);
			}
		} else {
			navigateToPage(to);
		}
	};

	return (
		<div
			onClick={handleOnClick}
			{...rest}
		>
			{children}
		</div>
	);
};

export default MobileMenuLink;
