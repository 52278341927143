import { useQuery } from '@tanstack/react-query';
import { useContext, useRef } from 'react';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { CMS_QUERY_TYPES } from 'Services/ApiService/Cms/constants';
import {
	ARTICLE_STATE,
	ARTICLE_STATE_VALUES_MAP,
	COLUMN,
	SORTING_COLUMN_MAP,
} from 'Services/ApiService/Cms/Blog/constants';
import BlogService from 'Services/ApiService/Cms/Blog';

import { POST_LIMIT } from './constants';
import { type Entries, type UseGetBlogPostArgs } from './interfaces';

const getEntries = <T extends object>(obj: T) =>
	Object.entries(obj) as Entries<T>;

const useGetBlogPosts = (
	params: Partial<UseGetBlogPostArgs> & {
		activeTag?: string;
		page?: number;
	} = {
		Limit: POST_LIMIT,
	}
) => {
	const authContext = useContext(AuthenticationContext);
	const { loginHash } = authContext.vinistoUser;

	const page = params.page || 1;
	const activeTag = params.activeTag || '';
	const previousTagRef = useRef<string>('');

	const tagToUse = activeTag || previousTagRef.current;

	if (activeTag) {
		previousTagRef.current = activeTag;
	}

	const fetchBlogPosts = () => {
		const queryParams = getEntries(params as UseGetBlogPostArgs)
			.map(([key, value]) => ({
				key,
				value,
			}))
			.concat([
				{
					key: 'Offset',
					value: (page - 1) * POST_LIMIT,
				},
				{
					key: 'Limit',
					value: POST_LIMIT,
				},
				{
					key: 'UserLoginHash',
					value: loginHash,
				},
				{
					key: 'SearchState',
					value: ARTICLE_STATE_VALUES_MAP[ARTICLE_STATE.PUBLISHED],
				},
				{
					key: 'SearchPublishDateTo',
					value: Math.floor(Date.now() / 1000).toString(),
				},
				{
					key: 'SortingColumn',
					value: SORTING_COLUMN_MAP[COLUMN.PUBLISH_DATE],
				},
				{
					key: 'IsSortingDescending',
					value: true,
				},
			]);

		if (tagToUse) {
			queryParams.push({
				key: 'SearchTagsURL',
				value: tagToUse,
			});
		}

		return BlogService.getList(queryParams);
	};

	const postsQuery = useQuery(
		[
			`${CMS_QUERY_TYPES.POSTS}, ${page}, ${activeTag}
	`,
		],
		fetchBlogPosts,
		{
			enabled: true,
			staleTime: 10 * 60 * 1000,
		}
	);

	return postsQuery;
};

export default useGetBlogPosts;
