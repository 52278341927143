import {
	lazy,
	Suspense,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';
import { uniqueId, unset } from 'lodash-es';
import { Form } from 'react-final-form';
import { FormApi, MutableState } from 'final-form';
import cx from 'classnames';
import { BasketContext } from 'Services/BasketService';
import { LocalizationContext } from 'Services/LocalizationService';
import { InputText } from 'Components/Form';
const OkayRedIcon = lazy(() => import('Components/Icons/OkayRed'));
import Loader from 'Components/View/Loader';

import { COUPON_INPUT } from './constants';
import styles from './styles.module.css';
import { ICouponFormProps } from './interfaces';

const CouponForm = ({ className }: ICouponFormProps) => {
	const basketContext = useContext(BasketContext);
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	const okayIconId = useMemo(() => uniqueId(), []);
	const [isRedeemed, setIsRedeemed] = useState(false);

	const required = (value: any): string | undefined => {
		return value ? undefined : 'form.input.discountCoupon.requiredValidation';
	};

	const handleOnSubmitDiscountCoupon = useCallback(
		(formValues: Record<any, any>, form: FormApi) => {
			return new Promise((resolve) => {
				const inputWitRemovedWhitespace = String(
					formValues[COUPON_INPUT] ?? ''
				).trim();
				basketContext
					.handleOnAddCoupon(inputWitRemovedWhitespace, false)
					.then(() => {
						form.reset();
						form.resetFieldState(COUPON_INPUT);
						setIsRedeemed(true);
						resolve({});
					})
					// TODO duplicate error handling across the functions causing unexpected behavior
					.catch((responseError: any) => {
						const isCouponAlreadyApplied = responseError?.error?.some(
							(err: any) =>
								err.specificError === 'DISCOUNT_COUPON_ID_IS_ALREADY_IN_BASKET'
						);

						const isCouponNotCombinable = responseError?.error?.some(
							(err: any) =>
								err.specificError === 'DISCOUNT_COUPON_IS_NOT_COMBINABLE'
						);

						const cantBeAppliedBecauseOfBasketValue =
							responseError?.error?.some(
								(err: any) =>
									err.specificError ===
									'DISCOUNT_COUPON_AMOUNT_VALUE_HIGHER_THEN_ALLOWD_FROM'
							);
						switch (true) {
							case isCouponAlreadyApplied || isCouponNotCombinable: {
								form.reset();
								form.resetFieldState(COUPON_INPUT);
								setIsRedeemed(true);
								resolve({});
								break;
							}
							case cantBeAppliedBecauseOfBasketValue:
								resolve({
									[COUPON_INPUT]:
										'basket.discountCoupon.error.allowedFromDoNotMatch',
								});
								break;
							default:
								resolve({
									[COUPON_INPUT]:
										'notification.message.discountCouponAdd.error',
								});
						}
					});
			});
		},
		[basketContext]
	);

	const mutators = {
		clearSubmissionState: (_: [], state: MutableState<Record<any, any>>) => {
			unset(state, `formState.submitErrors[${COUPON_INPUT}]`);
			setIsRedeemed(false);
		},
	};

	return (
		<div
			className={cx('vinisto-cart__discount', styles.couponFormWrap, className)}
		>
			<Form
				onSubmit={handleOnSubmitDiscountCoupon}
				{...{ mutators }}
			>
				{(formProps) => (
					<form
						onSubmit={formProps.handleSubmit}
						className={styles.couponForm}
					>
						<InputText
							identifier={COUPON_INPUT}
							name={COUPON_INPUT}
							className={styles.couponInput}
							labelClassName="sr-only"
							placeholder="basket.discountCoupon.placeholder"
							onChange={formProps.form.mutators.clearSubmissionState}
							validate={required}
							validateOnBlur={false}
							classNameWrapper={styles.couponInputWrapper}
						/>
						<button
							type="submit"
							className={`vinisto-btn vinisto-bg-green ${styles.couponBtn}`}
							disabled={formProps.invalid}
						>
							{t({ id: 'basket.discountCoupon.redeem' })}
						</button>
					</form>
				)}
			</Form>
			{isRedeemed && (
				<div className="vinisto-cart__discount__message-wrap">
					<Suspense fallback={<Loader blank />}>
						<OkayRedIcon
							id={okayIconId}
							alt={``}
							title={``}
							className={`OkayRedIcon`}
						/>
					</Suspense>
					<span className="vinisto-cart__discount__message vinisto-color-dark-red">
						{t({ id: 'basket.discountCoupon.redeemed' })}
					</span>
				</div>
			)}
		</div>
	);
};

export default CouponForm;
