import { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import cx from 'classnames';
import { AuthenticationContext } from 'Services/AuthenticationService/context';

import { MAX_NAME_LENGTH } from './constants';
import styles from './styles.module.css';

type TAvatarProps = {
	name: string;
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	fw?: 'light' | 'normal' | 'bolder' | 'bold';
	className?: string;
	isLoading?: boolean;
	inverted?: boolean;
};

const Avatar = ({
	name,
	size = 'md',
	fw = 'bolder',
	className = '',
	isLoading = false,
	inverted = false,
}: TAvatarProps) => {
	const truncatedName = name?.slice(0, MAX_NAME_LENGTH)?.toUpperCase();

	const classNames = cx(
		styles.wrapper,
		`fw-${fw}`,
		{
			[styles.sizeXs]: size === 'xs',
			[styles.sizeSm]: size === 'sm',
			[styles.sizeMd]: size === 'md',
			[styles.sizeLg]: size === 'lg',
			[styles.sizeXl]: size === 'xl',
			[styles.inverted]: inverted,
		},
		className
	);

	if (isLoading) {
		return (
			<Skeleton
				className={classNames}
				circle
			/>
		);
	}

	return <div className={classNames}>{truncatedName}</div>;
};

type TUserAvatarProps = Omit<TAvatarProps, 'name'>;

export const UserAvatar = (props: TUserAvatarProps) => {
	const { vinistoUser } = useContext(AuthenticationContext);
	const name = vinistoUser?.nickname ?? vinistoUser?.email ?? '';

	return (
		<Avatar
			name={name}
			isLoading={!name}
			{...props}
		/>
	);
};

export default Avatar;
