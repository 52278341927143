import Skeleton from 'react-loading-skeleton';
import useFormatMessage from 'Hooks/useFormatMessage';
import VinistoLink from 'Components/VinistoLink';
import removeDiacritics from 'Helpers/removeDiacritics';

import { ColumnProps } from './interfaces';
import styles from './styles.module.css';

const Column = ({ letter, values, isLoading }: ColumnProps) => {
	const t = useFormatMessage();

	return (
		<div className={styles.wrapper}>
			{/* ID Element is hidden & offseted from top to work with native #hash scrolling and top navbar height */}
			<div
				id={letter ?? undefined}
				className={styles.letterId}
			/>
			<h2 className={styles.title}>
				{isLoading ? <Skeleton width="4rem" /> : letter}
			</h2>
			<div className={styles.valuesList}>
				{values?.map((value, index) =>
					isLoading ? (
						<Skeleton key={index} />
					) : (
						<VinistoLink
							to={`/${t({
								id: 'routes.products.route',
							})}/Vyrobce/${removeDiacritics(`${value.url}`)?.replace(
								/\s/g,
								'+'
							)}`}
							key={index}
							className={styles.value}
						>
							{value.value}
						</VinistoLink>
					)
				)}
			</div>
		</div>
	);
};

export default Column;
