import { apiServiceInstance } from 'Services/ApiService';

import { NEWSLETTER_API_ENDPOINT } from './constants';

const NewsletterService = {
	subscribe: (email: string) => {
		return apiServiceInstance.post(NEWSLETTER_API_ENDPOINT, {
			email,
		});
	},
	unsubscribe: (email: string) => {
		return apiServiceInstance.put(NEWSLETTER_API_ENDPOINT, {
			email,
		});
	},
};

export default NewsletterService;
