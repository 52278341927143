import { FC } from 'react';

import { IIconProps } from './Interfaces';

const HeaderBottleWineIcon: FC<IIconProps> = ({ className }) => {
	return (
		<svg
			width={6.484}
			height={20.573}
			viewBox="0 0 6.484 20.573"
			className={className}
		>
			<path
				d="M291.649,128.915a2.651,2.651,0,0,0-.8-1.9c-.329-.349-.655-.7-.98-1.049a1.5,1.5,0,0,1-.431-1.069c.012-1.485,0-2.97,0-4.455a.482.482,0,0,0-.525-.419H287.9a.481.481,0,0,0-.525.419c0,1.485-.011,2.97,0,4.455a1.491,1.491,0,0,1-.431,1.069q-.486.526-.979,1.049a2.646,2.646,0,0,0-.8,1.9c.026,3.539.011,7.077.012,10.616a2.142,2.142,0,0,0,.025.382c.1.558.614.682,1.362.682h3.686c.747,0,1.262-.124,1.362-.682a2.264,2.264,0,0,0,.024-.382C291.637,135.992,291.622,132.453,291.649,128.915Zm-5.258.095a8.3,8.3,0,0,1-.135.978,3.676,3.676,0,0,1,.089-1.984,6.007,6.007,0,0,1,1.386-1.638C287.2,127.235,286.4,128,286.39,129.009Z"
				transform="translate(-285.165 -120.021)"
				fill="#4d4d4e"
			/>
		</svg>
	);
};

export default HeaderBottleWineIcon;
