import { FC } from 'react';
import { get } from 'lodash-es';
import Config from 'Config';

import LoginModal from './Components/Login';
import RegisterModal from './Components/Register';
import ForgottenPasswordModal from './Components/ForgottenPassword';
import RegistrationConfirmModal from './Components/RegisterConfirm';
import ForgottenConfirmModal from './Components/ForgottenConfirm';
import AddressInvoice from './Components/AddressInvoice';
import AddressDelivery from './Components/AddressDelivery';
import ReviewModal from './Components/Review';
import FillNicknameModal from './Components/FillNickname';
import PaymentModal from './Components/Payment';
import CookiesModal from './Components/Cookies';
import AddToBasketModal from './Components/AddToBasket';
import ConfirmDialog from './Components/ConfirmDialog';
import ZasilkovnaModal from './Components/Zasilkovna';
import PPLModal from './Components/PPL';
import CancelOrderConfirm from './Components/CancelOrderConfirm';
import {
	ADD_TO_BASKET_MODAL,
	ADDRESS_DELIVERY_MODAL,
	ADDRESS_INVOICE_MODAL,
	CANCEL_ORDER_CONFIRM_MODAL,
	CONFIRM_DIALOG,
	COOKIES_MODAL,
	FILL_NICKNAME_MODAL,
	FORGOTTEN_PASSWORD_CONFIRM_MODAL,
	FORGOTTEN_PASSWORD_MODAL,
	LOGIN_MODAL,
	PAYMENT_MODAL,
	PPL_MODAL,
	REGISTRATION_CONFIRM_MODAL,
	REGISTRATION_MODAL,
	REVIEW_MODAL,
	ZASILKOVNA_MODAL,
} from './constants';

export const getModalTitleLocalization = (
	modalType: string | null
): string | null => {
	if (modalType === LOGIN_MODAL) {
		return 'modal.logIn.modalTitle';
	} else if (modalType === REGISTRATION_MODAL) {
		return 'modal.registration.modalTitle';
	} else if (modalType === FORGOTTEN_PASSWORD_MODAL) {
		return 'modal.forgottenPassword.modalTitle';
	} else if (modalType === REGISTRATION_CONFIRM_MODAL) {
		return 'modal.registrationConfirm.modalTitle';
	} else if (modalType === FORGOTTEN_PASSWORD_CONFIRM_MODAL) {
		return 'modal.forgottenPasswordConfirm.modalTitle';
	} else if (modalType === ADDRESS_INVOICE_MODAL) {
		return 'modal.invoiceAddress.modalTitle';
	} else if (modalType === ADDRESS_DELIVERY_MODAL) {
		return 'modal.deliveryAddress.modalTitle';
	} else if (modalType === FILL_NICKNAME_MODAL) {
		return 'modal.fillNickname.modalTitle';
	} else if (modalType === PAYMENT_MODAL) {
		return 'modal.payment.modalTitle';
	} else if (modalType === COOKIES_MODAL) {
		return 'modal.cookies.modalTitle';
	} else if (modalType === ADD_TO_BASKET_MODAL) {
		return 'modal.addToBasket.modalTitle';
	} else if (modalType === CONFIRM_DIALOG) {
		return 'modal.confirmDialog.modalTitle';
	} else if (modalType === CANCEL_ORDER_CONFIRM_MODAL) {
		return 'modal.cancelOrderConfirm.modalTitle';
	}

	return null;
};

export const getModalComponent = (modalType: string | null): FC | null => {
	if (modalType === LOGIN_MODAL) {
		return LoginModal;
	} else if (modalType === REGISTRATION_MODAL) {
		return RegisterModal;
	} else if (modalType === FORGOTTEN_PASSWORD_MODAL) {
		return ForgottenPasswordModal;
	} else if (modalType === REGISTRATION_CONFIRM_MODAL) {
		return RegistrationConfirmModal;
	} else if (modalType === FORGOTTEN_PASSWORD_CONFIRM_MODAL) {
		return ForgottenConfirmModal;
	} else if (modalType === ADDRESS_INVOICE_MODAL) {
		return AddressInvoice;
	} else if (modalType === ADDRESS_DELIVERY_MODAL) {
		return AddressDelivery;
	} else if (modalType === REVIEW_MODAL) {
		return ReviewModal;
	} else if (modalType === FILL_NICKNAME_MODAL) {
		return FillNicknameModal;
	} else if (modalType === PAYMENT_MODAL) {
		return PaymentModal;
	} else if (modalType === COOKIES_MODAL) {
		return CookiesModal;
	} else if (modalType === ADD_TO_BASKET_MODAL) {
		return AddToBasketModal;
	} else if (modalType === CONFIRM_DIALOG) {
		return ConfirmDialog;
	} else if (modalType === ZASILKOVNA_MODAL) {
		return ZasilkovnaModal;
	} else if (modalType === PPL_MODAL) {
		return PPLModal;
	} else if (modalType === CANCEL_ORDER_CONFIRM_MODAL) {
		return CancelOrderConfirm;
	}

	return null;
};

export const getModalConfigByType = (modalType: string | null) => {
	const currentModalConfig = get(Config, `modals.${modalType}`, {});

	return currentModalConfig;
};
