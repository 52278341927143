import { useContext } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import getBundleImage, { IMAGE_SIZE_THUMB_80x80 } from 'Helpers/getBundleImage';
import { LocalizationContext } from 'Services/LocalizationService';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { SideBasketContext } from 'Components/SideBasket/context';

import { IBundleProps } from './interfaces';
import styles from './styles.module.css';

const BundleItem = ({ bundleData }: IBundleProps) => {
	const { openedBundleId } = useContext(SideBasketContext);
	const bundleId = bundleData.bundleId ?? '';
	const t = useContext(LocalizationContext).useFormatMessage();
	const getLocalizedValue = useLocalizedValue();
	const quantity = bundleData.quantity ?? '';

	return (
		<>
			<div
				className={cx(styles.item, {
					active: openedBundleId === bundleId,
				})}
			>
				<Link
					to={`/${t({
						id: 'routes.product.route',
					})}/${getLocalizedValue(bundleData.bundle.url ?? [])}`}
					className={styles.itemWrap}
				>
					<span className={styles.count}>{quantity}</span>
					<img
						className={styles.image}
						src={getBundleImage(
							bundleData.bundle.images ?? [],
							IMAGE_SIZE_THUMB_80x80
						)}
						alt={`${t({ id: 'alt.bundleImage' })}`}
					/>
				</Link>
			</div>
		</>
	);
};

export default BundleItem;
