import { apiServiceInstance } from 'Services/ApiService';

export const fetchOrder = (
	orderId: string,
	userLoginHash: string
): Promise<unknown> => {
	if (!userLoginHash)
		return new Promise((_, reject) =>
			reject('User login hash has to be provided.')
		);
	if (!orderId)
		return new Promise((_, reject) => reject('OrderId has to be provided.'));

	return apiServiceInstance.get(
		'order-api/orders/' + orderId,
		false,
		undefined,
		[
			{ key: 'orderId', value: orderId },
			{ key: 'UserLoginHash', value: userLoginHash },
		]
	);
};
