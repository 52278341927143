import { VinistoMongoConnectorModelsMultiLangValue } from '@/api-types/order-api';
import { LangValuePair } from '@/shared';

interface Adapter<DomainType, ApiType> {
	fromApi(apiData: ApiType, request: Record<PropertyKey, any>): DomainType;
	toApi(domainData: DomainType): ApiType;
	isValid(item: unknown): item is ApiType;
}

abstract class AbstractAdapter<DomainType, ApiType>
	implements Adapter<DomainType, ApiType>
{
	abstract fromApi(apiData: ApiType, request: Record<PropertyKey, any>): DomainType;

	toApi(domainData: DomainType): ApiType {
		throw new Error(`toApi is not implemented: ${domainData}`);
	}

	isValid(item: unknown): item is ApiType {
		throw new Error('isValid is not implemented');
	}

	protected convertMultiLangValue(
		multiLangValue:
			| VinistoMongoConnectorModelsMultiLangValue[]
			| null
			| undefined
	): LangValuePair[] {
		if (multiLangValue === undefined || multiLangValue === null) {
			return [];
		}

		return (
			multiLangValue
				?.map((item) => ({
					language: item.language || '',
					value: item.value || '',
				}))
				.filter((item) => item.value !== '') || []
		);
	}
}

export { AbstractAdapter };
export type { Adapter };
