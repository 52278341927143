import { apiServiceInstance } from 'Services/ApiService';

import { SKELETONS_NUM_SUBCATEGORIES } from './constants';
import {
	IFetchCategoryByUrlProps,
	IFetchCategorySubcategoriesProps,
} from './interfaces';

import { VinistoProductDllModelsApiCategoryCategoryReturn } from '@/api-types/product-api';

export const fetchCategoryByUrl = async (props: IFetchCategoryByUrlProps) => {
	const categoryUrl = props.categoryUrl;
	const requestParams = [{ key: 'IsCache', value: true }];

	const categoryData =
		await apiServiceInstance.get<VinistoProductDllModelsApiCategoryCategoryReturn>(
			`product-api/categories/${categoryUrl}/GetCategoryByUrl`,
			false,
			undefined,
			requestParams
		);
	return categoryData;
};

export const fetchCategorySubcategories = async (
	props: IFetchCategorySubcategoriesProps
) => {
	const categoryId = props.categoryId;

	const subcategoriesData = await apiServiceInstance.get(
		`product-api/categories/${categoryId}/subcategories`
	);
	return subcategoriesData;
};

export const generateSubCategoriesToShow = (
	subCategories: Record<string, any>[],
	isLoading = false
) => {
	if (isLoading)
		return Array(SKELETONS_NUM_SUBCATEGORIES).map(() => ({
			isLoading: true,
		}));
	return subCategories;
};
