import { useContext, useEffect, useMemo, useState } from 'react';
import ApiService from 'Services/ApiService';
import { NotificationsContext } from 'Services/NotificationService';
import { LocalizationContext } from 'Services/LocalizationService';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { OrderContext } from 'Services/OrderService/context';
import {
	VinistoOrderDllModelsApiDeliveryDeliveryGetByConditionsParameters,
	VinistoOrderDllModelsApiReturnDataDeliveriesReturn,
} from 'vinisto_api_client/src/api-types/order-api';

import { ERRORS } from './constants';
import { IOrderDeliveryData } from './interfaces';

const useDeliveryDetail = (deliveryId: string) => {
	const [deliveryDetail, setDeliveryDetail] = useState<IOrderDeliveryData>({
		isLoaded: false,
		// @ts-expect-error refactor to react-query
		data: {},
		deliveryPrice: null,
	});

	const apiService = useMemo(() => new ApiService(), []);
	const authenticationContext = useContext(AuthenticationContext);
	const localizationContext = useContext(LocalizationContext);
	const notificationsContext = useContext(NotificationsContext);
	const orderContext = useContext(OrderContext);

	useEffect(() => {
		if (!deliveryId) {
			setDeliveryDetail({
				isLoaded: true,
				// @ts-expect-error refactor to react-query
				data: {},
				deliveryPrice: null,
			});
			return;
		}
		setDeliveryDetail({
			isLoaded: false,
			// @ts-expect-error refactor to react-query
			data: {},
			deliveryPrice: null,
		});
		if (!deliveryId) return;
		const authorizationParams = authenticationContext?.isLoggedIn
			? {
					userLoginHash: authenticationContext?.vinistoUser?.loginHash,
			  }
			: {
					anonymousUser: authenticationContext?.anonymousUID?.anonymousUserId,
			  };

		apiService
			.post<VinistoOrderDllModelsApiReturnDataDeliveriesReturn>(
				`order-api/deliveries/GetDeliveriesByBasket`,
				{
					language: localizationContext?.activeLanguageKey,
					currency: localizationContext?.activeCurrency?.currency,
					allowedCountry: localizationContext.countryOfSale,
					...authorizationParams,
				} as VinistoOrderDllModelsApiDeliveryDeliveryGetByConditionsParameters,
				true
			)
			.then((response) => {
				const selectedDelivery = response?.deliveries
					?.filter(({ isActive }) => isActive)
					?.find(({ id }) => id === deliveryId);

				const deliveryPrice = selectedDelivery?.prices?.[0] ?? null;

				if (!selectedDelivery || !deliveryPrice) {
					throw new Error(ERRORS.DELIVERY_NO_LONGER_AVAILABLE);
				}

				setDeliveryDetail({
					isLoaded: true,
					data: selectedDelivery,
					deliveryPrice,
				});
			})
			.catch((err) => {
				orderContext.clearDeliveryPayment();
				if (err?.message === ERRORS.DELIVERY_NO_LONGER_AVAILABLE) {
					notificationsContext.handleShowErrorNotification(
						'notification.message.orderOverview.deliveryNoLongerAvailable'
					);
				} else {
					notificationsContext.handleShowErrorNotification(
						'notification.message.orderOverview.deliveryLoadError'
					);
				}
				setDeliveryDetail({
					isLoaded: true,
					// @ts-expect-error refactor to react-query
					data: {},
					deliveryPrice: null,
				});
			});
	}, [deliveryId, notificationsContext]);

	return deliveryDetail;
};

export default useDeliveryDetail;
