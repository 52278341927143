export const ORDER_NOT_EXIST = 'ObjectNotFound';
export const OBJECT_PERMISSION_ERROR = 'ObjectPermissionError';
export const GOPAY_PAYMENT_STATUS_ERROR = 'GOPAY_PAYMENT_STATUS_ERROR';

export enum ORDER_STATE {
	CREATED = 'CREATED',
	PAID = 'PAID',
}

export enum PAYMENT_STATE {
	CREATED = 'CREATED',
	PAID = 'PAID',
	CANCELED = 'CANCELED',
}

export const ANALYTICS_SENT_STORAGE_KEY = 'ANALYTICS_SENT';
export const NOT_FOUND = -1;
