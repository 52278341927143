import { lazy, Suspense, useContext } from 'react';
import cx from 'classnames';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LocalizationContext } from 'Services/LocalizationService';
import { DeviceServiceContext } from 'Services/DeviceService';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { VinistoSpanLink } from 'Components/VinistoLink';
import Loader from 'Components/View/Loader';

import { IBundleBreadcrumbProps } from './interfaces';
import styles from './styles.module.css';

const BreadcrumbHomeIcon = lazy(
	() => import('Components/Icons/BreadcrumbHome')
);

const BundleBreadcrumb = ({
	categories,
	bundleName,
	className,
}: IBundleBreadcrumbProps) => {
	const { isMobile } = useContext(DeviceServiceContext);
	const localizationContext = useContext(LocalizationContext);

	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();

	const category0 = categories && categories[0];
	const category1 = categories && categories[1];

	return (
		<Breadcrumb className={cx(styles.breadcrumbs, className)}>
			<Breadcrumb.Item
				linkAs={Link}
				linkProps={{ to: '/' }}
			>
				<Suspense fallback={<Loader blank />}>
					<BreadcrumbHomeIcon
						id="bundle-detail-breadcrumbs-homepage-ico"
						alt={t({ id: 'alt.breadcrumb.home' })}
						title=""
						className={styles.homeIcon}
					/>
				</Suspense>
			</Breadcrumb.Item>
			<Breadcrumb.Item
				linkAs={Link}
				linkProps={{
					to: `/${t({ id: 'routes.category.route' })}/${
						getLocalizedValue(category0?.url || []) ?? ''
					}`,
				}}
				className={styles.breadcrumb}
			>
				<VinistoSpanLink className={styles.link}>
					{getLocalizedValue(category0?.name || []) || '?'}
				</VinistoSpanLink>
			</Breadcrumb.Item>
			<Breadcrumb.Item
				linkAs={Link}
				linkProps={{
					to: `/${t({ id: 'routes.category.route' })}/${
						getLocalizedValue(category1?.url || []) ?? ''
					}`,
				}}
				className={styles.breadcrumb}
			>
				<VinistoSpanLink className={styles.link}>
					{getLocalizedValue(category1?.name || []) || '?'}
				</VinistoSpanLink>
			</Breadcrumb.Item>
			{!isMobile && (
				<Breadcrumb.Item
					active
					className={styles.breadcrumb}
				>
					{bundleName}
				</Breadcrumb.Item>
			)}
		</Breadcrumb>
	);
};

export default BundleBreadcrumb;
