import Skeleton from 'react-loading-skeleton';
import cx from 'classnames';
import Checkbox from 'Components/Forms/Components/Checkbox';
import { DeliveryMethodsPlacement } from 'Components/DeliveryMethods/types';

import { ShippingItemProps } from './interfaces';
import styles from './styles.module.css';
import { getPaymentIconData, getShippingIconData } from './helpers';

const ShippingItem = ({
	onClick,
	isLoading,
	isSelected,
	titleContent,
	priceContent,
	dateContent,
	selectable = false,
	handleReset,
	dataTestid,
	title,
	note,
	isPaymentItem,
	placement = DeliveryMethodsPlacement.PRODUCT_DETAIL,
	showIcons = true,
	...restProps
}: ShippingItemProps) => {
	const {
		source: iconSource,
		className: iconClassName,
		fallbackSource,
	} = isPaymentItem
		? // TODO: getPaymentIconData clearly expects string, but gets ReactNode! It works somehow, but it does not seem to be expected bevavior
		  getPaymentIconData(titleContent)
		: getShippingIconData(title);

	return (
		<label
			className={cx(
				styles.wrapper,
				{
					[styles.selected]: isSelected,
					[styles.loading]: isLoading,
					[styles.selectDisabled]: !selectable,
					[styles.checkoutPlacement]:
						placement === DeliveryMethodsPlacement.CHECKOUT,
					[styles.productDetailPlacement]:
						placement === DeliveryMethodsPlacement.PRODUCT_DETAIL,
				},
				restProps.className
			)}
			data-testid={dataTestid}
		>
			{selectable && (
				<div className={styles.radioWrapper}>
					{isLoading ? (
						<Skeleton
							width="20px"
							height="20px"
							style={{ marginRight: '0.25em' }}
							borderRadius=".313rem"
						/>
					) : (
						<Checkbox
							type={isSelected ? 'checkbox' : 'radio'}
							className="mb-0"
							checked={isSelected}
							onChange={isSelected ? handleReset : onClick}
						/>
					)}
				</div>
			)}

			{showIcons && (
				<div className={styles.iconWrapper}>
					{isLoading ? (
						<Skeleton
							width={40}
							height={40}
						/>
					) : (
						<div className={cx(styles.icon, iconClassName)}>
							<img
								src={`/assets/checkout-icons/${iconSource}`}
								onError={(e) => {
									(
										e.target as HTMLInputElement
									).src = `/assets/checkout-icons/${fallbackSource}`;
								}}
							/>
						</div>
					)}
				</div>
			)}

			<div className={styles.textContent}>
				<div className={styles.title}>
					{isLoading ? (
						<Skeleton width="90px" />
					) : (
						<>
							{titleContent}
							{note ? <div className={styles.note}>{note}</div> : null}
						</>
					)}
				</div>
				<div className={styles.priceDate}>
					{dateContent && (
						<div className={styles.date}>
							{isLoading ? <Skeleton width="120px" /> : dateContent}
						</div>
					)}
					{selectable && (
						<div className={styles.price}>
							{isLoading ? <Skeleton width="60px" /> : priceContent}
						</div>
					)}
				</div>
			</div>
		</label>
	);
};

export default ShippingItem;
