import { FC, useContext } from 'react';
import cx from 'classnames';
import Pagination from 'react-responsive-pagination';
import { LocalizationContext } from 'Services/LocalizationService';
import ItemCardSCrollArrowIcon from 'Components/Icons/ItemCardScrollArrow';
import { DeviceServiceContext } from 'Services/DeviceService';

import { PaginationData } from './interfaces';
import styles from './styles.module.css';

/**
 * This component is used to pagination
 * @prop currentPage - number of current page
 * @prop totalPaginationPages - number of maximum pagination pages
 * @prop itemsToLoadMore -  is there items to load more
 * @prop handleOnLoadMore - function to load more items
 * @prop handleOnSelectPreviousPage - function to go to previous page
 * @prop handleOnSelectNextPage - function to go to previous page
 * @prop handleOnSelectPage - function to go to selected page
 */

const PaginationNav: FC<PaginationData> = ({
	className,
	currentPage,
	totalPaginationPages,
	itemsToLoadMore,
	showLoadMore = true,
	handleOnLoadMore,
	handleOnSelectPreviousPage,
	handleOnSelectNextPage,
	handleOnSelectPage,
}) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const previousButtonDisabled = currentPage <= 1;
	const { layoutWidth } = useContext(DeviceServiceContext);

	return (
		<div className={`${className} ${styles.loadMore}`}>
			<div className={styles.empty}></div>
			<div className={`moreBtnWrap ${styles.moreBtnWrap}`}>
				<button
					className={styles.previousPage}
					onClick={handleOnSelectPreviousPage}
					disabled={previousButtonDisabled}
				>
					<ItemCardSCrollArrowIcon
						id="category-pagination-next-ico"
						alt={t({
							id: 'pagination.previousPage',
						})}
						title={``}
						className={styles.arrow}
					/>
				</button>
				{itemsToLoadMore !== 0 && (
					<>
						<button
							className={cx(styles.moreBtn, 'vinisto-btn vinisto-bg-green')}
							onClick={() => handleOnLoadMore()}
						>
							{t(
								{
									id: 'pagination.loadAnother',
								},
								{
									count: itemsToLoadMore,
								}
							)}
						</button>
						<button
							className={styles.nextPage}
							onClick={() => handleOnSelectNextPage()}
						>
							<ItemCardSCrollArrowIcon
								id="category-pagination-next-ico"
								alt={t({
									id: 'pagination.previousPage',
								})}
								title={``}
								className={styles.arrow}
							/>
						</button>
					</>
				)}
			</div>
			<div
				className={cx(
					styles.paginationWrap,
					{ [styles.paginationWrapMobile]: !showLoadMore },
					className
				)}
			>
				<Pagination
					className={styles.paging}
					pageItemClassName={styles.pagingItem}
					activeItemClassName={styles.active}
					pageLinkClassName={styles.pagingLink}
					disabledItemClassName={styles.pagingDisabled}
					renderNav={false}
					srOnlyClassName=""
					a11yActiveLabel={`${currentPage}`}
					ariaCurrentAttr={true}
					narrowStrategy={['dropNav', 'dropEllipsis']}
					current={currentPage}
					total={totalPaginationPages}
					onPageChange={handleOnSelectPage}
					maxWidth={layoutWidth < 500 ? undefined : 450}
				/>
			</div>
		</div>
	);
};

export default PaginationNav;
