import { useContext } from 'react';
import cx from 'classnames';
import useFormatMessage from 'Hooks/useFormatMessage';
import { useFormContext } from 'react-hook-form';
import { ModalContext } from 'Components/Modal/context';
import { LOGIN_MODAL } from 'Components/Modal/constants';
import { VinistoSpanLink } from 'Components/VinistoLink';
import { TEST_IDS } from 'Constants/test-ids';

import styles from './styles.module.css';

type TLoginMessageProps = {
	className?: string;
};

const LoginMessage = ({ className }: TLoginMessageProps) => {
	const modalContext = useContext(ModalContext);
	const t = useFormatMessage();

	const { watch } = useFormContext();

	const email = watch('email.delivery');

	const handleOpenLoginModal = () => {
		modalContext.handleOpenModal(LOGIN_MODAL, { email });
	};

	return (
		<div className={cx(styles.wrapper, className)}>
			<div className={styles.infoBox}>
				<div className={styles.hexagon}>
					<div>i</div>
				</div>
			</div>
			<div className={styles.messageBox}>
				{t(
					{ id: 'cartShippingData.logInMessage' },
					{
						logIn: (
							<VinistoSpanLink
								className="vinisto-color-darker-gray fw-bolder"
								onClick={handleOpenLoginModal}
								data-testid={TEST_IDS.BASKET_ADDRESS_LOGIN}
							>
								{t({
									id: 'cartShippingData.logInMessage.logIn',
								})}
							</VinistoSpanLink>
						),
					}
				)}
			</div>
		</div>
	);
};

export default LoginMessage;
