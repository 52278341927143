import cx from 'classnames';

import { IconGiftVariant, IconProps } from './interfaces';
import styles from './styles.module.css';

const GiftIcon = ({
	className,
	width = 41,
	height = 39.5,
	variant = IconGiftVariant.SIMPLE,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 40.951 39.451"
			className={cx(className, {
				[styles.iconGiftVariantSimple]: variant === IconGiftVariant.SIMPLE,
				[styles.iconGiftVariantActive]: variant === IconGiftVariant.ACTIVE,
				[styles.iconGiftVariantInActive]: variant === IconGiftVariant.INACTIVE,
			})}
		>
			<g transform="translate(-1153.8 -863.899)">
				<path
					d="M20.717,0a5,5,0,0,1,4.335,2.508l5,8.689a5,5,0,0,1,0,4.984l-5,8.689a5,5,0,0,1-4.335,2.508H10.763a5,5,0,0,1-4.335-2.508l-5-8.689a5,5,0,0,1,0-4.984l5-8.689A5,5,0,0,1,10.763,0Z"
					transform="translate(1167.49 863.899) rotate(30)"
					fill="#ad406f"
				/>
				<g transform="translate(1167.741 875.591)">
					<path
						d="M626.07,117.164h.454a2.223,2.223,0,0,1,1.126.557c.3.271.591.545.859.842.248.274.461.581.693.879l.036-.046c.213-.283.405-.584.643-.844a3.667,3.667,0,0,1,1.976-1.388h.454a.859.859,0,0,0,.091.029,1.825,1.825,0,0,1,.268,3.513c-.053.021-.1.045-.157.067l.009.022h.155c.77,0,1.541,0,2.311,0a.7.7,0,0,1,.729.664c.013.765.01,1.531,0,2.3a.687.687,0,0,1-.531.642c-.058.015-.117.026-.185.04v6.411a.745.745,0,0,1-.836.838h-9.94a.747.747,0,0,1-.847-.843v-6.4a.78.78,0,0,1-.726-.886c.018-.661,0-1.323.005-1.985a.722.722,0,0,1,.783-.774h2.3c.042,0,.086,0,.154-.007-.175-.084-.33-.143-.471-.226a1.812,1.812,0,0,1,.144-3.233A4.22,4.22,0,0,1,626.07,117.164Zm1.678,4.367v9.42h2.885v-9.42ZM627,130.951v-6.517h-2.882v6.517Zm7.264-6.517h-2.885v6.516h2.885Zm-10.877-.748H627v-2.153h-3.611Zm7.993-2.156v2.154h3.613V121.53Zm-1.828-1.1c.116.024.2.045.28.057a3.618,3.618,0,0,0,1.155-.058,5.413,5.413,0,0,0,1.59-.483,1.084,1.084,0,1,0-1.082-1.877,5.649,5.649,0,0,0-1.819,2.022A2.572,2.572,0,0,0,629.552,120.426Zm-.731-.01a1.348,1.348,0,0,0-.1-.286,10.573,10.573,0,0,0-.6-.905,4.8,4.8,0,0,0-1.242-1.168,1.086,1.086,0,0,0-1.658.943,1.084,1.084,0,0,0,.756,1.027,6.578,6.578,0,0,0,2.087.476A1.839,1.839,0,0,0,628.821,120.416Z"
						transform="translate(-622.652 -117.164)"
						fill="#fff"
					/>
				</g>
			</g>
		</svg>
	);
};

export default GiftIcon;
