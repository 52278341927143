import { useContext, useRef } from 'react';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { LocalizationContext } from 'Services/LocalizationService';
import { WarehouseContext } from 'Services/WarehouseService';
import BundleTag from 'Components/ProductBox/Components/BundleTag';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';
import ButtonAddToFavorites from 'Components/ProductBox/Components/ButtonAddToFavorites';
import { DirectQuantityBox } from 'Components/QuantityBox';
import ProductSpecifications from 'Components/ProductBox/Components/Specifications';
import ProductTitleAndImage from 'Components/ProductBox/Components/TitleAndImage';
import StockAvailability from 'Components/StockAvailability';
import quantityBoxStyles from 'Components/QuantityBox/styles.module.css';
import ProductBasketCount from 'Components/ProductBox/Components/ProductBasketCount';
import { useProductBasketCount } from 'Components/ProductBox/hooks';
import purgeHTML from 'Helpers/purgeHTML';
import useFavoriteItem from 'Components/ProductBox/Components/ButtonAddToFavorites/hook';
import { DiscountPercentage } from 'vinisto_ui';

import { IBundleProps } from './Interfaces';
import styles from './style.module.css';

import { bundleAdapter } from '@/index';

const BundleItem = (props: IBundleProps) => {
	const localizationContext = useContext(LocalizationContext);
	const warehouseContext = useContext(WarehouseContext);

	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();
	const { title: currencyTitle, currency } = localizationContext.activeCurrency;

	const bundle =
		props.bundleData && 'isLoading' in props.bundleData
			? null
			: bundleAdapter.fromApi(props.bundleData, { currency });

	const bundleId = bundle?.id ?? '';
	const bundleUrl = getLocalizedValue(bundle?.url ?? []);
	const bundleDescription = getLocalizedValue(bundle?.description ?? []);
	const specificationDetails = bundle?.specificationDetails ?? [];
	const { shortVariety: producerName, component: flag } =
		getFlagSpecification(specificationDetails);

	const alwaysRedirectToDetail = props?.alwaysRedirectToDetail ?? false;
	const isTemporaryUnavailable = bundle?.flags.isTemporaryUnavailable ?? false;
	const isGift = bundle?.flags.isGift ?? false;

	const isLoading = props?.isLoading ?? false;

	const bundlePrices = bundle?.bundlePrices;
	const { basePrice, discountedPrice, isDiscounted } = bundlePrices ?? {};

	const { basketQuantityPopover } = useProductBasketCount(bundle);

	const availableQuantity = [warehouseContext.getQuantity(bundleId)].filter(
		(x): x is number => x !== undefined
	);

	const orderLimitation = bundle?.orderLimitation;

	const { handleAddItemToFavorites, handleRemoveItemFromFavorites } =
		useFavoriteItem(bundleId, {
			item_name: getLocalizedValue(bundle?.name),
			price:
				(isDiscounted
					? discountedPrice?.valueWithVat
					: basePrice?.valueWithVat) ?? 0,
			currency: currencyTitle,
		});

	const bundleItemRef = useRef<HTMLDivElement | null>(null);

	return (
		<div
			className={cx('vinisto-wine__item', { loading: isLoading })}
			ref={bundleItemRef}
		>
			{isLoading ? (
				<ProductTitleAndImage
					isLoading
					bundle={bundle}
				/>
			) : (
				<>
					<Link
						className="vinisto-wine__item-overlay"
						to={`/${t({ id: 'routes.product.route' })}/${bundleUrl}`}
						rel="nofollow"
						id={'bundle-link-key-' + props.position}
					/>
					<ProductBasketCount text={basketQuantityPopover} />
					<div className="vinisto-wine__img-labels">
						{!isLoading &&
							bundle?.tags?.map((tagDetail, index) => (
								<BundleTag
									{...{ tagDetail }}
									key={`category-detail-grid-tag-item-${
										tagDetail?.id ?? index
									}`}
									isLink={false}
								/>
							))}
					</div>
					<Link
						to={`/${t({
							id: 'routes.product.route',
						})}/${bundleUrl}`}
						tabIndex={-1}
					>
						<ProductTitleAndImage bundle={bundle} />
					</Link>
					{!isTemporaryUnavailable && !isGift && (
						<ButtonAddToFavorites
							itemId={bundleId}
							addToFavorites={handleAddItemToFavorites}
							removeItemFromFavorites={handleRemoveItemFromFavorites}
						/>
					)}
				</>
			)}
			<BundleProducer
				flag={flag}
				name={producerName}
				isLoading={isLoading}
				isLink={false}
			/>
			<div className="vinisto-wine__specifications">
				<ProductSpecifications
					specifications={specificationDetails}
					isLoading={isLoading}
				/>
			</div>
			{isLoading ? (
				<Skeleton
					containerClassName="vinisto-wine__desc d-block"
					className="d-block h-100"
					inline
				/>
			) : (
				<>
					<p className="vinisto-wine__desc max-lines--4 ">
						{purgeHTML(bundleDescription)}
					</p>
				</>
			)}
			<div className="vinisto-wine__bottom-info">
				<div className="vinisto-wine__price">
					{isLoading ? (
						<Skeleton
							width="100%"
							inline
						/>
					) : (
						<>
							{isDiscounted && (
								<span className="vinisto-wine__price--original">
									{basePrice?.getFormatedValueWithVat()}
								</span>
							)}
							<div className={styles.discountPriceWrap}>
								<span>
									{isDiscounted
										? discountedPrice?.getFormatedValueWithVat()
										: basePrice?.getFormatedValueWithVat()}
								</span>
								{isDiscounted && (
									<DiscountPercentage
										discountedPriceWithVat={discountedPrice?.valueWithVat ?? 0}
										standardPriceWithVat={basePrice?.valueWithVat ?? 0}
										className={styles.discountPercentage}
									/>
								)}
							</div>
						</>
					)}
				</div>
				<div className="vinisto-wine__price--no-vat">
					{isLoading ? (
						<Skeleton
							width="100%"
							inline
						/>
					) : (
						t(
							{
								id: 'carousel.info.withoutVAT',
							},
							{
								priceWithCurrency: isDiscounted
									? discountedPrice?.getFormatedValue()
									: basePrice?.getFormatedValue(),
							}
						)
					)}
				</div>
				<div className="vinisto-wine__stock">
					<StockAvailability
						availableQuantity={availableQuantity}
						deliveryDate={warehouseContext.deliveryDate}
						isTemporaryUnavailable={isTemporaryUnavailable}
						fallback={<Skeleton width="70px" />}
					/>
				</div>
				{bundle && !alwaysRedirectToDetail && !isTemporaryUnavailable && (
					<div className={cx(styles.aboveProductLink, styles.cta, 'mt-3')}>
						<DirectQuantityBox
							bundle={bundle}
							className={quantityBoxStyles.fullWidth}
							orderLimitation={orderLimitation}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default BundleItem;
