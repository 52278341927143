import cx from 'classnames';
import CarouselSection from 'Components/CarouselSection';
import {
	DocumentHeaderAction,
	OpenGraphItemType,
	TwitterCardType,
} from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import Config from 'Config';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { VinistoProductDllModelsApiHomePageHomePageCustomCarouselsReturn } from 'vinisto_api_client/src/api-types/product-api';
import { LocalizationContext } from 'Services/LocalizationService';
import { WarehouseContext } from 'Services/WarehouseService';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';
import { DeviceServiceContext } from 'Services/DeviceService';

import ArticleTabs from './Components/ArticleTabs';
import BannerListBottom from './Components/BannerListBottom';
import BannerListTop from './Components/BannerListTop';
import BannerListUSP from './Components/BannerListUSP';
import LinkWidget from './Components/LinkWidget';
import { THRESHOLD_TOP_CAROUSELS } from './constants';
import styles from './styles.module.css';

import { Allowed_Sections } from '@/domain/link-widget/enums';
import { DEFAULT_BUNDLE_API_PARAMS } from '@/shared';
import api from '@/api';
import { bundleAdapter } from '@/index';

const HomePage = () => {
	const { isMobile, isTablet, isDesktop } = useContext(DeviceServiceContext);
	const localizationContext = useContext(LocalizationContext);
	const {
		activeCurrency: { currency },
		countryOfSale,
	} = localizationContext;
	const t = localizationContext.useFormatMessage();
	const warehouseContext = useContext(WarehouseContext);
	const { dispatch } = useContext(DocumentHeaderContext);
	const getLocalizedValue = useLocalizedValue();

	const customCarouselsQuery = useQuery(
		['customCarousels', { countryOfSale, currency }],
		async () => {
			const response =
				await api.get<VinistoProductDllModelsApiHomePageHomePageCustomCarouselsReturn>(
					'product-api/home-page/custom-carousels',
					{
						...DEFAULT_BUNDLE_API_PARAMS,
						IsCache: true,
						Limit: 20,
						SortingColumn: 'SEQUENCE_NUMBER',
						CountryOfSale: countryOfSale,
						Currency: currency,
					}
				);

			if (response.isError) return Promise.reject(response.error);

			const uniqueIds = Array.from(
				new Set(
					(response.homePageCustomCarousels ?? [])
						.flatMap((carousel) => carousel.bundles ?? [])
						.map((bundle) => bundle.id)
				)
			).filter((id): id is string => Boolean(id));

			warehouseContext.fetchQuantity(uniqueIds);

			const topCarousels = response.homePageCustomCarousels
				?.filter(
					(carousel) =>
						(carousel.sequenceNumber ?? 0) <= THRESHOLD_TOP_CAROUSELS
				)
				.map((carousel) => ({
					...carousel,
					bundles:
						carousel.bundles?.map((bundle) =>
							bundleAdapter.fromApi(bundle, { currency })
						) ?? [],
				}));

			const bottomCarousels = response.homePageCustomCarousels
				?.filter(
					(carousel) => (carousel.sequenceNumber ?? 0) > THRESHOLD_TOP_CAROUSELS
				)
				.map((carousel) => ({
					...carousel,
					bundles:
						carousel.bundles?.map(
							(bundle) => bundleAdapter.fromApi(bundle, { currency }),
							{
								currency,
							}
						) ?? [],
				}));

			return { topCarousels, bottomCarousels };
		}
	);

	const [isFirstRender, setIsFirstRender] = useState(true);

	useEffect(() => {
		if (!isFirstRender) return;
		setIsFirstRender(false);
		dispatch({
			type: DocumentHeaderAction.set,
			value: {
				title: `${t({ id: 'app.title.name' })}`,
				description: `${t({ id: 'app.description' })}`,
				twitterCard: {
					card: TwitterCardType.summary,
					title: Config.domainName,
					description: `${t({ id: 'app.description' })}`,
				},
				openGraph: {
					type: OpenGraphItemType.website,
					title: Config.domainName,
					description: `${t({ id: 'app.description' })}`,
					url: Config.baseUrl,
					image: '', // TODO: missing image from product ppl
					fbAdmins: Config.market.socials.facebookAdmins,
				},
				jsonLd: {
					'@context': 'https://schema.org',
					'@type': 'OnlineStore',
					name: `${t({ id: 'app.title.name' })}`,
					description: `${t({ id: 'app.description' })}`,
					image: [], // TODO: missing image from product ppl
					url: Config.baseUrl,
				},
			},
		});
	}, [dispatch, isFirstRender, t]);

	return (
		<>
			<section id="content-wrapper">
				<BannerListTop />
				<div className="container">
					<hr className="mb-0" />
				</div>

				{(isTablet || isMobile) && (
					<>
						<div className={cx('container', styles.linkWidgetContainer)}>
							<LinkWidget section={Allowed_Sections.HOMEPAGE_MOBILE} />
						</div>
						<div className="container">
							<hr />
						</div>
					</>
				)}

				<BannerListUSP />
				<div className="container mt-2">
					<hr className="mt-0" />
				</div>
				{isDesktop && (
					<>
						<div className={cx('container', styles.linkWidgetContainer)}>
							<LinkWidget section={Allowed_Sections.HOMEPAGE_DESKTOP} />
						</div>
						<div className="container">
							<hr />
						</div>
					</>
				)}

				{!!customCarouselsQuery?.data?.topCarousels?.length && (
					<>
						{customCarouselsQuery?.data?.topCarousels?.map(
							(carousel, index) => (
								<CarouselSection
									key={`topCarousels-${carousel?.id}-${index}`}
									data={carousel?.bundles ?? []}
									title={getLocalizedValue(carousel?.name)}
									isLoading={customCarouselsQuery.isLoading}
								/>
							)
						)}
						<div className="container">
							<hr />
						</div>
					</>
				)}

				<BannerListBottom />
				<div className="container">
					<hr />
				</div>
				{!!customCarouselsQuery?.data?.bottomCarousels?.length && (
					<>
						{customCarouselsQuery?.data?.bottomCarousels?.map(
							(carousel, index) => (
								<CarouselSection
									key={`bottomCarousels-${carousel?.id}-${index}`}
									data={carousel?.bundles ?? []}
									title={getLocalizedValue(carousel?.name)}
									// TODO: Enable loading state (this never happens now due to line 195)
									isLoading={customCarouselsQuery.isLoading}
								/>
							)
						)}
						<div className="container">
							<hr />
						</div>
					</>
				)}

				<div className="container">
					<div className="row">
						<ArticleTabs />
					</div>
				</div>
			</section>
			<Outlet />
		</>
	);
};

export default HomePage;
