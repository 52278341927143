import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash-es';

const useCustomNavigate = () => {
	const navigate = useNavigate();
	return React.useCallback(
		(event: React.MouseEvent<HTMLElement>, path: string) => {
			event && event.preventDefault();
			event && event.stopPropagation();
			if (get(event, 'button') === 1) {
				window.open(path, '_blank', 'noopener,noreferrer');
			} else if (get(event, 'button') === 0) {
				navigate(path);
			}
		},
		[navigate]
	);
};

export default useCustomNavigate;
