import { AxiosError } from 'axios';
import { VinistoHelperDllEnumsUserLoginHashType } from 'vinisto_api_client/src/api-types/user-api';

import ApiService from '../ApiService';

import {
	AUTH_API_PATH,
	CONFIRM_EMAIL_API_PATH,
	FORGOTTEN_PASSWORD_API_PATH,
	IS_EMAIL_ALREADY_USED_API_PATH,
	LOG_IN_API_PATH,
	LOG_OUT_API_PATH,
	REGISTRATION_API_PATH,
} from './constants';

const SHOP_HASH_TYPE = VinistoHelperDllEnumsUserLoginHashType.SHOP;

/**
 * Authentication Service
 * @class AuthenticationService
 */
class AuthenticationService {
	private apiService: ApiService;

	constructor() {
		this.apiService = new ApiService();
	}

	public register(
		email: string,
		password: string,
		isNewsletterActive: boolean,
		isAgreementCC: boolean
	): Promise<unknown> {
		return new Promise((resolve, reject) => {
			this.apiService
				.post(
					REGISTRATION_API_PATH,
					{ email, password, isNewsletterActive, isAgreementCC },
					true
				)
				.then((response) => {
					return resolve(response);
				})
				.catch((error: AxiosError) => {
					return reject(error);
				});
		});
	}

	public logIn(
		email: string,
		password: string,
		hashType: typeof SHOP_HASH_TYPE = SHOP_HASH_TYPE
	): Promise<unknown> {
		return new Promise((resolve, reject) => {
			this.apiService
				.put(LOG_IN_API_PATH, { email, password, hashType }, true)
				.then((response) => {
					return resolve(response);
				})
				.catch((error: AxiosError) => {
					return reject(error);
				});
		});
	}

	public logOut(
		userLoginHash: string,
		hashType: typeof SHOP_HASH_TYPE = SHOP_HASH_TYPE
	): Promise<unknown> {
		return new Promise((resolve, reject) => {
			this.apiService
				.put(LOG_OUT_API_PATH, { userLoginHash, hashType })
				.then((response) => {
					return resolve(response);
				})
				.catch((error: AxiosError) => {
					return reject(error);
				});
		});
	}

	public forgottenPassword(email: string): Promise<unknown> {
		return new Promise((resolve, reject) => {
			this.apiService
				.get(FORGOTTEN_PASSWORD_API_PATH, true, undefined, [
					{ key: 'email', value: encodeURIComponent(email) },
				])
				.then((response) => {
					return resolve(response);
				})
				.catch((error: AxiosError) => {
					return reject(error);
				});
		});
	}

	public confirmEmail(hash: string): Promise<unknown> {
		return new Promise((resolve, reject) => {
			this.apiService
				.put(CONFIRM_EMAIL_API_PATH, { hash })
				.then((response) => {
					return resolve(response);
				})
				.catch((error: AxiosError) => {
					return reject(error);
				});
		});
	}

	public resetPassword(
		resetHash: string,
		newPassword: string
	): Promise<unknown> {
		return new Promise((resolve, reject) => {
			this.apiService
				.put(FORGOTTEN_PASSWORD_API_PATH, { resetHash, newPassword })
				.then((response) => {
					return resolve(response);
				})
				.catch((error: AxiosError) => {
					return reject(error);
				});
		});
	}

	public checkIfEmailIsAlreadyInUse(email: string) {
		return new Promise((resolve, reject) => {
			this.apiService
				.get(IS_EMAIL_ALREADY_USED_API_PATH, true, undefined, [
					{ key: 'email', value: email },
				])
				.then((response) => {
					return resolve(response);
				})
				.catch((error: AxiosError) => {
					return reject(error);
				});
		});
	}

	public auth(userLoginHash: string): Promise<unknown> {
		return new Promise(
			(
				resolve: (thenableOrResult?: unknown) => void,
				reject: (error?: any) => void
			) => {
				return this.apiService
					.get(AUTH_API_PATH, true, undefined, [
						{ key: 'UserLoginHash', value: userLoginHash },
					])
					.then((response) => {
						return resolve(response);
					})
					.catch((error) => {
						return reject(error);
					});
			}
		);
	}
}

export default AuthenticationService;
