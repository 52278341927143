import { useContext, useEffect } from 'react';
import useFormatMessage from 'Hooks/useFormatMessage';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';

import ProducersPageContextProvider from './context';
import ProducersBreadcrumb from './Components/ProducersBreadcrumb';
import Header from './Components/Header';
import List from './Components/List';

const Producers = () => {
	const t = useFormatMessage();
	const documentHeaderContext = useContext(DocumentHeaderContext);

	useEffect(() => {
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.producers.name' })}` }
			)}`,
		});
	}, []);

	return (
		<section id="content-wrapper">
			<div className="container">
				<div className="row">
					<ProducersPageContextProvider>
						<ProducersBreadcrumb />
						<Header />
						<List />
					</ProducersPageContextProvider>
				</div>
			</div>
		</section>
	);
};

export default Producers;
