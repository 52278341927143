import { VinistoBasketDllModelsApiBasketBasketDiscountCoupon } from 'vinisto_api_client/src/api-types/basket-api';
import { LocalizationContext } from 'Services/LocalizationService';
import { useContext } from 'react';

const PriceLimitMessage = ({
	coupon,
}: {
	coupon: VinistoBasketDllModelsApiBasketBasketDiscountCoupon;
}) => {
	const localizationContext = useContext(LocalizationContext);
	const priceCurrency = localizationContext.activeCurrency.title;
	const t = localizationContext.useFormatMessage();

	return (
		<div>
			{t(
				{
					id: 'notification.message.discountCoupon.discountCouponBelowPriceLimit',
				},
				{
					price: (
						<span className="fw-bold nowrap">
							{`${coupon.minimalBasketAllowedPrice} ${priceCurrency}`}
						</span>
					),
				}
			)}
		</div>
	);
};

export default PriceLimitMessage;
