import * as React from 'react';

import { HEIGHT, WIDTH } from './constants';

const ErrorIcon: React.FC = (): JSX.Element => (
	<svg
		width={WIDTH}
		height={HEIGHT}
	>
		<g>
			<g>
				<g>
					<path
						d="m55.04 80.671-.5-12.581a.344.344 0 0 0-.183-.315l-8.237-4.3 7.685 4.809-.465 11.246-8.442-6.163V62.542l9.294-5.368 8.959 4.8-5.3 4.208 6.016-2.982.263 10.511-4.663 3.364 5.592-2.53.3-.133.01-.4.306-12.221.013-.5-.48-.291-10.393-6.429-.625-.387-.67.387-9.917 5.729-9.344-5.389-1.237-16.958-1.3 17.739-.052.718.7.4 9.321 5.375-5 3.971 5.614-2.785v9.894l-8.5 4.916-.421-10.121a.341.341 0 0 0-.183-.316l-8.237-4.3 7.684 4.809-.412 9.926-9.15-5.291-.649-.375-9.778 5.663-.42-10.091a.342.342 0 0 0-.183-.316l-8.237-4.3 7.684 4.809-.412 9.914-8.5-4.925V62.515l9.3-5.762 9.22 5.014-5.561 4.414 6.4-3.175.49 4.908.612-6.119.033-.324-.339-.206L12.317 54.9l-.429-.262-.488.263L.647 60.673 0 61.021v13.741l.646.375 9.962 5.77c.191 3.418.638 11.536.662 11.853l.284.127 5.605 2.513-4.671-3.341.624-11.151 9.329-5.395 8.654 5-4.97 3.945 5.6-2.779v9.927L23.238 96.5l-.421-10.139a.345.345 0 0 0-.183-.316l-8.237-4.3 7.684 4.808-.936 11.15v13.744l.649.347 10.765 5.783.489.262.428-.262 10.433-6.367.341-.208-.035-.323-.62-5.8-.563 5.416-9.2 5.005-.44-10.575a.342.342 0 0 0-.183-.316l-8.237-4.3 7.684 4.808-.427 10.289-8.489-5.251V99.207l9.31-5.369 8.932 4.789-5.283 4.194 6.94-3.305.429-.263 10.411-6.366.338-.207-.032-.323-.612-6.12-.573 5.736-9.182 5-.438-10.549a.344.344 0 0 0-.184-.316l-8.236-4.3 7.683 4.809-.426 10.27-8.5-5.254V80.882l9.37-5.419 9.607 4.283-6.012 4.773Z"
						fill="#720000"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M11.327 12.5v25L32.978 50l21.65-12.5v-25L32.978 0Z"
						fill="#720000"
					/>
					<path
						fill="none"
						stroke="#fff"
						strokeWidth={3}
						d="m40.477 17.5-15 15"
					/>
					<path
						fill="none"
						stroke="#fff"
						strokeWidth={3}
						d="m25.477 17.5 15 15"
					/>
				</g>
			</g>
		</g>
	</svg>
);

export default ErrorIcon;
