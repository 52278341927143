import { FC } from 'react';

import { SocialMediaLinkProps } from './interfaces';

const SocialMediaLink: FC<SocialMediaLinkProps> = ({
	className,
	url,
	children,
}) => {
	return (
		<a
			className={className}
			href={url}
			target="_blank"
			rel="noopener noreferrer"
		>
			{children}
		</a>
	);
};

export default SocialMediaLink;
