import { LocalizationContext } from 'Services/LocalizationService';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

const NotForDiscountedItemsMessage = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	return (
		<div>
			{t(
				{ id: 'notification.message.discountCoupon.unspecifiedIssue' },
				{
					support: (
						<Link to={`/${t({ id: 'routes.contact.route' })}`}>
							{t({ id: 'support.contact.call' })}
						</Link>
					),
				}
			)}
		</div>
	);
};

export default NotForDiscountedItemsMessage;
