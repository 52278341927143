import { useContext } from 'react';
import { SMART_SUPP_GROUP_KEY } from 'Pages/Bundle/Components/BundleDetail/Components/CustomerSupport/constants';
import { PHONE_NUMBER } from 'Components/Navbar/constants';
import { formatPhoneNumber } from 'Components/Navbar/helpers';
import useChat from 'Hooks/useChat';
import { LocalizationContext } from 'Services/LocalizationService';
import ImageLocal from 'Components/View/ImageLocal';

import styles from './styles.module.css';

const CustomerSupport = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const { openChat } = useChat();

	return (
		<div className={styles.customerSupportWrapper}>
			<div className={styles.header}>
				{t({ id: 'productDetail.customerSupport' })}
			</div>
			<div className={styles.chat}>
				<a
					className={styles.phone}
					href={`tel:${PHONE_NUMBER}`}
				>
					{formatPhoneNumber(PHONE_NUMBER)}
				</a>
				<button
					className={styles.startChat}
					onClick={() => openChat(SMART_SUPP_GROUP_KEY)}
				>
					{t({ id: 'productDetail.startChat' })}
					<ImageLocal
						fileName="chat.svg"
						alt=""
						className={styles.chatIcon}
						width={18}
						height={17}
					/>
				</button>
			</div>
		</div>
	);
};
export default CustomerSupport;
