import api from '@/api';
import { TagsListParams, VinistoProductDllModelsApiTagTagsReturn } from '@/api-types/product-api';

import { tagAdapter } from "@/index"

const getAll = async (params: TagsListParams) => {
	const response =
		await api.get<VinistoProductDllModelsApiTagTagsReturn>(
			`product-api/tags`,
			params
		);

	return response.tags?.map((tag) => tagAdapter.fromApi(tag)) ?? [];
};

const TagService = {
  getAll,
};

export default TagService;