/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ReactNode, useContext } from 'react';
import cx from 'classnames';
import { DeviceServiceContext } from 'Services/DeviceService';
import useMenuAnalytics from 'Components/Navbar/useMenuAnalytics';

import styles from './styles.module.css';

type TNavLinkProps = {
	children: ReactNode;
	icon?: ReactNode;
	variant?: string;
	withBackground?: boolean;
	withUnderline?: boolean;
};

const NavLink = ({
	children,
	icon,
	variant = 'success',
	withBackground = false,
	withUnderline = true,
}: TNavLinkProps) => {
	const { isMobile, isTablet } = useContext(DeviceServiceContext);

	const { handleOnClickMenuItem } = useMenuAnalytics();

	return (
		<div
			className={cx(styles.navLink, styles?.[variant], {
				[styles.withIcon]: !!icon,
				[styles.withBackground]: withBackground,
				[styles.withUnderline]: withUnderline,
			})}
			aria-current="page"
			onClick={() => children && handleOnClickMenuItem(children?.toString())}
		>
			<div className={cx(styles.iconWrapper, 'ps-2')}>{icon}</div>
			<span
				className={cx(styles.title, {
					[styles.mobileTitle]: isMobile || isTablet,
				})}
			>
				{children}
			</span>
		</div>
	);
};

export default NavLink;
