import { type MouseEvent, useContext, useEffect } from 'react';
import useFormatMessage from 'Hooks/useFormatMessage';
import { useFormContext } from 'react-hook-form';
import {
	ADDRESS_INVOICE_MODAL,
	CONFIRM_DIALOG,
} from 'Components/Modal/constants';
import { INVOICE_ELEMENT_TYPE } from 'Pages/UserSection/Addresses/Components/AddressAdd/constants';
import { ADDRESS_INVOICE_MODAL_TYPE } from 'Components/Modal/Components/AddressInvoice/constants';
import { apiServiceInstance } from 'Services/ApiService';
import { ModalContext } from 'Components/Modal/context';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { NotificationsContext } from 'Services/NotificationService';
import { BillingInfoApiHooks } from 'Services/Addresses/hooks';
import { getShippingId, setShippingId } from 'Services/Addresses/helpers';
import { VinistoAuthDllModelsApiBillingInfoBillingInfo } from 'vinisto_api_client/src/api-types/user-api';
import AddressAdd from 'Pages/UserSection/Addresses/Components/AddressAdd';
import { BILLING_INFO_ID_FIELD } from 'Pages/CartShippingData/constants';

import styles from '../../styles.module.css';

import InvoiceAddressBox from './Components/InvoiceAddressBox';

const InvoiceAddresses = () => {
	const authenticationContext = useContext(AuthenticationContext);
	const notificationContext = useContext(NotificationsContext);
	const t = useFormatMessage();
	const modalContext = useContext(ModalContext);
	const {
		vinistoUser: { id, loginHash },
	} = useContext(AuthenticationContext);
	const { setValue } = useFormContext();

	const { data, isFetched, refetch } = BillingInfoApiHooks.useGetAll();

	const billingInfoId = getShippingId(BILLING_INFO_ID_FIELD);

	const handleOnSelect = (id: string) => {
		setShippingId(BILLING_INFO_ID_FIELD, id);
		setValue(BILLING_INFO_ID_FIELD, id);
	};

	const handleOnCreate = (id: string | null | undefined) => {
		if (!id) return;
		setShippingId(BILLING_INFO_ID_FIELD, id);
		setValue(BILLING_INFO_ID_FIELD, id);
	};

	const handleOnEdit = ({
		data,
	}: {
		data: VinistoAuthDllModelsApiBillingInfoBillingInfo;
	}) => {
		modalContext.handleOpenModal(ADDRESS_INVOICE_MODAL, {
			modalType: ADDRESS_INVOICE_MODAL_TYPE.UPDATE,
			billingInfo: data,
			onCreateCallback: async () =>
				await refetch({
					stale: true,
				}),
		});
	};

	const handleOnDelete =
		(billingInfoId: string) => (event: MouseEvent<HTMLDivElement>) => {
			event.stopPropagation();
			modalContext.handleOpenModal(CONFIRM_DIALOG, {
				title: `${t({ id: 'userSection.addresses.remove' })}`,
				text: 'userSection.addresses.billingInfo.remove.confirm',
				onConfirm: () => {
					apiServiceInstance
						.delete(
							`user-api/users/${authenticationContext?.vinistoUser?.id}/billing-information/${billingInfoId}`,
							undefined,
							true,
							[
								{
									key: 'UserLoginHash',
									value: authenticationContext?.vinistoUser?.loginHash,
								},
							]
						)
						.then(() => {
							setValue(BILLING_INFO_ID_FIELD, null);
							refetch();
						})
						.catch(() => {
							notificationContext.handleShowErrorNotification(
								'userSection.addresses.billingInfo.remove.error'
							);
						});
				},
			});
		};

	useEffect(() => {
		if (
			isFetched &&
			!data?.billingInfos?.find(
				({ id }) => id === getShippingId(BILLING_INFO_ID_FIELD)
			)
		) {
			setShippingId(BILLING_INFO_ID_FIELD, data?.billingInfos?.[0]?.id);
			setValue(BILLING_INFO_ID_FIELD, data?.billingInfos?.[0]?.id);
		}
	}, [isFetched, data?.billingInfos, setValue]);

	if (!id || !loginHash) {
		return <></>;
	}

	return (
		<div className={styles.address}>
			<div className={styles.addressHeading}>
				{t({ id: 'cartShippingData.savedBillingInfo' })}
			</div>
			<div className="vinisto-order-addresses-wrap">
				{data?.billingInfos?.map((billingInfo, index) => (
					<InvoiceAddressBox
						key={billingInfo?.id ?? index}
						data={billingInfo}
						handleOnEdit={() => handleOnEdit({ data: billingInfo })}
						handleOnSelect={() => handleOnSelect(billingInfo?.id)}
						handleOnDelete={(event) => handleOnDelete(billingInfo?.id)(event)}
						isSelected={billingInfoId === billingInfo?.id}
					/>
				))}
				<AddressAdd
					elementType={INVOICE_ELEMENT_TYPE}
					handleOnCreate={handleOnCreate}
				/>
			</div>
		</div>
	);
};

export default InvoiceAddresses;
