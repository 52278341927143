import { apiServiceInstance } from 'Services/ApiService';

import { EHUB_POST_URL } from './constants';
import { EHubRequest } from './types';

const sendEHubRequest = async (data: EHubRequest) => {
	return apiServiceInstance.post(EHUB_POST_URL, data, true);
};

export const eHubService = {
	sendEHubRequest,
};
