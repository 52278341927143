/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useState } from 'react';
import { matchRoutes, Params, RouteObject } from 'react-router-dom';

import ItemLink from './Components/ItemLink';
import MobileMenuItem from './Components/Item';
import MobileMenuLink from './Components/Link';
import MobileMenuItemLink from './Components/MenuItemLink';
import MobileMenuPage from './Components/Page';

export const MobileMenuContext = createContext({
	pagePath: '/',
	params: {} as Params,
	navigateToPage: (_: string) => {},
	closeMenu: () => {},
});

type TMobileMenuProps = {
	onClose: () => void;
	routes: RouteObject[];
};

const MobileMenu = ({ onClose, routes }: TMobileMenuProps) => {
	const [pagePath, setPagePath] = useState('/');
	const matchedRoutes = matchRoutes(routes, pagePath);
	const matchedRoute = matchedRoutes
		? [matchedRoutes?.[matchedRoutes?.length - 1]]
		: null;
	const params = matchedRoute?.length ? matchedRoute[0]?.params : {};

	return (
		<MobileMenuContext.Provider
			value={{
				pagePath,
				params,
				navigateToPage: (pagePath: string) => setPagePath(pagePath),
				closeMenu: onClose,
			}}
		>
			{matchedRoute?.length ? matchedRoute[0]?.route?.element : <></>}
		</MobileMenuContext.Provider>
	);
};

MobileMenu.Page = MobileMenuPage;
MobileMenu.Link = MobileMenuLink;
MobileMenu.MenuItemLink = MobileMenuItemLink;
MobileMenu.Item = MobileMenuItem;
MobileMenu.ItemLink = ItemLink;

export default MobileMenu;
