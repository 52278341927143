import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'lodash-es';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { LocalizationContext } from 'Services/LocalizationService';

import Products from './Products';

import './styles.css';

const Search = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const documentHeaderContext = useContext(DocumentHeaderContext);
	const urlParams = useParams();
	const searchTerm = get(urlParams, 'searchTerm', '');

	useEffect(() => {
		if (searchTerm) {
			documentHeaderContext.dispatch({
				type: DocumentHeaderAction.setTitle,
				value: `${t({ id: 'app.title.page' }, { title: searchTerm })}`,
			});
		}
	}, [searchTerm]);

	return (
		<section id="content-wrapper">
			<Products {...{ searchTerm }} />
		</section>
	);
};

export default Search;
