import { useContext } from 'react';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import {
	PopoverTypes,
	QuantityBoxPlusBtnTypes,
} from 'Components/QuantityBox/constants';
import useTodayTomorrowDate from 'Hooks/useTodayTomorrowDate/useTodayTomorrowDate';
import { WarehouseContext } from 'Services/WarehouseService';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import { DirectQuantityBox, StandardQuantityBox } from 'Components/QuantityBox';
import quantityBoxStyles from 'Components/QuantityBox/styles.module.css';
import { Button, DiscountPercentage } from 'vinisto_ui';
import { useDiscountCoupons } from 'Pages/Bundle/hooks';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { ModalContext } from 'Components/Modal/context';
import { LOGIN_MODAL } from 'Components/Modal/constants';
import getBundleLimitPerOrder from 'Helpers/getBundleLimitPerOrder';
import { useFindBundleInBasket } from 'Pages/Bundle/hooks/use-find-bundle-in-basket';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';

import DisabledShopControlsInfo from '../TemporaryUnavailableInfo';
import IsGiftInfo from '../IsGiftInfo';

import {
	BundleItemVariants,
	QuantityBoxVariants,
	SHOW_EXACT_UNDER_COUNT,
} from './constants';
import { BundleItemProps } from './interfaces';
import bundleItemStyles from './styles.module.css';

const styles: { [key: string]: string } = {
	...quantityBoxStyles,
	...bundleItemStyles,
};

const BundleItem = ({
	bundle,
	variant = BundleItemVariants.STANDARD,
	quantityBox,
	isLoading,
	standardQuantityBoxMethods,
}: BundleItemProps) => {
	const t = useContext(LocalizationContext).useFormatMessage();
	const warehouseContext = useContext(WarehouseContext);
	const { isMobile } = useContext(DeviceServiceContext);
	const { isLoggedIn } = useContext(AuthenticationContext);
	const { handleOpenModal } = useContext(ModalContext);

	const getDateLabel = useTodayTomorrowDate();
	const activeCurrency = useContext(LocalizationContext).activeCurrency;

	const {
		isCouponAvailable,
		priceWhenCouponApplied,
		priceWhenCouponAppliedWithoutVat,
		mostValuableDiscountCouponCode,
		handleOnAddToBasketWithDiscountCoupon,
	} = useDiscountCoupons({ bundle: bundle ?? null });
	const itemInBasket = useFindBundleInBasket({ bundleId: bundle?.id });

	const bundlePrices = bundle?.bundlePrices;

	const { isDiscounted, basePrice, discountedPrice } = bundlePrices ?? {};

	const availableQuantity = warehouseContext.getQuantity(bundle?.id ?? '') ?? 0;
	const isAvailable = (availableQuantity as number) > 0;
	const isDeleted = bundle?.flags.isDeleted;
	const isTemporaryUnavailable = bundle?.flags.isTemporaryUnavailable ?? false;
	const isGift = bundle?.flags.isGift ?? false;
	const isSaleOver = bundle?.flags.isSaleOver;
	const bundleLimitPerOrder = getBundleLimitPerOrder(bundle?.orderLimitation);
	const wouldApplyingExceedOrderLimitation =
		typeof bundleLimitPerOrder === 'number' &&
		bundleLimitPerOrder <= (itemInBasket?.quantity ?? 0);
	const hasToLoginToBeAbleToPurchase = bundle?.flags.isForLogged
		? !isLoggedIn
		: false;

	const handleOpenLoginModal = () => {
		handleOpenModal(LOGIN_MODAL);
	};

	const supplier =
		bundle?.supplier?.nameWeb ??
		t({
			id: 'productDetail.seller.name.others',
		});

	if (isSaleOver)
		return (
			<DisabledShopControlsInfo
				isMobile={isMobile}
				translations={{
					cta:
						t({
							id: 'bundle.isSaleOver.productDetail.cta',
						})?.toString() ?? '',
					title:
						t({
							id: 'bundle.isSaleOver.productDetail.title',
						})?.toString() ?? '',
					seller:
						t({
							id: 'productDetail.seller.name',
						})?.toString() ?? '',
					withoutVat:
						t({
							id: 'basket.priceWithoutVAT',
						})?.toString() ?? '',
				}}
				price={basePrice?.getFormatedValueWithVat()}
				priceNoVat={basePrice?.getFormatedValue()}
				supplier={`${supplier}`}
			/>
		);

	if (isTemporaryUnavailable)
		return (
			<DisabledShopControlsInfo
				isMobile={isMobile}
				translations={{
					cta:
						t({
							id: 'bundle.temporaryUnavailable.productDetail.cta',
						})?.toString() ?? '',
					title:
						t({
							id: 'bundle.temporaryUnavailable.productDetail.title',
						})?.toString() ?? '',
					seller:
						t({
							id: 'productDetail.seller.name',
						})?.toString() ?? '',
					withoutVat:
						t({
							id: 'basket.priceWithoutVAT',
						})?.toString() ?? '',
				}}
				price={basePrice?.getFormatedValueWithVat()}
				priceNoVat={basePrice?.getFormatedValue()}
				supplier={`${supplier}`}
			/>
		);

	if (isGift) return <IsGiftInfo />;

	if (variant === BundleItemVariants.COMPACT) {
		return (
			<div className={`${styles.wrapper} ${styles.compact}`}>
				<div className={styles.wrapperInfo}>
					<span className={styles.availableCount}>
						{availableQuantity === undefined || isLoading ? (
							<Skeleton width="120px" />
						) : availableQuantity > SHOW_EXACT_UNDER_COUNT ? (
							t(
								{ id: 'bundleAvailability.inStock.moreThanCount2' },
								{
									valueWrap: (
										<span
											className={cx(styles.availableCountValue, {
												[styles.textGreen]: availableQuantity > 0,
											})}
										>
											{t(
												{
													id: 'bundleAvailability.inStock.moreThanCount2.valueWrap',
												},
												{
													value: (
														<>
															{t(
																{
																	id: 'bundleAvailability.inStock.moreThanCount.info',
																},
																{
																	count: SHOW_EXACT_UNDER_COUNT,
																}
															)}
														</>
													),
												}
											)}
										</span>
									),
								}
							)
						) : (
							<span className={styles.availableCountName}>
								{availableQuantity < 1 && (
									<span className={styles.stockNotAvaible}>
										{t({
											id: 'bundleAvailability.outOfStock',
										})}{' '}
										|{' '}
									</span>
								)}
								{t(
									{ id: 'bundle.warehouse.info.stock' },
									{
										value: (
											<span
												className={cx(styles.availableCountValue, {
													[styles.textGreen]: availableQuantity > 0,
												})}
											>
												{t(
													{ id: 'bundle.warehouse.quantity' },
													{
														count: availableQuantity,
													}
												)}
											</span>
										),
									}
								)}
							</span>
						)}
					</span>
					{isAvailable && warehouseContext.deliveryDate !== undefined && (
						<>
							{' '}
							|{' '}
							<span className={styles.deliveryDate}>
								{t(
									{ id: 'bundle.supplier.deliveryShortDate' },
									{
										date: (
											<span
												className={`${styles.deliveryDateValue} ${styles.textGreen}`}
											>
												{getDateLabel(warehouseContext.deliveryDate)}
											</span>
										),
									}
								)}
							</span>
						</>
					)}
				</div>

				<div
					className={cx(
						styles.shopWrap,
						'd-flex align-items-center justify-content-between gap-3'
					)}
				>
					<div className="d-flex align-items-center gap-3">
						<div className={styles.pricesWrap}>
							{isDiscounted && (
								<div
									className={cx(styles.priceBeforeDiscount, styles.lineThrough)}
								>
									{basePrice?.getFormatedValueWithVat()}
								</div>
							)}

							{isLoading ? (
								<Skeleton width="60px" />
							) : (
								<div className={styles.priceWithVat}>
									{isDiscounted
										? discountedPrice?.getFormatedValueWithVat()
										: basePrice?.getFormatedValueWithVat()}
								</div>
							)}
							<div className={styles.priceWithoutVatValue}>
								{isLoading ? (
									<Skeleton width="75px" />
								) : (
									t(
										{ id: 'price.withoutVAT' },
										{
											priceWithCurrency: (
												<span
													className={cx({
														[styles.priceWithoutVatDiscounted]: isDiscounted,
													})}
												>
													{isDiscounted
														? discountedPrice?.getFormatedValue()
														: basePrice?.getFormatedValue()}
												</span>
											),
										}
									)
								)}
							</div>
						</div>
						{isDiscounted && (
							<DiscountPercentage
								discountedPriceWithVat={discountedPrice?.valueWithVat ?? 0}
								standardPriceWithVat={basePrice?.valueWithVat ?? 0}
							/>
						)}
					</div>

					{isAvailable && !isDeleted && (
						<div className={styles.addToBasketWrap}>
							{quantityBox === QuantityBoxVariants.STANDARD &&
							standardQuantityBoxMethods ? (
								<StandardQuantityBox
									className={cx(styles.quantityBox, styles.productDetail)}
									bundle={bundle}
									isLoading={isLoading}
									orderLimitation={bundle?.orderLimitation}
									afterAddToBasket={() =>
										window.scrollTo({ top: 0, behavior: 'smooth' })
									}
									methods={standardQuantityBoxMethods}
								/>
							) : (
								<DirectQuantityBox
									className={cx(styles.quantityBox, styles.productDetail)}
									plusBtnType={QuantityBoxPlusBtnTypes.EXTENDED}
									bundle={bundle}
									isLoading={isLoading}
									orderLimitation={bundle?.orderLimitation}
									countPopover={true}
									popoverType={PopoverTypes.WINDOW}
									afterAddToBasket={() =>
										window.scrollTo({ top: 0, behavior: 'smooth' })
									}
								/>
							)}
						</div>
					)}
				</div>
			</div>
		);
	}

	return (
		<div className={cx(styles.wrapper, styles.standard)}>
			<div className={styles.mainPriceWrap}>
				<div className={styles.priceWithVat}>
					{isDiscounted && (
						<span
							className={cx(styles.priceBeforeDiscount, styles.lineThrough)}
						>
							{basePrice?.getFormatedValueWithVat()}
						</span>
					)}
					{isLoading ? (
						<Skeleton
							width="70px"
							className="d-block"
							inline={true}
						/>
					) : (
						<div className="d-flex align-items-center gap-3 mt-2 mt-xxl-0">
							<div>
								{isDiscounted
									? discountedPrice?.getFormatedValueWithVat()
									: basePrice?.getFormatedValueWithVat()}
							</div>
							{isDiscounted && (
								<DiscountPercentage
									discountedPriceWithVat={discountedPrice?.valueWithVat ?? 0}
									standardPriceWithVat={basePrice?.valueWithVat ?? 0}
								/>
							)}
						</div>
					)}
				</div>
				<div className={styles.priceWithoutVatValue}>
					{isLoading ? (
						<Skeleton width="75px" />
					) : (
						t(
							{ id: 'price.withoutVAT' },
							{
								priceWithCurrency: (
									<span>
										{isDiscounted
											? discountedPrice?.getFormatedValue()
											: basePrice?.getFormatedValue()}
									</span>
								),
							}
						)
					)}
				</div>
			</div>
			{isCouponAvailable && !wouldApplyingExceedOrderLimitation && (
				<>
					<div className={styles.discountCoupon}>
						<div className={styles.priceWithVat}>
							{isLoading ? (
								<Skeleton
									width="70px"
									height="36px"
									className="d-block"
								/>
							) : (
								<>
									<div className="d-flex align-items-center gap-3 mt-2 mt-xxl-0">
										<div>
											{getLocalizedPrice({
												price: priceWhenCouponApplied ?? 0,
												currency: activeCurrency.currency,
											})}
										</div>
										<DiscountPercentage
											discountedPriceWithVat={priceWhenCouponApplied ?? 0}
											standardPriceWithVat={basePrice?.valueWithVat ?? 0}
											className={styles.discountPercentage}
										/>
									</div>
								</>
							)}
						</div>
						<div className={styles.priceWithoutVatValue}>
							{isLoading ? (
								<Skeleton width="75px" />
							) : (
								t(
									{ id: 'price.withoutVAT' },
									{
										priceWithCurrency: (
											<span>
												{getLocalizedPrice({
													price: priceWhenCouponAppliedWithoutVat ?? 0,
													currency: activeCurrency.currency,
												})}
											</span>
										),
									}
								)
							)}
						</div>
						<div className={styles.discountCouponInfo}>
							{t(
								hasToLoginToBeAbleToPurchase
									? {
											id: 'basket.discountCoupon.addToBasketWithDiscount.notLoggedIn',
									  }
									: { id: 'basket.discountCoupon.addToBasketWithDiscount' }
							)}
							<Button
								className={styles.discountButton}
								onClick={
									hasToLoginToBeAbleToPurchase
										? handleOpenLoginModal
										: () =>
												handleOnAddToBasketWithDiscountCoupon({
													redirectToCrossell: true,
												})
								}
								disabled={!availableQuantity}
							>
								{mostValuableDiscountCouponCode}
							</Button>
						</div>
					</div>
				</>
			)}
			<div className={styles.avaibilityDeliveryWrapper}>
				<span className={styles.availableCount}>
					{availableQuantity === undefined || isLoading ? (
						<Skeleton width="200px" />
					) : availableQuantity > SHOW_EXACT_UNDER_COUNT ? (
						t(
							{ id: 'bundleAvailability.inStock.moreThanCount2' },
							{
								valueWrap: (
									<span
										className={cx(styles.availableCountValue, {
											[styles.textGreen]: availableQuantity > 0,
										})}
									>
										{t(
											{
												id: 'bundleAvailability.inStock.moreThanCount2.valueWrap',
											},
											{
												value: (
													<>
														{t(
															{
																id: 'bundleAvailability.inStock.moreThanCount.info',
															},
															{
																count: SHOW_EXACT_UNDER_COUNT,
															}
														)}
													</>
												),
											}
										)}
									</span>
								),
							}
						)
					) : (
						t(
							{ id: 'bundle.warehouse.info.stock' },
							{
								value: (
									<span
										className={cx(styles.availableCountValue, {
											[styles.textGreen]: availableQuantity > 0,
										})}
									>
										{t(
											{ id: 'bundle.warehouse.quantity' },
											{
												count: availableQuantity,
											}
										)}
									</span>
								),
							}
						)
					)}
					{!(availableQuantity === undefined || isLoading) && (
						<span
							className={cx(styles.availableCountValue, {
								[styles.textGreen]: availableQuantity > 0,
							})}
						>
							{', '}
							{t(
								{
									id: 'bundle.supplier.avaibility.available',
								},
								{
									value: t({
										id: 'bundle.supplier.avaibility.centralWarehouse',
									}),
								}
							)}
						</span>
					)}
				</span>
				{availableQuantity === undefined || isLoading ? (
					<Skeleton
						width="100px"
						count={1.6}
						inline
						className="ms-0 me-0"
					/>
				) : (
					isAvailable && (
						<div className={styles.avaibilityDelivery}>
							{warehouseContext.deliveryDate !== undefined && (
								<div className={styles.deliveryDate}>
									{t(
										{ id: 'bundle.supplier.deliveryDate' },
										{
											date: (
												<span
													className={`${styles.deliveryDateValue} ${styles.textGreen}`}
												>
													{getDateLabel(warehouseContext.deliveryDate, true)}
												</span>
											),
										}
									)}
								</div>
							)}
						</div>
					)
				)}
				{!isAvailable && (
					<span className="vinisto-wine__stock--not-available fw-bolder">
						{', '}
						{t({
							id: 'bundleAvailability.outOfStock',
						})}
					</span>
				)}
			</div>

			{isAvailable && (
				<>
					{quantityBox === QuantityBoxVariants.STANDARD &&
					standardQuantityBoxMethods ? (
						<StandardQuantityBox
							className={cx(
								styles.quantityBox,
								styles.productDetail,
								'other-sellers'
							)}
							bundle={bundle}
							isLoading={isLoading}
							orderLimitation={bundle?.orderLimitation}
							methods={standardQuantityBoxMethods}
						/>
					) : (
						<DirectQuantityBox
							className={cx(
								styles.quantityBox,
								styles.productDetail,
								'other-sellers'
							)}
							plusBtnType={QuantityBoxPlusBtnTypes.EXTENDED}
							bundle={bundle}
							isLoading={isLoading}
							orderLimitation={bundle?.orderLimitation}
						/>
					)}
				</>
			)}
			<div className={styles.seller}>
				{isLoading ? (
					<Skeleton
						width="100px"
						count={1.6}
						inline
						className="ms-0 me-0"
					/>
				) : (
					<>
						<span className={styles.sellerTitle}>{`${t({
							id: 'productDetail.seller.name',
						})}: `}</span>
						<span className={styles.sellerName}>{supplier}</span>
						{', '}
						{t(
							{
								id: 'bundle.warehouse.from',
							},
							{
								value: (
									<span className={styles.bolder}>
										{t({
											id: 'bundle.warehouse.from.vinisto',
										})}
									</span>
								),
							}
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default BundleItem;
