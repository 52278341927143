import debounce from 'lodash-es/debounce';
import { useRef } from 'react';

import { DATA_LAYER_PUSH_DEBOUNCE_TIME, GA_EVENT } from './constants';
import { GaEventParameters, SendEvent } from './types';

const IGNORED_EVENTS = new Set<GA_EVENT>([
	GA_EVENT.ADD_SHIPPING_INFO,
	GA_EVENT.ADD_PAYMENT_INFO,
	GA_EVENT.BEGIN_CHECKOUT,
]);

const handleDataLayerEvent = (eventType: GA_EVENT, data: GaEventParameters) => {
	if (!window.dataLayer) return;
	if (!eventType) return;

	window.dataLayer.push({
		event: eventType,
		...data,
	});
};

const useAnalytics = () => {
	const debouncedEventsRef = useRef(
		new Map<GA_EVENT, (parameters: GaEventParameters) => void>()
	);

	const getDebouncedEvent = (eventType: GA_EVENT) => {
		if (!debouncedEventsRef.current.has(eventType)) {
			const debouncedSendEvent = debounce((parameters: GaEventParameters) => {
				handleDataLayerEvent(eventType, parameters);
			}, DATA_LAYER_PUSH_DEBOUNCE_TIME);
			debouncedEventsRef.current.set(eventType, debouncedSendEvent);
		}
		return debouncedEventsRef.current.get(eventType);
	};

	const sendEvent: SendEvent = (eventType, parameters) => {
		if (IGNORED_EVENTS.has(eventType)) return;

		const debouncedSend = getDebouncedEvent(eventType);
		if (debouncedSend) debouncedSend(parameters);
	};

	/**
	 * @deprecated Use sendEvent instead
	 */
	const pushToDataLayer = (data: Record<string, any>) => {
		if (!window.dataLayer) return;
		if (IGNORED_EVENTS.has(data.event)) return;
		window.dataLayer.push(data);
	};

	return {
		sendEvent,
		pushToDataLayer,
	};
};

export default useAnalytics;
