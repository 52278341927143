import { get } from 'lodash-es';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop: React.FC = (): JSX.Element => {
	const { pathname, state } = useLocation();

	useEffect(() => {
		if (get(state, 'scrollToTop', true)) {
			window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
		}
	}, [pathname]);

	return <></>;
};

export default ScrollToTop;
