import { BasketContext } from 'Services/BasketService';
import { useContext, useMemo } from 'react';

import { VinistoBasketDllModelsApiBasketBasketBundle } from '@/api-types/basket-api';

export const useFindBundleInBasket = ({
	bundleId,
}: {
	bundleId: string | undefined;
}) => {
	const { basketState } = useContext(BasketContext);

	const itemInBasket = useMemo(() => {
		return (
			basketState?.items as
				| VinistoBasketDllModelsApiBasketBasketBundle[]
				| undefined
		)?.find((item) => item?.bundleId === bundleId);
	}, [bundleId, basketState]);

	return itemInBasket;
};
