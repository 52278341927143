import {
	createContext,
	// createRef,
	useCallback,
	useContext,
	// useEffect,
	// useRef,
	useState,
} from 'react';
import { entriesIn, forEach, get, map, set, uniq, valuesIn } from 'lodash-es';
import { dayjsInstance as dayjs } from 'Services/Date';
import {
	createIntl,
	IntlShape,
	MessageDescriptor,
	MessageFormatElement,
} from 'react-intl';
import { IntlProvider } from 'react-intl';
import { useQuery } from '@tanstack/react-query';

import { StorageContext } from '../StorageService/context';

import {
	// Country,
	Currency,
	LocalizationContextValue,
	LocalizationServiceProps,
	Rate,
	// Message,
} from './interfaces';
import { DEFAULT_LANGUAGE } from './constants';
import { getBrowserLanguage } from './helpers';
import locale_cs from './translations/cs.json';

// import locale_de from './translations/de.json';
// import locale_en from './translations/en.json';
// import locale_sk from './translations/sk.json';
import 'dayjs/locale/cs';
import {
	VinistoHelperDllEnumsCountryCode,
	VinistoHelperDllEnumsCurrency,
} from '@/api-types/product-api';
import ExchangeRateService from '@/exchange-rate-service';

dayjs.locale(DEFAULT_LANGUAGE);

const translations = {
	cs: locale_cs,
	// en: locale_en,
	// de: locale_de,
	// sk: locale_sk,
};

//const countries: Array<Country> = [
//	{ code: 'cs', title: 'Čeština', lang: 'cs' },
//	{ code: 'gb', title: 'English', lang: 'en' },
//	{ code: 'sk', title: 'Slovencina', lang: 'sk' },
//	{ code: 'de', title: 'Nemcina', lang: 'de' },
//];

const currencies: Currency[] = [
	{ currency: VinistoHelperDllEnumsCurrency.CZK, title: 'Kč' },
	{ currency: VinistoHelperDllEnumsCurrency.EUR, title: '€' },
	{ currency: VinistoHelperDllEnumsCurrency.USD, title: '$' },
];

const browserLanguage = getBrowserLanguage();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getLangugeKey = (currentLang: string) => {
	// if (currentLang === 'cs') {
	return 'CZECH';
	// } else if (currentLang === 'en') {
	// 	return 'ENGLISH';
	// } else if (currentLang === 'sk') {
	// 	return 'SLOVAK';
	// } else if (currentLang === 'de') {
	// 	return 'GERMAN';
	// }
};

const getCountryOfSale = (
	currencyKey: VinistoHelperDllEnumsCurrency
): VinistoHelperDllEnumsCountryCode => {
	switch (currencyKey) {
		case 'CZK':
			return VinistoHelperDllEnumsCountryCode.CZ;
		case 'EUR':
			return VinistoHelperDllEnumsCountryCode.SK;
		default:
			return VinistoHelperDllEnumsCountryCode.CZ;
	}
};

const defaultCurrency = {
	currency: VinistoHelperDllEnumsCurrency.CZK,
	title: 'Kč',
};

const defaultLocalizationContextValue: LocalizationContextValue = {
	activeLanguage: browserLanguage,
	activeLanguageKey: getLangugeKey(browserLanguage),
	// prevLanguage: createRef(),
	// prevLanguageKey: createRef(),
	activeCurrency: { currency: VinistoHelperDllEnumsCurrency.CZK, title: 'Kč' },
	countryOfSale: getCountryOfSale(VinistoHelperDllEnumsCurrency.CZK),
	setNextCurrency: () => null,
	//changeLanguage: () => null,
	useFormatMessage:
		() =>
		// eslint-disable-next-line react/function-component-definition, react/display-name
		() =>
			null,
	useFormatMessageInstance:
		// eslint-disable-next-line react/function-component-definition, react/display-name
		() => null,
	// useFormatMessageAll: () => () => [],
	useFormatMessageAllStrings: () => () => [],
	useFormatMessageFromLanguage: () => () => '',
	useAllMessagesFromLanguage: () => () => ({}),
	// currencies,
	// countries,
	convertCZKtoEUR: () => 0,
	convertEURtoCZK: () => 0,
	convertToActiveCurrencyIfPriceCurrencyIsDifferent: () => 0,
};

/**
 * Creates object with all locale intls
 */
const loadIntls = (): Record<string, IntlShape> => {
	const intls = {};
	forEach(entriesIn(translations), ([locale, messages]) => {
		set(intls, `[${locale}]`, createIntl({ locale, messages }));
	});
	return intls;
};

const intls = loadIntls();

export const LocalizationContext = createContext(
	defaultLocalizationContextValue
);

/**
 * Localization Service Provider
 * @class LocalizationServiceProvider
 * @return JSX Component
 */
const LocalizationServiceProvider = (props: LocalizationServiceProps) => {
	const storageContext = useContext(StorageContext);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [activeLanguage, setActiveLanguage] = useState(
		defaultLocalizationContextValue.activeLanguage
	);
	const [activeCurrency, setActiveCurrency] = useState(() => {
		// TODO extract to separate function, improve readability
		const storedActiveCurrency = storageContext.StorageService.getStorageItem(
			'ACTIVE_CURRENCY'
		) as VinistoHelperDllEnumsCurrency;
		if (storedActiveCurrency)
			return (
				currencies.find(
					(currency) => currency.currency === storedActiveCurrency
				) ?? defaultCurrency
			);

		const isActiveLanguageSlovak =
			(document?.referrer?.length > 0 &&
				(document.referrer.startsWith('https://vinisto.sk') ||
					document.referrer.startsWith('https://www.vinisto.sk'))) ||
			navigator.language === 'sk' ||
			navigator.userLanguage === 'sk' ||
			window.location.origin.split('.').pop() === 'sk';

		const currencyToStore = isActiveLanguageSlovak
			? currencies.find(
					(currency) => currency.currency === VinistoHelperDllEnumsCurrency.EUR
			  )
			: currencies.find(
					(currency) => currency.currency === VinistoHelperDllEnumsCurrency.CZK
			  );

		storageContext.StorageService.setItem(
			'ACTIVE_CURRENCY',
			String(currencyToStore?.currency ?? defaultCurrency.currency)
		);
		return currencyToStore ?? defaultCurrency;
	});

	// const prevLanguage = useRef(null);
	// const prevLanguageKey = useRef(null);

	// const useFormatMessageAll = () => getAllLangsForMessage;
	const useFormatMessageFromLanguage = () => getMessageForLanguage;
	const useFormatMessageAllStrings = () => getAllLangStringsForMessage;

	const setNextCurrency = useCallback(
		(nextCurrency: VinistoHelperDllEnumsCurrency): void => {
			const newCurrency = currencies.find(
				(currency) => currency.currency === nextCurrency
			);
			if (newCurrency && activeCurrency !== newCurrency) {
				setActiveCurrency(newCurrency);
				storageContext.StorageService.setItem('ACTIVE_CURRENCY', nextCurrency);
			}
		},
		[activeCurrency, storageContext.StorageService]
	);

	// const changeLanguage = useCallback(
	// 	(nextLanguage: string): void => {
	// 		if (activeLanguage !== nextLanguage) {
	// 			set(prevLanguage, 'current', activeLanguage);
	// 			set(prevLanguageKey, 'current', getLangugeKey(activeLanguage));
	// 			setActiveLanguage(nextLanguage);
	// 			storageContext.StorageService.setItem('ACTIVE_LANGUAGE', nextLanguage);
	// 		}
	// 	},
	// 	[activeLanguage, storageContext.StorageService]
	// );

	/**
	 * Get message for all languages
	 */
	//const getAllLangsForMessage = (
	//	props: MessageDescriptor,
	//	values: Record<string, any> = {}
	//): Message[] =>
	//	uniq(
	//		map(entriesIn(intls), ([locale, intl]: [string, IntlShape]) => ({
	//			lang: locale,
	//			message: intl.formatMessage(props, values),
	//		}))
	//	);

	/**
	 * Get message strings from all languages
	 * - Duplicated values are removed
	 */
	const getAllLangStringsForMessage = (
		props: MessageDescriptor,
		values: Record<string, any> = {}
	): string[] =>
		uniq(
			map(valuesIn(intls), (intl: IntlShape) =>
				intl.formatMessage(props, values)
			)
		);

	/**
	 * Get message for certain language
	 * @param {string} lang language code
	 */
	const getMessageForLanguage = (
		lang: string,
		props: MessageDescriptor,
		values: Record<string, string | number | boolean> = {}
	): string => {
		const intl = get(
			intls,
			`[${lang}]`,
			createIntl({ locale: lang, messages: {} })
		);
		return intl.formatMessage(props, values);
	};

	const useAllMessagesFromLanguage = () => getAllMessagesForLanguage;

	const getAllMessagesForLanguage = (
		lang: string
	): Record<string, MessageFormatElement[] | string> => {
		const intl = get(
			intls,
			`[${lang}]`,
			createIntl({ locale: lang, messages: {} })
		);
		return get(intl, 'messages', {});
	};

	/**
	 * Get message for certain language
	 * @param {string} lang language code
	 */
	const getMessageForLanguageInstance = (
		props: MessageDescriptor,
		values: Record<string, string | number | boolean> = {}
	): string => {
		const intl = get(
			intls,
			`[${activeLanguage}]`,
			createIntl({ locale: activeLanguage, messages: {} })
		);
		return intl.formatMessage(props, values);
	};

	const { data: exchangeRate } = useQuery(
		['exchange-rate'],
		() => ExchangeRateService.getExchangeRate(),
		{
			// One hour
			staleTime: 1000 * 60 * 60,
		}
	);

	const convertCZKtoEUR = useCallback(
		(valueCZK: number, rate: Rate = 'valueGoods'): number =>
			valueCZK / (exchangeRate?.[rate] ?? 25),
		[exchangeRate]
	);

	const convertEURtoCZK = useCallback(
		(valueEUR: number, rate: Rate = 'valueGoods'): number =>
			valueEUR * (exchangeRate?.[rate] ?? 25),
		[exchangeRate]
	);

	const convertToActiveCurrencyIfPriceCurrencyIsDifferent = useCallback(
		({
			price,
			priceCurrency,
			activeCurrency,
			rate = 'valueGoods',
		}: {
			price: number;
			priceCurrency: VinistoHelperDllEnumsCurrency;
			activeCurrency: VinistoHelperDllEnumsCurrency;
			rate?: Rate | undefined;
		}) => {
			if (
				priceCurrency === VinistoHelperDllEnumsCurrency.EUR &&
				activeCurrency === VinistoHelperDllEnumsCurrency.CZK
			) {
				return convertEURtoCZK(price, rate);
			}
			if (
				priceCurrency === VinistoHelperDllEnumsCurrency.CZK &&
				activeCurrency === VinistoHelperDllEnumsCurrency.EUR
			) {
				return convertCZKtoEUR(price, rate);
			}
			return price;
		},
		[convertCZKtoEUR, convertEURtoCZK]
	);

	const localizationContextValue: LocalizationContextValue = {
		// changeLanguage,
		activeLanguage,
		activeLanguageKey: getLangugeKey(activeLanguage),
		// prevLanguage,
		// prevLanguageKey,
		activeCurrency,
		// TODO rename to just setCurrency (or changeCurrency)
		setNextCurrency,
		countryOfSale: getCountryOfSale(activeCurrency.currency),
		useFormatMessage: () => getMessageForLanguageInstance,
		useFormatMessageInstance: getMessageForLanguageInstance,
		// useFormatMessageAll,
		useFormatMessageAllStrings,
		useFormatMessageFromLanguage,
		useAllMessagesFromLanguage,
		convertCZKtoEUR,
		convertEURtoCZK,
		convertToActiveCurrencyIfPriceCurrencyIsDifferent,
	};

	return (
		<LocalizationContext.Provider value={localizationContextValue}>
			<IntlProvider
				locale={activeLanguage}
				messages={get(translations, activeLanguage, locale_cs)}
				defaultLocale={activeLanguage}
			>
				{props.children}
			</IntlProvider>
		</LocalizationContext.Provider>
	);
};

export default LocalizationServiceProvider;
