import React, { useContext } from 'react';
import cx from 'classnames';
import { IBundleDetailProps } from 'Pages/Bundle/interfaces';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';
import Skeleton from 'react-loading-skeleton';
import { SHOW_EXACT_UNDER_COUNT } from 'Components/StockAvailability/constants';
import { LocalizationContext } from 'Services/LocalizationService';
import { StandardQuantityBox } from 'Components/QuantityBox';
import quantityBoxStyles from 'Components/QuantityBox/styles.module.css';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import { UseStandardQuantityBoxMethods } from 'Components/QuantityBox/Variants/StandardQuantityBox/hooks';

import styles from './styles.module.css';

import { Bundle } from '@/domain/bundle';

interface Props {
	isVisible: boolean;
	bundle: Bundle;
	bundleMeta: IBundleDetailProps['bundleMeta'];
	availableQuantity: number;
	isLoading: boolean;
	standardQuantityBoxMethods: UseStandardQuantityBoxMethods;
}

const FloatingShopControls = ({
	bundle,
	bundleMeta,
	availableQuantity,
	isLoading,
	standardQuantityBoxMethods,
	...props
}: Props) => {
	const {
		shortVariety: bundleProducerName,
		varietyUrl: bundleProducerUrl,
		component: bundleFlag,
	} = getFlagSpecification(bundle?.specificationDetails ?? []);

	if (isLoading) {
		return (
			<div
				className={cx(styles.wrapper, { [styles.visible]: props.isVisible })}
			>
				<div className="container">
					<div>
						<img
							className={styles.productImage}
							src={bundleMeta.bundleImageSmall}
							alt={bundleMeta.bundleName}
						/>
						<div className={styles.productInfo}>
							<div>
								<span className={styles.productTitle}>
									{bundleMeta.bundleName}
								</span>
								<Skeleton
									width="80px"
									height="12px"
								/>
							</div>
							<Skeleton
								width="120px"
								height="12px"
							/>
						</div>
					</div>
					<div>
						<div>
							<Skeleton
								width="70px"
								height="12px"
							/>
							<Skeleton
								width="80px"
								height="20px"
							/>
							<Skeleton
								width="120px"
								height="12px"
							/>
						</div>
						<div
							style={{
								display: 'flex',
								gap: '0.5rem',
							}}
						>
							<Skeleton
								width="144px"
								height="48px"
							/>
							<Skeleton
								width="304px"
								height="48px"
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={cx(styles.wrapper, { [styles.visible]: props.isVisible })}>
			<div className="container">
				<div>
					<img
						className={styles.productImage}
						src={bundleMeta.bundleImageSmall}
						alt={bundleMeta.bundleName}
					/>
					<div className={styles.productInfo}>
						<div>
							<span className={styles.productTitle}>
								{bundleMeta.bundleName}
							</span>
							<BundleProducer
								flag={bundleFlag}
								url={bundleProducerUrl}
								name={bundleProducerName}
							/>
						</div>
						<span className={styles.stock}>
							<StockAvailability
								availableQuantity={availableQuantity}
								isLoading={isLoading}
							/>
						</span>
					</div>
				</div>
				<div className={styles.quantityBoxWrapper}>
					<BundlePrice bundle={bundle} />
					<StandardQuantityBox
						className={cx(
							quantityBoxStyles.quantityBox,
							quantityBoxStyles.productDetail,
							styles.quantityBox
						)}
						orderLimitation={bundle?.orderLimitation}
						bundle={bundle}
						isLoading={isLoading}
						popoverClassname={styles.quantityBoxPopover}
						methods={standardQuantityBoxMethods}
					/>
				</div>
			</div>
		</div>
	);
};

export default FloatingShopControls;

interface BundlePriceProps {
	bundle: Bundle;
}

const BundlePrice = ({ bundle: { bundlePrices } }: BundlePriceProps) => {
	const localizationContext = useContext(LocalizationContext);

	const t = localizationContext.useFormatMessage();

	if (bundlePrices.isDiscounted && bundlePrices.discountedPrice) {
		return (
			<div className={styles.bundlePriceWrapper}>
				{bundlePrices.discountedPrice && (
					<span className={styles.oldPrice}>
						{getLocalizedPrice({
							price: bundlePrices.basePrice.valueWithVat,
							currency: bundlePrices.basePrice.currency,
						})}
					</span>
				)}
				<div className={styles.mainPriceWrapper}>
					<span className={styles.mainPrice}>
						{getLocalizedPrice({
							price: bundlePrices.discountedPrice.valueWithVat,
							currency: bundlePrices.discountedPrice.currency,
						})}
					</span>
					<span className={styles.percentageDiscount}>
						{`${bundlePrices.discountDifferenceAsPercentage.toFixed(0)} %`}
					</span>
				</div>
				<span className={styles.priceWithoutVat}>
					{t(
						{ id: 'price.withoutVAT' },
						{
							priceWithCurrency: getLocalizedPrice({
								price: bundlePrices.discountedPrice.value,
								currency: bundlePrices.discountedPrice.currency,
							}),
						}
					)}
				</span>
			</div>
		);
	}

	return (
		<div className={styles.bundlePriceWrapper}>
			<div className={styles.mainPriceWrapper}>
				<span className={styles.mainPrice}>
					{getLocalizedPrice({
						price: bundlePrices.basePrice.valueWithVat,
						currency: bundlePrices.basePrice.currency,
					})}
				</span>
			</div>
			<span className={styles.priceWithoutVat}>
				{t(
					{ id: 'price.withoutVAT' },
					{
						priceWithCurrency: getLocalizedPrice({
							price: bundlePrices.basePrice.value,
							currency: bundlePrices.basePrice.currency,
						}),
					}
				)}
			</span>
		</div>
	);
};

interface StockAvailabilityProps {
	availableQuantity: number;
	isLoading: boolean;
}

const StockAvailability = ({
	availableQuantity,
	isLoading,
}: StockAvailabilityProps) => {
	const t = useContext(LocalizationContext).useFormatMessage();

	return (
		<div className={styles.avaibilityDeliveryWrapper}>
			<span className={styles.availableCount}>
				{availableQuantity === undefined || isLoading ? (
					<Skeleton width="120px" />
				) : availableQuantity > SHOW_EXACT_UNDER_COUNT ? (
					t(
						{ id: 'bundleAvailability.inStock.moreThanCount2' },
						{
							valueWrap: (
								<span
									className={cx({
										[styles.textGreen]: availableQuantity > 0,
									})}
								>
									{t(
										{
											id: 'bundleAvailability.inStock.moreThanCount2.valueWrap',
										},
										{
											value: (
												<>
													{t(
														{
															id: 'bundleAvailability.inStock.moreThanCount.info',
														},
														{
															count: SHOW_EXACT_UNDER_COUNT,
														}
													)}
												</>
											),
										}
									)}
								</span>
							),
						}
					)
				) : (
					t(
						{ id: 'bundle.warehouse.info.stock' },
						{
							value: (
								<span
									className={cx({
										[styles.textGreen]: availableQuantity > 0,
									})}
								>
									{t(
										{ id: 'bundle.warehouse.quantity' },
										{
											count: availableQuantity,
										}
									)}
								</span>
							),
						}
					)
				)}
				<span
					className={cx({
						[styles.textGreen]: availableQuantity > 0,
					})}
				>
					{', '}
					{t(
						{
							id: 'bundle.supplier.avaibility.available',
						},
						{
							value: t({
								id: 'bundle.supplier.avaibility.centralWarehouse',
							}),
						}
					)}
				</span>
			</span>
		</div>
	);
};
