export const HOME_PAGE_PRODUCTS_CAROUSEL = 'HOME_PAGE_PRODUCTS_CAROUSEL';
export const HOME_PAGE_TABS_CAROUSEL = 'HOME_PAGE_TABS_CAROUSEL';
export const HOME_PAGE_REVIEWS_CAROUSEL = 'HOME_PAGE_REVIEWS_CAROUSEL';
export const HOME_PAGE_PRODUCERS_MOBILE_CAROUSEL =
	'HOME_PAGE_PRODUCERS_MOBILE_CAROUSEL';
export const HOME_PAGE_MOBILE_CAROUSEL_TABS = 'HOME_PAGE_MOBILE_CAROUSEL_TABS';
export const BLOG_TABS_CAROUSEL = 'BLOG_TABS_CAROUSEL';

export const SKELETONS_NUM_CLASSIC = 6;
export const SKELETONS_NUM_REVIEW_MOBILE = 1;
export const SKELETONS_NUM_REVIEW_TABLET = 2;
export const SKELETONS_NUM_REVIEW_DESKTOP = 3;
