import React from 'react';
import { get, isEqual, isFunction, set } from 'lodash-es';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { convertParamsToQuery, convertQueryToUrl } from './helpers';

const useURLParams = (): [
	Record<string, any>,
	(_: Record<string, any>) => void
] => {
	const { '*': params } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const mounted = React.useRef(false);
	const [query, setQuery] = React.useState<Record<string, any>>(
		convertParamsToQuery(`${params}`)
	);
	const url = React.useMemo(
		() => convertQueryToUrl(query, location, `${params}`),
		[query]
	);

	const handleOnSetQuery = React.useCallback(
		(callbackOrVal: Record<string, any> | ((query: any) => void)) => {
			setQuery((query) => {
				const val = isFunction(callbackOrVal)
					? callbackOrVal(query)
					: callbackOrVal;
				return { ...query, ...val };
			});
		},
		[]
	);

	React.useEffect(() => {
		if (mounted.current) {
			navigate(url, {
				state: {
					authUser: false,
					updateBasket: false,
					scrollToTop: false,
					loadQuery: false,
				},
			});
			history.replaceState({}, '');
		}
	}, [url]);

	React.useEffect(() => {
		if (get(location, 'state.loadQuery', true) && mounted.current) {
			const newQuery = convertParamsToQuery(`${params}`);
			if (!isEqual(query, newQuery)) {
				setQuery(newQuery);
			}
		}
		set(mounted, 'current', true);
	}, [location]);

	return [query, handleOnSetQuery];
};

export default useURLParams;
