import { FC } from 'react';

import { IIconProps } from './Interfaces';

const HeaderPeopleIcon: FC<IIconProps> = ({ className }) => {
	return (
		<svg
			width={19.104}
			height={12.014}
			viewBox="0 0 19.104 12.014"
			className={className}
		>
			<g transform="translate(-461.139 -1089.493)">
				<path
					d="M206.2,132.93c.013-.157.034-.311.059-.461q-.425,0-.848,0c-.549,0-1,0-1.43.014a.6.6,0,0,1-.428-.188.518.518,0,0,1-.128-.42,2.629,2.629,0,0,1,1.727-2.4,2.251,2.251,0,0,1,.9-.171q.68,0,1.361,0h.979a5.733,5.733,0,0,1,.67-.344,3.831,3.831,0,0,1,.513-.173,3.473,3.473,0,0,1-.322-.414q-.162-.016-.327-.016h-1.512l-1.364,0a3.2,3.2,0,0,0-1.268.249,3.569,3.569,0,0,0-2.3,3.2,1.492,1.492,0,0,0,1.5,1.631h.031c.466-.01.933-.014,1.4-.014.266,0,.531,0,.8,0A2.586,2.586,0,0,1,206.2,132.93Z"
					transform="translate(258.663 966.87)"
					fill="#4d4d4e"
				/>
				<g>
					<path
						d="M211.842,130.449h1.511a2.671,2.671,0,0,1,2.6,2.282,1.411,1.411,0,0,1,.005.536.521.521,0,0,1-.465.357h-3.584c-.343,0-.686,0-1.03,0s-.694,0-1.04,0c-.549,0-1,0-1.43.015a.6.6,0,0,1-.428-.188.529.529,0,0,1-.128-.421,2.629,2.629,0,0,1,1.727-2.4,2.277,2.277,0,0,1,.9-.171l1.362,0m0-.947h-1.365a3.215,3.215,0,0,0-1.267.249,3.573,3.573,0,0,0-2.3,3.2,1.492,1.492,0,0,0,1.5,1.631h.033c.466-.01.932-.014,1.4-.014.691,0,1.38.006,2.07.006H215.5a1.464,1.464,0,0,0,1.372-1.037,2.2,2.2,0,0,0,.026-.928,3.621,3.621,0,0,0-3.543-3.1Z"
						transform="translate(258.882 966.927)"
						fill="#4d4d4e"
					/>
				</g>
				<path
					d="M211.807,124.046H211.8a2.57,2.57,0,0,0-.009,5.139h.015a2.57,2.57,0,1,0,.005-5.139Zm-.015,4.193a1.589,1.589,0,0,1-1.141-.482,1.673,1.673,0,0,1-.467-1.189,1.622,1.622,0,1,1,1.608,1.671Z"
					transform="translate(258.997 966.657)"
					fill="#4d4d4e"
				/>
				<path
					d="M208.521,124.32a1.6,1.6,0,0,1,.406.694,3.5,3.5,0,0,1,.651-.858,2.571,2.571,0,0,0-2.207-1.263h-.01a2.57,2.57,0,0,0,0,5.139h.014a2.567,2.567,0,0,0,1.307-.358,3.644,3.644,0,0,1-.153-1.061v-.009a1.633,1.633,0,0,1-1.162.481,1.593,1.593,0,0,1-1.142-.481,1.682,1.682,0,0,1-.467-1.189,1.623,1.623,0,0,1,1.613-1.575v0A1.626,1.626,0,0,1,208.521,124.32Z"
					transform="translate(258.778 966.6)"
					fill="#4d4d4e"
				/>
				<path
					d="M216.007,122.893H216a2.587,2.587,0,0,0-2.125,1.139c.065.057.13.112.191.174a3.553,3.553,0,0,1,.465.581,1.647,1.647,0,0,1,1.476-.947,1.623,1.623,0,1,1-.01,3.245,1.584,1.584,0,0,1-.919-.293,3.461,3.461,0,0,1-.178.989,2.543,2.543,0,0,0,1.093.252H216a2.57,2.57,0,1,0,.005-5.14Z"
					transform="translate(259.227 966.6)"
					fill="#4d4d4e"
				/>
				<path
					d="M220.967,131.454a3.621,3.621,0,0,0-3.542-3.1h-1.511l-1.365,0c-.028,0-.058,0-.087,0a3.569,3.569,0,0,1-.336.426,4.545,4.545,0,0,1,1.189.516h2.111a2.67,2.67,0,0,1,2.6,2.282,1.38,1.38,0,0,1,0,.535.518.518,0,0,1-.464.358h-2.118c0,.022.008.045.012.067a3.682,3.682,0,0,1,.029.879h2.078a1.461,1.461,0,0,0,1.372-1.036A2.183,2.183,0,0,0,220.967,131.454Z"
					transform="translate(259.239 966.87)"
					fill="#4d4d4e"
				/>
			</g>
		</svg>
	);
};

export default HeaderPeopleIcon;
