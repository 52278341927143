export const REGISTRATION_MODAL = 'REGISTRATION_MODAL';
export const REGISTRATION_CONFIRM_MODAL = 'REGISTRATION_CONFIRM_MODAL';
export const LOGIN_MODAL = 'LOGIN_MODAL';
export const FORGOTTEN_PASSWORD_MODAL = 'FORGOTTEN_PASSWORD_MODAL';
export const FORGOTTEN_PASSWORD_CONFIRM_MODAL =
	'FORGOTTEN_PASSWORD_CONFIRM_MODAL';
export const ADDRESS_INVOICE_MODAL = 'ADDRESS_INVOICE_MODAL';
export const ADDRESS_DELIVERY_MODAL = 'ADDRESS_DELIVERY_MODAL';
export const REVIEW_MODAL = 'REVIEW_MODAL';
export const FILL_NICKNAME_MODAL = 'FILL_NICKNAME_MODAL';
export const PAYMENT_MODAL = 'PAYMENT_MODAL';
export const COOKIES_MODAL = 'COOKIES_MODAL';
export const ADD_TO_BASKET_MODAL = 'ADD_TO_BASKET_MODAL';
export const CONFIRM_DIALOG = 'CONFIRM_DIALOG';
export const ZASILKOVNA_MODAL = 'ZASILKOVNA_MODAL';
export const PPL_MODAL = 'PPL_MODAL';
export const CANCEL_ORDER_CONFIRM_MODAL = 'CANCEL_ORDER_CONFIRM_MODAL';
