import { TemporaryUnavailableInfoProps } from './interfaces';
import styles from './styles.module.css';

// can extract to vinisto-ui, maybe name it better
const DisabledShopControlsInfo = ({
	isMobile,
	translations,
	price,
	priceNoVat,
	supplier,
}: TemporaryUnavailableInfoProps) => {
	const handleScrollToNearestCarousel = () => {
		const scrollTarget = document.querySelector(
			'[data-scroll-target="temporary_unavailable_cta"]'
		);

		if (!(scrollTarget instanceof HTMLElement)) return;
		if (isMobile && window) {
			return window.scrollTo(0, scrollTarget.getBoundingClientRect().y);
		}
		return scrollTarget.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	};

	return (
		<div>
			<div className={styles.wrapper}>
				{(price || priceNoVat) && (
					<div className={styles.prices}>
						<span className={styles.priceVat}>{price}</span>
						<span className={styles.priceNoVat}>
							{translations.withoutVat} {priceNoVat}
						</span>
					</div>
				)}
				<div className={styles.title}>{translations.title}</div>
				<button
					className={styles.cta}
					onClick={handleScrollToNearestCarousel}
				>
					{translations.cta}
				</button>
			</div>
			{supplier && (
				<div className={styles.supplier}>
					{translations.seller}:{' '}
					<span className={styles.supplierName}>{supplier}</span>
				</div>
			)}
		</div>
	);
};

export default DisabledShopControlsInfo;
