import { IIconProps } from './Interfaces';

const PhoneIcon = ({ className, title = '', alt = '' }: IIconProps) => {
	return (
		<svg
			width="22"
			height="22"
			viewBox="0 0 22 22"
			className={className}
		>
			<title>{title}</title>
			<desc>{alt}</desc>
			<g transform="translate(-260.782 -243.926)">
				<path
					d="M266.54,252.686a17.146,17.146,0,0,0,7.387,7.408l1.23-1.572c.138-.178.273-.361.417-.535a1.459,1.459,0,0,1,1.816-.4q2.288,1.157,4.558,2.35a1.426,1.426,0,0,1,.54,2.061c-.618.991-1.241,1.98-1.892,2.949a2.357,2.357,0,0,1-2.33.973,16.256,16.256,0,0,1-8.345-3.28,23.7,23.7,0,0,1-7.35-8.278,14.376,14.376,0,0,1-1.629-4.528,12.555,12.555,0,0,1-.156-2.223,1.874,1.874,0,0,1,.9-1.5q1.448-.984,2.921-1.935a1.462,1.462,0,0,1,2.143.625c.55,1.055,1.088,2.117,1.63,3.175.207.4.416.8.62,1.205a1.46,1.46,0,0,1-.425,1.916c-.6.478-1.212.946-1.819,1.418C266.685,252.566,266.617,252.623,266.54,252.686Z"
					fill="#4d4d4e"
				/>
			</g>
		</svg>
	);
};

export default PhoneIcon;
