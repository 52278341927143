import * as React from 'react';
import { get } from 'lodash-es';
import cx from 'classnames';
import { LocalizationContext } from 'Services/LocalizationService';
import { BasketContext } from 'Services/BasketService';

import { MIN_QUANTITY } from '../QuantityBox/constants';

import { IButtonAddToBasketProps } from './interfaces';

const ButtonAddToBasket: React.FC<IButtonAddToBasketProps> = (
	props: IButtonAddToBasketProps
): JSX.Element => {
	const localizationContext = React.useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const basketContext = React.useContext(BasketContext);

	const bundleId = get(props, 'bundleId');
	const bundleUrl = get(props, 'bundleUrl');
	const availableCount = get(props, 'availableCount', 0);
	const minAllowedQuantity = Math.min(availableCount, MIN_QUANTITY);
	const bundleQuantity = get(props, 'count', minAllowedQuantity);
	const redirectToCrossSell = get(props, 'redirectToCrossSell', true);
	const isDisabled = get(props, 'disabled', false);

	const handleAddToBasket = React.useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			if (event) {
				event.stopPropagation();
				event.preventDefault();
			}

			basketContext.handleOnAddToBasket(
				bundleQuantity,
				bundleId,
				bundleUrl,
				availableCount,
				redirectToCrossSell
			);
		},
		[
			basketContext.handleOnAddToBasket,
			bundleQuantity,
			bundleId,
			bundleUrl,
			availableCount,
			redirectToCrossSell,
		]
	);

	return (
		<button
			className={cx(
				'vinisto-wine__add-to-cart__button',
				'vinisto-bg-green vinisto-btn',
				props.className
			)}
			onClick={handleAddToBasket}
			disabled={isDisabled}
		>
			{t({ id: 'carousel.button.addToBasket' })}
		</button>
	);
};

export default ButtonAddToBasket;
