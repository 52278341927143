import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { get, isNumber, map } from 'lodash-es';
import { Link, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useInViewport } from 'react-in-viewport';
import { CTA_VISIBILITY_THRESHOLD } from 'Pages/CartShippingData/constants';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { BasketContext } from 'Services/BasketService';
import { LocalizationContext } from 'Services/LocalizationService';
import { OrderContext } from 'Services/OrderService/context';
import { DeviceServiceContext } from 'Services/DeviceService';
import { InputCheckBox } from 'Components/Form';
import { FormSpy } from 'react-final-form';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';

import OrderOverviewItem from './Components/OrderOverviewItem';
import { IOrderOverviewProps } from './interfaces';
import { SKELETONS_NUM_ITEMS } from './constants';
import styles from './styles.module.css';

const OrderOverview = (props: IOrderOverviewProps) => {
	const authenticationContext = useContext(AuthenticationContext);
	const localizationContext = useContext(LocalizationContext);
	const basketContext = useContext(BasketContext);
	const orderContext = useContext(OrderContext);
	const deviceContext = useContext(DeviceServiceContext);

	const navigate = useNavigate();
	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();

	const isLoading = get(props, 'isLoading', false);
	const isBasketLoading = get(props, 'isBasketLoading', false);
	const deliveryDetail = get(props, 'deliveryDetail', {});
	const deliveryPriceWithVat = get(props, 'deliveryPriceWithVat');
	const paymentDetail = get(props, 'paymentDetail', {});
	const paymentPriceWithVat = get(props, 'paymentPriceWithVat');
	const totalPriceWithVAT = get(props, 'totalPriceWithVAT', 0);
	const totalPriceWithoutVAT = get(props, 'totalPriceWithoutVAT', 0);
	const totalPriceBeforeDiscounts = get(props, 'totalPriceBeforeDiscounts', 0);
	const isBasketDiscounted = get(props, 'isBasketDiscounted', false);
	const isNewsletterActive = get(
		authenticationContext,
		'vinistoUser.isNewsletterActive',
		''
	);

	const discountCoupons = basketContext?.basketState?.discountCoupons;

	const discountCouponsList = discountCoupons?.discountCoupons ?? [];

	const appliedDiscountCouponsList = discountCouponsList.filter(
		(coupon) => coupon.isCouponApplied
	);

	const discountAmountWithVat = appliedDiscountCouponsList.reduce(
		(acc, curr) => {
			acc += curr.discountAmountWithVat ?? 0;
			return acc;
		},
		0
	);

	const items = useMemo(() => {
		if (isBasketLoading)
			return map(Array(SKELETONS_NUM_ITEMS), () => ({ isLoading: true }));
		return get(basketContext, 'basketState.items', []);
	}, [isBasketLoading, get(basketContext, 'basketState')]);

	const priceCurrency = basketContext?.basketState?.currency;

	const isShowButtons = get(props, 'isShowButtons', false);
	const isShowAgreement = get(props, 'isShowAgreement', false);

	const ctaRef = useRef<HTMLDivElement>(null);
	const { inViewport: isCtaInViewport } = useInViewport(ctaRef, {
		// consider CTA button hidden behind header and footer
		rootMargin: `${deviceContext.headerHeight * -1}px 0px ${
			deviceContext.footerHeight * -1
		}px 0px`,
		threshold: CTA_VISIBILITY_THRESHOLD,
	});

	const toggleFloatingFooter = get(props, 'toggleFloatingFooter', null);
	useEffect(() => {
		if (
			!isShowButtons ||
			!toggleFloatingFooter ||
			!ctaRef.current ||
			get(getComputedStyle(ctaRef.current), 'display') === 'none'
		) {
			return;
		}
		toggleFloatingFooter(!isCtaInViewport);
	}, [
		isShowButtons,
		toggleFloatingFooter,
		isCtaInViewport,
		deviceContext.isTablet, // trigger on tablet viewport status change
	]);

	const handleGoBack = useCallback(
		!isShowButtons ? () => {} : () => navigate(-1),
		[isShowButtons, navigate]
	);

	const areGiftsRefused = basketContext.basketState?.areGiftsRefused;
	const assignedGifts = basketContext?.assignedGifts;

	return (
		<div className="vinisto-shipping__small-col">
			<div className={styles.summary}>
				<h2 className="vinisto-heading underline mb-0">
					{t({ id: 'basket.orderOverview' })}
				</h2>
				<div className="vinisto-user-orders__orders__order vinisto-user-favorites--mobile vinisto-crosssell--mobile vinisto-cart__items--mobile vinisto-shipping__summary__items">
					{map(items, (cartItem, index) => (
						<OrderOverviewItem
							key={index}
							// @ts-expect-error Incompatibile discriminate union types, but it seems that typescript went actually insane here
							cartItem={cartItem}
							isLoading={get(cartItem, 'isLoading', false)}
						/>
					))}
					{!areGiftsRefused &&
						assignedGifts?.map((assignedGift) => {
							return assignedGift?.bundles?.map((bundle, index) => {
								const giftData = {
									bundle: {
										...bundle.bundle,
									},
									amount: bundle.amount,
								};
								return (
									<OrderOverviewItem
										key={index}
										// @ts-expect-error Slightly different structure of the gift data
										cartItem={giftData}
										isGift={true}
									/>
								);
							});
						})}
					{!!appliedDiscountCouponsList.length && (
						<div className={styles.couponWrap}>
							<div className="vinisto-shipping__summary__items__footer__shipping__type">
								{isLoading ? (
									<Skeleton width="130px" />
								) : (
									t({ id: 'orderOverview.discounts' })
								)}
							</div>
							<div className={styles.price}>
								{isLoading ? (
									<Skeleton width="70px" />
								) : (
									`-${getLocalizedPrice({
										price: discountAmountWithVat,
										// @ts-expect-error possible "undefined" issue
										currency: priceCurrency,
									})}`
								)}
							</div>
						</div>
					)}
				</div>

				<div className={styles.footer}>
					<div className={styles.shipping}>
						<div>
							{isLoading ? (
								<Skeleton width="140px" />
							) : (
								<span>
									{t(
										{ id: 'basket.packaging' },
										{
											value: `${
												!basketContext.selectedShippingPackaging
													? t({ id: 'basket.packaging.eco.title' })
													: getLocalizedValue(
															basketContext.selectedShippingPackaging.name
													  )
											}`,
										}
									)}
								</span>
							)}
						</div>
						<div className={styles.price}>
							{isLoading ? (
								<Skeleton width="60px" />
							) : !basketContext.selectedShippingPackaging ? (
								t({ id: 'basket.price.free' })
							) : basketContext.selectedShippingPackaging.bundlePrices.basePrice
									.valueWithVat && priceCurrency !== undefined ? (
								basketContext.selectedShippingPackaging.bundlePrices.basePrice.getFormatedValueWithVat()
							) : (
								''
							)}
						</div>
					</div>
					<div className={styles.shipping}>
						<div>
							{isLoading ? (
								<Skeleton width="140px" />
							) : (
								<span>
									{t(
										{ id: 'basket.deliveryMethod' },
										{
											value: get(deliveryDetail, 'data.name')
												? `${getLocalizedValue(
														get(deliveryDetail, 'data.name')
												  )}`
												: `${t({
														id: 'basket.orderOverview.notSelected',
												  })}`,
										}
									)}
								</span>
							)}
							{orderContext?.deliveryMethod?.pickupPoint && (
								<span className="vinisto-shipping__summary__items__footer__shipping__type__pickup-point">{`(${orderContext?.deliveryMethod?.pickupPoint?.title})`}</span>
							)}
						</div>
						<div className={styles.price}>
							{isLoading ? (
								<Skeleton width="60px" />
							) : deliveryPriceWithVat === undefined ? (
								'-'
							) : deliveryPriceWithVat === 0 ? (
								t({ id: 'basket.price.free' })
							) : isNumber(deliveryPriceWithVat) &&
							  priceCurrency !== undefined ? (
								getLocalizedPrice({
									price: deliveryPriceWithVat,
									currency: priceCurrency,
								})
							) : (
								''
							)}
						</div>
					</div>
					<div className={styles.shipping}>
						<div>
							{isLoading ? (
								<Skeleton width="110px" />
							) : (
								t(
									{ id: 'basket.paymentMethod' },
									{
										value: get(paymentDetail, 'data.name')
											? `${getLocalizedValue(get(paymentDetail, 'data.name'))}`
											: `${t({
													id: 'basket.orderOverview.notSelected',
											  })}`,
									}
								)
							)}
						</div>
						<div className={styles.price}>
							{isLoading ? (
								<Skeleton width="50px" />
							) : paymentPriceWithVat === undefined ? (
								'-'
							) : paymentPriceWithVat === 0 ? (
								t({ id: 'basket.price.free' })
							) : isNumber(paymentPriceWithVat) &&
							  priceCurrency !== undefined ? (
								getLocalizedPrice({
									price: paymentPriceWithVat,
									currency: priceCurrency,
								})
							) : (
								''
							)}
						</div>
					</div>
					<div className={styles.pricesWrap}>
						<div className={styles.prices}>
							<div className="vinisto-shipping__summary__items__footer__shipping__type">
								{isLoading ? (
									<Skeleton width="130px" />
								) : (
									t({ id: 'basket.totalPriceWithVAT' })
								)}
							</div>
							{isBasketDiscounted && (
								<div className={styles.priceBeforeDiscount}>
									{isLoading ? (
										<Skeleton width="70px" />
									) : (
										getLocalizedPrice({
											price: totalPriceBeforeDiscounts,
											// @ts-expect-error possible "undefined" issue
											currency: priceCurrency,
										})
									)}
								</div>
							)}
							<div className={styles.price}>
								{isLoading ? (
									<Skeleton width="70px" />
								) : (
									getLocalizedPrice({
										price: totalPriceWithVAT,
										// @ts-expect-error possible "undefined" issue
										currency: priceCurrency,
									})
								)}
							</div>
						</div>
						<div className={styles.pricesNoVat}>
							<div className="vinisto-shipping__summary__items__footer__shipping__type">
								{isLoading ? (
									<Skeleton width="140px" />
								) : (
									t({ id: 'basket.totalPriceWithoutVAT' })
								)}
							</div>
							<div className={styles.price}>
								{isLoading ? (
									<Skeleton width="70px" />
								) : (
									getLocalizedPrice({
										price: totalPriceWithoutVAT,
										// @ts-expect-error possible "undefined" issue
										currency: priceCurrency,
									})
								)}
							</div>
						</div>
						{isShowAgreement && (
							<div className="vinisto-shipping-data__right-wrap tablet-only">
								{get(authenticationContext, 'isLoggedIn', false) &&
								isNewsletterActive ? null : (
									<InputCheckBox
										identifier="isNewsletterActive-tablet"
										name="isNewsletterActive"
										label="cartShippingData.form.isNewsletterActive.label"
									/>
								)}

								<p className="underline-effect underline-effect--vinisto">
									{t(
										{
											id: 'cartShippingData.completeOrderMessage',
										},
										{
											termsAndConditions: (
												<Link
													className="color-primary underline-item"
													to={`/${t({
														id: 'routes.termsAndConditions.route',
													})}`}
													target="_blank"
												>
													{t({
														id: 'cartShippingData.completeOrderMessage.termsAndConditions',
													})}
												</Link>
											),
											personalDataProcessing: (
												<>
													{t({
														id: 'cartShippingData.completeOrderMessage.personalDataProcessing',
													})}
												</>
											),
										}
									)}
								</p>
							</div>
						)}
						{isShowButtons && (
							<div
								className="vinisto-crosssell__ctas tablet-only"
								ref={ctaRef}
							>
								<div
									className="vinisto-crosssell__ctas__back"
									onClick={handleGoBack}
								>
									&lt; {t({ id: 'basket.backToShipping' })}
								</div>
								<FormSpy
									subscription={{
										invalid: true,
										values: true,
									}}
								>
									{(props) => (
										<button
											disabled={
												props.invalid ||
												(authenticationContext.isLoggedIn &&
													(!get(orderContext, 'orderState.billingInfoId') ||
														(get(props.values, 'useDeliveryAddress', false) &&
															!get(orderContext, 'orderState.addressId'))))
											}
											type="submit"
											className="vinisto-btn vinisto-user-orders__orders__order__cta__btn vinisto-crosssell__ctas__cta vinisto-success-btn"
										>
											{t({ id: 'basket.orderFinish' })}
										</button>
									)}
								</FormSpy>
							</div>
						)}
					</div>
					{!!props?.children && (
						<div className="vinisto-shipping__summary__items__footer__content">
							{props?.children}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default OrderOverview;
