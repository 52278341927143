import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { WarehouseContext } from 'Services/WarehouseService';
import { LocalizationContext } from 'Services/LocalizationService';
import { CURRENTLY_RECOMMENDED_TAG_ID } from 'Services/Bundle/constants';

import { DEFAULT_BUNDLE_API_PARAMS } from '@/shared';
import {
	VinistoHelperDllEnumsBundleSortableColumns,
	VinistoProductDllModelsApiBundleBundlesReturn,
} from '@/api-types/product-api';
import api from '@/api';
import { bundleAdapter } from '@/index';

export const useCurrentlyRecommendedTagBundles = () => {
	const warehouseContext = useContext(WarehouseContext);
	const localizationContext = useContext(LocalizationContext);
	const {
		activeCurrency: { currency },
		countryOfSale,
	} = localizationContext;

	const { data, isLoading } = useQuery(
		['currentlyRecommendedTagBundles', { currency, countryOfSale }],
		async () => {
			const response =
				await api.post<VinistoProductDllModelsApiBundleBundlesReturn>(
					'product-api/bundles/GetBundles',
					undefined,
					{
						isInImperialUnits: false,
						isCache: true,
						...DEFAULT_BUNDLE_API_PARAMS,
						isInStock: true,
						tagId: CURRENTLY_RECOMMENDED_TAG_ID,
						sortingColumn: VinistoHelperDllEnumsBundleSortableColumns.SCORING,
						isSortingDescending: true,
						currency,
						countryOfSale,
					}
				);
			if (response.isError) return Promise.reject(response.error);

			const uniqueIds = Array.from(
				new Set((response.bundles ?? []).map((bundle) => bundle.id))
			).filter((id): id is string => Boolean(id));

			warehouseContext.fetchQuantity(uniqueIds);

			return (
				response.bundles?.map((bundle) =>
					bundleAdapter.fromApi(bundle, { currency })
				) ?? []
			);
		}
	);

	return { data, isLoading };
};
