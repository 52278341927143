import { IconProps } from './interfaces';

const CopyIcon = ({ className }: IconProps) => {
	return (
		<svg
			width={16}
			height={19}
			viewBox="0 0 16 19"
			className={className}
		>
			<g
				id="Group_5449"
				data-name="Group 5449"
				transform="translate(-1254 -298)"
			>
				<g
					transform="translate(1254 298)"
					fill="#fff"
					stroke="#707070"
					strokeWidth="1"
				>
					<rect
						width="13"
						height="16"
						stroke="none"
					/>
					<rect
						x="0.5"
						y="0.5"
						width="12"
						height="15"
						fill="none"
					/>
				</g>
				<g
					transform="translate(1257 301)"
					fill="#fff"
					stroke="#707070"
					strokeWidth="1"
				>
					<rect
						width="13"
						height="16"
						stroke="none"
					/>
					<rect
						x="0.5"
						y="0.5"
						width="12"
						height="15"
						fill="none"
					/>
				</g>
			</g>
		</svg>
	);
};

export default CopyIcon;
