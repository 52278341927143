import React, { useContext, useTransition } from 'react';
import { LocalizationContext } from 'Services/LocalizationService';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { BasketContext } from 'Services/BasketService';

import RadioOption, { RadioOptionProps } from '../RadioOption';

import styles from './styles.module.css';
import { useShippingPackaging } from './hooks';
import { useChangePackaging } from './hooks';

const DEFAULT_PACKAGING_VALUE = 'DEFAULT_PACKAGING';

const PackagingOptions = () => {
	const { mutate: changePackaging } = useChangePackaging();
	const { data: packagingBundles } = useShippingPackaging();

	const { selectedShippingPackaging } = useContext(BasketContext);

	const [_, startTransition] = useTransition();

	const handlePackagingChange = (value: string) => {
		if (value === DEFAULT_PACKAGING_VALUE) {
			startTransition(() => {
				changePackaging(null);
			});
		} else {
			startTransition(() => {
				changePackaging(value);
			});
		}
	};

	const { useFormatMessage } = useContext(LocalizationContext);
	const t = useFormatMessage();

	const getLocalizedValue = useLocalizedValue();

	const commonProps = {
		groupName: 'packaging',
		onChange: handlePackagingChange,
	} as const;

	const options: Omit<RadioOptionProps, 'groupName' | 'onChange'>[] =
		packagingBundles?.map((bundle) => ({
			title: getLocalizedValue(bundle.name),
			description: getLocalizedValue(bundle.description),
			price: bundle.bundlePrices.basePrice.valueWithVat,
			value: bundle.id,
			checked: selectedShippingPackaging?.id === bundle.id,
			...commonProps,
		})) ?? [];

	return (
		<div className={styles.radioOptions}>
			<RadioOption
				{...commonProps}
				description={`${t({ id: 'basket.packaging.eco.description' })}`}
				title={`${t({ id: 'basket.packaging.eco.title' })}`}
				price={0}
				value={DEFAULT_PACKAGING_VALUE}
				checked={!selectedShippingPackaging}
			/>
			{options?.map((option) => (
				<RadioOption
					key={option.value}
					{...option}
					{...commonProps}
				/>
			))}
		</div>
	);
};

export default PackagingOptions;
