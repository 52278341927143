import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import api from '@/api';
import {
	PaymentsGetAllowedPaymentsListParams,
	VinistoHelperDllEnumsLanguage,
	VinistoOrderDllModelsApiDeliveryDeliveryGetByConditionsParameters,
	VinistoOrderDllModelsApiReturnDataDeliveriesReturn,
	VinistoOrderDllModelsApiReturnDataPaymentsReturn,
} from '@/api-types/order-api';
import { ApiError } from '@/domain/error';
import {
	VinistoOrderDllModelsApiDeliveryDelivery,
	VinistoOrderDllModelsApiPaymentPayment,
} from '@/api-types/warehouse-api';

export const useGetDeliveriesByBasket = (
	params: VinistoOrderDllModelsApiDeliveryDeliveryGetByConditionsParameters,
	options?: Omit<
		UseQueryOptions<
			VinistoOrderDllModelsApiDeliveryDelivery[] | null | undefined,
			ApiError,
			VinistoOrderDllModelsApiDeliveryDelivery[] | null | undefined
		>,
		'queryKey'
	>
) => {
	const getDeliveriesByBasketQuery = useQuery(
		['GetDeliveriesByBasket', { ...params }],
		async () => {
			const requestBody: VinistoOrderDllModelsApiDeliveryDeliveryGetByConditionsParameters =
				{
					...params,
					language: params.language ?? VinistoHelperDllEnumsLanguage.CZECH,
				};

			const response =
				await api.post<VinistoOrderDllModelsApiReturnDataDeliveriesReturn>(
					'order-api/deliveries/GetDeliveriesByBasket',
					undefined,
					requestBody
				);

			return response.deliveries;
		},
		options
	);

	return getDeliveriesByBasketQuery;
};

export const useGetAllowedPayments = (
	params: PaymentsGetAllowedPaymentsListParams,
	options?: Omit<
		UseQueryOptions<
			VinistoOrderDllModelsApiPaymentPayment[] | null | undefined,
			ApiError,
			VinistoOrderDllModelsApiPaymentPayment[] | null | undefined
		>,
		'queryKey'
	>
) => {
	const getActivePaymentsQuery = useQuery(
		['GetAllowedPayments', { ...params }],
		async () => {
			const response =
				await api.get<VinistoOrderDllModelsApiReturnDataPaymentsReturn>(
					'order-api/payments/GetAllowedPayments',
					{
						...params,
						Language: params.Language ?? VinistoHelperDllEnumsLanguage.CZECH,
					}
				);

			return response.payments;
		},
		options
	);

	return getActivePaymentsQuery;
};
