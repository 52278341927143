import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { BasketContext } from 'Services/BasketService';
import { LocalizationContext } from 'Services/LocalizationService';
import {
	CANCELLED,
	DELIVERED,
	LOSS_EVENT,
	REFUNDED,
	RETURNED,
	RETURNING_GOODS,
	REVERT_FINANCE_AND_FEES,
} from 'Pages/UserSection/Orders/constants';
import { IBundleChange } from 'Services/BasketService/interfaces';

import { VinistoHelperDllEnumsOrderOrderState } from '@/api-types/order-api';

export const showBuyAgainButton = (
	orderState?: VinistoHelperDllEnumsOrderOrderState
) => {
	if (
		orderState === CANCELLED ||
		orderState === DELIVERED ||
		orderState === LOSS_EVENT ||
		orderState === REFUNDED ||
		orderState === RETURNED ||
		orderState === RETURNING_GOODS ||
		orderState === REVERT_FINANCE_AND_FEES
	) {
		return true;
	}
	return false;
};

/**
 * Handles adding an array of Bundles to Basket
 * Adds a delay between each item addition to ensure API can process the requests
 * @param items - Array of bundle items
 * @param delayMs - Optional delay in milliseconds between each request (default is 200ms)
 * @returns {addItemsToBasket} Function that adds items to the basket
 */
const useAddItemsToBasket = () => {
	const { bulkUpdate } = useContext(BasketContext);
	const t = useContext(LocalizationContext).useFormatMessage();
	const navigate = useNavigate();

	const addItemsToBasket = async (items: IBundleChange[]) => {
		bulkUpdate(items);

		navigate(
			`/${t({
				id: 'routes.cart.route',
			})}`
		);
	};

	return { addItemsToBasket };
};

export default useAddItemsToBasket;
