import cx from 'classnames';
import { AnchorHTMLAttributes, HTMLProps } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import styles from './styles.module.css';

const VinistoLink = ({ children, to, className, ...props }: LinkProps) => {
	return (
		<Link
			to={to}
			className={cx(styles.link, className)}
			{...props}
		>
			{children}
		</Link>
	);
};

type TVinistoAnchorLinkProps = AnchorHTMLAttributes<HTMLAnchorElement>;

export const VinistoAnchorLink = ({
	children,
	className,
	href,
	...props
}: TVinistoAnchorLinkProps) => {
	return (
		<a
			href={href}
			className={cx(styles.link, className)}
			{...props}
		>
			{children}
		</a>
	);
};

type TVinistoSpanLinkProps = HTMLProps<HTMLSpanElement>;

export const VinistoSpanLink = ({
	children,
	className,
	...props
}: TVinistoSpanLinkProps) => {
	return (
		<span
			className={cx(styles.link, className)}
			{...props}
		>
			{children}
		</span>
	);
};

export default VinistoLink;
