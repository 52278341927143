import React from 'react';
import {
	endsWith,
	get,
	isEqual,
	join,
	keysIn,
	map,
	pickBy,
	split,
	startsWith,
	without,
} from 'lodash-es';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalizationContext } from 'Services/LocalizationService';

const TranslateURLOnLanguageChange: React.FC = (): JSX.Element => {
	const localizationContext = React.useContext(LocalizationContext);
	const lAll = localizationContext.useAllMessagesFromLanguage();
	const prevLanguage = get(localizationContext, 'prevLanguage', null);
	const navigate = useNavigate();
	const location = useLocation();
	const activeLanguage = get(localizationContext, 'activeLanguage', null);

	React.useEffect(() => {
		// If both previous language and active language are defined
		if (get(prevLanguage, 'current', null) && activeLanguage) {
			// Split pathname by "/" and remove empty string
			const splittedPathname = without(
				split(get(location, 'pathname', ''), '/'),
				''
			);
			// Get all routes from previous language
			const prevRouteLangs = pickBy(
				lAll(`${get(prevLanguage, 'current', '')}`),
				(_, key) => startsWith(key, 'routes') && endsWith(key, 'route')
			);
			// Get all routes from active language
			const activeRouteLangs = pickBy(
				lAll(activeLanguage),
				(_, key) => startsWith(key, 'routes') && endsWith(key, 'route')
			);
			// Generate new pathname
			const newPathname = map(splittedPathname, (pathPart) => {
				const pathKey = get(
					keysIn(
						pickBy(prevRouteLangs, (message) => isEqual(message, pathPart))
					),
					'[0]',
					null
				);
				if (!pathKey) return pathPart;
				const activeLangPathPart = get(activeRouteLangs, `${pathKey}`, null);
				if (activeLangPathPart) return activeLangPathPart;
				return pathPart;
			});
			// Navigate to new URL, don't add to history
			navigate(`${join(newPathname, '/')}${get(location, 'search', '')}`, {
				state: { loadQuery: false },
				replace: true,
			});
		}
	}, [activeLanguage]);

	return <></>;
};

export default TranslateURLOnLanguageChange;
