import { StorageContext } from 'Services/StorageService/context';
import { EHUB_STORAGE_KEY } from 'Services/eHub/constants';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const EhubLoader = () => {
	const storage = useContext(StorageContext);
	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const ehub = queryParams.get('ehub');
		if (ehub) storage.StorageService.setItem(EHUB_STORAGE_KEY, ehub);
	}, [location.search, storage.StorageService]);

	return null;
};

export default EhubLoader;
