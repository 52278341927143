import { IQueryArgument } from 'Services/ApiService/interfaces';
import { apiServiceInstance } from 'Services/ApiService';
import { VinistoOrderDllModelsApiReturnDataDeliveriesReturn } from 'vinisto_api_client/src/api-types/order-api';

export const fetchDeliveriesData = async (requestQuery: IQueryArgument[]) => {
	try {
		return await apiServiceInstance.get<VinistoOrderDllModelsApiReturnDataDeliveriesReturn>(
			'order-api/deliveries',
			true,
			undefined,
			requestQuery
		);
	} catch (error) {
		return [];
	}
};

export const fetchBannersData = async (requestQuery: IQueryArgument[]) => {
	try {
		return await apiServiceInstance.get(
			'cms-api/slider-carousels/GetSliderCarouselForType',
			true,
			undefined,
			requestQuery
		);
	} catch (error) {
		return [];
	}
};

export const fetchPaymentsData = async (requestQuery: IQueryArgument[]) => {
	try {
		return await apiServiceInstance.get(
			'order-api/payments/GetActivePayments',
			true,
			undefined,
			requestQuery
		);
	} catch (error) {
		return [];
	}
};
