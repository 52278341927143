import { FC } from 'react';

import { IIconProps } from './Interfaces';

const HeaderSomelierIcon: FC<IIconProps> = ({ className }) => {
	return (
		<svg
			width={11.81}
			height={13.871}
			viewBox="0 0 11.81 13.871"
			className={className}
		>
			<g transform="translate(-565.617 -142.91)">
				<g transform="translate(567.834 142.91)">
					<path
						d="M580.739,155.677c-.008-.068-.02-.136-.034-.208h-.03a4.049,4.049,0,0,0-.784.075,4.591,4.591,0,0,0-1.432.578l-.08.047c-.274.159-.557.323-.849.467a1.741,1.741,0,0,1-.771.184,1.809,1.809,0,0,1-.644-.123,3.02,3.02,0,0,1-.687-.377l-.084-.062c0,.086-.007.172-.008.257a1.263,1.263,0,0,0,.08.461,2.244,2.244,0,0,0,.6.869,4.936,4.936,0,0,0,1.121.831,2.034,2.034,0,0,0,.929.238,1.817,1.817,0,0,0,.318-.028,1.566,1.566,0,0,0,.231-.062l.168-.05.039-.016a3.094,3.094,0,0,0,.653-.368,4.676,4.676,0,0,0,.976-.9,1.59,1.59,0,0,0,.359-1.068C580.8,156.166,580.766,155.906,580.739,155.677Z"
						transform="translate(-574.391 -151.383)"
						fill="#92013f"
					/>
					<path
						d="M579.755,146.711a17.686,17.686,0,0,0-.923-3.052l-.005.006c-.078-.716-2.231-.755-2.67-.755s-2.592.038-2.67.755l-.005-.006,0,.009c-.055.157-.112.313-.169.47-.106.293-.216.6-.314.9a10.989,10.989,0,0,0-.552,2.533,2.639,2.639,0,0,0,.193,1.342,3.692,3.692,0,0,0,.515.8,7.914,7.914,0,0,0,1.314,1.182c.334.254.6.465.838.664a.619.619,0,0,1,.138.2,2.344,2.344,0,0,1,.111.28l.034.1.021.055a.992.992,0,0,1,.054.169c0,.028.007.051.012.078h.963a1.394,1.394,0,0,1,.125-.389,1.54,1.54,0,0,0,.106-.279.387.387,0,0,1,.1-.17,1.753,1.753,0,0,1,.165-.155q.224-.174.449-.345c.212-.161.423-.323.632-.487a5.576,5.576,0,0,0,1.167-1.187,2.526,2.526,0,0,0,.5-1.531A6.9,6.9,0,0,0,579.755,146.711Zm-3.6-3.33a5.238,5.238,0,0,1,2.16.322,7.4,7.4,0,0,1-4.32,0A5.242,5.242,0,0,1,576.157,143.381Zm2.714,5.768a5,5,0,0,1-1.068.995,3.212,3.212,0,0,1-1.1.545,2.19,2.19,0,0,1-.553.073,2.425,2.425,0,0,1-1.12-.294,5.317,5.317,0,0,1-1.5-1.2,2.107,2.107,0,0,1-.466-.887,2.4,2.4,0,0,1-.039-.8,10.5,10.5,0,0,1,.506-2.312c.094-.3.2-.6.3-.893l.068-.2a8.067,8.067,0,0,0,4.5.006,16.3,16.3,0,0,1,.76,2.547,6.31,6.31,0,0,1,.135,1.161A1.982,1.982,0,0,1,578.871,149.15Z"
						transform="translate(-572.432 -142.91)"
						fill="#92013f"
					/>
				</g>
				<g transform="translate(565.617 150.657)">
					<path
						d="M568.471,172.168l.04-.038a1.824,1.824,0,0,1,1.36-.554c1.273,0,2.547,0,3.819,0a.376.376,0,0,0,.313-.143l1.086-1.338q.793-.977,1.588-1.953a.484.484,0,0,0,.037-.562.478.478,0,0,0-.742-.084q-.893.86-1.784,1.722a.087.087,0,0,0-.028.1.989.989,0,0,1,0,.426,1.083,1.083,0,0,1-1.074.878h-2.428a.3.3,0,0,1-.259-.127.29.29,0,0,1-.024-.315.286.286,0,0,1,.268-.167h2.436a.477.477,0,0,0,.49-.436.486.486,0,0,0-.446-.523.481.481,0,0,0-.072,0h-2.467a1.7,1.7,0,0,1-.793-.178,2.4,2.4,0,0,0-1.5-.256,2.628,2.628,0,0,0-1.945,1.316.084.084,0,0,0,.009.119l.007.005q.924.92,1.844,1.843C568.3,171.988,568.382,172.077,568.471,172.168Zm-.349.511c-.175-.175-2.273-2.269-2.414-2.414-.21-.218-.014-.415.147-.705a3.19,3.19,0,0,1,2-1.483,3.018,3.018,0,0,1,2.167.227,1.239,1.239,0,0,0,.567.131h2.454a1.143,1.143,0,0,1,.742.26l.034.028.682-.659,1.047-1.011a1.091,1.091,0,0,1,1.6,1.484q-.834,1.018-1.661,2.043c-.341.419-.68.84-1.022,1.258a.938.938,0,0,1-.756.354c-1.254,0-2.508,0-3.762,0a1.447,1.447,0,0,0-1.091.465.472.472,0,0,1-.662.091.464.464,0,0,1-.072-.067Z"
						transform="translate(-565.617 -166.719)"
						fill="#92013f"
					/>
				</g>
			</g>
		</svg>
	);
};

export default HeaderSomelierIcon;
