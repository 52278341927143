import configuration from './_config.json';

const location = document.location;
const isSlovak = location.hostname.includes('.sk');

const Config = {
	...configuration,
	baseUrl: isSlovak
		? import.meta.env.VITE_BASE_URI_SK || ''
		: import.meta.env.VITE_BASE_URI || '',

	apiUrl: isSlovak
		? import.meta.env.VITE_API_URI_SK || ''
		: import.meta.env.VITE_API_URI || '',

	advisorUrl: isSlovak
		? import.meta.env.VITE_ADVISOR_URI_SK || ''
		: import.meta.env.VITE_ADVISOR_URI || '',
};

export default Config;
