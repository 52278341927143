import { FC, lazy, Suspense } from 'react';
import useFormatMessage from 'Hooks/useFormatMessage';
import { PHONE_NUMBER } from 'Components/Navbar/constants';
import { formatPhoneNumber } from 'Components/Navbar/helpers';
import Loader from 'Components/View/Loader';
const PhoneCallIcon = lazy(() => import('Components/Icons/PhoneCall'));
const VinistoLogoIcon = lazy(() => import('Components/Icons/VinistoLogo'));

import styles from './styles.module.css';

const BasketHeader: FC = (): JSX.Element => {
	const t = useFormatMessage();

	return (
		<div className="container">
			<div className="row justify-content-between">
				<div className="logo-wrapper">
					<a href="/">
						<Suspense fallback={<Loader blank />}>
							<VinistoLogoIcon />
						</Suspense>
					</a>
				</div>
				<div className="col d-flex justify-content-end align-items-center pe-3">
					<a
						href={`tel:${PHONE_NUMBER}`}
						className={styles.basketHeaderCta}
					>
						<div className="d-flex flex-row align-items-center">
							<Suspense fallback={<Loader blank />}>
								<PhoneCallIcon className="me-2 vinisto-header-phone-icon" />
							</Suspense>
							<div className="d-flex flex-column">
								<div className="text-end">
									{t({ id: 'navbar.customerCare.label' })}
								</div>
								<div className={styles.phoneNumber}>
									{formatPhoneNumber(PHONE_NUMBER)}
								</div>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	);
};

export default BasketHeader;
