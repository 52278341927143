import { InputHTMLAttributes, ReactNode } from 'react';
import cx from 'classnames';
import {
	ControllerProps,
	FieldPath,
	FieldValues,
	useController,
} from 'react-hook-form';
import Form from 'Components/Forms';
import { TestIdType } from 'Constants/test-ids';

import styles from './styles.module.css';

type TInputField = InputHTMLAttributes<HTMLInputElement> & {
	id?: string;
	name: FieldPath<FieldValues>;
	label?: ReactNode;
	type?: string;
	inputMode?:
		| 'search'
		| 'text'
		| 'none'
		| 'tel'
		| 'url'
		| 'email'
		| 'numeric'
		| 'decimal'
		| undefined;
	placeholder?: string;
	wrapperClassName?: string;
	labelClassName?: string;
	inputClassName?: string;
	messageClassName?: string;
	showSuccess?: boolean;
	showSuccessIcon?: boolean;
	showError?: boolean;
	successMessage?: string;
	isRequired?: boolean;
	autocomplete?: string;
	rules?: ControllerProps['rules'];
	dataTestid?: TestIdType;
};

const InputField = ({
	id,
	name,
	label,
	type,
	pattern,
	inputMode,
	placeholder,
	wrapperClassName,
	labelClassName,
	inputClassName,
	messageClassName,
	showSuccess = false,
	showSuccessIcon = false,
	showError = true,
	successMessage,
	isRequired = false,
	autocomplete,
	rules,
	dataTestid,
}: TInputField) => {
	const {
		field,
		fieldState: { error, isTouched },
	} = useController({
		name,
		rules,
	});

	return (
		<div className={cx(styles.wrapper, wrapperClassName)}>
			{!!label && (
				<Form.Label
					className={labelClassName}
					isRequired={isRequired || !!rules?.required}
				>
					{label}
				</Form.Label>
			)}
			<Form.Input
				id={id}
				inputType={type}
				pattern={pattern}
				inputMode={inputMode}
				placeholder={placeholder}
				className={inputClassName}
				isError={!!error && showError}
				isSuccess={!error && isTouched && showSuccess}
				showSuccessIcon={showSuccess && showSuccessIcon}
				autocomplete={autocomplete}
				{...field}
				data-testid={dataTestid}
			/>
			{!!error && (
				<Form.Message
					className={messageClassName}
					variant="error"
				>
					{error.message}
				</Form.Message>
			)}
			{!error && isTouched && successMessage && (
				<Form.Message
					className={messageClassName}
					variant="success"
				>
					{successMessage}
				</Form.Message>
			)}
		</div>
	);
};

export default InputField;
