import { useContext } from 'react';
import { LOGIN_REDIRECT_TIMEOUT } from 'Hooks/useLoginRedirect/constants';
import { LOCAL_STORAGE_KEYS } from 'Services/StorageService/constants';
import { StorageContext } from 'Services/StorageService/context';
import { useNavigate } from 'react-router-dom';

const useLoginRedirect = (navigateFromAuthRequired: boolean) => {
	const storageContext = useContext(StorageContext);
	const navigate = useNavigate();
	const location = storageContext.StorageService.getStorageItem(
		LOCAL_STORAGE_KEYS.LOGIN_REDIRECT_PATH
	) as Location;

	const loginRedirect = () => {
		if (!location || !location?.pathname || !navigateFromAuthRequired) return;

		setTimeout(() => {
			navigate(
				`${location?.pathname}${location?.search ? location?.search : ''}`
			);
			storageContext.StorageService.removeItem(
				LOCAL_STORAGE_KEYS.LOGIN_REDIRECT_PATH
			);
		}, LOGIN_REDIRECT_TIMEOUT);
	};

	return loginRedirect;
};

export default useLoginRedirect;
