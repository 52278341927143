import VolumeDiscountContainer from './volume-discount-container';
import VolumeDiscountList from './volume-discount-list';
import VolumeDiscountListItem, {
	DoubleEmphasized,
	Emphasized,
} from './volume-discount-list-item';
import VolumeDiscountBadge from './volume-discount-badge';

const VolumeDiscount = {
	Container: VolumeDiscountContainer,
	List: VolumeDiscountList,
	ListItem: VolumeDiscountListItem,
	Badge: VolumeDiscountBadge,
	DoubleEmphasized,
	Emphasized,
};

export default VolumeDiscount;
