import { FC, Fragment, Suspense, useContext, useState } from 'react';
import cx from 'classnames';
import { get, map, orderBy, uniqueId } from 'lodash-es';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import useSpecificationParamValue from 'Hooks/useSpecificationParamValue';
import VinistoLink from 'Components/VinistoLink';
import Loader from 'Components/View/Loader';
import FilterDropdownArrowIcon from 'Components/Icons/FilterDropdownArrow';
import { LocalizationContext } from 'Services/LocalizationService';

import { IBundleInfoParams } from './interfaces';
import styles from './styles.module.css';

const BundleInfoParams: FC<IBundleInfoParams> = (props) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	const [isShowMoreParams, setIsShowMoreParams] = useState(false);

	const handleToggleMoreParams = () => {
		setIsShowMoreParams((isShowMoreParams) => !isShowMoreParams);
	};

	const getLocalizedValue = useLocalizedValue();
	const getParamValue = useSpecificationParamValue();

	const params = get(props, 'bundleParams', []).filter(
		(param) =>
			param.value?.selectedValueName?.length > 0 ||
			param.value?.selectedValuesName?.length > 0 ||
			param.value?.value !== undefined ||
			(param.value?.specificationType === 'TEXT' &&
				param.definition.isHidden === true)
	);

	const paramsOrdered = isShowMoreParams
		? orderBy(params, 'definition.orderDetail', 'asc')
		: orderBy(params, 'definition.orderDetail', 'asc').slice(0, 4);

	const paramIndexLastFirstColumn = Math.ceil(paramsOrdered.length / 2) - 1;
	return (
		<>
			<dl className={styles.params}>
				{map(paramsOrdered, (param, index) => {
					const paramValues = getParamValue(param);

					return (
						<Fragment key={get(param, 'definition.id', index)}>
							<dt className={styles.paramName}>
								{getLocalizedValue(get(param, 'definition.name', [])) ?? '-'}
							</dt>
							<dd
								className={cx(
									styles.paramValue,
									index === paramIndexLastFirstColumn && styles.paramMiddle
								)}
							>
								{Array.isArray(paramValues) ? (
									paramValues?.map(({ name, url }, index) => (
										<Fragment key={index}>
											<VinistoLink to={url}>
												{`${name}${
													index !== paramValues?.length - 1 ? ',' : ''
												}`}
											</VinistoLink>
											{`${index !== paramValues?.length - 1 ? ' ' : ''}`}
										</Fragment>
									))
								) : paramValues?.url === '' ? (
									<span>{paramValues?.name}</span>
								) : (
									<VinistoLink to={paramValues?.url}>
										{paramValues?.name}
									</VinistoLink>
								)}
							</dd>
						</Fragment>
					);
				})}
			</dl>

			<button
				className={cx(styles.showMore, isShowMoreParams && styles.showLess)}
				onKeyDown={handleToggleMoreParams}
				onKeyUp={handleToggleMoreParams}
				onClick={handleToggleMoreParams}
			>
				{isShowMoreParams
					? t({ id: 'bundle.toggleShowLess' })
					: t({ id: 'bundle.toggleShowMore' })}
				<Suspense fallback={<Loader blank />}>
					<FilterDropdownArrowIcon
						id={uniqueId()}
						alt={
							isShowMoreParams
								? t({ id: 'bundle.toggleShowLess' })
								: t({ id: 'bundle.toggleShowMore' })
						}
						title={``}
						className={styles.showMoreArrow}
					/>
				</Suspense>
			</button>
		</>
	);
};

export default BundleInfoParams;
