export const FIELD_NAME = 'password';
export const FIELD_SECOND_NAME = 'confirm_password';
export const FIELD_IDENTIFIER = 'password';
export const FIELD_SECOND_IDENTIFIER = 'confirm_password';
export const FIELD_TEXT_TYPE = 'text';
export const FIELD_PASSWORD_TYPE = 'password';
export const FIELD_LABEL = 'password';
export const FIELD_SECOND_LABEL = 'confirm_password';

export const PASSWORD_TYPE = 'PASSWORD_TYPE';
export const CONFIRM_PASSWORD_TYPE = 'CONFIRM_PASSWORD_TYPE';
export const LOGIN_PASSWORD_TYPE = 'LOGIN_PASSWORD_TYPE';
