export const SHOW_EXACT_UNDER_COUNT = 24;

export enum BundleItemVariants {
	STANDARD = 'STANDARD',
	COMPACT = 'COMPACT',
}

export enum QuantityBoxVariants {
	STANDARD = 'STANDARD',
	DIRECT = 'DIRECT',
}
