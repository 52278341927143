import cx from 'classnames';
import { Link } from 'react-router-dom';

import { BottomBannerProps } from './interfaces';
import styles from './styles.module.css';

const BottomBanner = ({
	url,
	title,
	subtitle,
	imageOriginalUrl,
	srcSet,
	ctaLabel,
}: BottomBannerProps) => {
	const bannerContent = (
		<div className={cx('vinisto-card', styles.card)}>
			<div className={styles.imageContainer}>
				<img
					className={styles.image}
					srcSet={srcSet}
					// Cannot use these sizes because they can be padded with transparency
					//sizes={`(max-width: 375px) 100vw, (max-width 767.98) 50vw, 25vw`}
					sizes={`(max-width 767.98) 500px, 500px`}
					src={imageOriginalUrl}
					alt={title}
					loading="lazy"
				/>
			</div>
			<div>
				{title && (
					<p className="vinisto-card__heading h2 w-100 text-white position-relative">
						{title}
					</p>
				)}
				{subtitle && (
					<p className="vinisto-card__top-text text-white position-relative">
						{subtitle}
					</p>
				)}
			</div>
			{ctaLabel && (
				<div className="vinisto-card__bottom-link text-right underline-effect underline-effect--white">
					<span
						className={'underline-item text-white'}
						aria-current="page"
					>
						{ctaLabel}
					</span>
				</div>
			)}
		</div>
	);

	return url.match(/^https?:\/\//) === null ? (
		<Link to={url}>{bannerContent}</Link>
	) : (
		<a href={url}>{bannerContent}</a>
	);
};

export default BottomBanner;
