// These are available filenames. Needs to be kept in sync with the actual images in static assets directory.
export const AVAILABLE_FLAGS = new Set([
	'afg',
	'alb',
	'dza',
	'asm',
	'and',
	'ago',
	'aia',
	'ata',
	'atg',
	'arg',
	'arm',
	'abw',
	'aus',
	'aut',
	'aze',
	'bhs',
	'bhr',
	'bgd',
	'brb',
	'blr',
	'bel',
	'blz',
	'ben',
	'bmu',
	'btn',
	'bol',
	'bes',
	'bih',
	'bwa',
	'bra',
	'iot',
	'brn',
	'bgr',
	'bfa',
	'bdi',
	'khm',
	'cmr',
	'can',
	'cpv',
	'cym',
	'caf',
	'tcd',
	'chl',
	'chn',
	'cxr',
	'cck',
	'col',
	'com',
	'cog',
	'cod',
	'cok',
	'cri',
	'hrv',
	'cub',
	'cuw',
	'cyp',
	'cze',
	'civ',
	'dnk',
	'dji',
	'dma',
	'dom',
	'ecu',
	'egy',
	'slv',
	'gnq',
	'eri',
	'est',
	'eth',
	'flk',
	'fro',
	'fji',
	'fin',
	'fra',
	'guf',
	'pyf',
	'atf',
	'gab',
	'gmb',
	'geo',
	'deu',
	'gha',
	'gib',
	'grc',
	'grl',
	'grd',
	'glp',
	'gum',
	'gtm',
	'ggy',
	'gin',
	'gnb',
	'guy',
	'hti',
	'vat',
	'hnd',
	'hkg',
	'hun',
	'isl',
	'ind',
	'idn',
	'irn',
	'irq',
	'irl',
	'imn',
	'isr',
	'ita',
	'jam',
	'jpn',
	'jey',
	'jor',
	'kaz',
	'ken',
	'kir',
	'prk',
	'kor',
	'kwt',
	'kgz',
	'lao',
	'lva',
	'lbn',
	'lso',
	'lbr',
	'lby',
	'lie',
	'ltu',
	'lux',
	'mac',
	'mkd',
	'mdg',
	'mwi',
	'mys',
	'mdv',
	'mli',
	'mlt',
	'mhl',
	'mtq',
	'mrt',
	'mus',
	'myt',
	'mex',
	'fsm',
	'mda',
	'mco',
	'mng',
	'mne',
	'msr',
	'mar',
	'moz',
	'mmr',
	'nam',
	'nru',
	'npl',
	'nld',
	'ncl',
	'nzl',
	'nic',
	'ner',
	'nga',
	'niu',
	'nfk',
	'mnp',
	'nor',
	'omn',
	'pak',
	'plw',
	'pse',
	'pan',
	'png',
	'pry',
	'per',
	'phl',
	'pcn',
	'pol',
	'prt',
	'pri',
	'qat',
	'rou',
	'rus',
	'rwa',
	'reu',
	'blm',
	'shn',
	'kna',
	'lca',
	'maf',
	'spm',
	'vct',
	'wsm',
	'smr',
	'stp',
	'sau',
	'sen',
	'srb',
	'syc',
	'sle',
	'sgp',
	'sxm',
	'svk',
	'svn',
	'slb',
	'som',
	'zaf',
	'sgs',
	'ssd',
	'esp',
	'lka',
	'sdn',
	'sur',
	'sjm',
	'swz',
	'swe',
	'che',
	'syr',
	'twn',
	'tjk',
	'tza',
	'tha',
	'tls',
	'tgo',
	'tkl',
	'ton',
	'tto',
	'tun',
	'tur',
	'tkm',
	'tca',
	'tuv',
	'uga',
	'ukr',
	'are',
	'gbr',
	'usa',
	'umi',
	'ury',
	'uzb',
	'vut',
	'ven',
	'vnm',
	'vgb',
	'vir',
	'wlf',
	'yem',
	'zmb',
	'zwe',
	'ala',
	'xxk',
	'gb_eng',
	'gb_nir',
	'gb_sct',
	'gb_wls',
	// Why are some countries iso-2?
	'ar',
	'cz',
	'bm',
]);
