export const CREATED = 'CREATED';
export const PAID = 'PAID';
export const IN_WMS = 'IN_WMS';
export const WMS_ACCEPTED = 'WMS_ACCEPTED';
export const WMS_INCOMPLETE = 'WMS_INCOMPLETE';
export const WMS_READY = 'WMS_READY';
export const SENT = 'SENT';
export const DELIVERED = 'DELIVERED';
export const CANCELLED = 'CANCELLED';
export const RETURNED = 'RETURNED';
export const REFUNDED = 'REFUNDED';
export const REVERT_FINANCE_AND_FEES = 'REVERT_FINANCE_AND_FEES';
export const RETURNING_GOODS = 'RETURNING_GOODS';
export const LOSS_EVENT = 'LOSS_EVENT';

export const NOT_FOUND = -1;
export const QUERY_KEY = 'orders';
export const DEFAULT_LIMIT_PER_PAGE = 20;

export const SKELETONS_NUM_BUNDLES = 3;

export enum OrderSortingColumn {
	ID = 'ID',
	PRICE = 'PRICE',
	TIME = 'TIME',
}

export const ORDER_SORTING_COLUMNS: { [key in OrderSortingColumn]: string } = {
	[OrderSortingColumn.ID]: 'userSection.orders.orderId',
	[OrderSortingColumn.TIME]: 'userSection.orders.date',
	[OrderSortingColumn.PRICE]: 'userSection.orders.price',
};

export const ORDER_SORTING_COLUMNS_MOBILE: {
	label: string;
	column: OrderSortingColumn;
	isDescending: boolean;
}[] = [
	{
		label: 'userSection.orders.filter.dateDesc',
		column: OrderSortingColumn.TIME,
		isDescending: true,
	},
	{
		label: 'userSection.orders.filter.dateAsc',
		column: OrderSortingColumn.TIME,
		isDescending: false,
	},
];
