import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
	UsersAddressesDeleteParams,
	UsersAddressesDetailParams,
	UsersBillingInformationDeleteParams,
	UsersBillingInformationDetailParams,
	VinistoAuthDllModelsApiAddressAddress,
	VinistoAuthDllModelsApiAddressUserAddressesReturn,
	VinistoAuthDllModelsApiBillingInfoBillingInfo,
	VinistoAuthDllModelsApiBillingInfoUserBillingInfosReturn,
} from 'vinisto_api_client/src/api-types/user-api';
import {
	DELIVERY_ADDRESSES_QUERY_KEY,
	INVOICE_ADDRESSES_QUERY_KEY,
} from 'Services/Addresses/constants';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { OrderContext } from 'Services/OrderService/context';

import { AddressesApi, BillingInfoApi } from '.';

export const AddressesApiHooks = {
	useGetAll: (
		params?: Omit<UsersAddressesDetailParams, 'userId' | 'UserLoginHash'>
	) => {
		const { vinistoUser } = useContext(AuthenticationContext);
		const { deliveryMethod } = useContext(OrderContext);
		return useQuery<VinistoAuthDllModelsApiAddressUserAddressesReturn>(
			[DELIVERY_ADDRESSES_QUERY_KEY, deliveryMethod],
			() =>
				AddressesApi.getAll({
					UserLoginHash: vinistoUser?.loginHash,
					userId: vinistoUser?.id ?? '',
					deliveryId: deliveryMethod?.id ?? '',
					...params,
				}),
			{
				cacheTime: 0,
				staleTime: 0,
				enabled: !!vinistoUser?.id && !!vinistoUser?.loginHash,
			}
		);
	},
	useCreate: (onCreateCallback: (id: string | null | undefined) => void) => {
		const queryClient = useQueryClient();
		const authenticationContext = useContext(AuthenticationContext);

		return useMutation(
			// TODO: come up with a better type, omitting id property is a workaround
			(address: Omit<VinistoAuthDllModelsApiAddressAddress, 'id'>) =>
				AddressesApi.create({
					...address,
					userId: authenticationContext?.vinistoUser?.id ?? '',
					userLoginHash: authenticationContext?.vinistoUser?.loginHash,
				}),
			{
				onSuccess: (data) => {
					onCreateCallback(data?.address?.id);
					queryClient.invalidateQueries([DELIVERY_ADDRESSES_QUERY_KEY]);
				},
			}
		);
	},
	useUpdate: ({ addressId }: { addressId: string }) => {
		const queryClient = useQueryClient();
		const authenticationContext = useContext(AuthenticationContext);

		return useMutation(
			// TODO: come up with a better type, omitting id property is a workaround
			(address: Omit<VinistoAuthDllModelsApiAddressAddress, 'id'>) =>
				AddressesApi.update({
					...address,
					userId: authenticationContext?.vinistoUser?.id ?? '',
					userLoginHash: authenticationContext?.vinistoUser?.loginHash,
					addressId,
				}),
			{
				onSuccess: () => {
					queryClient.invalidateQueries([DELIVERY_ADDRESSES_QUERY_KEY]);
				},
			}
		);
	},
	useDelete: () => {
		const queryClient = useQueryClient();
		const { vinistoUser } = useContext(AuthenticationContext);
		return useMutation(
			(params: Omit<UsersAddressesDeleteParams, 'userId' | 'UserLoginHash'>) =>
				AddressesApi.delete({
					userId: vinistoUser?.id ?? '',
					UserLoginHash: vinistoUser?.loginHash,
					...params,
				}),
			{
				onSuccess: () => {
					queryClient.invalidateQueries([DELIVERY_ADDRESSES_QUERY_KEY]);
				},
			}
		);
	},
};

export const BillingInfoApiHooks = {
	useGetAll: (
		params?: Omit<
			UsersBillingInformationDetailParams,
			'userId' | 'UserLoginHash'
		>
	) => {
		const { vinistoUser } = useContext(AuthenticationContext);
		return useQuery<VinistoAuthDllModelsApiBillingInfoUserBillingInfosReturn>(
			[INVOICE_ADDRESSES_QUERY_KEY],
			() =>
				BillingInfoApi.getAll({
					UserLoginHash: vinistoUser?.loginHash,
					userId: vinistoUser?.id ?? '',
					...params,
				}),
			{
				cacheTime: 0,
				staleTime: 0,
				enabled: !!vinistoUser?.id && !!vinistoUser?.loginHash,
			}
		);
	},
	useCreate: (onCreateCallback: (id: string | null | undefined) => void) => {
		const queryClient = useQueryClient();
		const authenticationContext = useContext(AuthenticationContext);

		return useMutation(
			(address: Omit<VinistoAuthDllModelsApiBillingInfoBillingInfo, 'id'>) =>
				BillingInfoApi.create({
					...address,
					userId: authenticationContext?.vinistoUser?.id ?? '',
					userLoginHash: authenticationContext?.vinistoUser?.loginHash,
				}),
			{
				onSuccess: (data) => {
					onCreateCallback(data?.billingInfo?.id);
					queryClient.invalidateQueries([INVOICE_ADDRESSES_QUERY_KEY]);
				},
			}
		);
	},
	useUpdate: ({ billingInfoId }: { billingInfoId: string }) => {
		const queryClient = useQueryClient();
		const authenticationContext = useContext(AuthenticationContext);

		return useMutation(
			(address: Omit<VinistoAuthDllModelsApiBillingInfoBillingInfo, 'id'>) =>
				BillingInfoApi.update({
					...address,
					userId: authenticationContext?.vinistoUser?.id ?? '',
					userLoginHash: authenticationContext?.vinistoUser?.loginHash,
					billingInfoId,
				}),
			{
				onSuccess: () => {
					queryClient.invalidateQueries([INVOICE_ADDRESSES_QUERY_KEY]);
				},
			}
		);
	},
	useDelete: () => {
		const queryClient = useQueryClient();
		const { vinistoUser } = useContext(AuthenticationContext);
		return useMutation(
			(
				params: Omit<
					UsersBillingInformationDeleteParams,
					'userId' | 'UserLoginHash'
				>
			) =>
				BillingInfoApi.delete({
					userId: vinistoUser?.id ?? '',
					UserLoginHash: vinistoUser?.loginHash,
					...params,
				}),
			{
				onSuccess: () => {
					queryClient.invalidateQueries([INVOICE_ADDRESSES_QUERY_KEY]);
				},
			}
		);
	},
};
