import Carousel from 'Components/Carousel';
import { HOME_PAGE_PRODUCTS_CAROUSEL } from 'Components/Carousel/constants';
import { CARD_TYPE } from 'Components/Carousel/interfaces';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import FreeDeliveryProgressBar from 'Components/FreeDeliveryProgressBar';
import { QuantityBoxTypes } from 'Components/QuantityBox/constants';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import usePrevious from 'Hooks/usePrevious';
import { CTA_VISIBILITY_THRESHOLD } from 'Pages/CartShippingData/constants';
import { apiServiceInstance } from 'Services/ApiService';
import { VinistoProductDllModelsApiBundleBundlesReturn } from 'vinisto_api_client/src/api-types/product-api';
import { BasketContext } from 'Services/BasketService';
import BundleService from 'Services/Bundle';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import { NotificationsContext } from 'Services/NotificationService';
import { WarehouseContext } from 'Services/WarehouseService';
import { find, get, isEmpty, uniqueId } from 'lodash-es';
import {
	MouseEvent,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useInViewport } from 'react-in-viewport';
import { useQuery } from '@tanstack/react-query';
import { useMatch, useNavigate } from 'react-router-dom';
import { useCurrentlyRecommendedTagBundles } from 'Hooks/useCurrentlyRecommendedTagBundles';

import '../UserSection/Orders/styles.css';
import OrderDesktop from './Components/OrderDesktop';
import OrderMobile from './Components/OrderMobile';
import './styles.css';
import styles from './styles.module.css';

import { VinistoHelperDllEnumsCountryCode } from '@/api-types/warehouse-api';
import { bundleAdapter } from '@/index';

const CrossSell = () => {
	const deviceContext = useContext(DeviceServiceContext);
	const localizationContext = useContext(LocalizationContext);
	const documentHeaderContext = useContext(DocumentHeaderContext);
	const notificationsContext = useContext(NotificationsContext);
	const basketContext = useContext(BasketContext);
	const warehouseContext = useContext(WarehouseContext);
	const t = localizationContext.useFormatMessage();
	const { currency } = localizationContext.activeCurrency;
	const countryOfSale = localizationContext.countryOfSale;

	const [isShowFloatingFooter, setIsShowFloatingFooter] = useState(false);
	const ctaRef = useRef<HTMLDivElement>(null);
	const { inViewport: isCtaInViewport } = useInViewport(ctaRef, {
		threshold: CTA_VISIBILITY_THRESHOLD,
	});

	const getLocalizedValue = useLocalizedValue();
	const navigate = useNavigate();
	const match = useMatch(`${t({ id: 'routes.crossSell.route' })}/:itemUrl`);

	const handleGoToCart = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			if (event) {
				event.preventDefault();
				event.stopPropagation();
			}
			navigate(`/${t({ id: 'routes.cart.route' })}`);
		},
		[navigate]
	);

	const handleGoBack = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	const itemUrl = get(match, 'params.itemUrl', '');

	const { data: bundleData, isLoading } = useQuery(
		['bundleDetailData', { itemUrl, currency, countryOfSale }],
		() =>
			BundleService.getBundleByUrl(itemUrl, {
				currency,
				countryOfSale: countryOfSale as VinistoHelperDllEnumsCountryCode,
			}),
		{
			enabled: Boolean(itemUrl),
			onError: () => {
				notificationsContext.handleShowErrorNotification(
					'productDetail.loading.error'
				);
				navigate('/');
			},
		}
	);

	const sanitizeBundlesAndFetchQuantities = useCallback(
		(bundles: any) => {
			const validBundles = [...bundles].filter((bundle) => bundle && bundle.id);
			const ids = validBundles
				.map((bundle) => bundle?.id)
				.filter((id): id is string => !!id);

			warehouseContext.fetchQuantity(ids);
		},
		[warehouseContext]
	);

	//TODO: update dep array, set translation variable outside of useEffect
	useEffect(() => {
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.crossSell.name' })}` }
			)}`,
		});
	}, []);

	useEffect(() => {
		if (
			ctaRef.current &&
			get(getComputedStyle(ctaRef.current), 'display') !== 'none'
		) {
			setIsShowFloatingFooter(!isCtaInViewport);
		}
	}, [
		setIsShowFloatingFooter,
		isCtaInViewport,
		deviceContext.isMobile,
		deviceContext.isTablet,
	]);

	const currentlyRecommendedCarouselDataQuery =
		useCurrentlyRecommendedTagBundles();

	const alsoBoughtCarouselQuery = useQuery(
		['product-api/bundles/GetLastViewed', { itemUrl, currency, countryOfSale }],
		() => {
			return apiServiceInstance
				.getCollection<VinistoProductDllModelsApiBundleBundlesReturn>(
					'product-api/bundles/GetLastViewed',
					[
						{ key: 'IsCache', value: false },
						{ key: 'limit', value: 10 },
						{ key: 'offset', value: 10 },
						{ key: 'isDeleted', value: false },
						{ key: 'isGift', value: false },
						{ key: 'isEnabled', value: true },
						{ key: 'Currency', value: currency },
						{ key: 'CountryOfSale', value: countryOfSale },
					],
					true
				)
				.then((payload) => {
					const bundles = payload?.bundles || [];
					sanitizeBundlesAndFetchQuantities(bundles);
					return bundles.map((bundle) =>
						bundleAdapter.fromApi(bundle, { currency })
					);
				});
		},
		{ enabled: Boolean(itemUrl) }
	);

	const basketItem = find(
		get(basketContext, 'basketState.items'),
		(basketItem) => get(basketItem, 'bundleId', '') === bundleData?.id
	);

	// redirect when displayed item is not in basket
	useEffect(() => {
		if (bundleData && !basketItem && !isEmpty(basketContext.basketState)) {
			navigate(
				`/${t({ id: 'routes.product.route' })}/${getLocalizedValue(
					bundleData.url ?? []
				)}`,
				{
					replace: true,
				}
			);
		}
	}, [
		navigate,
		bundleData,
		basketItem,
		basketContext.basketState,
		t,
		getLocalizedValue,
	]);

	const nextBasketItem = {
		...basketItem,
		bundle: bundleData, // TODO: isn't this identical to basketItem.bundle?
	};
	const previousBasketItem = usePrevious(nextBasketItem);

	useEffect(() => {
		if (
			basketItem &&
			!!get(previousBasketItem, 'bundleId', false) &&
			!get(nextBasketItem, 'bundleId', false)
		) {
			navigate(-1);
		}
	}, [basketItem, nextBasketItem, previousBasketItem]);

	return (
		<section id="content-wrapper">
			<div className="container mt-4">
				<div className="row">
					<div className="col-12">
						<div className="vinisto-crosssell">
							{deviceContext.isMobile ? (
								<OrderMobile
									key={`order-mobile-${get(
										basketItem,
										'bundleId',
										uniqueId()
									)}`}
									basketItem={nextBasketItem}
									isLoading={isLoading}
								/>
							) : (
								<OrderDesktop
									key={`order-desktop-${get(
										basketItem,
										'bundleId',
										uniqueId()
									)}`}
									basketItem={nextBasketItem}
									isLoading={isLoading}
								/>
							)}
							<div className="vinisto-crosssell__ctas desktop-only">
								<button
									onClick={handleGoBack}
									className="vinisto-crosssell__ctas__back"
								>
									&lt; {t({ id: 'basket.backToShopping' })}
								</button>
								{basketContext?.minimalPriceForFreeDelivery !== null && (
									<div className="vinisto-crosssell__ctas__delivery-bar desktop-only">
										<FreeDeliveryProgressBar variant="wide-horizontal" />
									</div>
								)}
								<button
									onClick={handleGoToCart}
									className={`vinisto-btn vinisto-bg-green ${styles.crosssellNextStepBtn}`}
								>
									{t({ id: 'basket.continueToCart' })}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="position-relative overflow-hidden">
							<p className="h2 vinisto-crosssell__heading">
								{t({ id: 'crossSell.youMightAlsoLike' })}
							</p>
							<div className="vinisto-crosssell__carousel-wrap">
								<Carousel
									isLoading={currentlyRecommendedCarouselDataQuery.isLoading}
									data={currentlyRecommendedCarouselDataQuery.data}
									carouselType={HOME_PAGE_PRODUCTS_CAROUSEL}
									cardType={CARD_TYPE.CAROUSEL_CLASSIC}
									isCrossSell
									showAddToBasketBtn
									displayPriceAsRange
									redirectToCrossSell
									quantityBoxType={QuantityBoxTypes.DIRECT}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="position-relative overflow-hidden">
							<p className="h2 vinisto-crosssell__heading">
								{t({ id: 'crossSell.peopleAlsoShop' })}
							</p>
							<div className="vinisto-crosssell__carousel-wrap">
								<Carousel
									isLoading={alsoBoughtCarouselQuery.isLoading}
									data={alsoBoughtCarouselQuery.data}
									carouselType={HOME_PAGE_PRODUCTS_CAROUSEL}
									cardType={CARD_TYPE.CAROUSEL_CLASSIC}
									isCrossSell
									showAddToBasketBtn
									displayPriceAsRange
									redirectToCrossSell
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container vinisto-crosssell">
				<div className="row">
					<div className="col-12">
						<div
							className="vinisto-crosssell__ctas tablet-mobile-only"
							ref={ctaRef}
						>
							<button
								onClick={handleGoBack}
								className="vinisto-crosssell__ctas__back"
							>
								&lt; {t({ id: 'basket.backToShopping' })}
							</button>
							<button
								onClick={handleGoToCart}
								className="vinisto-btn vinisto-user-orders__orders__order__cta__btn vinisto-crosssell__ctas__cta vinisto-success-btn"
							>
								{t({ id: 'basket.continueToCart' })}
							</button>
						</div>
					</div>
				</div>
			</div>

			{isShowFloatingFooter && (
				<div
					className="container vinisto-crosssell vinisto-crosssell--floating"
					style={{ bottom: `${deviceContext.footerHeight}px` }}
				>
					<div className="row">
						<div className="col-12">
							<div className="vinisto-crosssell__ctas tablet-mobile-only">
								<button
									onClick={handleGoBack}
									className="vinisto-crosssell__ctas__back"
								>
									&lt; {t({ id: 'basket.backToShopping' })}
								</button>
								<button
									onClick={handleGoToCart}
									className="vinisto-btn vinisto-user-orders__orders__order__cta__btn vinisto-crosssell__ctas__cta vinisto-success-btn"
								>
									{t({ id: 'basket.continueToCart' })}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</section>
	);
};

export default CrossSell;
