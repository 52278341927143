import { useContext, useEffect } from 'react';
import { ModalContext } from 'Components/Modal/context';

interface IOpenModalProps {
	modalName: string;
	modalData?: Record<any, any> | null | undefined;
}

const OpenModal: React.FC<IOpenModalProps> = ({
	modalName,
	modalData = {},
}) => {
	const { handleOpenModal } = useContext(ModalContext);

	useEffect(() => {
		handleOpenModal(modalName, modalData);
	}, []);

	return <></>;
};

export default OpenModal;
