import { FC } from 'react';

import { IIconProps } from './Interfaces';

const HeaderContactIcon: FC<IIconProps> = ({ className }) => {
	return (
		<svg
			width={13.505}
			height={15}
			viewBox="0 0 13.505 15"
			className={className}
		>
			<path
				d="M705.213,105.431c-.631-.061-1.475.178-1.478-.757s.841-.708,1.451-.768v-1.5c-.229,0-.464.007-.7,0a.745.745,0,0,1-.78-.736.755.755,0,0,1,.782-.741c.224-.009.448,0,.7,0v-1.51h-.544c-.585,0-.939-.286-.935-.75s.349-.727.947-.731h.534v-1.52c-.229,0-.451.009-.672,0a.748.748,0,1,1,.021-1.488c.21,0,.42,0,.676,0,0-.286-.006-.536,0-.785a1.463,1.463,0,0,1,1.453-1.454q4.539-.015,9.078,0a1.471,1.471,0,0,1,1.463,1.57c.008,1.387,0,2.773,0,4.159q0,3.76,0,7.519a1.524,1.524,0,0,1-1.711,1.729c-2.84,0-5.68-.029-8.519.016a1.6,1.6,0,0,1-1.77-1.8C705.224,105.741,705.213,105.608,705.213,105.431ZM709,103.136h4.445a2.222,2.222,0,0,0-2.3-2.217A2.175,2.175,0,0,0,709,103.136Zm2.242-5.968a1.5,1.5,0,0,0-1.533,1.461,1.529,1.529,0,0,0,1.5,1.55,1.55,1.55,0,0,0,1.522-1.494A1.519,1.519,0,0,0,711.238,97.168Z"
				transform="translate(-703.708 -92.674)"
				fill="#4d4d4e"
			/>
		</svg>
	);
};

export default HeaderContactIcon;
