import useLocalizedValue from 'Hooks/useLocalizedValue';
import BundleService from 'Services/Bundle';
import { CategoryData } from 'Services/Bundle/interfaces';
import { LocalizationContext } from 'Services/LocalizationService';
import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

const CategoryMessage = ({ categoryId }: { categoryId: string }) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();

	const categoryQuery = useQuery<CategoryData | undefined>(
		['getCategoryById', categoryId],
		() => BundleService.getCategoriesById(categoryId),
		{
			enabled: !!categoryId,
		}
	);

	return categoryQuery.data ? (
		<div>
			{t(
				{
					id: 'notification.message.discountCoupon.discountCouponDoesNotMatch.category',
				},
				{
					category: (
						<Link
							to={`/${t({ id: 'routes.category.route' })}/${getLocalizedValue(
								categoryQuery.data.url ?? []
							)}`}
						>
							{getLocalizedValue(categoryQuery.data.name ?? [])}
						</Link>
					),
				}
			)}
		</div>
	) : null;
};

export default CategoryMessage;
