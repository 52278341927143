import {
	DocumentHeaderAction,
	OpenGraphItemType,
	TwitterCardType,
} from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import Config from 'Config';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import usePrevious from 'Hooks/usePrevious';
import { createContext, FC, useContext, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalizationContext } from 'Services/LocalizationService';
import { NotificationsContext } from 'Services/NotificationService';

import { fetchTagByUrl } from './helpers';
import { ITagContextProviderProps, ITagContextValues } from './interfaces';

const tagContextDefaultValues: ITagContextValues = {
	tagData: {},
	tagUrl: '',
};

export const TagContext = createContext(tagContextDefaultValues);

const TagContextProvider: FC<ITagContextProviderProps> = (props) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const notificationsContext = useContext(NotificationsContext);
	const documentHeaderContext = useContext(DocumentHeaderContext);
	const getLocalizedValue = useLocalizedValue();
	const navigate = useNavigate();
	const location = useLocation();
	const pathname = location.pathname;
	const prevPathname = usePrevious(pathname);
	let tagUrl = props.tagUrl ?? '';

	// TODO: hardcoded for now
	if (tagUrl === 'novinky') {
		tagUrl = 'novinka';
	}

	// Tag Data
	const tagData = useQuery(
		['tag', { tagUrl }],
		() => fetchTagByUrl({ tagUrl }),
		{
			onError: (err: Error) => {
				if (err.message === 'ObjectNotFound') {
					navigate('/');
					return notificationsContext.handleShowErrorNotification(
						'notification.message.tag.error.ObjectNotFound'
					);
				}
			},
		}
	);
	const isLoading = tagData.isLoading;
	const isPreviousLoading = usePrevious(isLoading);

	useEffect(() => {
		if ((isPreviousLoading || pathname !== prevPathname) && !isLoading) {
			const tag = tagData.data?.tag;
			let tagName = getLocalizedValue(tag?.name ?? []);
			// TODO: hardcoded for now
			if (tagName === 'Novinka') {
				tagName = 'Novinky';
			}
			const pageTitle = `${t({ id: 'app.title.page' }, { title: tagName })}`;
			const pageDescription = getLocalizedValue(tag?.description ?? []);
			documentHeaderContext.dispatch({
				type: DocumentHeaderAction.set,
				value: {
					title: pageTitle,
					description: pageDescription,
					twitterCard: {
						card: TwitterCardType.summary,
						title: pageTitle,
						description: pageDescription,
						image: '', // TODO: missing image from product ppl
					},
					openGraph: {
						type: OpenGraphItemType.article,
						title: pageTitle,
						description: pageDescription,
						url: `${Config.baseUrl}${t({
							id: 'routes.tag.route',
						})}/${tagUrl}`,
						image: '', // TODO: missing image from product ppl
						fbAdmins: Config.market.socials.facebookAdmins,
					},
					jsonLd: [
						{
							'@context': 'https://schema.org',
							'@type': 'BreadcrumbList',
							itemListElement: [
								{
									'@type': 'ListItem',
									position: 1,
									name: Config.domainName,
									item: Config.baseUrl,
								},
								{
									'@type': 'ListItem',
									position: 2,
									name: tagName,
								},
							],
						},
						{
							'@context': 'https://schema.org',
							'@type': 'Article',
							headline: pageTitle,
							image: [], // TODO: missing image from product ppl
						},
					],
				},
			});
		}
	}, [tagData, isLoading, isPreviousLoading, t, getLocalizedValue]);

	return (
		<TagContext.Provider
			value={{
				tagData,
				tagUrl,
			}}
		>
			{props.children}
		</TagContext.Provider>
	);
};

export default TagContextProvider;
