import { apiServiceInstance } from 'Services/ApiService';
import { VinistoProductDllModelsApiEvaluationEvaluationsReturn } from 'vinisto_api_client/src/api-types/product-api';

import { IFetchReviewPageProps } from './interfaces';
import { API_EVALUATIONS_URL, SORTING_API_COLUMN } from './constants';

export const fetchReviewPage = (
	data: IFetchReviewPageProps
): Promise<VinistoProductDllModelsApiEvaluationEvaluationsReturn> => {
	const page = data.page ?? 1;
	const limit = data.limit ?? 10;
	const productId = data.productId;
	const sorting = data.sorting ?? null;
	const isSortingDesc = !!data.isSortingDesc;

	if (!productId)
		return new Promise((_, reject) =>
			reject('Product ID for reviews is missing.')
		);

	const offset = (page - 1) * limit;
	const params = [
		{ key: 'ProductId', value: productId },
		{ key: 'Offset', value: offset },
		{ key: 'Limit', value: limit },
		{ key: 'IsCache', value: true },
	];

	if (sorting !== null) {
		params.push({
			key: 'SortingColumn',
			value: SORTING_API_COLUMN[sorting],
		});
		params.push({ key: 'IsSortingDescending', value: `${isSortingDesc}` });
	}

	return apiServiceInstance.get(API_EVALUATIONS_URL, false, undefined, params);
};
