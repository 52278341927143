import { useContext, useEffect } from 'react';
import { DeviceServiceContext } from 'Services/DeviceService';
import { TagContext } from 'Pages/Tag/context';
import Card from 'Components/View/Card';
import Bundles from 'Pages/Category/Components/CategoryBundlesWithFilters/Components/Bundles';
import Filters from 'Pages/Category/Components/CategoryBundlesWithFilters/Components/Filters';
import styles from 'Pages/Category/Components/CategoryBundlesWithFilters/styles.module.css';
import Container from 'Components/View/Container';
import ActiveFilters from 'Pages/Category/Components/ActiveFilters';
import { useScrollFixedFilters } from 'Hooks/useScrollFixedFilters';
import { NavbarContext } from 'Components/Navbar/context';

import TagHeader from '../TagHeader';

const TagBundlesWithFilters = () => {
	const { tagUrl } = useContext(TagContext);
	const deviceContext = useContext(DeviceServiceContext);
	const { isFiltersVisible: isVisible } = useContext(NavbarContext);

	const filterStyles =
		deviceContext.isMobile || deviceContext.isTablet
			? {
					display: isVisible ? 'fixed' : 'none',
			  }
			: {};

	useEffect(() => {
		if (isVisible) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.removeProperty('overflow');
		}
		return () => {
			document.body.style.removeProperty('overflow');
		};
	}, [isVisible]);

	const { filtersContainerRef, filtersRef, contentRef } =
		useScrollFixedFilters();

	return (
		<Container
			className="align-items-start"
			containerClassName="mt-0"
		>
			<div
				className="col vinisto-filter"
				style={filterStyles}
				// to force React to treat it as a new component and re-render it when the isVisible state changes
				key={isVisible ? 1 : 0}
				ref={filtersContainerRef}
			>
				<Card className={styles.filtersCard}>
					<Filters
						itemUrl={tagUrl}
						parentRef={filtersRef}
					/>
				</Card>
			</div>
			<div
				className="col vinisto-category-list"
				ref={contentRef}
			>
				<TagHeader />
				<ActiveFilters />
				<Bundles />
			</div>
		</Container>
	);
};

export default TagBundlesWithFilters;
