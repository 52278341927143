import { useContext, useEffect } from 'react';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { LocalizationContext } from 'Services/LocalizationService';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import userSectionStyles from 'Pages/UserSection/styles.module.css';

import BreadCrumbsUserSection from '../Breadcrumbs';

import LoginCredentials from './Components/LoginCredentials';
import Newsletter from './Components/LoginCredentials/Components/Newsletter';

const Settings = () => {
	const documentHeaderContext = useContext(DocumentHeaderContext);
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	useEffect(() => {
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.user-section.settings.name' })}` }
			)}`,
		});
	}, []);

	return (
		<>
			<BreadCrumbsUserSection />

			<h1 className={userSectionStyles.userSectionMainHeader}>
				{t({ id: 'userSection.settings.loginCredentials.title' })}
			</h1>

			<h2 className={userSectionStyles.userSectionHeader}>
				{t({ id: 'userSection.settings.loginCredentials.loginInfo' })}
			</h2>
			<LoginCredentials />

			<h2 className={userSectionStyles.userSectionHeader}>
				{t({ id: 'userSection.settings.newsletter.title' })}
			</h2>
			<Newsletter />
		</>
	);
};

export default Settings;
