import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import Error from 'Components/Error';
import ContainerFullWidth from 'Components/View/ContainerFullWidth';

import './styles.css';
import UserHeader from './UserHeader';

const UserSection = () => {
	return (
		<section id="content-wrapper">
			<ContainerFullWidth>
				<UserHeader />
			</ContainerFullWidth>
			<ContainerFullWidth>
				<ErrorBoundary fallback={<Error />}>
					<Outlet />
				</ErrorBoundary>
			</ContainerFullWidth>
		</section>
	);
};

export default UserSection;
