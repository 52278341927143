import { useEffect, useRef } from 'react';
import { PPLSelectDetail } from 'Vendor/PPL/interfaces';

import {
	MAPY_CZ_SCRIPT_ID,
	PPL_PICK_EVENT,
	SCRIPT_ID,
	SCRIPT_URL,
	STYLES_ID,
	STYLES_URL,
} from './constants';
import styles from './styles.module.css';

type PPLWidgetProps = {
	onPick: (result: PPLSelectDetail) => void;
	lat?: string;
	lon?: string;
	language?: string;
	mode?: 'static' | 'catalog' | 'default';
	/** PPL widget filters string example: "ParcelShop, CardPayment"
	 * Available filters: ParcelShop, Morning, Saturday, CardPayment, ParcelBox, Afternoon, Sunday
	 */
	initialFilters?: string;
};

const PPLWidget = ({
	onPick,
	lat,
	lon,
	language,
	mode = 'default',
	initialFilters,
}: PPLWidgetProps) => {
	const widgetRef = useRef<HTMLDivElement>(null);

	const handleOnPick = (event: any) => {
		const detail = event?.detail as PPLSelectDetail;
		if (typeof onPick === 'function') {
			onPick(detail);
		}
	};

	useEffect(() => {
		const widgetElement = widgetRef.current;
		if (!widgetElement) return;
		const stylesElement = document.getElementById(STYLES_ID);
		const scriptElement = document.getElementById(SCRIPT_ID);
		if (!stylesElement) {
			const link = document.createElement('link');
			link.rel = 'stylesheet';
			link.href = STYLES_URL;
			document.head.appendChild(link);
		}
		if (scriptElement) {
			scriptElement.remove();
		}
		const script = document.createElement('script');
		script.id = SCRIPT_ID;
		script.src = SCRIPT_URL;
		script.async = true;
		document.head.appendChild(script);

		document.addEventListener(PPL_PICK_EVENT, handleOnPick);
		return () => {
			document.removeEventListener(PPL_PICK_EVENT, handleOnPick);
			// Remove mapy.cz script imported by PPL widget
			document.getElementById(MAPY_CZ_SCRIPT_ID)?.remove();
		};
	}, []);

	return (
		<div
			ref={widgetRef}
			id={PPL_PICK_EVENT}
			className={styles.pplWidget}
			data-lat={lat}
			data-lon={lon}
			data-language={language}
			data-mode={mode}
			data-initialfilters={initialFilters}
		/>
	);
};

export default PPLWidget;
