import * as React from 'react';
import { get } from 'lodash-es';
import { useQueryParam } from 'use-query-params';
import { apiServiceInstance } from 'Services/ApiService';
import { LocalizationContext } from 'Services/LocalizationService';
import { NotificationsContext } from 'Services/NotificationService';
import { PreloaderContext } from 'Components/Preloader/context';

import { fetchPostCreatePaymentFromOrder } from './helpers';
import {
	IGoPayPaymentStatusState,
	IGoPayServiceContextModel,
	IGoPayServiceProviderProps,
} from './interfaces';

const defaultGoPayPaymentStatusState: IGoPayPaymentStatusState = {
	loadingPaymentStatus: false,
	loadedPaymentStatus: false,
	paymentStatusData: {} as Record<any, any>,
	paymentStatusError: null,
};

const defaultGoPayServiceContextModel: IGoPayServiceContextModel = {
	handleGetGoPayPaymentStatus: () => null,
	handleOnPayOnline: () => null,
	goPayPaymentStatusState: defaultGoPayPaymentStatusState,
};

export const GoPayServiceContext = React.createContext(
	defaultGoPayServiceContextModel
);

const GoPayServiceProvider = (props: IGoPayServiceProviderProps) => {
	const [goPayPaymentStatusState, setGoPayPaymentStatusState] =
		React.useState<IGoPayPaymentStatusState>(defaultGoPayPaymentStatusState);
	const preloaderContext = React.useContext(PreloaderContext);
	const notificationsContext = React.useContext(NotificationsContext);
	const localizationContext = React.useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const queryParams = useQueryParam('id');

	const handleGetGoPayPaymentStatus = React.useCallback(
		(goPayPaymentId: number) => {
			if (
				!get(goPayPaymentStatusState, 'loadingPaymentStatus') &&
				!!queryParams
			) {
				setGoPayPaymentStatusState({
					loadingPaymentStatus: true,
					loadedPaymentStatus: false,
					paymentStatusData: {},
					paymentStatusError: null,
				});
				apiServiceInstance
					.get(`services-api/gopay/${goPayPaymentId}`)
					.then((statusResponse) => {
						setGoPayPaymentStatusState({
							loadingPaymentStatus: false,
							loadedPaymentStatus: true,
							paymentStatusData: statusResponse,
							paymentStatusError: null,
						});
					})
					.catch((error) => {
						setGoPayPaymentStatusState({
							loadingPaymentStatus: false,
							loadedPaymentStatus: true,
							paymentStatusData: {},
							paymentStatusError: get(error, 'message', ''),
						});
					});
			} else {
				setGoPayPaymentStatusState({
					loadingPaymentStatus: false,
					loadedPaymentStatus: true,
					paymentStatusData: {},
					paymentStatusError: null,
				});
			}
		},
		[goPayPaymentStatusState]
	);

	const handleOnPayOnline = async (order: Record<string, any>) => {
		if (order?.payment?.goPayId) {
			preloaderContext.togglePreloader(true);

			try {
				const returnUrl = `${window.location.origin}/${t({
					id: 'routes.cart.confirmation.route',
				})}`;
				const data: Record<any, any> = await fetchPostCreatePaymentFromOrder({
					orderId: order?.id,
					notificationUrl: returnUrl,
					returnUrl: returnUrl,
				});

				if (data?.payment?.gwUrl) {
					window.location = data?.payment?.gwUrl;
				} else {
					throw new Error();
				}
			} catch (err: unknown) {
				preloaderContext.togglePreloader(false);
				notificationsContext.handleShowErrorNotification(
					'userSection.order.payOnline.error'
				);
			}
		}
	};

	const goPayServiceContextModel: IGoPayServiceContextModel = {
		handleGetGoPayPaymentStatus,
		handleOnPayOnline,
		goPayPaymentStatusState,
	};

	return (
		<GoPayServiceContext.Provider value={goPayServiceContextModel}>
			{get(props, 'children', '')}
		</GoPayServiceContext.Provider>
	);
};

export default GoPayServiceProvider;
