import { useContext } from 'react';
import { PHONE_NUMBER } from 'Components/Navbar/constants';
import { formatPhoneNumber } from 'Components/Navbar/helpers';
import { LocalizationContext } from 'Services/LocalizationService';

export const useInfoColumns = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	return [
		{
			title: t({ id: 'footer.howToBuy' }),
			links: [
				{
					name: t({ id: 'routes.delivery.name' }),
					to: `/${t({ id: 'routes.delivery.route' })}`,
				},
				{
					name: t({ id: 'routes.payments.name' }),
					to: `/${t({ id: 'routes.payments.route' })}`,
				},
				{
					name: t({ id: 'routes.termsAndConditions.name' }),
					to: `/${t({ id: 'routes.termsAndConditions.route' })}`,
				},
				{
					name: t({ id: 'routes.privacyProtection.name' }),
					to: `/${t({ id: 'routes.privacyProtection.route' })}`,
				},
				{
					name: t({ id: 'routes.returns.name' }),
					to: `/${t({ id: 'routes.returns.route' })}`,
				},
			],
		},
		{
			title: t({ id: 'footer.information' }),
			links: [
				{
					name: t({ id: 'routes.aboutUs.name' }),
					to: `/${t({ id: 'routes.aboutUs.route' })}`,
				},
				{
					name: t({ id: 'routes.contact.name' }),
					to: `/${t({ id: 'routes.contact.route' })}`,
				},
				{
					name: t({ id: 'routes.producers.name' }),
					to: `/${t({ id: 'routes.producers.route' })}`,
				},
				{
					name: t({ id: 'routes.blog.name' }),
					to: `/${t({ id: 'routes.community.route' })}/tag/blog`,
				},
			],
		},
		{
			title: t({ id: 'footer.sellWithUs' }),
			links: [
				{
					name: t({ id: 'routes.faqSeller.name' }),
					to: `/${t({ id: 'routes.faqSeller.route' })}`,
				},
				{
					name: t({ id: 'routes.becomeSupplier.name' }),
					to: `/${t({ id: 'routes.becomeSupplier.route' })}`,
				},
				{
					to: `https://b2b.vinisto.cz/`,
					name: t({ id: 'routes.horeca.name' }),
					as: 'button',
				},
			],
		},
		{
			title: t({ id: 'footer.contact' }),
			links: [
				{
					name: (
						<>
							vinisto s.r.o.
							<br />
							Jankovcova 1057/6 170 00, Praha 7<br />
							<a
								href="mailto:info@vinisto.cz"
								className="vnav-link underline-item"
							>
								info@vinisto.cz
							</a>
							<br />
							{formatPhoneNumber(PHONE_NUMBER)}
						</>
					),
					to: null,
				},
			],
		},
	];
};
