import { useCallback, useContext } from 'react';
import { get } from 'lodash-es';
import { LoginFormData } from 'Components/Modal/Components/Login/interfaces';
import { LOGIN_PASSWORD_TYPE } from 'Components/Form/Components/Password/constants';
import { requireEmail } from 'Components/Form/validators';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { LocalizationContext } from 'Services/LocalizationService';
import { Form, InputEmail, InputPassword } from 'Components/Form';
import { VinistoSpanLink } from 'Components/VinistoLink';
import useLoginRedirect from 'Hooks/useLoginRedirect';
import { useNavigate } from 'react-router-dom';

import { ModalContext } from '../../context';
import * as modalTypes from '../../constants';

import RegisterCta from './RegisterCta';

const LoginModal = () => {
	const modalContext = useContext(ModalContext);
	const modalData = modalContext.modalData ?? {};
	const authenticationContext = useContext(AuthenticationContext);
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const navigateFromAuthRequired = modalData?.navigateFromAuthRequired ?? false;
	const onCloseCallback = modalContext?.modalData?.onCloseCallback;
	const loginRedirect = useLoginRedirect(navigateFromAuthRequired);
	const navigate = useNavigate();

	const { showRegisterCta = true, showForgottenPasswordLink = true } =
		modalData;

	const handleOnLogIn = useCallback(
		(formValues: LoginFormData) => {
			authenticationContext.handleOnLogIn(
				formValues.email,
				formValues.password
			);
			loginRedirect();
		},
		[authenticationContext, loginRedirect]
	);

	const handleOpenRegistrationModal = useCallback(() => {
		const { REGISTRATION_MODAL } = modalTypes;

		modalContext.handleCloseModal();
		if (typeof onCloseCallback === 'function') {
			navigate(-1);
		}
		setTimeout(() => {
			modalContext.handleOpenModal(REGISTRATION_MODAL, {});
		}, 200);
	}, [modalContext, navigate, onCloseCallback]);

	const handleOpenForgottenModal = useCallback(() => {
		const { FORGOTTEN_PASSWORD_MODAL } = modalTypes;
		modalContext.handleCloseModal();
		if (typeof onCloseCallback === 'function') {
			navigate(-1);
		}
		setTimeout(() => {
			modalContext.handleOpenModal(FORGOTTEN_PASSWORD_MODAL, {});
		}, 200);
	}, [modalContext, navigate, onCloseCallback]);

	return (
		<div className="vinisto-popup__split">
			<div className="vinisto-popup__split__form">
				<Form
					submitCallback={handleOnLogIn}
					submitText={'modal.logIn.submitButtonText'}
					initializationValues={{
						email: get(modalContext, 'modalData.email', ''),
					}}
				>
					<div className="mb-3">
						<InputEmail validate={requireEmail} />
						<InputPassword type={LOGIN_PASSWORD_TYPE} />
					</div>
				</Form>
				<button
					onClick={handleOpenRegistrationModal}
					className="vinisto-btn vinisto-bg-outline-green tablet-mobile-only w-100 mt-3"
				>
					{t({ id: 'modal.registration.submitButtonText' })}
				</button>
				{showForgottenPasswordLink && (
					<div className="vinisto-popup__links vinisto-popup__links--login">
						<VinistoSpanLink
							className="vinisto-popup__link--gray"
							onClick={handleOpenForgottenModal}
						>
							{t({ id: 'modal.logIn.moveForgottenPasswordButton' })}
						</VinistoSpanLink>
					</div>
				)}
			</div>
			{showRegisterCta && (
				<RegisterCta
					handleOpenRegistrationModal={handleOpenRegistrationModal}
				/>
			)}
		</div>
	);
};

export default LoginModal;
