import useLocalizedValue from 'Hooks/useLocalizedValue';
import { apiServiceInstance } from 'Services/ApiService';
import { VinistoProductDllModelsApiSpecificationSpecificationReturn } from 'vinisto_api_client/src/api-types/product-api';
import { LocalizationContext } from 'Services/LocalizationService';
import { SpecificationDefinition } from 'Services/Specification/interfaces';
import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

export interface SpecificationMessageProps {
	specification?: {
		name?: string;
		definitionId?: string;
		allowedValues: string[] | number[] | boolean[];
	} | null;
}

const SpecificationMessage = ({ specification }: SpecificationMessageProps) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	const definitionId = specification?.definitionId ?? '';
	const allowedValues = specification?.allowedValues ?? [];

	const getLocalizedValue = useLocalizedValue();

	const specificationQuery = useQuery(
		['getSpecificationById', definitionId],
		() =>
			apiServiceInstance.get<
				Omit<
					VinistoProductDllModelsApiSpecificationSpecificationReturn,
					'specification'
				> & { specification: SpecificationDefinition }
			>(`product-api/specifications/${definitionId}/GetSpecification`),
		{
			enabled: !!definitionId,
		}
	);

	const specificationValue = allowedValues.map((value: unknown) => {
		return getLocalizedValue(
			specificationQuery.data?.specification?.allowedValues?.[String(value)]
				.name
		);
	});

	return (
		<div>
			{t(
				{
					id: 'notification.message.discountCoupon.discountCouponDoesNotMatch.specification',
				},
				{
					specification: (
						<Link
							to={`/produkty/${getLocalizedValue(
								specificationQuery.data?.specification?.name
							)}/${specificationValue}`}
						>
							{getLocalizedValue(specificationQuery.data?.specification?.name)}:{' '}
							{allowedValues.map((value: unknown, index: number) => {
								const translation =
									specificationQuery.data?.specification?.allowedValues?.[
										String(value)
									].name;
								return (
									<span key={index}>
										{index > 0 && ','} {getLocalizedValue(translation ?? [])}
									</span>
								);
							})}
						</Link>
					),
				}
			)}
		</div>
	);
};

export default SpecificationMessage;
