import cx from 'classnames';
import { HTMLProps } from 'react';

import styles from './styles.module.css';

type TTextAreaProps = HTMLProps<HTMLTextAreaElement>;

const Textarea = ({ className, children, ...props }: TTextAreaProps) => {
	return (
		<textarea
			className={cx(styles.textarea, className)}
			{...props}
		>
			{children}
		</textarea>
	);
};

export default Textarea;
