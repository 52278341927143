import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { apiServiceInstance } from 'Services/ApiService';
import { WarehouseContext } from 'Services/WarehouseService';
import { LocalizationContext } from 'Services/LocalizationService';
import CarouselSection from 'Components/CarouselSection';

import {
	VinistoHelperDllEnumsBundleSortableColumns,
	VinistoProductDllModelsApiBundleBundlesReturn,
} from '@/api-types/product-api';
import { bundleAdapter } from '@/index';

const UserCarousel = () => {
	const { fetchQuantity } = useContext(WarehouseContext);
	const { activeCurrency } = useContext(LocalizationContext);

	const userCarouselsQuery = useQuery(['userCarousel'], async () => {
		const response =
			await apiServiceInstance.post<VinistoProductDllModelsApiBundleBundlesReturn>(
				'product-api/bundles/GetBundles',
				{
					currency: activeCurrency.currency,
					isDeleted: false,
					isEnabled: true,
					isGift: false,
					isTemporaryUnavailable: false,
					tagId: '64456a70340fb40acd6c6ca0',
					sortingColumn: VinistoHelperDllEnumsBundleSortableColumns.SCORING,
					isSortingDescending: true,
				},
				true
			);

		if (response.isError) return Promise.reject(response.error);

		const bundleIds = response.bundles
			?.map((b) => b?.id)
			.filter((id): id is string => id !== null && id !== undefined);

		if (bundleIds && bundleIds.length > 0) fetchQuantity(bundleIds);

		// return response.bundles ?? [];
		return (
			response.bundles?.map((bundle) =>
				bundleAdapter.fromApi(bundle, { currency: activeCurrency.currency })
			) ?? []
		);
	});

	return (
		<CarouselSection
			key={`userSectionCarousel`}
			data={userCarouselsQuery.data ?? []}
			title={''}
			isLoading={userCarouselsQuery.isLoading}
		/>
	);
};
export default UserCarousel;
