import React from 'react';
import { VIEW } from 'Hooks/useCategoryView/interfaces';
import { type UseQueryResult } from '@tanstack/react-query';

import { MappedVinistoProductDllModelsApiBundleBundlesReturn } from './context';

import { Specification } from '@/domain/specification/schema';
import ProductTag from '@/domain/tag';
import { VinistoHelperDllEnumsCurrency } from '@/api-types/product-api';
import { Bundle } from '@/domain/bundle';

export interface IBundlesWithFiltersProviderProps {
	children?: React.ReactNode;
}

export interface IBundlesWithFiltersContextValues {
	activeSpecificationFilters: Record<any, any>[];
	activeTagFilters: ProductTag[];
	bundlesData: Record<any, any>[];
	bundles: (Bundle | { isLoading: boolean })[];
	bundlesCount: number;
	bundlesToLoadMore: number;
	currentPage: number;
	isBundlesLoading: boolean;
	isDataLoading: boolean;
	handleOnRemoveFilter: (specificationName: string) => () => void;
	handleOnViewChange: (view: VIEW) => () => void;
	limit: number;
	page: [number] | [number, number];
	query: Record<any, any>;
	setPageParam: (page: [number] | [number, number]) => void;
	isInStockParam: string;
	isInStockParamRef?: string;
	isDiscountedParam: string;
	isInStockActive: boolean;
	isDiscountedActive: boolean;
	totalActiveFiltersCount: number;
	setQuery: (query: Record<any, any>) => void;
	specificationsQuery: UseQueryResult<{ specifications: Specification[] }>;
	specificationsWithBundleFilters: {
		specificationFilters: Record<any, any>[];
		tagFilters: AvailableTagFilter[];
		supplierFilters: Record<any, any>[];
		isInStockFilters: Record<any, any>[];
		isDiscountedFilters: Record<any, any>[];
	};
	view: VIEW;
	sorting: BundleSorting;
	setSorting: (sort: BundleSorting) => void;
}

export interface IFetchBundlePageProps {
	page: number;
	limit: number;
	categoryId: string;
	sortingColumn: string;
	isSortingDescending?: boolean;
	countryOfSale: 'CZ' | 'SK' | 'DE' | 'UK';
	currency: VinistoHelperDllEnumsCurrency;
	// TODO can it be typed better?
	filters: Record<any, any>[];
	tagId?: string;
}

export interface ICreateBundlePageQueriesProps {
	categoryId: string;
	sortingColumn: string;
	isSortingDescending?: boolean;
	page: number[];
	countryOfSale: 'CZ' | 'SK' | 'DE' | 'UK';
	currency: VinistoHelperDllEnumsCurrency;
	// TODO can it be typed better?
	filters?: Record<any, any>[];
	tagId?: string;
	limit?: number;
	enabled?: boolean;
	isEnabled?: boolean;
	onError?: (err: unknown) => void;
	onSuccess?: (
		data: MappedVinistoProductDllModelsApiBundleBundlesReturn
	) => void;
}

export type BundleSorting = {
	title: string;
	sortingColumn: string;
	isSortingDescending: boolean;
};

export interface AvailableTagFilter {
	id: string;
	name: string;
	color: string;
	occurence: number;
}

export enum ListingType {
	Category = 'Category',
	Tag = 'Tag',
	All = 'All',
}
