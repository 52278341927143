import { useQuery } from '@tanstack/react-query';

import { LinksListParams } from '@/api-types/link-widget-api';
import { Allowed_Sections } from '@/domain/link-widget/enums';
import linkWidgetService from '@/link-widget-service';

/**
 * Hook for fetching link widgets for a given section.
 * @param section - Section for which to fetch link widgets.
 * @param pathname - Pathname of the current page.
 * @param widgetLimit - Limit of link widgets to fetch.
 * @returns Query object and filtered links.
 */
const useSectionLinkWidgetsQuery = (
	section: Allowed_Sections,
	pathname?: string,
	widgetLimit?: number
) => {
	const removeSortingParam = (url: string) =>
		url.split('/').slice(0, 3).join('/');

	const req: LinksListParams = {
		Sort: 'order',
		PathId: `${removeSortingParam(pathname ?? '/')}|${section}`,
	};
	const queryKey = ['linkWidgets', req];

	const query = useQuery(queryKey, async () =>
		linkWidgetService.linksList(req)
	);

	if (!widgetLimit) return { query, filteredLinks: query.data?.data };

	return {
		query,
		filteredLinks: query.data?.data?.slice(0, widgetLimit),
	};
};

export default useSectionLinkWidgetsQuery;
