import { apiServiceInstance } from 'Services/ApiService';
import { IQueryArgument } from 'Services/ApiService/interfaces';
import {
	SmartformListParams,
	VinistoSmartformDllModelsResponsePostResponse,
} from 'vinisto_api_client/src/api-types/services-api';

export const AddressSuggestionsApi = {
	get: ({ SearchAddress, Country, Limit, IsCache }: SmartformListParams) => {
		const params: IQueryArgument[] = [
			{ key: 'SearchAddress', value: SearchAddress },
		];

		if (Country) params.push({ key: 'Country', value: Country as string });
		if (Limit) params.push({ key: 'Limit', value: Limit as number });
		if (IsCache) params.push({ key: 'IsCache', value: IsCache as boolean });

		return apiServiceInstance.get(
			'services-api/smartform',
			true,
			undefined,
			params
		) as Promise<VinistoSmartformDllModelsResponsePostResponse>;
	},
};
