import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Config from 'Config';
import { LocalizationContext } from 'Services/LocalizationService';

import {
	IDocumentHeaderContextModel,
	IDocumentHeaderReducerAction,
	IDocumentHeaderState,
} from './interfaces';
import {
	DocumentHeaderAction as Action,
	DEFAULT_OG_IMAGE,
	DOCUMENT_HEADER_INIT_STATE,
	OpenGraphItemType,
	PREFIX_ATTRIBUTE,
	TwitterCardType,
} from './constants';

const reducer = (
	state: IDocumentHeaderState,
	action: IDocumentHeaderReducerAction
): IDocumentHeaderState => {
	switch (action.type) {
		case Action.set:
			return {
				...state,
				...action.value,
			};
		case Action.setTitle:
			return {
				...state,
				title: action.value,
			};
		case Action.setDescription:
			return {
				...state,
				description: action.value,
			};
		case Action.setJsonLd:
			return {
				...state,
				jsonLd: action.value,
			};
		case Action.setTwitterCard:
			return {
				...state,
				twitterCard: action.value,
			};
		case Action.setOpenGraph:
			return {
				...state,
				openGraph: action.value,
			};
	}
};

const defaultDocumentHeaderContextModel: IDocumentHeaderContextModel = {
	state: DOCUMENT_HEADER_INIT_STATE,
	dispatch: () => null,
};

export const DocumentHeaderContext = React.createContext(
	defaultDocumentHeaderContextModel
);

const DocumentHeaderContextProvider: React.FC<React.PropsWithChildren> = ({
	children,
}): JSX.Element => {
	const localizationContext = React.useContext(LocalizationContext);
	const [state, dispatch] = React.useReducer(
		reducer,
		defaultDocumentHeaderContextModel.state
	);
	const location = useLocation();

	const documentHeaderContextModel: IDocumentHeaderContextModel = {
		state,
		dispatch,
	};

	const jsonLd = state.jsonLd || {
		'@context': 'https://schema.org',
		'@type': 'Article',
		headline: state.title,
		image: [], // TODO: missing image from product ppl
	};

	const url = React.useMemo(
		() => `${Config.baseUrl}${location.pathname.replace(/^\//g, '')}`,
		[location.pathname]
	);

	React.useEffect(() => {
		const prefixes = new Set();
		if (state.openGraph) {
			prefixes.add('og: http://ogp.me/ns#');
			if (state.openGraph.type === OpenGraphItemType.product) {
				prefixes.add('product: http://ogp.me/ns/product#');
			} else if (state.openGraph.fbAdmins) {
				prefixes.add('fb: http://ogp.me/ns/fb#');
			}
		} else if (!state.twitterCard && !state.openGraph) {
			prefixes.add('og: http://ogp.me/ns#');
			prefixes.add('fb: http://ogp.me/ns/fb#');
		}
		if (prefixes.size) {
			document.head.setAttribute(
				PREFIX_ATTRIBUTE,
				Array.from(prefixes).join(' ')
			);
		} else {
			document.head.removeAttribute(PREFIX_ATTRIBUTE);
		}
	}, [state.openGraph, state.twitterCard]);

	return (
		<DocumentHeaderContext.Provider value={documentHeaderContextModel}>
			<Helmet
				htmlAttributes={{
					lang: localizationContext.activeLanguage,
				}}
			>
				<title>{state.title}</title>
				{state.description && (
					<meta
						name="description"
						content={state.description}
					/>
				)}
				<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
			</Helmet>
			{state.twitterCard && (
				<Helmet>
					<meta
						name="twitter:card"
						content={state.twitterCard.card || ''}
					/>
					<meta
						name="twitter:site"
						content={`@${(
							state.twitterCard.site ?? Config.market.socials.twitter
						).replace(/^@/g, '')}`}
					/>
					{!state.openGraph?.title && ( // Twitter parser will fallback to og:title
						<meta
							name="twitter:title"
							content={state.twitterCard.title || state.title}
						/>
					)}
					{state.twitterCard.description &&
						!state.openGraph?.description && ( // Twitter parser will fallback to og:description
							<meta
								name="twitter:description"
								content={state.twitterCard.description || ''}
							/>
						)}
					<meta
						name="twitter:image"
						content={state.twitterCard.image || DEFAULT_OG_IMAGE}
					/>
					{state.twitterCard.imageAlt &&
						!state.openGraph?.imageAlt && ( // Twitter parser will fallback to og:image:alt
							<meta
								name="twitter:image:alt"
								content={state.twitterCard.imageAlt || ''}
							/>
						)}
				</Helmet>
			)}
			{state.openGraph && (
				<Helmet>
					<meta
						property="og:type"
						content={state.openGraph.type || ''}
					/>
					<meta
						property="og:title"
						content={state.openGraph.title || state.title}
					/>
					<meta
						property="og:url"
						content={state.openGraph.url || url}
					/>
					<meta
						property="og:description"
						content={state.openGraph.description || state.description || ''}
					/>
					<meta
						property="og:image"
						content={state.openGraph.image || DEFAULT_OG_IMAGE}
					/>
					{state.openGraph.imageAlt && (
						<meta
							property="og:image:alt"
							content={state.openGraph.imageAlt || ''}
						/>
					)}
					{state.openGraph.type === OpenGraphItemType.product &&
						state.openGraph.productPriceAmount && (
							<meta
								property="product:price:amount"
								content={`${state.openGraph.productPriceAmount || ''}`}
							/>
						)}
					{state.openGraph.type === OpenGraphItemType.product &&
						state.openGraph.productPriceCurrency && (
							<meta
								property="product:price:currency"
								content={state.openGraph.productPriceCurrency || ''}
							/>
						)}
					{state.openGraph.type !== OpenGraphItemType.product &&
						state.openGraph.fbAdmins && (
							<meta
								property="fb:admins"
								content={state.openGraph.fbAdmins || ''}
							/>
						)}
				</Helmet>
			)}
			{
				// defaults for pages without specified values
				!state.twitterCard && !state.openGraph && (
					<Helmet>
						<meta
							name="twitter:card"
							content={TwitterCardType.summary}
						/>
						<meta
							name="twitter:site"
							content={`@${Config.market.socials.twitter.replace('@', '')}`}
						/>
						{/* for others, Twitter parser will fallback to og: equivalents */}
						<meta
							property="og:type"
							content={OpenGraphItemType.article}
						/>
						<meta
							property="og:title"
							content={state.title}
						/>
						<meta
							property="og:url"
							content={url}
						/>
						<meta
							property="og:image"
							content={DEFAULT_OG_IMAGE}
						/>
						<meta
							property="fb:admins"
							content={Config.market.socials.facebookAdmins}
						/>
						{state.description && (
							<meta
								property="og:description"
								content={state.description}
							/>
						)}
					</Helmet>
				)
			}
			{children}
		</DocumentHeaderContext.Provider>
	);
};

export default DocumentHeaderContextProvider;
