import * as React from 'react';

const usePrevious = <T,>(value: T): T => {
	const ref = React.useRef();
	React.useEffect(() => {
		ref.current = value as any;
	}, [value]);
	return ref.current as T;
};

export default usePrevious;
